import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {
  imgPasswordInVisible,
  imgPasswordVisible,
  macIcon,
  phoneIcon,
  windowIcon,
} from "./assets";
import React from "react";
import { getCountryCode } from "countries-list";
import {
  EstablishmentListErrorDetail,
  FormError,
  FormErrorTouched,
} from "./Cfentrepriseaccount.web";
import { defaultScroll, returnTruthyString } from "../../../components/src/HelperUtils";
import {  setStorageData } from "../../../framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";
interface ICountryModel {
  label: string;
  value: string;
}
interface IUpdateEmailModel {
  current_password: string;
  email: string;
  email_confirmation: string;
}
interface IUpdatePhoneModel {
  current_password: string;
  full_phone_number: string;
  old_phone_number: string;
  country_code: string;
  old_country_code: string;
}

interface IResetPasswordModel {
  current_password: string;
  password: string;
  confirm_password: string;
}
interface IGeneralInfoModel {
  full_name: string;
  tax_number: number;
  client_id: string;
  iban: string;
  company_name: string;
  address: string;
  zip_code: string;
  city: string;
  country: string;
  email: string;
  full_phone_number: string;
  country_code: string;
  faceBookLink: string;
  instagramLink: string;
  tikTokLink: string;
  linkedinLink: string;
}

interface INewEstablishmentModel {
  establishment_name: string;
  address: string;
  zip_code: string;
  city: string;
  country: string;
  email: string;
  full_phone_number: string;
  country_code: string;
  faceBookLink: string;
  instagramLink: string;
  tikTokLink: string;
  linkedinLink: string;
}
export interface EstablishmentAttributes {
  id: number;
  establishment_name: string;
  address: string;
  country: string;
  city: string;
  zipcode: string;
  email: string;
  phone_number: number;
  facebook_url: string;
  instagram_url: string;
  linkedin_url: string;
  tiktok_url: string;
  activated: false;
  created_at: string;
  updated_at: string;
  vandor_name: string;
  establishment_photo: string | null;
}
interface ListEstablishment {
  id: string;
  type: string;
  attributes: EstablishmentAttributes;
}
interface EstablishmentListApiResponse {
  data: ListEstablishment[];
  errors: EstablishmentListErrorDetail[];
  error: EstablishmentListErrorDetail[];
}

interface GeneralUserInfoModel {
  id: number;
  first_name: string;
  last_name: string;
  company_name: string | null;
  iban: string | null;
  tax_number: string;
  client_id: string | null;
  address: string;
  country: string;
  city: string;
  zipcode: string;
  email: string;
  full_phone_number: string;
  phone_number: number;
  country_code: string;
  user_name: string | null;
  tiktok_url: string | null;
  facebook_url: string | null;
  instagram_url: string | null;
  linkedin_url: string | null;
}

interface GeneralInfoUserApiResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      first_name: string;
      last_name: string;
      company_name: string | null;
      iban: string | null;
      tax_number: string;
      client_id: string | null;
      address: string;
      country: string;
      city: string;
      zipcode: string;
      email: string;
      full_phone_number: string;
      phone_number: number;
      country_code: string;
      user_name: string | null;
      tiktok_url: string | null;
      facebook_url: string | null;
      instagram_url: string | null;
      linkedin_url: string | null;
      vendor_image: string | null;
    };
  };
  errors?: EstablishmentListErrorDetail[];
  error: EstablishmentListErrorDetail[];
}

interface AddNewEstablishMentApiResponse {
  data: { id: string; type: string; attributes: EstablishmentAttributes };
  errors: EstablishmentListErrorDetail[];
  error: EstablishmentListErrorDetail[];
}

interface UpdatePhoneApiResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      activated: true;
      country_code: number;
      email: string;
      first_name: string;
      full_phone_number: string;
      last_name: number;
      phone_number: number;
      type: string;
      created_at: string;
      updated_at: string;
      device_id: number;
      unique_auth_id: string;
      nationality: string;
      address: string;
      zipcode: string;
      language: string;
      country: string;
      city: string;
      confirm_email: number;
      vendor_image: number;
    };
  };
  message?:string,
  errors: ErrorObject[];
}

interface ResendApiResponseModel {
  data: {
    id: string;
    type: string;
    attributes: {
      first_name: string;
      last_name: string;
      full_phone_number: string;
      country_code: string;
      phone_number: number;
      email: string;
      activated: boolean;
      country: string;
      city: string;
    };
  };
  meta: {
    token: string;
  };
  errors: ErrorObject[];
}

interface ErrorObject {
  token: string;
}

interface ResetPasswordResponseModel {
  message: string;
  errors: ErrorObject[];
}

interface IApiModel {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string | null;
  isJsonStringify?: boolean;
}
interface ILoggedInDevice {
  id: number;
  device_name: string;
  device_id: string;
  last_active: string;
  location: string;
  created_at: string;
  updated_at: string;
  name: string;
}
interface ILoggedInDeviceList {
  id: string;
  type: string;
  attributes: ILoggedInDevice;
}
interface ILoggedInDeviceListResponseModel {
  data: ILoggedInDeviceList[];
  errors: EstablishmentListErrorDetail[];
}

interface IDeviceEndSessionResponseModel {
  message: string;
  errors: ErrorObject[];
}

interface ISuggestionList {
  description: string;
}

interface ISuggestionAddressPredication {
  description: string;
  matched_substrings: {
    length: number;
    offset: number;
  }[];
  place_id: string;
  reference: string;
  structured_formatting: {
    main_text: string;
    main_text_matched_substrings: {
      length: number;
      offset: number;
    }[];
    secondary_text: string;
  };
  terms: {
    offset: number;
    value: string;
  }[];
  types: string[];
}

interface ISuggestionAPIResponse {
  message: string;
  predictions: ISuggestionAddressPredication[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  tabValue: number;
  countries: {
    label: string;
    value: string | number | undefined;
  }[];
  cities: {
    label: string;
    value: string | number | undefined;
  }[];
  isoCountryName: string | boolean;
  isGeneralInfoEdit: boolean;
  isEmailVerifyDialogOpen: boolean;
  isUpdateEmailDialogOpen: boolean;
  isResetPasswordDialogOpen: boolean;
  isUpdatePhoneDialogOpen: boolean;
  isEndSessionDialogOpen: boolean;
  timerSecond: number;
  searchText: string;
  statusType: string;
  updateEmailValue: IUpdateEmailModel;
  generalInfoForm: IGeneralInfoModel;
  newEstablishmentForm: INewEstablishmentModel;
  isShowAddNewEstablish: boolean;
  listEstablishment: EstablishmentListApiResponse;
  vendorEstablishmentListError: EstablishmentListErrorDetail[];
  vendorAddNewEstablishmentError: EstablishmentListErrorDetail[];
  selectedHeaderValue: string;
  currentMenuItem: string | null;
  dialogError: ErrorObject[] | string;
  generalInfoError: EstablishmentListErrorDetail[];
  generalInfoUserData: GeneralUserInfoModel;
  generalInfoFile: File | null;
  generalInfoFileUrl: string;
  endSessionDeviceInfo: { name: string; id: string };
  passwordAndSecurityError: EstablishmentListErrorDetail[];
  loggedInDeviceList: ILoggedInDeviceList[];
  addNewEstablishmentAddress: string;
  addGeneralInfoAddress: string;
  suggestionAddress: ISuggestionList[];
  suggestionAddressResponse: ISuggestionAPIResponse;
  newEstablishmentFile: File | null;
  newEstablishmentUrl: string;
  fileError:string;
  isLoadingApp: boolean;
  getEstablishmentListLoading:boolean;
  demoaddress:string;
  //LOADER
  getLoggedInListLoading:boolean;
  apiToken: string
  isEmailPhoneLoading: boolean
  //NAVIGATION MENU
  isProfileUpdate:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfentrepriseaccountController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  establishmentListApiCallId: string = "";
  addNewEstablishmentApiCallId: string = "";
  updatePhoneApiCallId: string = "";
  updateGeneralInfoEstablishmentApiCallId: string = "";
  updateEmailApiCallId: string = "";
  resendEmailVerifyApiCallId: string = "";
  generalInfoUserApiCallId: string = "";
  updateGeneralInfoApiCallId: string = "";
  resetPasswordApiCallId: string = "";
  loggedInDeviceListApiCallId: string = "";
  endDeviceEndSessionApiCallId: string = "";
  addressOfAddNewEstablishmentApiCallId: string = "";
  private debounceSearchTimeout: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      tabValue: 0,
      countries: [],
      cities: [],
      isoCountryName: "",
      isGeneralInfoEdit: false,
      isEmailVerifyDialogOpen: false,
      isUpdateEmailDialogOpen: false,
      isUpdatePhoneDialogOpen: false,
      isResetPasswordDialogOpen: false,
      isEndSessionDialogOpen: false,
      timerSecond: 0,
      searchText: "",
      statusType: "",
      updateEmailValue: {
        current_password: "",
        email: "",
        email_confirmation: "",
      },
      generalInfoForm: {
        full_name: "",
        tax_number: 0,
        client_id: "",
        iban: "",
        company_name: "",
        address: "",
        zip_code: "",
        city: "",
        country: "",
        email: "",
        full_phone_number: "",
        country_code: "",
        faceBookLink: "",
        instagramLink: "",
        tikTokLink: "",
        linkedinLink: "",
      },
      isShowAddNewEstablish: false,
      listEstablishment: { data: [], errors: [], error: [] },
      vendorEstablishmentListError: [],
      newEstablishmentForm: {
        establishment_name: "",
        address: "",
        zip_code: "",
        city: "",
        country: "",
        email: "",
        full_phone_number: "",
        country_code: "",
        faceBookLink: "",
        instagramLink: "",
        tikTokLink: "",
        linkedinLink: "",
      },
      vendorAddNewEstablishmentError: [],
      selectedHeaderValue: "Establishment",
      currentMenuItem: localStorage.getItem("selectedValueVendor") !== null ? localStorage.getItem("selectedValueVendor")  : "0",

      dialogError: "",
      generalInfoError: [],
      generalInfoUserData: {
        id: 0,
        first_name: "",
        last_name: "",
        company_name: null,
        iban: null,
        tax_number: "",
        client_id: null,
        address: "",
        country: "",
        city: "",
        zipcode: "",
        email: "",
        full_phone_number: "",
        phone_number: 0,
        country_code: "",
        user_name: null,
        tiktok_url: null,
        facebook_url: null,
        instagram_url: null,
        linkedin_url: null,
      },
      generalInfoFile: null,
      generalInfoFileUrl: "",
      endSessionDeviceInfo: { name: "", id: "" },
      passwordAndSecurityError: [],
      loggedInDeviceList: [],
      addNewEstablishmentAddress: "",
      addGeneralInfoAddress: "",
      suggestionAddress: [],
      suggestionAddressResponse: {} as ISuggestionAPIResponse,
      newEstablishmentFile:null,
      newEstablishmentUrl: "",
      fileError:"",
      // Customizable Area Start
      isLoadingApp: true,
      getEstablishmentListLoading:false,
      demoaddress:'',
      //LOADER
      getLoggedInListLoading:false,
      apiToken: returnTruthyString(localStorage.getItem("login_token")),
      isEmailPhoneLoading:false,
      //NAVIGATION MENU
      isProfileUpdate:false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson) {
      switch (apiRequestCallId) {
        case this.establishmentListApiCallId:
          this.handleEstablishmentListResponse(responseJson);
          break;
        case this.generalInfoUserApiCallId:
          this.handleGeneralInfoUserResponse(responseJson);
          break;
        case this.addNewEstablishmentApiCallId:
          this.handleAddNewEstablishmentResponse(responseJson);
          break;
        case this.updateGeneralInfoEstablishmentApiCallId:
          this.handleUpdateGeneralInfoEstablishmentResponse(responseJson);
          break;
        case this.updatePhoneApiCallId:
          this.handleUpdatePhoneResponse(responseJson);
          break;
        case this.updateEmailApiCallId:
          this.handleUpdateEmailResponse(responseJson);
          break;
        case this.resendEmailVerifyApiCallId:
          this.handleResendEmailVerifyResponse(responseJson);
          break;
        case this.updateGeneralInfoApiCallId:
          this.handleUpdateGeneralInfoApiResponse(responseJson);
          break;
        case this.resetPasswordApiCallId:
          this.handleResetPasswordResponse(responseJson);
          break;
        case this.loggedInDeviceListApiCallId:
          this.handleLoggedInDeviceListResponse(responseJson);
          break;
        case this.endDeviceEndSessionApiCallId:
          this.handleDeviceEndSessionResponse(responseJson);
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({ tabValue: Number(newValue) });

    if (Number(newValue) === 1) {
      this.loggedInDeviceListApi();
    }
  };

  renderTabStyle = (activeTab: number) => {
    return this.state.tabValue === activeTab
      ? {
          fontWeight: 700,
          color: "#6F05EA",
          fontSize: "16px",
          textTransform: "none",
          width: "100%",
        }
      : {
          fontWeight: 500,
          color: "#B7B7B7",
          fontSize: "16px",
          textTransform: "none",
          width: "100%",
        };
  };
  async componentDidMount() {
    super.componentDidMount();
    this.checkUserRole()
    defaultScroll()
    this.doEmailLogin();
    this.establishmentListApiCall();
    this.getUserGeneralInfo();
    this.getCountryListData();
    this.handleCountryNameChange(configJSON.defaultCountry);
    const headerSelectedValue = await localStorage.getItem("selectedValueVendor") ?  await localStorage.getItem("selectedValueVendor") : "0";
    this.setState({currentMenuItem:headerSelectedValue === "4" ? "0" : headerSelectedValue });
    if(this.state.tabValue === 1){
      this.loggedInDeviceListApi();
    }
  }
  checkUserRole = () => {
    const is_customer = returnTruthyString(localStorage.getItem('login_user_role')) == "customer"
    if (is_customer) {
      this.props.navigation?.navigate("Home")
    }
  }
  doEmailLogin = () => {
    const token = localStorage.getItem("login_token") || '';
    const userId = localStorage.getItem("login_user_id") || '';
    if(token && userId) {
      this.setState({ isLoadingApp: false });
    } else {
      this.goToLoginPage();
    }
  };
  goToLoginPage = () => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };
  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  getCountryListData = () => {
    const countriesWithCities: string[] =
      require("countries-cities").getCountries();
    const countryList: ICountryModel[] = countriesWithCities
      .filter((country: string) => {
        const cities: string[] = require("countries-cities").getCities(country);
        return cities && cities.length > 0;
      })
      .map((country: string) => ({
        label: country,
        value: country,
      }));
    this.setState({ countries: countryList });
  };
  handleCountryNameChange = (country: string) => {
    const cityList = require("countries-cities")
      .getCities(country)
      .map((city: string) => ({
        label: city,
        value: city,
      }));
    this.setState({
      cities: cityList,
      isoCountryName: getCountryCode(country || "Portugal"),
    });
  };
  getErrorAndHelperText = (
    field: string,
    errors: FormError,
    touched: FormErrorTouched
  ) => {
    const isError: boolean = Boolean(errors[field]) && Boolean(touched[field]);
    const errorText = isError ? errors[field] ?? "" : ""
    const helperText: string = this.transLanding(errorText);
    return { isError, helperText };
  };
  handleDialogClose = (dialogType: string) => {
    this.setState({ dialogError: "" });
    switch (dialogType) {
      case "EmailVerify":
        this.setState({ isEmailVerifyDialogOpen: false });
        break;
      case "UpdateEmail":
        this.setState({ isUpdateEmailDialogOpen: false });
        break;
      case "UpdatePhone":
        this.setState({ isUpdatePhoneDialogOpen: false });
        break;
      case "ResetPassword":
        this.setState({ isResetPasswordDialogOpen: false });
        break;
      case "EndSession":
        this.setState({ isEndSessionDialogOpen: false });
        break;
      default:
        break;
    }
  };
  handleTimer = () => {
    this.setState({
      timerSecond: 60,
    });
    let timer = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.timerSecond > 0) {
          return { ...prevState, timerSecond: prevState.timerSecond - 1 };
        } else {
          clearInterval(timer);
          return { ...prevState, isActive: false };
        }
      });
    }, 1000);
  };
  handleUpdateEmailForm = async (value: IUpdateEmailModel) => {
    this.setState({ updateEmailValue: value });
    this.handleTimer();
    let emailData = new FormData();
    emailData.append("current_password", value.current_password);
    emailData.append("email", value.email);
    emailData.append("confirm_email", value.email_confirmation);
    emailData.append("old_email",this.state.generalInfoForm.email);
    this.setState({isEmailPhoneLoading:true})
    this.updateEmailApiCallId = await this.apiCall({
      method: configJSON.apiMethodPut,
      endPoint: configJSON.updateEmailEndPoint,
      token: this.state.apiToken,
      body: emailData,
      isJsonStringify: false,
    });
  };
  handleUpdatePhoneForm = async (value: IUpdatePhoneModel) => {
    let phoneData = new FormData();
    phoneData.append("country_code",value.country_code)
    phoneData.append("full_phone_number",`${value.country_code}${value.full_phone_number}`)
    phoneData.append("new_phone_number",value.full_phone_number)
    phoneData.append("confirm_new_phone_number",value.full_phone_number)
    phoneData.append("current_password",value.current_password)
    this.setState({isEmailPhoneLoading:true})
    this.updatePhoneApiCallId = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.updatePhoneEndPoint,
      token: this.state.apiToken,
      body: phoneData,
      isJsonStringify: false,
    });
  };
  handleNewEstablishClick = () => {
    this.setState({ isShowAddNewEstablish: true, addGeneralInfoAddress: "", suggestionAddress: [] });
  };
  handleNewEstablishCancelClick = () => {
    this.setState({
      isShowAddNewEstablish: false,
      vendorAddNewEstablishmentError: [],
      fileError: "",
      newEstablishmentFile: null,
      newEstablishmentUrl: "",
    });
  };
  apiCall = async (data: IApiModel) => {
    const { contentType, method, endPoint, body, token, isJsonStringify } =
      data;
    let header;
    if (token) {
      header = {
        "Content-Type": contentType,
        token: token,
      };
    } else {
      header = {
        "Content-Type": contentType,
      };
    }
    const requestedMessaged = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestedMessaged.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestedMessaged.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestedMessaged.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestedMessaged.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestedMessaged.id, requestedMessaged);
    return requestedMessaged.messageId;
  };

  establishmentListApiCall = async () => {
    this.setState({getEstablishmentListLoading:true});
    this.establishmentListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      token: this.state.apiToken,
      endPoint: configJSON.establishmentListApiEndPoint,
    });
  };

  getUserGeneralInfo = async () => {
    const userId = localStorage.getItem("login_user_id");
    this.generalInfoUserApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      endPoint: `${configJSON.generalInfoEndPoint}/${userId}`,
      method: configJSON.validationApiMethodType,
      token: this.state.apiToken,
    });
  };

  addNewEstablishmentApiCall = async (values: INewEstablishmentModel) => {
    
    let formData = new FormData();
    formData.append(
      "establishment[establishment_name]",
      values.establishment_name
    );
    formData.append("establishment[address]", values.address);
    formData.append("establishment[zipcode]", values.zip_code);
    formData.append("establishment[city]", values.city);
    formData.append("establishment[country]", values.country);
    formData.append("establishment[email]", values.email);
    formData.append("establishment[phone_number]", values.country_code+values.full_phone_number);
    formData.append("establishment[linkedin_url]", values.linkedinLink);
    formData.append("establishment[facebook_url]", values.faceBookLink);
    formData.append("establishment[instagram_url]", values.instagramLink);
    formData.append("establishment[tiktok_url]", values.tikTokLink);
    if (this.state.newEstablishmentFile) {
      formData.append(
        "establishment[establishment_photo]",
        this.state.newEstablishmentFile
      );
    }
    this.addNewEstablishmentApiCallId = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.establishmentListApiEndPoint,
      token: this.state.apiToken,
      body: formData,
      isJsonStringify: false,
    });
  };

  handleEstablishmentListResponse = (
    responseJson: EstablishmentListApiResponse
  ) => {
    this.setState({getEstablishmentListLoading:false})
    if (responseJson.data) {
      this.setState({ listEstablishment: responseJson, newEstablishmentUrl:"" });
    } else if (responseJson?.errors?.some((error) => error.token)) {
      this.goToLoginPage();
    } else {
      this.setState({ vendorEstablishmentListError: responseJson.error });
    }
  };
  NavigateToCustomisableVendorProfile=(establishmentInfo:any)=>{
    const infoObecjt=JSON.stringify(establishmentInfo);
    const EstablishmentId=(establishmentInfo.id);
    localStorage.setItem("establishmentInfo",infoObecjt);
    localStorage.setItem("establishment_ID",EstablishmentId);    
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "CustomisableUserProfilesVender"
    );
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };
  handleGeneralInfoUserResponse = (
    responseJson: GeneralInfoUserApiResponse
  ) => {
    if (responseJson.errors) {
      if (responseJson.errors.some((error) => error.token)) {
        this.goToLoginPage();
      } else {
        this.setState({ generalInfoError: responseJson.error });
      }
    } else {
      const responseData = responseJson.data.attributes;
      this.setState({ generalInfoFileUrl: responseData.vendor_image || "" });
      this.setState({
       
      }, () => {
          this.setState({
            generalInfoForm: {
              full_name: responseData.first_name,
              tax_number: isNaN(+responseData.tax_number)  ? "-" as unknown as number : +responseData.tax_number ,
              client_id: responseData.client_id || "",
              address: responseData.address,
              company_name: responseData.company_name || "-",
              zip_code: responseData.zipcode,
              iban: responseData.iban || "",
              city: responseData.city,
              country: responseData.country,
              email: responseData.email,
              full_phone_number: returnTruthyString(responseData.phone_number),
              country_code: responseData.country_code,
              faceBookLink: responseData.facebook_url || "",
              tikTokLink: responseData.tiktok_url || "",
              instagramLink: responseData.instagram_url || "",
              linkedinLink: responseData.linkedin_url || "",
            },
            demoaddress:responseData.address
          });
          })


     
      this.handleCountryNameChange(responseJson.data.attributes.country);
    }
  };

  handleAddNewEstablishmentResponse = (
    responseJson: AddNewEstablishMentApiResponse
  ) => {
    if (responseJson.data) {
      this.establishmentListApiCall();
      this.setState({
        isShowAddNewEstablish: false,
        addNewEstablishmentAddress: "",
        addGeneralInfoAddress: "",
        newEstablishmentUrl: responseJson.data.attributes.establishment_photo || ""
      });
    } else if (responseJson.errors.some((error) => error.token)) {
      this.goToLoginPage();
    } else {
      this.setState({ vendorAddNewEstablishmentError: responseJson.error });
    }
  };
  handleGeneralInfoForm = async (value: IGeneralInfoModel) => {
    this.setState({ generalInfoForm: value });
    const generalInfoUserId = localStorage.getItem("login_user_id");
    let generalInfoForm = new FormData();
    generalInfoForm.append("data[first_name]", value.full_name);
    generalInfoForm.append("data[company_name]", value.company_name);
    generalInfoForm.append("data[tax_number]", value?.tax_number.toString());
    generalInfoForm.append("data[address]", value.address);
    generalInfoForm.append("data[country]", value.country);
    generalInfoForm.append("data[zipcode]", value?.zip_code.toString());
    generalInfoForm.append("data[facebook_url]", value.faceBookLink);
    generalInfoForm.append("data[city]", value.city);
    generalInfoForm.append("data[tiktok_url]", value.tikTokLink);
    generalInfoForm.append("data[client_id]", value.client_id);
    generalInfoForm.append("data[instagram_url]", value.instagramLink);
    generalInfoForm.append("data[linkedin_url]", value.linkedinLink);
    generalInfoForm.append("data[iban]", value.iban);
    if (this.state.generalInfoFile) {
      generalInfoForm.append(
        "data[vendor_image]",
        this.state.generalInfoFile
      );
    }
    this.updateGeneralInfoApiCallId = await this.apiCall({
      method: configJSON.apiMethodPut,
      token: this.state.apiToken,
      endPoint: `${configJSON.generalInfoEndPoint}/${generalInfoUserId}`,
      isJsonStringify: false,
      body: generalInfoForm,
    });
  };
  handleUpdateEmailClick = () => {
    this.setState({
      isUpdatePhoneDialogOpen: false,
      isUpdateEmailDialogOpen: true,
    });
  };
  handleUpdatePhoneClick = () => {
    this.setState({
      isUpdatePhoneDialogOpen: true,
      isUpdateEmailDialogOpen: false,
    });
  };
  handleGeneralEditClick = () => {
    this.setState({ isGeneralInfoEdit: true});

  };
  handleGeneralCancelClick = () => {
    this.setState({ isGeneralInfoEdit: false, generalInfoError: [], addGeneralInfoAddress:"" });
  };
  handleSwitchChange = (establishmentId: number, activated: boolean) => {
    this.updateEstablishmentApiCall(establishmentId, activated);
  };
  handleSearch = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({ searchText: event.target.value });
  };
  handleStatusChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    this.setState({ statusType: event.target.value as string });
  };
  handleGeneralInfoFile = (image: React.ChangeEvent<HTMLInputElement>) => {
    const files = image.target.files;
    this.setState({fileError:""})
    if (files) {
      const file = files[0];
      const fileSize = file.size / (1024 * 1024);
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      const fileType = file.type;


      if (fileSize <= 5 && allowedTypes.includes(fileType)) {
        this.setState({
          generalInfoFile: file,
          generalInfoFileUrl: URL.createObjectURL(file),
          fileError:""
        });
      } else {
        this.setState({fileError:"File must be a PNG, JPEG, or JPG and less than 5 MB."})
      }
  }
    
  };
  goToForgotPasswordPage = () => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ForgotPassword"
    );
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };
  handleSelectHeaderChange = (value: string) => {
    this.setState({ selectedHeaderValue: value });
  };
  updateEstablishmentApiCall = async (
    establishmentId: number,
    activated: boolean
  ) => {
    let establishmentFormData = new FormData();
    establishmentFormData.append(
      "establishment[activated]",
      !activated ? "true" : "false"
    );
    this.updateGeneralInfoEstablishmentApiCallId = await this.apiCall({
      method: configJSON.apiMethodPut,
      endPoint: `${configJSON.establishmentListApiEndPoint}/${establishmentId}`,
      token: this.state.apiToken,
      body: establishmentFormData,
      isJsonStringify: false,
    });
  };

  resendEmailApiCall = async () => {
    this.resendEmailVerifyApiCallId = await this.apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.resendEmailEndPoint,
      token: this.state.apiToken,
    });
  };

  handleUpdateGeneralInfoEstablishmentResponse = (
    responseJson: AddNewEstablishMentApiResponse
  ) => {
    if (responseJson.data) {
      this.establishmentListApiCall();
    } else if (responseJson.errors.some((error) => error.token)) {
      this.goToLoginPage();
    } else {
      this.setState({ vendorAddNewEstablishmentError: responseJson.error });
    }
  };
  handleUpdatePhoneResponse = (responseJson: UpdatePhoneApiResponse) => {
    this.setState({isEmailPhoneLoading:false, dialogError:""})
    if (responseJson.data) {
      this.setState({ isUpdatePhoneDialogOpen: false, isGeneralInfoEdit:false}, () => {
        this.getUserGeneralInfo();
      });
    } else {
      this.setState({
        dialogError: responseJson.errors[0].token
          ? responseJson.errors[0].token
          : responseJson.errors,
      });
    }
  };
  handleUpdateEmailResponse = (responseJson: UpdatePhoneApiResponse) => {
    this.setState({isEmailPhoneLoading:false, dialogError:""})
    if (responseJson.message?.includes("Email updated successfully")) {
      this.setState({
        isUpdateEmailDialogOpen: false,
        isEmailVerifyDialogOpen: true,
      });
      this.getUserGeneralInfo();
    } else {
      this.setState({
        dialogError: responseJson.errors[0].token
          ? responseJson.errors[0].token
          : responseJson.errors,
      });
    }
  };

  handleResendEmailVerifyResponse = (responseJson: ResendApiResponseModel) => {
    if (responseJson.errors) {
      this.setState({
        dialogError: responseJson.errors[0].token
          ? responseJson.errors[0].token
          : responseJson.errors,
      });
    } else {
      this.getUserGeneralInfo();
    }
  };

  handleUpdateGeneralInfoApiResponse = (
    responseJson: GeneralInfoUserApiResponse
  ) => {
    if (responseJson.errors) {
      if (responseJson.errors.some((error) => error.token)) {
        this.goToLoginPage();
      }
    } else if (responseJson.error) {
      this.setState({ generalInfoError: responseJson.error });
    } else {
      this.setState({
        isGeneralInfoEdit: false,
        addNewEstablishmentAddress: "",
        addGeneralInfoAddress: "",
      });
      setStorageData("login_user_profile", responseJson.data.attributes.vendor_image);
      this.getUserGeneralInfo();
      this.setState({isGeneralInfoEdit: false,currentMenuItem:'1'});
      localStorage.setItem("selectedValueVendor",'1');
      this.setState(prevState => ({isProfileUpdate: !prevState.isProfileUpdate}))
    }
  };

  handleResetPasswordClick = () => {
    this.setState({ isResetPasswordDialogOpen: true });
  };

  handleResetPasswordForm = async (values: IResetPasswordModel) => {
    this.setState({ dialogError: [] });
    let resetPassword = new FormData();
    resetPassword.append("current_password", values.current_password);
    resetPassword.append("password", values.password);
    resetPassword.append("password_confirmation", values.confirm_password);
    this.resetPasswordApiCallId = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      token: this.state.apiToken,
      endPoint: configJSON.changePasswordEndPoint,
      body: resetPassword,
      isJsonStringify: false,
    });
  };
  handleEndSessionClick = (deviceName: string, deviceId: string) => {
    this.setState({
      isEndSessionDialogOpen: true,
      endSessionDeviceInfo: { name: deviceName, id: deviceId },
    });
  };
  handleEndSessionCancelClick = () => {
    this.setState({ isEndSessionDialogOpen: false });
  };
  handleResetPasswordResponse = (responseJson: ResetPasswordResponseModel) => {
    if (responseJson.message) {
      this.setState({ isResetPasswordDialogOpen: false });
    } else {
      this.setState({
        dialogError: responseJson.errors[0].token
          ? responseJson.errors[0].token
          : responseJson.errors,
      });
    }
  };
  loggedInDeviceListApi = async () => {
    this.setState({getLoggedInListLoading:true})
    this.loggedInDeviceListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.loggedInDeviceEndPoint,
      token: this.state.apiToken,
    });
  };
  handleLoggedInDeviceListResponse = (
    responseJson: ILoggedInDeviceListResponseModel
  ) => {
    this.setState({getLoggedInListLoading:false})
    if (responseJson.data) {
      this.setState({ loggedInDeviceList: responseJson.data });
    } else if (responseJson.errors.some((error) => error.token)) {
      this.goToLoginPage();
    } else {
      this.setState({ passwordAndSecurityError: responseJson.errors });
    }
  };

  getSortedSessionsList = () => {
    const currentUUID = localStorage.getItem('device_uuid') || '';
    const list = [...this.state.loggedInDeviceList];
    const index = list.findIndex(item => item.attributes.device_id === currentUUID);
    if (index > -1) {
      list.unshift(list.splice(index, 1)[0]);
    }

    return list;
  }

  getIconSrc = (os: string) => {
    switch (os) {
      case "Windows":
      case "Linux":
        return windowIcon;
      case "MacOS":
        return macIcon;
      case "Android":
      case "iOS":
        return phoneIcon;
    }
  };
  handelDialogEndSession = async (deviceId: string) => {
    let endSessionData = new FormData();
    endSessionData.append("sessions[device_id]", deviceId);
    this.endDeviceEndSessionApiCallId = await this.apiCall({
      method: configJSON.deleteAPiMethod,
      endPoint: configJSON.endDeviceSessionEndPoint,
      token: this.state.apiToken,
      body: endSessionData,
      isJsonStringify: false,
    });
  };
  handleDeviceEndSessionResponse = (
    responseJson: IDeviceEndSessionResponseModel
  ) => {
    if (responseJson.message) {
      this.setState({ isEndSessionDialogOpen: false });
      this.loggedInDeviceListApi();
    } else
      this.setState({
        dialogError: responseJson.errors[0].token
          ? responseJson.errors[0].token
          : responseJson.errors,
      });
  };

  handleAddNewEstablishmentAddressChange = (value: string) => {
    this.setState({ addNewEstablishmentAddress: value });
  };
  handleCustomerHeaderSelect = (selectedValue:string) => {
    this.setState(prevState => ({
      currentMenuItem: selectedValue === "4" ? prevState.currentMenuItem : selectedValue}));
    this.setState({isGeneralInfoEdit:false,isShowAddNewEstablish:false,
      generalInfoForm: {
        ...this.state.generalInfoForm,  // Spread the existing fields
        address: this.state.demoaddress  // Only update the address field
      }
    })
    this.getUserGeneralInfo();
  }
  componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.currentMenuItem === '1' && prevState.currentMenuItem !== '1') {
      this.debounceTimeSearch(
        async () => {
          this.getUserGeneralInfo();        
        },
        500
      )();
    }
    if (this.state.tabValue === 0 && prevState.tabValue !== 0) {
      this.debounceTimeSearch(
        async () => {
          this.getUserGeneralInfo();        
        },
        500
      )();
    }
  }
  handleGeneralInfoAddressChange = (addressValue: string) => {

    const addressParts = addressValue.split(',').map(part => part.trim());

    const country = addressParts[addressParts.length - 1];
    const city = addressParts[addressParts.length - 2];

    this.setState({
      addGeneralInfoAddress: addressValue,
      generalInfoForm: {
        ...this.state.generalInfoForm,
        address: addressValue,
        city: city,
        country: country,
      }
    });

  };

  setAddressList = (predication: ISuggestionAddressPredication[]) => {
    return predication.map((item) => {
      return { description: item.description };
    });
  };

  getOptionForSuggestionAddress = () => {
    return this.state.suggestionAddress.map((option) => option.description);
  };

  debounceTimeSearch(func: (...args: any[]) => void, delay: number) {
    return (...args: any[]) => {
      if (this.debounceSearchTimeout) {
        clearTimeout(this.debounceSearchTimeout);
      }
      this.debounceSearchTimeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  handleNewEstablishmentAddressBlur = () => {
    if(this.state.addNewEstablishmentAddress === ""){
      this.setState({suggestionAddress:[]})
    }
  }
  handleAddNewEstablishmentFile = (image: React.ChangeEvent<HTMLInputElement>) => {
    const files = image.target.files;
    this.setState({fileError:""});
    if (files) {
      const file = files[0];
      const fileSize = file.size / (1024 * 1024);
      const fileType = file.type;

      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

      if (fileSize <= 5 && allowedTypes.includes(fileType)) {
        this.setState({
          newEstablishmentFile: files[0],
          newEstablishmentUrl: URL.createObjectURL(files[0]),
          fileError:""
        });
      } else {
        this.setState({fileError:"File must be a PNG, JPEG, or JPG and less than 5 MB."})
      }
    }
  };
  handleGeneralInfoLinkClick = (link : string) => {
    if(link && !this.state.isGeneralInfoEdit){
      window.open(link,'_blank')
    }
  }
  openListOfEstablishment = () => {
    this.setState({
      isShowAddNewEstablish: false,
      currentMenuItem: "0"
    })
    localStorage.setItem("selectedValueVendor", "4")
    defaultScroll()
  }

  transLanding = (cfKey: string) => {
    return i18n.t(cfKey, { ns: "vendorLandingPage" });
  }
  // Customizable Area End
}
