import React,{useState} from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Menu,
  MenuItem,
  Popover,
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { SimpleDialog } from "../../../components/src/HelperComponents";
import { userProfile, headerLogo, languageIcon, headerLogoutIcon, headerEstablishmentSelectIcon, managementIcon, headerEstablishmentSelectIconselected, managementIconselected, chatIcon } from "./assets";
import { LanguageCtx } from "../../../components/src/context/LanguageContext";
const isShowLangIcon = false; 

const BoxStyle = styled(Box)(({ theme }) => ({
  padding: "42px 152px",
  display: "flex",
  flexDirection: "row",
  background: "linear-gradient(to right, #6928F2, #884FFF)",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    padding: "22px 100px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "22px 60px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "14px 0px",
    justifyContent: "space-around",
  },
  [theme.breakpoints.down("xs")]: {
    padding: "12px 42px",
    justifyContent: "space-between",
  },
  "& .Header-container": {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  "& .Header-Name": {
    display: "flex",
    gap: "8px",
  },
  "& .Header-language": {
    fontSize: "20px",
    fontWeight: 700,
    color: "#FFFFFF",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "17px",
    },
  },
  "& .Header-Register": {
    color: "#FFFFFF",
    fontSize: "20px",
    padding: "9.69px 40.36px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    "& .MuiTypography-colorPrimary": {
      color: "#FFFFFF",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "17px",
    },
  },
  "& .Header-btn-container": {
    display: "flex",
    gap: "20px",
  },
  "& .Header-Btn": {
    borderRadius: "35px",
    padding: "10px 45px",
    backgroundColor: "#FFFFFF",
    color: "#6200EA",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: 700,
    [theme.breakpoints.down("lg")]: {
      padding: "0px 35px",
      display: "flex",
      alignItems: "center",
      fontSize: "15px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px 35px",
      display: "flex",
      alignItems: "center",
      fontSize: "15px",
    },
  },
  "& .MuiIconButton-root": {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      color: "#FFFFFF",
    },
  },
  "& .Header-menu": {
    display: "flex",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
      gap: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  "& .header-logo": {
    cursor: 'pointer',
    [theme.breakpoints.down("lg")]: {
      height: "30px",
    },
    [theme.breakpoints.down("md")]: {
      height: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "20px",
    },
  },
}));
const StylesBoxLoggedIn = styled(Box)(({ theme }) => ({
  "& .header-profile-name": {
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  "& .header-pts": {
    backgroundColor: "#FFCE00",
    padding: "10px 36px",
    borderRadius: "35px",
    fontWeight: "600",
    fontSize: "14px",
    [theme.breakpoints.down(700)]: {
      padding: "10px 16px",
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 16px",
      fontSize: "12px",
    },
    [theme.breakpoints.down(450)]: {
      display: "none",
    },
  },
  "& .header-logo": {
    cursor: 'pointer',
    [theme.breakpoints.down("lg")]: {
      height: "30px",
    },
    [theme.breakpoints.down("md")]: {
      height: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "20px",
    },
  },

  "& .header-user": {
    display: "flex",
    alignItems: "center",
    gap: "30px",
    [theme.breakpoints.down(700)]: {
      gap: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      gap: "20px",
    },
    [theme.breakpoints.down(450)]: {
      gap: "10px",
    },
  },
  "& .user-profile-img": {
    width: "42px",
    height: "42px",
    borderRadius: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "36px",
      height: "36px",
    },
  },
  "& .Header-language": {
    fontSize: "20px !important",
    fontWeight: 700,
    color: "#FFFFFF",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "17px !important",
    },
  },
  "& .container": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 125px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 85px !important",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px 70px !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 5px !important",
    },
  },
}));

const PopoverContainer = styled(Popover)(({ theme }) => ({
  marginTop: "96px",
  "& .MuiPopover-paper": {
    top: "0 !important",
    marginLeft: "-57px !important",
    [theme.breakpoints.down("md")]: {
      marginLeft: "-2px !important",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "-80px !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px !important",
    },
  },
  "& .MuiMenuItem-root": {
    padding: "8px 30px",
    color: "#000",
    fontSize: "14px",
    fontWeight: "600",
  },
  "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
    background: "linear-gradient(to right,#6928F2, #884FFF)",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
  },
}));

// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  static contextType = LanguageCtx;

  renderLogoutDialog = () => {
    return (
      <SimpleDialog
        open={this.state.isLoggedOutOpen}
        message={this.transNav("You're Logging Out of Petzai, are you sure?")}
        showRightBtn={true}
        showLeftBtn={true}
        leftBtnTitle={this.transNav("Yes, I'm Sure")}
        rightBtnTitle={this.transNav("No, keep me Logged In")}
        handleRightClick={this.handleCancelLogout}
        handleLeftClick={this.handleSureLogout}
        data-test-id="logout-dialog"
      />
    )
  }
  getUnreadCount() {
    const unreadString = localStorage.getItem('total_unread_count');
    try {
      return unreadString ? JSON.parse(unreadString) : 0;
    } catch (error) {
      return 0; // Default to 0 in case of a parsing error
    }
  }
   // Function to determine the management icon source
   getManagementIconSrc = () => {
    const { selectMenuForLoggedInUser } = this.state;
    return selectMenuForLoggedInUser === "Management" ? managementIconselected : managementIcon;
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const open = Boolean(this.state.anchorEl);
    const unread = this.getUnreadCount()
    return (
      <>
        {this.state.isLoggedIn !== "true" ? (
          <BoxStyle>
            <Box>
              <img data-test-id="headerlogo" onClick={() => this.gotoHome} className="header-logo" src={headerLogo} alt="Logo" />
            </Box>
            <Box className="Header-container">
              <Box className="Header-Name">
                <img src={languageIcon} alt="Language Icon" />
                <Typography className="Header-language">EN</Typography>
              </Box>
              <Box className="Header-menu">
                <Typography className="Header-Register">
                  <Box data-test-id="registerbusiness" onClick={() => this.gotoVendorSignUp()}>
                    {this.transNav("Register your business")}
                  </Box>
                </Typography>
                <Box className="Header-btn-container">
                  <Box data-test-id="signup" className="Header-Btn" onClick={() => this.gotoSignUp()}>
                    {this.transNav("Sign up")}
                  </Box>
                  <Box data-test-id="login" className="Header-Btn" onClick={() => this.gotoLogin()}>
                    {this.transNav("Login")}
                  </Box>
                </Box>
              </Box>
              <IconButton
                data-test-id="openmenu"
                aria-controls={open ? "header-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={this.handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="header-menu"
                data-test-id="closemenu"
                anchorEl={this.state.anchorEl}
                open={open}
                onClose={this.handleClose}
                keepMounted
              >
                <MenuItem onClick={this.handleClose}>
                  <Box data-test-id="registerbusiness2" onClick={() => this.gotoVendorSignUp()}>
                    {this.transNav("Register your business")}
                  </Box>
                </MenuItem>
                <MenuItem onClick={this.handleClose}>
                  <Box data-test-id="signup2" onClick={() => this.gotoSignUp}>
                    {this.transNav("Sign up")}
                  </Box>
                </MenuItem>
                <MenuItem onClick={this.handleClose}>
                  <Box data-test-id="login2" onClick={() => { this.gotoLogin() }}>
                    {this.transNav("Login")}
                  </Box>
                </MenuItem>
              </Menu>
            </Box>
          </BoxStyle>
        ) : (
          <StylesBoxLoggedIn style={styles.header.wrapper}>
            <Container maxWidth="xl" className="container">
              <Box>
                <img data-test-id="headerlogo3" onClick={() => { this.gotoHome() }} className="header-logo" src={headerLogo} />
              </Box>
              <Box className="header-user">

                <Box>
                  <Box
                    data-test-id="userprofile"
                    style={styles.header.profile}
                  >
                    <Box
                      style={styles.header.popover}
                      onClick={this.handleMenuOpen}
                    >
                      <img
                        src={this.state.userProfile}
                        alt=""
                        className="user-profile-img"
                      />
                      <Typography
                        className="header-profile-name">
                        {this.state.userName}
                      </Typography>
                    </Box>
                    <PopoverContainer
                      open={open}
                      onClose={this.handleClose}
                      anchorEl={this.state.anchorEl}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: 55,
                      }}
                    >
                      <MenuItem
                        data-test-id="establishment"
                        onClick={() =>
                          this.handleSelectForLoggedInUser("Establishment")
                        }
                        selected={
                          this.state.selectMenuForLoggedInUser === "Establishment" ? true : false
                        }
                      >
                        <ListItemIcon>
                          <img
                            src={
                              this.state.selectMenuForLoggedInUser === "Establishment" ? headerEstablishmentSelectIconselected : headerEstablishmentSelectIcon
                            }
                            alt="document"
                            className="header-menu-img"
                            style={{height:31, width:31}}
                          />
                        </ListItemIcon>
                        <Typography variant="inherit">{this.transNav("Establishment")}</Typography>
                      </MenuItem>
                      <MenuItem
                        data-test-id="management"
                        onClick={() =>
                          this.handleSelectForLoggedInUser("Management")
                        }
                        selected={
                          this.state.selectMenuForLoggedInUser === "Management" ? true : false
                        }
                      >
                        <ListItemIcon>
                          <img
                            src={this.getManagementIconSrc()}
                            alt="management"
                            className="header-menu-img"
                            style={{height:31, width:31}}
                          />
                        </ListItemIcon>
                        <Typography variant="inherit">{this.transNav("Management")}</Typography>
                      </MenuItem>

                      <MenuItem data-test-id="logout" onClick={() => this.handleSelectForLoggedInUser("Logout")}>
                        <ListItemIcon>
                          <img
                            src={headerLogoutIcon}
                            alt="Logout"
                            className="header-menu-img"
                            style={{height:31, width:31}}
                          />
                        </ListItemIcon>
                        <Typography variant="inherit">{this.transNav("Logout")}</Typography>
                      </MenuItem>
                    </PopoverContainer>
                  </Box>

                </Box>
                {this.props.isChat && (
                  <Box data-test-id="Chatid" onClick={() => this.gotoChat()} style={{ position: "relative" }}>
                    <img
                      src={chatIcon}
                      alt="Chat Icon"
                      style={{ cursor: "pointer" }}
                    />
                    {unread > 0 && (
                      <span style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        background: "red",
                        color: "white",
                        borderRadius: "50%",
                        fontSize: "10px",
                        fontWeight: "bold",
                        width: '21px',
                        height: '21px',
                        display: ' flex',
                        alignContent: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                        {unread}
                      </span>
                    )}
                  </Box>
                )}
                 {isShowLangIcon && <div
                  style={{
                    display: 'inline-block',
                    position: 'relative',
                    cursor: 'pointer',
         
                    borderRadius: '5px',
                    padding: '10px 20px',
                   
                  }}
                  data-test-id="languagedropdown"
                  onClick={() => this.setDropdownOpen()}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={this.state.selectLang[this.context.language]?.image}
                      alt={`flag`}
                      style={{ width: '20px', height: '20px', marginRight: '10px' }}
                    />
                    <span>{this.context.language?.toUpperCase()}</span>
                  </div>
                  {this.state.dropdownOpen && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '100%',
                        left: '0',
                        width: '100%',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        background: '#fff',
                        zIndex: 1000,
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
                      }}
                    >
                      {Object.keys(this.state.selectLang).map((lang,index) => (
                        <div
                        data-test-id={`lang-${index}`}
                          key={lang}
                          onClick={() => this.handleSelectLanguage(lang)}
                          style={{
                            padding: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            borderBottom: '1px solid #f0f0f0',
                            background: this.context.language === lang ? '#f0f0f0' : '#fff'
                          }}
                        >
                          <img
                            src={this.state.selectLang[lang].image}
                            alt={`${lang} flag`}
                            style={{ width: '20px', height: '20px', marginRight: '10px' }}
                          />
                          <span>{lang.toUpperCase()}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>}

              </Box>
              
            </Container>
            {this.state.isLoggedOutOpen && this.renderLogoutDialog()}
          </StylesBoxLoggedIn>
        )}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const styles = {
  header: {
    wrapper: {
      minHeight: "127px",
      paddingLeft: "16px",
      paddingRight: "16px",
      display: "flex",
      alignItems: "center",
      background: "linear-gradient(to right,#6928F2, #884FFF)",
    },
    user: {
      display: "flex",
      alignItems: "center",
      gap: "30px",
    },
    profile: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      color: "#fff",
    },
    languageContainer: {
      display: "flex",
      gap: "8px",
    },
    languageName: {
      fontSize: "20px",
      fontWeight: 700,
      color: "#FFFFFF",
    },
    popover: {
      display: "flex",
      gap: "20px",
      cursor: "pointer",
    }
  },
};
const webStyle = {
  userProfileWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
  },
  userProfile: {
    width: 100,
  },
  userDesignation: {
    fontSize: 14,
    color: "#ccc",
  },
  logout: {
    color: "#2196F3",
    marginTop: 15,
    marginBottom: 5,
  },
  drawerItemIcon: {
    width: 20,
    selfAlign: "center",
    marginLeft: 7,
  },
};
// Customizable Area End
