import React from "react";
// Customizable Area Start
import ViewPetController, { Props } from "./ViewPetController.web";
import NavigationMenu from "../../../navigationmenu/src/NavigationMenu.web";
import { AppFooter } from "../../../../components/src/AppFooter/appFooter";
import { Avatar, Box, IconButton, styled, Typography, Tabs, Tab, TextField, Badge, CircularProgress } from "@material-ui/core";
import { CheckedSwitch, PawBlue, UnCheckedSwitch } from "../assets";
import { KeyboardArrowLeft } from "@material-ui/icons";
import PhoneInput from "react-phone-input-2";
import { checkCondition, returnTruthyString } from "../../../../components/src/HelperUtils";
const webConfigJSON = require("../config.js");

interface ITraits {
    left: string,
    right: string,
    key: string,
    value: string,
}

export interface ITraitsAttr {
    id: number,
    calm: string,
    relaxed: string,
    easy_going: string,
    friendly: string | null,
    gentle: string | null,
    obedient: string | null,
    walks: number,
    walking_schedule: string,
    potty: number,
    potty_schedule: string,
    meal: number,
    meal_schedule: string,
    created_at: string,
    updated_at: string,
    animal_type: string
}

interface AttributeItem {
    left: string;
    right: string;
    key: string;
    value: string;
}

const formatData = (data: string | null | undefined): string => {
    return data ? data : "-";
}

const attributeMapping: Record<string, { left: string; right: string }> = {
    calm: { left: "Calm", right: "Energetic" },
    relaxed: { left: "Relaxed", right: "Anxious" },
    easy_going: { left: "Easy-going", right: "Shy" },
    friendly: { left: "Friendly", right: "Aggressive" },
    gentle: { left: "Gentle", right: "Strong" },
    obedient: { left: "Obedient", right: "Rebbelious" },
};

const transformAttributes = (traitsInfo: ITraitsAttr): AttributeItem[] => {
    return Object.keys(attributeMapping).map((key) => ({
        left: attributeMapping[key].left,
        right: attributeMapping[key].right,
        key: key,
        value: (traitsInfo as unknown as Record<string, string | null>)[key] ?? "",
    }));
};
// Customizable Area End

export default class ViewPet extends ViewPetController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderLoader = () => {
        return (
            <LoaderContainer>
                <CustomLoader />
            </LoaderContainer>
        )
    }

    renderPhoneField = (value: string) => {
        const isShowPhoneInput = checkCondition(Boolean(value === "-"), false, true) as boolean;
        return (
            isShowPhoneInput ?
                (
                    <PhoneInputWrapper>
                        <PhoneInput
                            inputClass="custom-phone"
                            buttonStyle={style.phoneButtonStyle}
                            inputStyle={style.phoneInputStyle}
                            dropdownStyle={style.phoneDropDownStyle}
                            placeholder="Your contact number"
                            value={value}
                            disabled
                        />
                    </PhoneInputWrapper>
                ) : (
                    <StyledField fullWidth disabled value={value} />
                )
        )
    }

    renderTabSection = () => {
        return (
            <ContentWrapper>
                <CenterBox>
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        value={this.state.tab}
                        onChange={(event, newValue) => {
                            this.handleTabChange(event, newValue)
                        }}
                        data-testid="tabs-wrapper"
                        TabIndicatorProps={{
                            style: style.tabIndicator,
                        }}
                    >
                        <CustomTab label={this.transViewPet("General")} value={1} />
                        <CustomTab label={this.transViewPet("Medical")} value={2} />
                        <CustomTab label={this.transViewPet("Traits")} value={3} />
                    </Tabs>

                    {this.renderTabContent()}
                </CenterBox>
            </ContentWrapper>
        )
    }
    renderTabContent = () => {
        const { tab } = this.state
        switch (tab) {
            case 1:
                return this.generalSection()
            case 2:
                return this.medicalSection()
            case 3:
                return this.traitsSection()
        }
    }
    generalSection = () => {
        const { isGeneralLoading, generalInfo } = this.state;

        return (
            <>
                {isGeneralLoading ? (this.renderLoader()) : (<></>)}
                {!isGeneralLoading ?
                    (
                        <StyledCard>
                            <GeneralTitleBox>
                                <img src={PawBlue} />
                                {this.transViewPet("About")}
                            </GeneralTitleBox>
                            <GeneralWrapper>
                                <RowBox>
                                    <StyledBox maxWidth={361}>
                                        <LabelFieldFont>{this.transViewPet("Animal")}</LabelFieldFont>
                                        <StyledField fullWidth disabled
                                            value={formatData(generalInfo.pet_category)}
                                        />
                                    </StyledBox>
                                    <StyledBox maxWidth={361}>
                                        <LabelFieldFont>{this.transViewPet("Gender")}</LabelFieldFont>
                                        <StyledField fullWidth disabled
                                            value={formatData(generalInfo.gender)}
                                        />
                                    </StyledBox>
                                </RowBox>
                                <RowBox>
                                    <StyledBox maxWidth={361}>
                                        <LabelFieldFont>{this.transViewPet("Breed")}</LabelFieldFont>
                                        <StyledField fullWidth disabled
                                            value={formatData(generalInfo.breed)}
                                        />
                                    </StyledBox>
                                    <StyledBox maxWidth={361}>
                                        <LabelFieldFont>{this.transViewPet("Birthdate")}</LabelFieldFont>
                                        <StyledField fullWidth disabled
                                            value={formatData(generalInfo.birthdate)}
                                        />
                                    </StyledBox>
                                </RowBox>
                                <RowBox>
                                    <StyledBox maxWidth={361}>
                                        <LabelFieldFont>{this.transViewPet("Weight")}</LabelFieldFont>
                                        <StyledField fullWidth disabled
                                            value={formatData(generalInfo.weight)}
                                        />
                                    </StyledBox>
                                    <StyledBox maxWidth={361}>
                                        <LabelFieldFont>{this.transViewPet("Size")}</LabelFieldFont>
                                        <StyledField fullWidth disabled
                                            value={formatData(generalInfo.size)}
                                        />
                                    </StyledBox>
                                </RowBox>
                                <RowBox>
                                    <StyledBox maxWidth={361}>
                                        <LabelFieldFont>{this.transViewPet("Fur")}</LabelFieldFont>
                                        <StyledField fullWidth disabled value={formatData(generalInfo.fur)} />
                                    </StyledBox>
                                    <StyledBox maxWidth={361}>
                                        <LabelFieldFont>{this.transViewPet("Species")}</LabelFieldFont>
                                        <StyledField fullWidth disabled value={formatData(generalInfo.species)} />
                                    </StyledBox>
                                </RowBox>
                            </GeneralWrapper>
                        </StyledCard>
                    ) : (<></>)}
            </>
        )
    }
    medicalSection = () => {
        const { isMedicalLoading, medicalInfo } = this.state;
        const medicalConditions = formatData(medicalInfo.medical_conditions);
        const allergies = formatData(medicalInfo.allergies);
        const usualMedication = formatData(medicalInfo.usual_medication);
        const lastHealthCheckUp = formatData(medicalInfo.last_health_check_up);
        const microchip = checkCondition(Boolean(medicalInfo.microchip === 0 || medicalInfo.microchip === null || medicalInfo.microchip === undefined), false, true) as boolean;
        const microchipNumber = checkCondition(Boolean(medicalInfo.microchip === 0), 0, medicalInfo.microchip) as number;
        const sterilized = medicalInfo.sterilized;
        const vaccines = medicalInfo.vaccines_up_to_date;
        const hospitalAddress = formatData(medicalInfo.hospital_address);
        const doctorName = formatData(medicalInfo.doctor_name);
        const hospitalName = formatData(medicalInfo.hospital);
        const hospitalPhone = formatData(medicalInfo.hospital_number);
        const companyName = formatData(medicalInfo.company);
        const policyNumber = formatData(medicalInfo.policy_number);
        const primaryContact = formatData(medicalInfo.primary_name);
        const primaryContactNumber = formatData(medicalInfo.primary_contact);
        const secondaryContact = formatData(medicalInfo.secondary_name);
        const secondaryContactNumber = formatData(medicalInfo.secondary_contact);

        const microchipSwitch = checkCondition(microchip, CheckedSwitch, UnCheckedSwitch) as string;
        const vaccinesSwitch = checkCondition(medicalInfo.vaccines_up_to_date, CheckedSwitch, UnCheckedSwitch) as string;
        const sterilizedSwitch = checkCondition(medicalInfo.sterilized, CheckedSwitch, UnCheckedSwitch) as string;

        return (
            <>
                {isMedicalLoading ? (this.renderLoader()) : (<></>)}
                {!isMedicalLoading ? (
                    <StyledCard display={"flex"} flexDirection={"column"} gridGap={"57px"}>
                        <Box>
                            <Styledheading>{this.transViewPet("Health bulletin")}</Styledheading>
                            <Box maxWidth={730}>
                                <StyledBox marginBottom={"35px"}>
                                    <LabelFieldFont>{this.transViewPet("Medical conditions")}</LabelFieldFont>
                                    <StyledField fullWidth disabled value={medicalConditions} />
                                </StyledBox>
                                <StyledBox marginBottom={"35px"}>
                                    <LabelFieldFont>{this.transViewPet("Allergies")}</LabelFieldFont>
                                    <StyledField fullWidth disabled value={allergies} />
                                </StyledBox>
                                <StyledBox marginBottom={"35px"}>
                                    <LabelFieldFont>{this.transViewPet("Usual medication")}</LabelFieldFont>
                                    <StyledField fullWidth disabled value={usualMedication} />
                                </StyledBox>
                                <StyledBox>
                                    <LabelFieldFont>{this.transViewPet("Last health check up")}</LabelFieldFont>
                                    <StyledField fullWidth disabled value={lastHealthCheckUp} />
                                </StyledBox>
                            </Box>
                        </Box>

                        <Box display={"flex"} flexDirection={"column"} gridRowGap={"22px"}>
                            <ChipBox>
                                <img src={microchipSwitch} />
                                <TraitFonts isActive={microchip}>{this.transViewPet("Microchip")}</TraitFonts>
                            </ChipBox>
                            {microchip && <StyledBox>
                                <LabelFieldFont>{this.transViewPet("Microchip number")}</LabelFieldFont>
                                <StyledField fullWidth disabled value={microchipNumber} />
                            </StyledBox>}
                            <ChipBox>
                                <img src={vaccinesSwitch} />
                                <TraitFonts isActive={vaccines}>{this.transViewPet("Vaccines up to date")}</TraitFonts>
                            </ChipBox>
                            <ChipBox>
                                <img src={sterilizedSwitch} />
                                <TraitFonts isActive={sterilized}>{this.transViewPet("Sterilized")}</TraitFonts>
                            </ChipBox>

                        </Box>

                        <Box maxWidth={730}>
                            <Styledheading>{this.transViewPet("Usual veterinary")}</Styledheading>
                            <RowBox rowGap={27} columnGap={27} marginBottom={"27px"}>
                                <StyledBox>
                                    <LabelFieldFont>{this.transViewPet("Clinic Address")}</LabelFieldFont>
                                    <StyledField fullWidth disabled value={hospitalAddress} />
                                </StyledBox>
                                <StyledBox >
                                    <LabelFieldFont>{this.transViewPet("Doctor")}</LabelFieldFont>
                                    <StyledField fullWidth disabled value={doctorName} />
                                </StyledBox>
                            </RowBox>
                            <RowBox rowGap={27} columnGap={27}>
                                <StyledBox>
                                    <LabelFieldFont>{this.transViewPet("Clinic/Hospital")}</LabelFieldFont>
                                    <StyledField fullWidth disabled value={hospitalName} />
                                </StyledBox>
                                <StyledBox >
                                    <LabelFieldFont>{this.transViewPet("Phone")}</LabelFieldFont>
                                    {this.renderPhoneField(hospitalPhone)}
                                </StyledBox>
                            </RowBox>
                        </Box>

                        <Box maxWidth={730}>
                            <Styledheading>{this.transViewPet("Insurance")}</Styledheading>
                            <RowBox rowGap={27} columnGap={27}>
                                <StyledBox>
                                    <LabelFieldFont>{this.transViewPet("Company")}</LabelFieldFont>
                                    <StyledField fullWidth disabled value={companyName} />
                                </StyledBox>
                                <StyledBox >
                                    <LabelFieldFont>{this.transViewPet("Policy number")}</LabelFieldFont>
                                    <StyledField fullWidth disabled value={policyNumber} />
                                </StyledBox>
                            </RowBox>
                        </Box>

                        <Box maxWidth={730}>
                            <Styledheading>{this.transViewPet("Emergency contacts")}</Styledheading>
                            <RowBox rowGap={27} columnGap={27} marginBottom={"27px"}>
                                <StyledBox>
                                    <LabelFieldFont>{this.transViewPet("Primary Contact")}</LabelFieldFont>
                                    <StyledField fullWidth disabled value={primaryContact} />
                                </StyledBox>
                                <StyledBox >
                                    <LabelFieldFont>{this.transViewPet("Phone")}</LabelFieldFont>
                                    {this.renderPhoneField(primaryContactNumber)}
                                </StyledBox>
                            </RowBox>
                            <RowBox rowGap={27} columnGap={27}>
                                <StyledBox>
                                    <LabelFieldFont>{this.transViewPet("Secondary Contact")}</LabelFieldFont>
                                    <StyledField fullWidth disabled value={secondaryContact} />
                                </StyledBox>
                                <StyledBox >
                                    <LabelFieldFont>{this.transViewPet("Phone")}</LabelFieldFont>
                                    {this.renderPhoneField(secondaryContactNumber)}
                                </StyledBox>
                            </RowBox>
                        </Box>

                    </StyledCard>
                ) : (<></>)}
            </>
        )
    }
    traitsSection = () => {
        const { isTraitsLoading, traitsInfo } = this.state;
        const walks = formatData(checkCondition(Boolean(traitsInfo.walks === 0), "-", traitsInfo.walks) as string);
        const walksSchedule = formatData(traitsInfo.walking_schedule);
        const potty = formatData(checkCondition(Boolean(traitsInfo.potty === 0), "-", traitsInfo.potty) as string);
        const pottySchedule = formatData(traitsInfo.potty_schedule)
        const meals = formatData(checkCondition(Boolean(traitsInfo.meal === 0), "-", traitsInfo.meal) as string);
        const mealsSchedule = formatData(traitsInfo.meal_schedule);
        const traitsList = transformAttributes(traitsInfo);

        const dotBoxes = (value: string, className: "mobile" | "desktop") => {
            return (
                <>
                    <DotWrapper className={className}>
                        <CenterDotBox className="content-start"><CustomDot selected={value.includes("1")} /></CenterDotBox>
                        <CenterDotBox><CustomDot selected={value.includes("2")} /></CenterDotBox>
                        <CenterDotBox className="content-end"><CustomDot selected={value.includes("3")} /></CenterDotBox>
                    </DotWrapper>
                </>
            )
        }
        return (
            <>
                {isTraitsLoading ? (this.renderLoader()) : (<></>)}
                {!isTraitsLoading ? (<StyledCard display={"flex"} flexDirection={"column"} gridGap={"48px"}>
                    <Box>
                        <Styledheading>{this.transViewPet("Personality")}</Styledheading>
                        <TraitFonts isActive>{this.transViewPet("How would you describe your pet in unknown environments?")}</TraitFonts>
                        {traitsList.map((record: ITraits) => {
                            return (
                                <>
                                    <TraitBox>
                                        <Box width={"110px"}><TraitFonts isActive={record.value}>{this.transViewPet(record.left)}</TraitFonts></Box>
                                        {dotBoxes(record.value, "desktop")}
                                        <TraitFonts isActive={record.value}>{this.transViewPet(record.right)}</TraitFonts>
                                    </TraitBox>
                                    {dotBoxes(record.value, 'mobile')}
                                </>
                            )
                        })}
                    </Box>
                    <Box maxWidth={730}>
                        <Styledheading>{this.transViewPet("Habits & Routines")}</Styledheading>
                        <RowBox rowGap={27} columnGap={27} marginBottom={"27px"}>
                            <StyledBox>
                                <LabelFieldFont>{this.transViewPet("How many walks")}</LabelFieldFont>
                                <StyledField fullWidth disabled value={walks} />
                            </StyledBox>
                            <StyledBox >
                                <LabelFieldFont>{this.transViewPet("Walking schedules")}</LabelFieldFont>
                                <StyledField fullWidth disabled value={walksSchedule} />
                            </StyledBox>
                        </RowBox>
                        <RowBox rowGap={27} columnGap={27} marginBottom={"27px"}>
                            <StyledBox>
                                <LabelFieldFont>{this.transViewPet("How many potty")}</LabelFieldFont>
                                <StyledField fullWidth disabled value={potty} />
                            </StyledBox>
                            <StyledBox >
                                <LabelFieldFont>{this.transViewPet("Potty schedules")}</LabelFieldFont>
                                <StyledField fullWidth disabled value={pottySchedule} />
                            </StyledBox>
                        </RowBox>
                        <RowBox rowGap={27} columnGap={27}>
                            <StyledBox>
                                <LabelFieldFont>{this.transViewPet("How many meals")}</LabelFieldFont>
                                <StyledField fullWidth disabled value={meals} />
                            </StyledBox>
                            <StyledBox >
                                <LabelFieldFont>{this.transViewPet("Meal schedules")}</LabelFieldFont>
                                <StyledField fullWidth disabled value={mealsSchedule} />
                            </StyledBox>
                        </RowBox>
                    </Box>
                </StyledCard>
                ) : (<></>)}
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { generalInfo } = this.state;
        return (
            <>
                <NavigationMenu id={''} navigation={this.props.navigation} />
                <MainWrapper>
                    <BackButtonBox>
                        <BackButton disableRipple data-testid='back-to-list' onClick={() => this.goBackToCustomer()}>
                            <BackArrowIcon /><BackButtonTitle>{returnTruthyString(generalInfo.name)}</BackButtonTitle>
                        </BackButton>
                    </BackButtonBox>

                    <AvatarBox>
                        <StyledAvatar src={returnTruthyString(generalInfo.pet_photo)}>{returnTruthyString(generalInfo.name)}</StyledAvatar>
                        <CustomerName>{returnTruthyString(generalInfo.name)}</CustomerName>
                    </AvatarBox>

                    {this.renderTabSection()}

                </MainWrapper>
                <AppFooter navigation={this.props.navigation} />
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
    padding: "32px",
    backgroundColor: "#F6F4F9",
    "@media (max-width: 600px)": {
        padding: "24px",
    },
    "@media (max-width: 400px)": {
        padding: "12px",
    },
})
const BackButtonBox = styled(Box)({
    margin: "48px 32px",
    "@media (min-width: 600px) and (max-width: 991px)": {
        margin: "32px 16px",
    },
    "@media (max-width: 600px)": {
        margin: "32px 8px",
    },
})
const BackButton = styled(IconButton)({
    padding: 0,
    "&:hover": {
        background: "none !important",
    },
})
const BackArrowIcon = styled(KeyboardArrowLeft)({
    color: "#6F05EA",
    width: 48,
    height: 48
})
const BackButtonTitle = styled(Typography)({
    marginLeft: "12px",
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "30px",
    lineHeight: "20px",
    color: "#0F172A",
    "@media (min-width: 600px) and (max-width: 991px)": {
        fontSize: "22px",
    },
    "@media (max-width: 600px)": {
        fontSize: "16px",
    },
})

const AvatarBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 24
})
const StyledAvatar = styled(Avatar)({
    height: 148,
    width: 148,
    "@media (max-width: 600px)": {
        height: 120,
        width: 120,
    },
})
const CustomerName = styled(Typography)({
    fontWeight: 600,
    fontSize: "20px",
    fontFamily: "Montserrat",
    color: "#000000"
})
const ContentWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
})


const CustomTab = styled(Tab)({
    minHeight: 40,
    fontFamily: 'Montserrat',
    fontSize: '16px',
    color: '#B7B7B7',
    fontWeight: 500,
    flex: 1,
    textTransform: 'none',
    maxWidth: "33%",
    borderBottom: "1px solid #B7B7B7",
    "&.Mui-selected": {
        color: '#6F05EA',
        fontWeight: 700,
    },
    "@media (max-width: 600px)": {
        fontSize: "14px",
    },
})
const CenterBox = styled(Box)({
    width: "100%",
    maxWidth: "966px",
    marginTop: "28px"
})
const StyledCard = styled(Box)({
    margin: "40px 0",
    padding: "32px 38px",
    boxShadow: "0px 8px 32px 0px #0000000F",
    backgroundColor: "#FFF",
    borderRadius: "10px",
    "@media (max-width: 600px)": {
        padding: "24px",
    },
})
const GeneralTitleBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    gap: "7px",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    fontFamily: "Montserrat",
    color: "#6F05EA",
    marginBottom: 32
})
const GeneralWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "32px"
})
const RowBox = styled(Box)(({ rowGap, columnGap }: any) => ({
    display: "flex",
    flexDirection: "row",
    rowGap: rowGap || 32,
    columnGap: columnGap || 52,
    "@media (max-width: 600px)": {
        flexWrap: 'wrap'
    },
}));
const StyledBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "4px",
    "@media (max-width: 600px)": {
        maxWidth: "unset",
    },
})
const LabelFieldFont = styled(Typography)({
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#6F05EA",
})
const StyledField = styled(TextField)({
    "& .MuiInputAdornment-root": {
        margin: 0
    },
    "& .MuiInputBase-root": {
        height: "34px",
    },
    "& .MuiInput-underline ": {
        borderColor: "#D9D9D9 !important",
    },
    "& .MuiInput-underline.Mui-disabled::before ": {
        borderBottomStyle: "solid"
    },
    "& .MuiInput-underline::before ": {
        borderColor: "#D9D9D9 !important",
    },
    "& .MuiInput-underline::after ": {
        borderColor: "#D9D9D9 !important",
    },
    "& .MuiInputBase-input": {
        padding: "8px",
        fontWeight: 500,
        fontSize: "18px",
        fontFamily: "Montserrat",
        color: "#000000",
        "@media (max-width: 600px)": {
            fontSize: "16px",
        },
        "@media (max-width: 400px)": {
            fontSize: "14px",
        },
    }
})
const Styledheading = styled(Typography)({
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#6F05EA",
    marginBottom: "35px",
    "@media (max-width: 600px)": {
        fontSize: "20px",
    },
    "@media (max-width: 400px)": {
        fontSize: "18px",
    },
})
const ChipBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    gap: "22px",
    alignItems: "center"
})
const TraitFonts = styled(Typography)(({ isActive }: any) => ({
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "22px",
    color: isActive ? "#000000" : "#777777",
    "@media (max-width: 600px)": {
        fontSize: "16px",
    },
    "@media (max-width: 400px)": {
        fontSize: "14px",
    },
}))
const DotWrapper = styled(Box)({
    "&.desktop": {
        display: "flex",
        "@media (max-width: 751px)": {
            display: "none"
        }
    },
    "&.mobile": {
        display: "none",
        "@media (max-width: 751px)": {
            marginTop: "12px",
            display: "flex",
            width: "unset"
        }
    }
})
const CenterDotBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "105",
    "&.content-start": {
        "@media (max-width: 751px)": {
            justifyContent: "start",
        }
    },
    "&.content-end": {
        "@media (max-width: 751px)": {
            justifyContent: "end",
        }
    },
    "@media (max-width: 751px)": {
        width: "100%"
    }

})
const CustomDot = styled(Badge)(({ selected }: any) => ({
    borderRadius: "50%",
    height: selected ? 20 : 11,
    width: selected ? 20 : 11,
    backgroundColor: selected ? "#6F05EA" : "#E2CDFB",
}))
const TraitBox = styled(Box)({
    marginTop: "35px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "@media (max-width: 751px)": {
        justifyContent: "space-between"
    }
})
const PhoneInputWrapper = styled(Box)({
    "& .react-tel-input input": {
        "@media (max-width: 600px)": {
            fontSize: "16px !important",
        },
        "@media (max-width: 400px)": {
            fontSize: "14px !important",
        },
    }

})

const style = {
    tabIndicator: {
        backgroundColor: '#6F05EA',
        height: "3px",
        bottom: "6px"
    } as React.CSSProperties,
    phoneButtonStyle: {
        border: "none",
        background: "transparent",
    } as React.CSSProperties,
    phoneInputStyle: {
        border: "none",
        width: "100%",
        height: "34px",
        borderRadius: "0px",
        borderBottom: "1px solid #D9D9D9",
        paddingLeft: "40px",
        cursor: "auto",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "16px",
        color: "#000000",
        fontFamily: "Montserrat",
    } as React.CSSProperties,
    phoneDropDownStyle: {
        fontSize: "13px"
    } as React.CSSProperties,
}

const LoaderContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "50px",
    marginBottom: "50px"
});

const CustomLoader = styled(CircularProgress)({
    color: "#6F05EA"
});
// Customizable Area End
