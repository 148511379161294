export const enHeader = {
    //vendor
    "Establishment":"Establishment",
    "Management":"Management",
    "Logout":"Logout",
    "You're Logging Out of Petzai, are you sure?":"You're Logging Out of Petzai, are you sure?",
    "Register your business":"Register your business",
    "Sign up":"Sign up",
    "Login":"Login",
    //Button
    "Yes, I'm Sure":"Yes, I'm Sure",
    "No, keep me Logged In":"No, keep me Logged In",
}

export const ptHeader= {
    //vendor
    "Establishment":"Estabelecimento",
    "Management":"Gerenciamento",
    "Logout":"Sair",
    "You're Logging Out of Petzai, are you sure?":"Você está saindo do Petzai, tem certeza?",
    "Register your business":"Cadastre sua empresa",
    "Sign up":"Inscrever-se",
    "Login":"Conecte-se",
    //Button
    "Yes, I'm Sure":"Sim, tenho certeza",
    "No, keep me Logged In":"Não, mantenha-me conectado",
}