import React from "react";
// Customizable Area Start
import ViewCustomerController, { Props } from "./ViewCustomerController.web";
import NavigationMenu from "../../../navigationmenu/src/NavigationMenu.web";
import { AppFooter } from "../../../../components/src/AppFooter/appFooter";
import {
    Avatar, Box, styled, Typography, Grid, TextField, InputAdornment,
    Chip,
    IconButton,
    CircularProgress,
} from "@material-ui/core";
import { BookingHistory, female, male, NoAppoinment } from "../assets";
import PhoneInput from "react-phone-input-2";
import Flag from 'react-world-flags';
import moment from "moment";
import { KeyboardArrowLeft } from "@material-ui/icons";
import { checkCondition, formatDateAsYearsOld, returnTruthyString } from "../../../../components/src/HelperUtils";

const getGenderIcon = (gender: string) => {
    if (gender.toLocaleLowerCase() == "female") {
        return female;
    }

    return male;
};
const getChipColor = (status: string) => {
    let color = ""
    let bgColor = ""
    switch (status) {
        case "Booked":
            color = "#F59E0B"
            bgColor = "#FEF3C7"
            break;
        case "Completed":
            color = "#059669"
            bgColor = "#D1FAE5"
            break;
        case "Cancelled":
            color = "#C10000"
            bgColor = "#FEE2E2"
            break;
    }
    return { color, bgColor }

}
// Customizable Area End

export default class ViewCustomer extends ViewCustomerController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderPersonalDetails = () => {
        const { customerInfo } = this.state;
        const lastName = checkCondition(Boolean(customerInfo.last_name === ""), "-", customerInfo.last_name) as string;
        const language = checkCondition(Boolean(customerInfo.language === ""), "English", customerInfo.language) as string;
        const nationality = checkCondition(Boolean(customerInfo.nationality === ""), "-", customerInfo.nationality) as string;
        const address = checkCondition(Boolean(customerInfo.address === null), "-", customerInfo.address) as string;
        const zipcode = checkCondition(Boolean(customerInfo.zipcode === null), "-", customerInfo.zipcode) as string;
        return (
            <>
                <GridContainer container>
                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>{this.transViewCustomer("First Name")}</CustomLabel>
                        <StyledField fullWidth disabled
                            value={returnTruthyString(customerInfo.first_name)}
                        />
                    </GridItem>
                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>{this.transViewCustomer("Last Name")}</CustomLabel>
                        <StyledField fullWidth disabled
                            value={lastName}
                        />
                    </GridItem>

                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>{this.transViewCustomer("Language")}</CustomLabel>
                        <StyledField fullWidth disabled
                            value={language}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Avatar style={{ width: '18px', height: '18px', overflow: 'hidden', marginLeft: "8px" }}>
                                            <Flag code="us" style={{ width: 'auto', height: '100%' }} />
                                        </Avatar>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </GridItem>
                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>{this.transViewCustomer("Nationality")}</CustomLabel>
                        <StyledField fullWidth disabled
                            value={nationality}
                        />
                    </GridItem>

                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>{this.transViewCustomer("Phone")}</CustomLabel>
                        <PhoneInput
                            buttonStyle={style.phoneButtonStyle}
                            inputStyle={style.phoneInputStyle}
                            dropdownStyle={style.phoneDropDownStyle}
                            placeholder="Your contact number"
                            value={returnTruthyString(customerInfo.full_phone_number)}
                            disabled
                        />
                    </GridItem>
                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>{this.transViewCustomer("Birthdate")}</CustomLabel>
                        <StyledField fullWidth disabled
                            value={returnTruthyString(customerInfo.date_of_birth)}
                        />
                    </GridItem>

                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>{this.transViewCustomer("Address")}</CustomLabel>
                        <StyledField fullWidth disabled
                            value={address}
                        />

                    </GridItem>
                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>{this.transViewCustomer("Country")}</CustomLabel>
                        <StyledField fullWidth disabled
                            value={returnTruthyString(customerInfo.country)}
                        />
                    </GridItem>

                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>{this.transViewCustomer("City")}</CustomLabel>
                        <StyledField fullWidth disabled
                            value={returnTruthyString(customerInfo.city)}
                        />
                    </GridItem>
                    <GridItem item md={6} sm={12} xs={12}>
                        <CustomLabel>{this.transViewCustomer("Zip Code")}</CustomLabel>
                        <StyledField fullWidth disabled
                            value={zipcode}
                        />
                    </GridItem>
                </GridContainer>
            </>
        )
    }
    renderPetDetails = () => {
        const { petInfo } = this.state;
        return (
            <>
                <ListWrapper>
                    <PetBoxWrapper>
                        {petInfo.length > 0 && petInfo.map((record) => {
                            const petAge = formatDateAsYearsOld(record.birthdate);
                            const ageText = petAge.replace(/^\d+\s/, "");
                            const ageNumber = petAge.split(" ")[0];
                            const displayAge = `${ageNumber} ${this.transViewCustomer(ageText)}`
                            
                            return (
                                <PetBox onClick={() => this.goToPet(record.id)} data-test-id="pet-info">
                                    <PetAvatar src={record.pet_photo} />
                                    <Box>
                                        <PetName title={record.name}>{record.name}</PetName>
                                        <PetBreed title={returnTruthyString(record.name)}>{returnTruthyString(record.breed)}</PetBreed>
                                    </Box>
                                    <PetAgeBox>
                                        <img src={getGenderIcon(record.gender)} />
                                        {displayAge}
                                    </PetAgeBox>
                                </PetBox>
                            )
                        })}
                    </PetBoxWrapper>
                </ListWrapper>
            </>
        )
    }
    renderBookingDetails = () => {
        const {historyInfo} = this.state;
        return (
            <>
                <BookingWrapper>
                    <TitleWrapper>
                        <img src={BookingHistory} />
                        <HistoryTitle>{this.transViewCustomer("Booking History")}</HistoryTitle>
                    </TitleWrapper>
                    {historyInfo.length <= 0 ? (this.renderNoBookingHistory()) : (<></>)}
                    {historyInfo.length > 0 ? (
                        <BookingListWrapper>
                            {historyInfo.map((record, index) => {
                                const status = record.service_status
                                const chipColor = getChipColor(status)
                                const serviceDateDay = moment(record.service_date).format("DD")
                                const serviceDateMonth = moment(record.service_date).format("MMM")
                                return (
                                    <BookingBox key={index}>
                                        <CenterBox>
                                            <CenterBox width={60} flexDirection={"column"}>
                                                <RowFont>{serviceDateDay}</RowFont>
                                                <RowFont className="month">{serviceDateMonth}</RowFont>
                                            </CenterBox>
                                            <CenterBox width={130}>
                                                <RowFont noWrap title={record.service_type}>{record.service_type}</RowFont>
                                            </CenterBox>

                                            <CenterBox width={100}>
                                                <RowFont noWrap title={record.customer_phone}>{record.customer_phone}</RowFont>
                                            </CenterBox>
                                        </CenterBox>

                                        <CenterBox>
                                            <CenterBox width={96}>
                                                <CustomChip
                                                    label={status}
                                                    style={{ color: chipColor.color, backgroundColor: chipColor.bgColor }}
                                                />
                                            </CenterBox>
                                            <CenterBox width={55}>
                                                <RowFont>{record.service_charge}€</RowFont>
                                            </CenterBox>
                                        </CenterBox>
                                    </BookingBox>
                                )
                            })}
                        </BookingListWrapper>
                    ) : (<></>)}
                </BookingWrapper>
            </>
        )
    }

    renderLoader = () => {
        return (
            <StyledLoaderBox>
                <StyledLoader />
            </StyledLoaderBox>
        )
    }

    renderNoBookingHistory = () => {
        return (
            <CenterBox style={{ padding: "50px 0px" }}>
                <img src={NoAppoinment} />
                <NoRecordFont>{this.transViewCustomer("No booking history yet")}</NoRecordFont>
            </CenterBox>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { customerInfo, isLoading } = this.state;
        const fullName = returnTruthyString(`${customerInfo.first_name} ${customerInfo.last_name}`);
        const customerProfile = returnTruthyString(customerInfo.customer_img);
        return (
            <>
                <NavigationMenu id={''} navigation={this.props.navigation} />
                {isLoading ? (<>{this.renderLoader()}</>) : (<></>)}
                {!isLoading ? (
                    <MainWrapper>

                        <BackBox>
                            <ArrowBtn disableRipple data-test-id='back-to-list' onClick={() => this.goToDashboard()}>
                                <ArrowIcon /><BackTitle>{fullName}</BackTitle>
                            </ArrowBtn>
                        </BackBox>

                        <DetailsWrapper>
                            <WhiteBox>
                                <AvatarBox>
                                    <StyledAvatar src={customerProfile}>{fullName}</StyledAvatar>
                                    <CustomerName>{fullName}</CustomerName>
                                </AvatarBox>
                                {this.renderPersonalDetails()}
                                {this.renderPetDetails()}
                                {this.renderBookingDetails()}
                            </WhiteBox>
                        </DetailsWrapper>

                    </MainWrapper>
                ) : (<></>)}
                <AppFooter navigation={this.props.navigation} />
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
    backgroundColor: "#F6F4F9",
    padding: "32px",
})
const DetailsWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center"
})
const WhiteBox = styled(Box)({
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    width: "100%",
    maxWidth: "1040px",
    padding: "32px",
    "@media(max-width:600px)": {
        padding: "32px 0px 32px 0px",
    }
})

const AvatarBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 18
})
const StyledAvatar = styled(Avatar)({
    height: 142,
    width: 142
})
const CustomerName = styled(Typography)({
    fontWeight: 600,
    fontSize: "20px",
    fontFamily: "Montserrat",
    color: "#000000"
})
const GridContainer = styled(Grid)({
    marginTop: "48px",
    padding: "24px",
})
const GridItem = styled(Grid)({
    display: "flex",
    flexDirection: "column",
    padding: "18px !important"
})
const CustomLabel = styled(Typography)({
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    fontFamily: "Montserrat",
    color: "#777777",
    marginBottom: 4
})

const StyledField = styled(TextField)({
    "& .MuiInputAdornment-root": {
        margin: 0
    },
    "& .MuiInputBase-root": {
        height: "34px",
    },
    "& .MuiInput-underline ": {
        borderColor: "#B7B7B7 !important",
    },
    "& .MuiInput-underline.Mui-disabled::before ": {
        borderBottomStyle: "solid"
    },
    "& .MuiInput-underline::before ": {
        borderColor: "#B7B7B7 !important",
    },
    "& .MuiInput-underline::after ": {
        borderColor: "#B7B7B7 !important",
    },
    "& .MuiInputBase-input": {
        padding: "8px",
        fontWeight: 500,
        fontSize: "14px",
        fontFamily: "Montserrat",
        color: "#A6A6A6",
    }
})
const ListWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "80px 0"
})
const PetBoxWrapper = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    gap: "35px",
    maxWidth: "971px",
    width: "100%",
    padding: "12px",
    maxHeight: "292px",
    overflowY: "auto",
    "@media(max-width:780px)": {
        justifyContent: "center"
    }
})
const PetBox = styled(Box)({
    height: "234px",
    maxWidth: "297px",
    width: "100%",
    padding: "29px 0",
    borderRadius: "4px",
    boxShadow: "0px 0px 7.5px 0px #0000001A",

    display: "flex",
    flexDirection: "column",
    gap: "24px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
})

const PetAvatar = styled(Avatar)({
    height: "123px",
    width: "123px"
})

const PetName = styled(Typography)({
    fontWeight: 600,
    fontSize: "24px",
    fontFamily: "Montserrat",
    color: "#000000",
    textAlign: "center",
    maxWidth: "230px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
})
const PetBreed = styled(Typography)({
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: "Montserrat",
    color: "#B7B7B7",
    textAlign: "center",
    marginTop: 9,
    maxWidth: "230px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
})
const PetAgeBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "13px",
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "18px",
    fontFamily: "Montserrat",
    color: "#000000",
})

const BookingWrapper = styled(Box)({
    margin: "48px 0"
})
const TitleWrapper = styled(Box)({
    height: "58px",
    padding: "0px",
    margin: "0 40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    gap: 7
})
const BookingListWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    maxHeight: "320px",
    overflowY: "auto",
    padding: "0 12px",
    margin: "0 22px",
    "&::-webkit-scrollbar": {
        height: "0 !important",
    },
})
const HistoryTitle = styled(Typography)({
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    fontFamily: "Montserrat",
    color: "#6F05EA",

})
const BookingBox = styled(Box)({
    cursor: "pointer",
    minHeight: "49px",
    borderBottom: "1px solid #E2E8F0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
})
const CenterBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
})
const CustomChip = styled(Chip)({
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    fontFamily: "Montserrat",
    height: 22,
})
const RowFont = styled(Typography)({
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    fontFamily: "Montserrat",
    color: "#000000",
    "&.month": {
        fontSize: "10px",
        lineHeight: "4px",
        color: "#7D7D7D"
    }
})

const BackBox = styled(Box)({
    margin: "48px 32px",
    "@media (min-width: 600px) and (max-width: 991px)": {
        margin: "32px 16px",
    },
    "@media (max-width: 600px)": {
        margin: "32px 8px",
    },
})
const ArrowBtn = styled(IconButton)({
    padding: 0,
    "&:hover": {
        background: "none !important",
    },
})
const ArrowIcon = styled(KeyboardArrowLeft)({
    color: "#6F05EA",
    width: 48,
    height: 48
})
const BackTitle = styled(Typography)({
    marginLeft: "12px",
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: "30px",
    lineHeight: "20px",
    color: "#0F172A",
    "@media (min-width: 600px) and (max-width: 991px)": {
        fontSize: "22px",
    },
    "@media (max-width: 600px)": {
        fontSize: "16px",
    },
})

const style = {
    phoneButtonStyle: {
        border: "none",
        background: "transparent",
    } as React.CSSProperties,
    phoneInputStyle: {
        border: "none",
        width: "100%",
        borderBottom: "1px solid #A6A6A6",
        borderRadius: "0px",
        height: "34px",
        paddingLeft: "40px",
        fontWeight: 400,
        fontSize: "13px",
        lineHeight: "16px",
        fontFamily: "Montserrat",
        cursor: "auto"
    } as React.CSSProperties,
    phoneDropDownStyle: {
        fontSize: "13px"
    } as React.CSSProperties,
}

const StyledLoaderBox = styled(Box)({
    padding: "60 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    border: "1px solid #E2E8F0",
    background: "#FFF",
    borderRadius: "8px",
    height: "100%"
})

const StyledLoader = styled(CircularProgress)({
    color: "#6F05EA"
})

const NoRecordFont = styled(Typography)({
    fontWeight: 600,
    fontSize: "20px",
    fontFamily: "Montserrat",
    color: "#B7B7B7",
    padding: "0 8px"
})
// Customizable Area End
