export const enServiceDashboard = {
    //FILTER
    "Select Date Range":"Select Date Range",
    "Today":"Today",
    "This Week":"This Week",
    "This Month":"This Month",
    "This Year":"This Year",
    "Custom Date Range":"Custom Date Range",
    //BOOKING SECTION
    "No booking yet":"No booking yet",
    "No completed appointment yet":"No completed appointment yet",
    "No cancelled appointment yet":"No cancelled appointment yet",
    //SERVICE POPULARITY SECTION
    "All Booking":"All Booking",
    "last Week":"last Week",
    "Completed":"Completed",
    "Cancelled":"Cancelled",
    "Service Popularity":"Service Popularity",
    "Total Revenue":"Total Revenue",
    "Change":"Change",
    "Total Customers":"Total Customers",
    "View Details":"View Details",
    //APPOINTMENT SECTION
    "No appointments yet":"No appointments yet",
    "Appointments":"Appointments",
    "Search":"Search",
    "No results found!":"No results found!",
    //BOOKING DETAILS MODAL
    "Booking Details":"Booking Details",
    "Client Name":"Client Name",
    "Pet Name":"Pet Name",
    "Complementary services":"Complementary services",
    "Medication":"Medication",
    "Transportation":"Transportation",
    "Total":"Total",
    //CUSTOMER LIST MODAL
    "Customers":"Customers",
    "Download in CSV":"Download in CSV",
    "Has no customers yet":"Has no customers yet",
    "Date Range":"Date Range",
    "to":"to",
    //VIEW CUSTOMER
    "First Name":"First Name",
    "Last Name":"Last Name",
    "Language":"Language",
    "Nationality":"Nationality",
    "Phone":"Phone",
    "Birthdate":"Birthdate",
    "Address":"Address",
    "Country":"Country",
    "City":"City",
    "Zip Code":"Zip Code",
    "years old":"years old",
    "year old":"year old",
    "Booking History":"Booking History",
    //VIEW PET
    "General":"General",
    "Medical":"Medical",
    "Traits":"Traits",
        //GENERAL
        "About":"About",
        "Animal":"Animal",
        "Gender":"Gender",
        "Breed":"Breed",
        "Weight":"Weight",
        "Size":"Size",
        "Fur":"Fur",
        "Species":"Species",
        //MEDICAL
        "Health bulletin":"Health bulletin",
        "Medical conditions":"Medical conditions",
        "Allergies":"Allergies",
        "Usual medication":"Usual medication",
        "Last health check up":"Last health check up",
        "Microchip":"Microchip",
        "Microchip number":"Microchip number",
        "Vaccines up to date":"Vaccines up to date",
        "Sterilized":"Sterilized",
        "Usual veterinary":"Usual veterinary",
        "Clinic Address":"Clinic Address",
        "Doctor":"Doctor",
        "Clinic/Hospital":"Clinic/Hospital",
        "Insurance":"Insurance",
        "Company":"Company",
        "Policy number":"Policy number",
        "Emergency contacts":"Emergency contacts",
        "Primary Contact":"Primary Contact",
        "Secondary Contact":"Secondary Contact",
        //TRAITS
        "Personality":"Personality",
        "How would you describe your pet in unknown environments?":"How would you describe your pet in unknown environments?",
        "Habits & Routines":"Habits & Routines",
        "How many walks":"How many walks",
        "Walking schedules":"Walking schedules",
        "How many potty":"How many potty",
        "Potty schedules":"Potty schedules",
        "How many meals":"How many meals",
        "Meal schedules":"Meal schedules",
        "Calm":"Calm",
        "Energetic":"Energetic",
        "Relaxed":"Relaxed",
        "Anxious":"Anxious",
        "Easy-going":"Easy-going",
        "Shy":"Shy",
        "Friendly":"Friendly",
        "Aggressive":"Aggressive",
        "Gentle":"Gentle",
        "Strong":"Strong",
        "Obedient":"Obedient",
        "Rebellious":"Rebellious"
}
export const ptServiceDashboard = {
    //FILTER
    "Select Date Range":"Selecione o intervalo de datas",
    "Today":"Hoje",
    "This Week":"Essa semana",
    "This Month":"Este mês",
    "This Year":"Este ano",
    "Custom Date Range":"Intervalo de datas personalizado",
    //BOOKING SECTION
    "No booking yet":"Nenhuma reserva ainda",
    "No completed appointment yet":"Nenhum compromisso concluído ainda",
    "No cancelled appointment yet":"Nenhum compromisso cancelado ainda",
    //SERVICE POPULARITY SECTION
    "All Booking":"Todas as reservas",
    "last Week":"semana passada",
    "Completed":"Concluída",
    "Cancelled":"Cancelado",
    "Service Popularity":"Popularidade do serviço",
    "Total Revenue":"Receita total",
    "Change":"Mudar",
    "Total Customers":"Total de clientes",
    "View Details":"Ver detalhes",
    //APPOINTMENT SECTION
    "No appointments yet":"Ainda não há compromissos",
    "Appointments":"Compromissos",
    "Search":"Procurar",
    "No results found!":"Nenhum resultado encontrado!",
    //BOOKING DETAILS MODAL
    "Booking Details":"Detalhes da reserva",
    "Client Name":"Nome do cliente",
    "Pet Name":"Nome do animal de estimação",
    "Complementary services":"Serviços complementares",
    "Medication":"Medication",
    "Transportation":"Transportation",
    "Total":"Total",
    //CUSTOMER LIST MODAL
    "Customers":"Clientes",
    "Download in CSV":"Baixar em CSV",
    "Has no customers yet":"Ainda não tem clientes",
    "Date Range":"Período",
    "to":"para",
    //VIEW CUSTOMER
    "First Name":"Primeiro Nome",
    "Last Name":"Apelido",
    "Language":"Língua",
    "Nationality":"Nacionalidade",
    "Phone":"Telemóvel",
    "Birthdate":"Data de Nascimento",
    "Address":"Morada",
    "Country":"País",
    "City":"Cidade",
    "Zip Code":"Código Postal",
    "years old":"anos",
    "year old":"anos",
    "Booking History":"Histórico de reservas",
    //VIEW PET
    "General":"Em geral",
    "Medical":"Médica",
    "Traits":"Características",
        //GENERAL
        "About":"Sobre",
        "Animal":"Animal",
        "Gender":"Gênero",
        "Breed":"Raça",
        "Weight":"Peso",
        "Size":"Tamanho",
        "Fur":"Pelagem",
        "Species":"Espécies",
        //MEDICAL
        "Health bulletin":"Boletim de Saúde",
        "Medical conditions":"Condições médicas",
        "Allergies":"Alergias",
        "Usual medication":"Medicação habitual",
        "Last health check up":"Last health check up",
        "Microchip":"Microchip",
        "Microchip number":"Número do microchip",
        "Vaccines up to date":"Vacinas atualizadas",
        "Sterilized":"Esterilizado",
        "Usual veterinary":"Veterinário Habitual",
        "Clinic Address":"Morada da clínica",
        "Doctor":"Médico",
        "Clinic/Hospital":"Clínica/Hospital",
        "Insurance":"Seguro",
        "Company":"Empresa",
        "Policy number":"Número da apólice",
        "Emergency contacts":"Contactos de Emergência",
        "Primary Contact":"Contacto principal",
        "Secondary Contact":"Contacto Secundário",
         //TRAITS
         "Personality":"Personalidade",
         "How would you describe your pet in unknown environments?":"Como descreverias o teu animal de estimação em ambientes desconhecidos?",
         "Habits & Routines":"Hábitos e rotinas",
         "How many walks":"Quantos passeios",
         "Walking schedules":"Horários das caminhadas",
         "How many potty":"Quantas vezes vai fazer as necessidades",
         "Potty schedules":"Quais os horários para fazer as necessidade",
         "How many meals":"Quantas refeições",
         "Meal schedules":"Horário das refeições",
         "Calm":"Calmo",
         "Energetic":"Energético",
         "Relaxed":"Descontraído",
         "Anxious":"Ansioso",
         "Easy-going":"Fácil de lidar",
         "Shy":"Tímido",
         "Friendly":"Amigável",
         "Aggressive":"Agressivo",
         "Gentle":"Delicado",
         "Strong":"Forte",
         "Obedient":"Obediente",
         "Rebellious":"Rebelde",
}