import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react'
import { Calendar, Formats, momentLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DayCell from '../../../../../components/src/calendarWeb/DayCell';
import { Box, Typography, styled } from '@material-ui/core';
import { leftArrow, rightArrow } from '../../../../../blocks/customisableuserprofiles/src/assets';
import { getEventStartEndDate, getWeekRange } from '../../../../../components/src/helpers';
import CalendarHeader from '../../../../../blocks/customisableuserprofiles/src/components/CalendarHeader';
import { EmployeeEvent } from '../../../../../blocks/customisableuserprofiles/src/types';
import WeekEvent from './WeekEvent';
import MonthEvent from './MonthEvent';
import { Schedule } from '../ViewEmployeeController.web';


type Keys = keyof typeof Views;

type Props = {
    calendarView: (typeof Views)[Keys];
    calendarDate: Date;
    changeView?: (view: (typeof Views)[Keys]) => void;
    changeDate?: (date: Date) => void;
    events: Schedule[];
}

export interface FormattedEvent extends Schedule {
  start: Date;
  end: Date;
}

const localizer = momentLocalizer(moment);

/* istanbul ignore next */
const formats: Formats = {
    timeGutterFormat: (date, _, localizer) => localizer?.format(date, "ha") ?? "",
    agendaTimeFormat: (date, _, localizer) =>
      localizer?.format(date, "h:mma") ?? "",
};

const EmployeeCalendar = ({ events, calendarDate, calendarView, changeDate = () => undefined, changeView = () => undefined }: Props) => {
    const [formattedEvents, setFormattedEvents] = useState<FormattedEvent[]>([]);

    /* istanbul ignore next */
    useEffect(() => {
      if(events.length) {
        const listEvents = events.map(event => {
          const { start_date, end_date } = getEventStartEndDate(event);
          return {
            ...event,
            end: end_date,
            start: start_date,

          }
        })
        setFormattedEvents(listEvents)
      }
    }, [events])

    const components = {
        header: CalendarHeader,
        dateCellWrapper: (props: any) => (
            <DayCell events={events} calendarDate={calendarDate} view={calendarView} {...props} />
        ),
        event: calendarView === Views.WEEK ? WeekEvent : MonthEvent,
    };

    const onPrevClick = useCallback(() => {
        if (calendarView === Views.MONTH) {
          changeDate(moment(calendarDate).subtract(1, "M").toDate());
        } else {
          changeDate(moment(calendarDate).subtract(1, "w").toDate());
        }
      }, [calendarView, calendarDate]);
    
      const onNextClick = useCallback(() => {
        if (calendarView === Views.MONTH) {
          changeDate(moment(calendarDate).add(1, "M").toDate());
        } else {
          changeDate(moment(calendarDate).add(1, "w").toDate());
        }
      }, [calendarView, calendarDate]);

    
      const renderHeaderContent = () => {
        if(calendarView === Views.WEEK) {
          return (
            <Typography className='week-name'>{getWeekRange(calendarDate)}</Typography>
          )
        } else {
          return (
            <>
              <Typography className='month-name'>{moment(calendarDate).format("MMMM")}</Typography>
              <Typography className='year-name'>{moment(calendarDate).year()}</Typography>
            </>
          )
        }
      }

    return (
        <AppWrapper>
                <Box className="border-wrapper">
                    <Box className="calendar-wrapper-emp">
                        <Box className="calendar-control">
                            <Box className='header-wrapper'>
                                <Box className="header-month">
                                    <Box data-test-id="prev-arrow" onClick={onPrevClick}>
                                        <img src={leftArrow} className='arrow-style-emp'/>
                                    </Box>
                                    <Box className='header-year-month'>
                                        {renderHeaderContent()}
                                    </Box>
                                    <Box data-test-id="next-arrow" onClick={onNextClick}>
                                        <img src={rightArrow} className='arrow-style-emp'/>
                                    </Box>
                                </Box>
                                <Box className='view-switch'>
                                    <Box data-test-id="switch-week" onClick={() => changeView(Views.WEEK)} className={calendarView === Views.WEEK ? 'view-item-active' : 'view-item-emp'}>Week</Box>
                                    <Box data-test-id="switch-month" onClick={() => changeView(Views.MONTH)} className={calendarView === Views.MONTH ? 'view-item-active' : 'view-item-emp'}>Month</Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ overflowX: 'auto', width: '100%' }}>
                          <Box style={{ minWidth: '515px'}}>
                          <Calendar
                            startAccessor="start"
                            localizer={localizer}
                            endAccessor="end"
                            defaultDate={calendarDate}
                            events={formattedEvents}
                            date={calendarDate}
                            toolbar={false}
                            view={calendarView}
                            onNavigate={changeDate}
                            onView={changeView}
                            views={["week", "month"]}
                            defaultView="week"
                            components={components}
                            style={{ height: calendarView === Views.WEEK ? '480px' : '420px' }}
                            scrollToTime={new Date(2024, 7, 3, 8, 0, 0)}
                            formats={formats}
                        />
                          </Box>
                        </Box>
                    </Box>
                </Box>
        </AppWrapper>
    )

}


const AppWrapper = styled("div")(({ theme }) => ({
  fontFamily: 'Montserrat',

  "& .MuiTypography-root.MuiTypography-body1": {
    fontFamily: 'Montserrat',
  },

  "& .arrow-style-emp": {
    userSelect: 'none', cursor: 'pointer'
  },
  "& .border-wrapper": {
    border: '1px solid #E2E8F0',
    padding: '8px',
    [theme.breakpoints.up("md")]: {
        padding: '16px',
      },
  },

  "& .month-name": {
    fontSize: '24px',
    color: '#6F05EA',
    fontWeight: 600,
    [theme.breakpoints.up("sm")]: {
      fontSize: '36px',
    },
  },

  "& .week-name": {
    color: '#000',
    fontWeight: 600,
    fontSize: '24px',
    [theme.breakpoints.up("sm")]: {
      fontSize: '36px',
    },
  },

  "& .year-name": {
    color: '#000000',
    fontSize: '24px',
    fontWeight: 600,
    padding: '0 6px',
    borderBottom: '1px solid #000',
    lineHeight: '1.2',
    [theme.breakpoints.up("sm")]: {
      fontSize: '36px',
      padding: '0 12px',
      // lineHeight: '1.2',
    },
  },

  "& .header-year-month": {
    gap: '8px',
    display: 'flex',
    alignItems: 'center'
  },

  "& .view-switch": {
    background: '#F5F5F4',
    padding: '4px',
    borderRadius: '50px',
    display: 'flex',
    gap: '6px',
    color: '#64748B',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 500
  },

  "& .view-item-emp": {
    cursor: 'pointer',
    padding: '6px 12px'
  },

  "& .view-item-active": {
    padding: '6px 12px',
    borderRadius: '50px',
    background: '#F0E5FF',
    fontSize: '14px',
    fontWeight: 700,
    color: '#6200EA',
    border: '1px solid #E1CCFF',
    cursor: 'pointer',
  },

  "& .header-wrapper": {
    width: '100%',
    display: 'flex',
    gap: '12px',
    padding: '16px',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up("md")]: {
        flexDirection: 'row',
        padding: '16px 40px',
      },
  },
  "& .header-month": {
    alignItems: 'center',
    display: 'flex',
    gap: '28px',
    [theme.breakpoints.down("xs")]: {
      width: '100%',
      justifyContent: 'space-between'
    },
  },
  "& .calendar-wrapper-emp": {
    width: "100%",
  },
  "& .rbc-allday-cell": {
    height: "auto",
    "& .rbc-event": {
      "& .week-event-wrapper": {
        padding: '3px 16px',
        background: "#8833FF",
        "& .event-title": {
          color: "#fff",
        }
      },
      "& .top-time": {
        display: 'none'
      }
    },
    "& .rbc-row": {
      minHeight: 0,
    },
  },
  "& .rbc-calendar": {
    padding: "0px",
    [theme.breakpoints.up("sm")]: {
        padding: "0 28px",
      },
  },
  "& .rbc-time-content": {
    border: "none",
  },
  "& .rbc-time-view": {
    border: "none",
  },
  "& .rbc-time-header": {
    marginRight: "6px !important",
  },
  "& .rbc-current-time-indicator": {
    backgroundColor: "#6F05EA",
    height: "2px",
  },
  "& .rbc-header": {
    borderLeft: "none !important",
    borderBottom: "none !important",
    paddingBottom: "8px",
  },
  "& .rbc-event, .rdc-background-event": {
    cursor: 'default',
    padding: "0px !important",
    border: "none !important",
    borderRadius: "18px",
    backgroundColor: "transparent",
  },
  "& .rbc-overflowing": {
    borderRight: "none !important",
    paddingTop: "16px",
    borderTop: "1px solid #B7B7B7",
  },
  
  "& .rbc-event-label": {
    display: "none",
  },
  "& .rbc-button-link": {
    cursor: 'default'
  },
  "& .rbc-events-container": {
    width: "100% !important",
    borderRadius: "18px",
    border: "none",
  },
  "& .c-header-text": {
    fontSize: "14px",
    fontWeight: 600,
    color: "#6F05EA",
  },
  "& .custom-header": {
    fontFamily: "inherit",
    display: "flex",
    flexDirection: "column",
    gap: '8px',
  },
  "& .rbc-timeslot-group": {
    minHeight: "66px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    borderLeft: "none !important",
    borderBottom: "1px solid #B7B7B7",
  },
  "& .calendar-control": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .rbc-time-slot": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    flex: 0,
    fontWeight: 600,
    fontSize: '14px'
  },
  "& .rbc-month-header": {
    paddingTop: "12px",
    borderTop: "1px solid #B7B7B7",
  },
  "& .rbc-time-header-content": {
    border: "none",
  },
  "& .rbc-month-view": {
    border: 0,
  },
  "& .rbc-month-row": {
    maxHeight: "70px",
    borderTop: "none",
  },
  "& .rbc-show-more": {
    display: "none",
  },
  "& .rbc-date-cell": {
    display: "none",
  },
}));



export default EmployeeCalendar;

