import React, { CSSProperties } from "react";
// Customizable Area Start
import { Box, Button, CircularProgress, MenuItem, Select, SelectProps, styled, Typography } from "@material-ui/core";
import ServiceDashboardController, { Props } from "./ServiceDashboardController.web";
import { allBookingIcon, cancelledBookingIcon, chartBlue, chartGreen, chartRed, completedBookingIcon, lossBlack, profitBlack, profitGreen, userGroup } from "../assets";
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import DoughnutChart from "./DoughnutChart.web";
import AppoinmentsDashboard from "./AppoinmentsDashboard.web";
import CustomersDashboard from "./CustomersDashboard.web";
import { checkCondition } from "../../../../components/src/HelperUtils";
import CustomRangePicker from "./CustomRangePicker.web";

const configJSON = require("../config.js");
const selectMenuProps: Partial<SelectProps['MenuProps']> = {
  getContentAnchorEl: null,
  anchorOrigin: {
    horizontal: "left",
    vertical: "bottom",
  },
  transformOrigin: {
    horizontal: "left",
    vertical: "top",
  },
};
const CustomSelectMenuProps = {
  ...selectMenuProps,
  PaperProps: {
    style: {
      borderRadius: "8px"
    }
  }
}
interface IMenu {
  label: string,
  value: string,
}
interface IChartLabel {
  label: string,
  value: string,
  color: string
}
// Customizable Area End

export default class ServiceDashboard extends ServiceDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderLoader = () => {
    return (
      <StyledLoaderBox>
        <StyledLoader />
      </StyledLoaderBox>
    )
  }

  renderCalender = () => {
    return (
      <CustomRangePicker onChangeDate={this.handleGetDateRange} rangeDatePicker={this.state.rangePickerData} data-test-id="range-picker" />
    )
  }
 
  renderFilter = () => {
    return (
      <>
        <FilterWrapper>
          <SelectFilter
            defaultValue={""}
            displayEmpty
            disableUnderline
            MenuProps={CustomSelectMenuProps}
            IconComponent={CustomMenuIcon}
            value={this.state.selectRange}
          >
            <CustomMenuItem value={""} disabled>{this.transDash("Select Date Range")}</CustomMenuItem>
            {(configJSON.filterMenuList as IMenu[]).map((item, index) => {
              return <CustomMenuItem value={item.value} onClick={() => this.handleRangeChange(item.value)} data-test-id={`select-filter-${index}`}>
                {this.transDash(item.label)}
              </CustomMenuItem>
            })}
          </SelectFilter>
        </FilterWrapper>
      </>
    )
  }
  renderDefaultFirstSection = (msg: string, img: any) => {
    return (
      <>
        <LeftSubBox>
          <DefaultFirstMsg>{this.transDash(msg)}</DefaultFirstMsg>
        </LeftSubBox>
        <RightSubBox>
          <img src={img} />
        </RightSubBox>
      </>
    )
  }
  renderFirstSection = () => {
    const { isBookingInfoLoading, bookingList } = this.state;
    const isAllBooking = checkCondition(Boolean(bookingList.total_schedule === 0), true, false) as boolean;
    const isCompleted = checkCondition(Boolean(bookingList.total_schedule === 0), true, false) as boolean;
    const isCancelled = checkCondition(Boolean(bookingList.total_schedule === 0), true, false) as boolean;
    return (
      isBookingInfoLoading ? (
        this.renderLoader()
      ) : (
        <RowBox>
          <SmallCard>
            {isAllBooking ? (
              <>
                {this.renderDefaultFirstSection("No booking yet", allBookingIcon)}
              </>
            ) : (
              <>
                <LeftSubBox>
                  <StyledFont1 style={defaultStyle.colorBlue}>{bookingList.total_schedule}</StyledFont1>
                  <Typography style={defaultStyle.smallFont}>{this.transDash("All Booking")}</Typography>
                  <RowCenterBox>
                    <img src={profitBlack} />
                    <Typography style={defaultStyle.smallFont3}>20,3%</Typography>
                  </RowCenterBox>
                  <Typography style={defaultStyle.smallFont2}>( {this.transDash("last Week")} )</Typography>
                </LeftSubBox>
                <RightSubBox>
                  <img src={chartBlue} />
                </RightSubBox>
              </>
            )}
          </SmallCard>
          <SmallCard>
            {isCompleted ? (
              <>
                {this.renderDefaultFirstSection("No completed appointment yet", completedBookingIcon)}
              </>
            ) : (
              <>
                <LeftSubBox>
                  <StyledFont1 style={defaultStyle.colorGreen}>{bookingList.completed_schedule}</StyledFont1>
                  <Typography style={defaultStyle.smallFont}>{this.transDash("Completed")}</Typography>
                  <RowCenterBox>
                    <img src={profitBlack} />
                    <Typography style={defaultStyle.smallFont3}>20,3%</Typography>
                  </RowCenterBox>
                  <Typography style={defaultStyle.smallFont2}>( {this.transDash("last Week")} )</Typography>
                </LeftSubBox>
                <RightSubBox>
                  <img src={chartGreen} />
                </RightSubBox>
              </>)
            }
          </SmallCard>
          <SmallCard>
            {isCancelled ? (
              <>
                {this.renderDefaultFirstSection("No cancelled appointment yet", cancelledBookingIcon)}
              </>
            ) : (
              <>
                <LeftSubBox>
                  <StyledFont1 style={defaultStyle.colorRed}>{bookingList.cancelled_schedule}</StyledFont1>
                  <Typography style={defaultStyle.smallFont}>{this.transDash("Cancelled")}</Typography>
                  <RowCenterBox>
                    <img src={lossBlack} />
                    <Typography style={defaultStyle.smallFont3}>20,3%</Typography>
                  </RowCenterBox>
                  <Typography style={defaultStyle.smallFont2}>( {this.transDash("last Week")} )</Typography>
                </LeftSubBox>
                <RightSubBox>
                  <img src={chartRed} />
                </RightSubBox>
              </>
            )}
          </SmallCard>
        </RowBox>
      )
    )
  }
  renderSecondSection = () => {
    const { isServiceLoading } = this.state;
    return (
      <Section2Wrapper>
        {isServiceLoading ? (this.renderLoader()) : (<></>)}
        {!isServiceLoading ? (
          <>
            <SubSection>
              {this.renderChart()}
            </SubSection>
            <SubSection>
              {this.renderMediumCards()}
            </SubSection>
          </>
        ) : (<></>)}
      </Section2Wrapper>
    )
  }
  renderChart = () => {
    const { servicePopularityInfo } = this.state;
    const labels = servicePopularityInfo?.serviceData?.data?.map((item) => Object.keys(item)[0]);
    const dataValues:number[] = servicePopularityInfo?.serviceData?.data?.map(
      (item) => parseInt(Object.values(item)[0], 10)
    );

    const chartData = {
      labels,
      dataValues,
      colorArray: servicePopularityInfo.colorArray
    };

    return (
      <ChartWrapper>
        <ChartTitleFont>{this.transDash("Service Popularity")}</ChartTitleFont>
        <ChartBox>
          <DoughnutChart chartData={chartData}/>
          <ChartLabelWrapper>
            {servicePopularityInfo?.serviceData?.data?.length > 0 && (servicePopularityInfo?.serviceData?.data).map((item,index) => {
              return (
                <LabelBox>
                  <DotBox style={{ backgroundColor: servicePopularityInfo.colorArray[index] }}> </DotBox>
                  {Object.entries(item).map(([label, value]:[string,string]) => (
                    <>
                      <Typography style={defaultStyle.chartLabelFont}>{label}</Typography>
                      <Typography style={defaultStyle.labelValueFont}>{value}</Typography>

                    </>
                  ))}

                </LabelBox>
              )
            })}
          </ChartLabelWrapper>
        </ChartBox>
      </ChartWrapper>
    )
  }
  renderMediumCards = () => {
    const servicePopularityInfo  = this.state.servicePopularityInfo?.serviceData;
    const isRevenueGrowth = checkCondition(Boolean(servicePopularityInfo.growth_of_revenue === "0 %" || servicePopularityInfo.growth_of_revenue === undefined), false, true) as boolean;
    const isCustomerGrowth = checkCondition(Boolean(servicePopularityInfo.growth_of_customer === "0 %" || servicePopularityInfo.growth_of_customer === undefined), false, true) as boolean;
    const totalRevenue = checkCondition(Boolean(servicePopularityInfo.total_revenue === 0 || servicePopularityInfo.total_revenue === undefined), 0, servicePopularityInfo.total_revenue) as string;
    const totalCustomer = checkCondition(Boolean(servicePopularityInfo.number_of_customer === 0 || servicePopularityInfo.number_of_customer === undefined), 0, servicePopularityInfo.number_of_customer) as string;
    
    return (
      <ColumnBox>
        <MediumCard>
          <LeftMediumBox>
            <TotalLabelFont>{this.transDash("Total Revenue")}</TotalLabelFont>
            <TotalRevenueFont style={{ marginTop: 28 }}>{servicePopularityInfo.total_revenue} {" "}€</TotalRevenueFont>
          </LeftMediumBox>
          <RightMediumBox>
            <Typography style={defaultStyle.mediumFont}>{this.transDash("Change")}</Typography>
            <RowCenterBox2>
              {isRevenueGrowth && <img src={profitGreen} />}
              <StyledMediumFont style={checkCondition(isRevenueGrowth, defaultStyle.colorGreen, defaultStyle.defaultMediumCard) as CSSProperties}>{isRevenueGrowth ? (`+${servicePopularityInfo.growth_of_revenue}`) : (`0%`)}</StyledMediumFont>
            </RowCenterBox2>
            <Typography style={defaultStyle.smallFont4}>{isRevenueGrowth && (this.transDash("last Week"))}</Typography>
          </RightMediumBox>
        </MediumCard>

        <MediumCard>
          <LeftMediumBox>
            <TotalLabelFont>{this.transDash("Total Customers")}</TotalLabelFont>
            <RowCenterBox style={{ marginTop: 16 }}>
              <Typography style={defaultStyle.mediumFont1}>{totalCustomer}</Typography>
              <img src={userGroup} />
            </RowCenterBox>
            <ViewDetailsBtn disableTouchRipple disableRipple onClick={this.openCustomerModal}>
              {this.transDash("View Details")} <ViewRightIcon />
            </ViewDetailsBtn>
          </LeftMediumBox>
          <RightMediumBox>
            <Typography style={defaultStyle.mediumFont}>{this.transDash("Change")}</Typography>
            <RowCenterBox2>
              {isCustomerGrowth && <img src={profitGreen} />}
              <StyledMediumFont style={checkCondition(isCustomerGrowth, defaultStyle.colorGreen, defaultStyle.defaultMediumCard) as CSSProperties}>{isCustomerGrowth ? (`+${servicePopularityInfo.growth_of_customer}`) : (`0%`)}</StyledMediumFont>
            </RowCenterBox2>
            <Typography style={defaultStyle.smallFont4}>{isCustomerGrowth && (this.transDash("last Week"))}</Typography>
          </RightMediumBox>
        </MediumCard>

      </ColumnBox>
    )
  }
  renderAppoinments = () => {
    return (
      <>
        <AppoinmentsDashboard navigation={this.props.navigation} appointmentProp={{
          selectedService: this.props.selectedService,
          startDate: this.state.appointmentDates.start_date,
          endDate: this.state.appointmentDates.end_date
        }} />
      </>
    )
  }
  renderCustomerModal = () => {
    const { openCustomerModal } = this.state
    return (
      <>
        {openCustomerModal ?
          <CustomersDashboard
            navigation={this.props.navigation}
            handleClose={this.closeCustomerModal}
            customerListProp={{
              selectedService: this.props.selectedService,
              startDate: this.state.appointmentDates.start_date,
              endDate: this.state.appointmentDates.end_date
            }}
          />
          : <></>}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    const {isRangePickerOpen} = this.state.rangePickerData;
    return (
      <MainWrapper>
        {this.renderFilter()}
        {isRangePickerOpen && this.renderCalender()}
        {this.renderFirstSection()}
        {this.renderSecondSection()}
        {this.renderAppoinments()}
        {this.renderCustomerModal()}
      </MainWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  backgroundColor: "#F6F4F9",
  // padding: "64px"
})
const RowBox = styled(Box)({
  padding: "0 10px",
  display: "flex",
  flexDirection: "row",
  gap: "15px",
  "@media(max-width:600px)": {
    flexDirection: "column",
  }
})

const SmallCard = styled(Box)({
  width: "100%",
  border: "1px solid #E2E8F0",
  background: "#FFF",
  borderRadius: "8px",
  padding: "10px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  height: "128px",
  "@media(max-width:600px)": {
    padding: "0px",
  }
})
const LeftSubBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  alignItems: "center"
})
const RightSubBox = styled(Box)({
  height: "100%",
  display: "flex",
  alignItems: "end",
})
const StyledFont1 = styled(Typography)({
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "36px",
  lineHeight: "36px",
})
const FilterWrapper = styled(Box)({
  marginBottom: "40px"
})

const SelectFilter = styled(Select)({
  background: "#FFF",
  borderRadius: "8px",
  height: "40px",
  width: "100%",
  maxWidth: "223px",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "20px",
  fontFamily: "Montserrat",
  display: "flex",
  alignItems: "center",
  color: "#94A3B8",
  "& .MuiSelect-select": {
    backgroundColor: "transparent",
    height: "40px",
    padding: "0 16px",
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
  }
})
const CustomMenuIcon = styled(KeyboardArrowDown)({
  color: '#6F05EA',
  width: 32,
  height: 32,
  marginTop: "-4px",
  marginRight: "8px"
});
const CustomMenuItem = styled(MenuItem)({
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "17px",
  fontFamily: "Montserrat",
  height: 56,
  padding: "0 30px",
  backgroundColor: "#FFF",
  color: "#6F05EA",
  "&.Mui-disabled": {
    color: "#94A3B8",
    backgroundColor: "#FFF",
  },
  "&.Mui-selected.Mui-disabled": {
    color: "#94A3B8",
    backgroundColor: "#FFF",
  },
  "&.Mui-selected": {
    backgroundColor: "#6F05EA",
    color: "#FFF"
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#6F05EA",
    color: "#FFF"
  },
})

const RowCenterBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "2px"
})

const Section2Wrapper = styled(Box)({
  margin: "15px 0",
  display: "flex",
  gap: "15px",
  "@media(max-width:1200px)": {
    flexDirection: "column",
  }
})
const SubSection = styled(Box)({
  flex: 1,
  "@media(min-width:1200px)": {
    width:"50%",
  }
})

const ColumnBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  "@media(max-width:1200px)": {
    width:"100%"
  },
})

const MediumCard = styled(Box)({
  border: "1px solid #E2E8F0",
  background: "#FFF",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 36px",
  height: 132,
})
const LeftMediumBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
})
const RightMediumBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: '12.5px',
  alignItems: "center"
})
const RowCenterBox2 = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "9px"
})
const StyledMediumFont = styled(Typography)({
  fontWeight: 600,
  fontSize: "26px",
  lineHeight: "18px",
  fontFamily: "Montserrat",
  "@media(max-width:450px)": {
    fontSize: "22px",
  },
})
const TotalLabelFont = styled(Typography)({
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "14px",
  fontFamily: "Montserrat",
  color: "#292D32",
  "@media(max-width:450px)": {
    fontSize: "18px",
  },
})
const TotalRevenueFont = styled(Typography)({
  fontWeight: 500,
  fontSize: "40px",
  lineHeight: "28px",
  fontFamily: "Montserrat",
  color: "#292D32",
  maxWidth:225,
  overflow:"hidden",
  textOverflow:"ellipsis",
  whiteSpace:"nowrap",
  height:32,
  padding:"1px 0",
  "@media(max-width:450px)": {
    fontSize: "30px",
  },
})
const ViewDetailsBtn = styled(Button)({
  textTransform: "none",
  padding: 0,
  marginTop: 9,
  height: 22,
  width: 'fit-content',
  backgroundColor: "transparent",
  "&:hover": {
    background: "none !important",
  },
  "& .MuiButton-label": {
    justifyContent: "start",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "12px",
    fontFamily: "Montserrat",
    color: "#6F05EA"
  }
})
const ViewRightIcon = styled(KeyboardArrowRight)({
  marginLeft: 6
})

const ChartWrapper = styled(Box)({
  border: "1px solid #E2E8F0",
  background: "#FFF",
  borderRadius: "8px",
  padding: "40px 36px",
})

const ChartTitleFont = styled(Typography)({
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "14px",
  fontFamily: "Montserrat",
  color: "#000000",
  marginBottom: "28px"
})
const ChartBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  gap: "50px",
  "@media(max-width:450px)": {
    flexDirection: "column",
    alignItems:"center"
  },
})
const ChartLabelWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "12px"
})
const LabelBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center"
})
const DotBox = styled(Box)({
  height: 12,
  width: 12,
  borderRadius: "50%"
})
const defaultStyle = {
  colorBlue: {
    color: "#6F05EA"
  } as CSSProperties,
  colorGreen: {
    color: "#059669"
  } as CSSProperties,
  defaultMediumCard: {
    color: "#94A3B8"
  } as CSSProperties,
  colorRed: {
    color: "#C10000"
  } as CSSProperties,
  smallFont: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    fontFamily: "Montserrat",
    color: "#34424B"
  } as CSSProperties,
  smallFont2: {
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "12px",
    fontFamily: "Montserrat",
    color: "#292D32"
  } as CSSProperties,
  smallFont3: {
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "14px",
    fontFamily: "Montserrat",
    color: "#34424B"
  } as CSSProperties,
  smallFont4: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "8px",
    fontFamily: "Montserrat",
    color: "#292D32",
    "@media(max-width:450px)": {
      fontSize: "10px",
    },
  } as CSSProperties,
  chartLabelFont: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    fontFamily: "Montserrat",
    color: "#292D32",
    overflow:"hidden",
    whiteSpace:"nowrap",
    maxWidth:"112px",
    textOverflow:"ellipsis",
  } as CSSProperties,
  labelValueFont: {
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "16px",
    fontFamily: "Montserrat",
    color: "#0F172A"
  } as CSSProperties,
  mediumFont: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "13px",
    fontFamily: "Montserrat",
    color: "#292D32",
    "@media(max-width:450px)": {
      fontSize: "16px",
    },
  } as CSSProperties,
  mediumFont1: {
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "15px",
    fontFamily: "Montserrat",
    color: "#292D32",
    maxWidth:170,
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding:"1px 0px",
    whiteSpace: "nowrap",
    "@media(max-width:450px)": {
      fontSize: "16px",
    },
  } as CSSProperties,
}

const StyledLoaderBox = styled(Box)({
  padding: "60 0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  border: "1px solid #E2E8F0",
  background: "#FFF",
  borderRadius: "8px",
})

const StyledLoader = styled(CircularProgress)({
  color: "#6F05EA"
})

const DefaultFirstMsg = styled(Typography)({
  color: "#34424B",
  fontSize: 15,
  fontWeight: 600,
  maxWidth: 119
})
// Customizable Area End
