import React, { Component } from 'react';
import { Chart, DoughnutController, ArcElement, Tooltip, Legend, ChartConfiguration } from 'chart.js';
import { checkCondition } from '../../../../components/src/HelperUtils';

interface DoughnutChartProps {
  chartData: {
    labels: string[];
    dataValues: number[];
    colorArray: string[];
  };
}

Chart.register(DoughnutController, ArcElement, Tooltip, Legend);

export default class DoughnutChart extends Component<DoughnutChartProps> {
  
  chartRef = React.createRef<HTMLCanvasElement>();
  chartInstance: Chart<"doughnut"> | null = null; 

  componentDidMount() {
    this.getChartInfo();
  }

  componentDidUpdate(prevProps: Readonly<DoughnutChartProps>) {
    if (prevProps.chartData !== this.props.chartData) {
      this.destroyChart();
      this.getChartInfo();
    }
  }

  componentWillUnmount() {
    this.destroyChart();
  }

  getChartInfo = () => {
    const { chartData } = this.props;
    const ctx = this.chartRef.current?.getContext("2d");
    if (!ctx) return;

    const isNullValue = chartData?.dataValues?.every((value) => value === 0);
    const dataSet = checkCondition(isNullValue, [100], chartData?.dataValues) as number[];
    const chartColor = checkCondition(isNullValue, ["#E2E8F0"], chartData?.colorArray) as string[];
    const borderWidth = checkCondition(isNullValue, 0, 2) as number;
   
    const data = {
      labels: chartData.labels,
      datasets: [
        {
          label: "Votes",
          data: dataSet,
          backgroundColor: chartColor,
          borderColor: ["#FFF", "#FFF", "#FFF", "#FFF", "#FFF"],
          borderWidth: borderWidth,
        },
      ],
    };

    const options: ChartConfiguration<"doughnut">["options"] = {
      responsive: false,
      plugins: {
        legend: { display: false },
        tooltip: { enabled: !isNullValue },
      },
      cutout: "70%",
    };

    this.destroyChart();

    this.chartInstance = new Chart<"doughnut">(ctx, {
      type: "doughnut",
      data,
      options,
    });
  };

  destroyChart = () => {
    if (this.chartInstance) {
      this.chartInstance.destroy();
      this.chartInstance = null;
    }
  };

  render() {
    return (
      <div style={{ width: "160px", height: "160px" }}>
        <canvas
          ref={this.chartRef}
          width="160"
          height="160"
          style={{ display: "block" }}
        ></canvas>
      </div>
    );
  }
}