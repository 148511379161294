import React from "react";
// Customizable Area Start
import { Box, styled, TextField, InputAdornment, Chip, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from "@material-ui/core";
import AppoinmentsDashboardController, { Props } from "./AppoinmentsDashboardController.web";
import Search from '@material-ui/icons/Search';
import moment from "moment";
import { NoAppoinment, NoResult } from "../assets";
import BookingDetails from "./BookingDetails.web";

const getChipColor = (status: "Completed" | "Booked" | "Cancelled") => {
    let color = ""
    let bgColor = ""
    switch (status) {
        case "Completed":
            color = "#059669"
            bgColor = "#D1FAE5"
            break;
        case "Booked":
            color = "#F59E0B"
            bgColor = "#FEF3C7"
            break;
        case "Cancelled":
            color = "#C10000"
            bgColor = "#FEE2E2"
            break;
    }
    return { color, bgColor }

}

// Customizable Area End

export default class AppoinmentsDashboard extends AppoinmentsDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderDefaultSection = () => {
        return (
            <DefaultWrapper>
                <img src={NoResult} />
                <NoRecordFont>{this.transAppointmentDash("No results found!")}</NoRecordFont>
            </DefaultWrapper>
        )
    }
    renderList = () => {
        const { filteredList, appointmentList } = this.state
        return (
            <>
                <RowWrapper>
                    {appointmentList.length > 0 && (
                        filteredList.length ? filteredList.map((record, index) => {
                            const status = record.service_status
                            const chipColor = getChipColor(status)
                            const serviceDateDay = moment(record.service_date).format("DD")
                            const serviceDateMonth = moment(record.service_date).format("MMM")
                            return (
                                <RowBox key={index} onClick={() => this.handleOpenBookingDetails(record.id)} data-testid={`record-${index + 1}`}>
                                    <CenterBox>
                                        <CenterBox width={60} flexDirection={"column"}>
                                            <RowFont>{serviceDateDay}</RowFont>
                                            <RowFont className="month">{serviceDateMonth}</RowFont>
                                        </CenterBox>
                                        <CenterBox width={130}>
                                            <RowFont noWrap title={record.service_type}>{record.service_type}</RowFont>
                                        </CenterBox>
                                        <CenterBox width={150}>
                                            <RowFont noWrap title={record.customer_name}>{record.customer_name}</RowFont>
                                        </CenterBox>
                                        <CenterBox width={100}>
                                            <RowFont noWrap title={record.customer_phone}>{record.customer_phone}</RowFont>
                                        </CenterBox>
                                    </CenterBox>

                                    <CenterBox>
                                        <CenterBox width={96}>
                                            <CustomChip
                                                label={status}
                                                style={{ color: chipColor.color, backgroundColor: chipColor.bgColor }}
                                            />
                                        </CenterBox>
                                        <CenterBox width={55}>
                                            <RowFont>{record.service_charge}€</RowFont>
                                        </CenterBox>
                                    </CenterBox>
                                </RowBox>
                            )
                        }) : this.renderDefaultSection()
                    )}
                    {appointmentList.length > 0 ? this.renderAppoinments() : this.renderNoAppoinments()} 
                </RowWrapper>
            </>
        )
    }
    renderNoAppoinments = () => {
        return (
            <CenterBox style={{ padding: "106px 12px" }}>
                <img src={NoAppoinment} />
                <NoRecordFont>{this.transAppointmentDash("No appointments yet")}</NoRecordFont>
            </CenterBox>
        )
    } 

    renderAppoinments = () => {
        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Service Type</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(this.state.appointmentData?.data || []).map((booking: any) => (
                            <TableRow key={booking.id}>  
                                <TableCell>{booking.attributes.start_date}</TableCell>
                                <TableCell>{booking.attributes.service.service_type}</TableCell>
                                <TableCell>{booking.attributes.customer.name}</TableCell>
                                <TableCell>{booking.attributes.customer.full_phone_number}</TableCell>
                                <TableCell>
                                    <Chip
                                        label={booking.attributes.appointment_status}
                                        style={booking.attributes.appointment_status}
                                    />
                                </TableCell>
                                <TableCell>{booking.attributes.total_price}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


        )
    }

    renderBookingDetails = () => {
        const { openBookingDetails, scheduleId } = this.state;
        const { selectedService } = this.props.appointmentProp;
        return (
            <>
                {openBookingDetails ?
                    <BookingDetails
                        navigation={this.props.navigation}
                        handleClose={this.handleCloseBookingDetails}
                        bookingDetailsProp={{
                            selectedService: selectedService,
                            scheduleId: scheduleId
                        }}
                        data-testid="booking-details-popup"
                    /> : <></>}
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MainWrapper>
                <TitleBox>{this.transAppointmentDash("Appointments")}</TitleBox>
                <SearchField
                    placeholder={this.transAppointmentDash("Search")}
                    value={this.state.searchValue}
                    onChange={(event) => this.handleChangeSearch(event.target.value)}
                    data-testid="search-field"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                {this.renderList()}
                {this.renderBookingDetails()}
            </MainWrapper>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
    marginTop: "15px",
    border: "1px solid #E2E8F0",
    background: "#FFF",
    borderRadius: "8px",
    padding: "10px 25px",
})
const TitleBox = styled(Box)({
    padding: "9px 14px",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "24px",
    fontFamily: "Montserrat",
    color: "#6F05EA"
})
const SearchField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        borderColor: "#CBD5E1",
        paddingLeft: "8",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CBD5E1",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CBD5E1",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CBD5E1",
        },
    },
    "& .MuiInputAdornment-root": {
        margin: "0"
    },
    "& .MuiOutlinedInput-input": {
        padding: "10px 8px",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Montserrat",
        "&::placeholder": {
            color: "#94A3B8",
            opacity: 1
        },
    }
})
const SearchIcon = styled(Search)({
    color: "#94A3B8"
})
const RowWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    maxHeight: "320px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
        height: "0 !important",
    },
})
const RowBox = styled(Box)({
    cursor: "pointer",
    minHeight: "49px",
    borderBottom: "1px solid #E2E8F0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
})
const CenterBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
})
const CustomChip = styled(Chip)({
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    fontFamily: "Montserrat",
    height: 22,
})
const RowFont = styled(Typography)({
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    fontFamily: "Montserrat",
    color: "#000000",
    "&.month": {
        fontSize: "10px",
        lineHeight: "4px",
        color: "#7D7D7D"
    }
})
const NoRecordFont = styled(Typography)({
    fontWeight: 600,
    fontSize: "20px",
    fontFamily: "Montserrat",
    color: "#B7B7B7",
    padding: "0 8px"
})

const DefaultWrapper = styled(Box)({
    padding: "106px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
})
// Customizable Area End
