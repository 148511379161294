// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IFilteredService } from "../../../../components/src/interfaces.web";
import moment from "moment";
import { generateRandomColors, getDateRange } from "../../../../components/src/HelperUtils";
import { getStorageData } from "../../../../framework/src/Utilities";
import i18n from "../../../../components/src/i18next/i18n";

export interface IRangePickerProp {
    isRangePickerOpen: boolean;
    startDate: Date | null,
    endDate: Date | null
}

export interface IBookingList {
    total_schedule: number,
    completed_schedule: number,
    cancelled_schedule: number
}

export interface IBookingListResp {
    data: IBookingList,
    meta: {
        message: string
    }
}
// Customizable Area End

const configJSON = require("../config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    selectedService: IFilteredService;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    openCustomerModal: boolean,
    selectRange: string,
    calendarDate: Date,
    apiToken:string
    //RESPONSE
    servicePopularityInfo:IServicePopularity,
    bookingList: IBookingList,
    //LOADER
    isServiceLoading:boolean,
    isBookingInfoLoading:boolean,
    //CHILD PROPS
    rangePickerData: IRangePickerProp,
    appointmentDates:{
        start_date:string,
        end_date:string
    }
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
export interface APIModal {
    body?: object;
    formData?: object;
    token?: string;
    contentType?: string;
    method: string;
    endPoint: string;
}

export interface IServicePopularity {
    serviceData: IServicePopularityResp,
    colorArray: string[]
}

export interface IServicePopularityResp {
    data: keyValuePair[],
    number_of_customer: number,
    growth_of_customer: string,
    total_revenue: number,
    growth_of_revenue: string,
    meta: {
        message: string
    }
}

type keyValuePair = {
    label: string; 
    value: string ; 
  };
// Customizable Area End

export default class ServiceDashboardController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apiServicePopularityCallId: string = "";
    apiBookingListCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.AlertMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            openCustomerModal: false,
            selectRange: "Today",
            calendarDate: moment().toDate(),
            apiToken: localStorage.getItem("login_token") || "",
            //RESPONSE
            servicePopularityInfo: {
                serviceData: {} as IServicePopularityResp,
                colorArray: []
            },
            bookingList: {
                total_schedule: 0,
                completed_schedule: 0,
                cancelled_schedule: 0
            },
            //LOADER
            isServiceLoading:false,
            isBookingInfoLoading:false,
            //CHILD PROPS
            rangePickerData:{
                isRangePickerOpen: false,
                startDate: null,
                endDate: null
            },
            appointmentDates:{
                start_date:"",
                end_date:""
            }
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }    

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.apiServicePopularityCallId) {
                this.handleServicePopularityResp(responseJson)
            }
            if (apiRequestCallId === this.apiBookingListCallId) {
                this.handleBookingInfoResp(responseJson)
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    openCustomerModal = () => {
        this.setState({
            openCustomerModal: true
        })
    }
    closeCustomerModal = () => {
        this.setState({
            openCustomerModal: false
        })
    }

    handleRangeChange = (selectType: string) => {
        this.setState({
            selectRange: (selectType)
        });
        if (selectType === "Custom Date Range") {
            this.setState((prevState) => ({
                rangePickerData: {
                    ...prevState.rangePickerData,
                    isRangePickerOpen: true
                },
            }));
        } else {
            const { startDate, endDate } = getDateRange(selectType);
            this.getServicePopularity(startDate, endDate);
            this.getBookingInfo(startDate,endDate);
            this.setState({
                rangePickerData: {
                    isRangePickerOpen: false,
                    startDate: null,
                    endDate: null
                },
                appointmentDates:{
                    start_date: startDate,
                    end_date: endDate
                }
            });
        }
    };

    apiCall = async (data: APIModal) => {
        const { contentType, method, endPoint, body, token, formData } = data;
        let header;
        header = {
            "Content-Type": contentType,
        };
        if (token) {
            header = {
                "Content-Type": contentType,
                token: token,
            };
        }

        const requestedMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestedMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestedMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestedMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestedMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );

        formData &&
            requestedMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formData
            );
        runEngine.sendMessage(requestedMessage.id, requestedMessage);
        return requestedMessage.messageId;
    };

    async componentDidMount() {
        const { startDate, endDate } = getDateRange("Today");
        this.getServicePopularity(startDate, endDate);
        this.getBookingInfo(startDate, endDate);
        this.setState({appointmentDates:{
            start_date: startDate,
            end_date: endDate
        }})
    }

    getServicePopularity = async (startDate: string, endDate: string) => {
        this.setState({ isServiceLoading: true });
        const establishment_ID = await getStorageData("establishment_ID");
        this.apiServicePopularityCallId = await this.apiCall({
            method: configJSON.validationApiMethodType,
            endPoint: `${configJSON.ENDPOINTS.GET_SERVICE_POPULARITY}establishment_id=${establishment_ID}&service_id=${this.props.selectedService.service_id}&start_date=${startDate}&end_date=${endDate}`,
            token: this.state.apiToken,
        });
    };

    handleServicePopularityResp = (responseJson: IServicePopularityResp) => {
        this.setState({ isServiceLoading: false });
        const randomColorArray = generateRandomColors(responseJson?.data?.length);
        this.setState({
            servicePopularityInfo: {
                serviceData: responseJson,
                colorArray: randomColorArray
            }
        })
    }

    handleGetDateRange = (start: Date | null, end: Date | null) => {
        const startDate = moment(start).format("DD-MM-YYYY");
        const endDate = moment(end).format("DD-MM-YYYY")
        this.setState({
            rangePickerData: {
                isRangePickerOpen: false,
                startDate: start,
                endDate: end
            },
            appointmentDates:{
                start_date: startDate,
                end_date: endDate
            }
        });

        this.getServicePopularity(startDate, endDate);
        this.getBookingInfo(startDate, endDate);
    }

    getBookingInfo = async (startDate: string, endDate: string) => {
        const establishment_ID = await getStorageData("establishment_ID");
        this.setState({ isBookingInfoLoading: true })
        this.apiBookingListCallId = await this.apiCall({
            method: configJSON.validationApiMethodType,
            endPoint: `${configJSON.ENDPOINTS.GET_BOOKING_LIST}establishment_id=${establishment_ID}&service_id=${this.props.selectedService.service_id}&start_date=${startDate}&end_date=${endDate}`,
            token: this.state.apiToken,
        });
    };

    handleBookingInfoResp = (responseJson: IBookingListResp) => {
        this.setState({ isBookingInfoLoading: false })
        if (responseJson.data) {
            this.setState({ bookingList: responseJson.data })
        }
    }

    transDash = (dashKey: string) => {
        return i18n.t(dashKey, { ns: "serviceDashboard" });
    }
    // Customizable Area End
}