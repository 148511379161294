// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IFilteredService } from "../../../../components/src/interfaces.web";
import { APIModal } from "./ServiceDashboardController.web";
import { ICustomer } from "./CustomersDashboard.web";
import { getStorageData } from "../../../../framework/src/Utilities";
import { downloadFile } from "../../../../components/src/helpers";
import i18n from "../../../../components/src/i18next/i18n";

export const baseURL = require("../../../../framework/src/config.js").baseURL;

const webConfigJSON = require("../config.js");

export interface ICustomerAttr {
    id: number;
    activated: boolean;
    country_code: number;
    email: string;
    first_name: string;
    full_phone_number: string;
    date_of_birth: string | null;
    last_name: string;
    phone_number: number;
    type: string;
    created_at: string;
    updated_at: string;
    device_id: string | null;
    unique_auth_id: string;
    nationality: string;
    address: string;
    language: string;
    country: string;
    city: string;
    confirm_email: string | null;
    company_name: string | null;
    iban: string | null;
    tax_number: string | null;
    client_id: string | null;
    zipcode: string | null;
    facebook_url: string | null;
    instagram_url: string | null;
    linkedin_url: string | null;
    tiktok_url: string | null;
    extra_details: {
        ISO: string;
        role: string;
    };
    vendor_image: string;
}

interface IScheduleAttr {
    id: number;
    start_date: string;
    end_date: string;
    created_at: string;
    updated_at: string;
}

interface ICustomerList {
    customer: ICustomerAttr;
    schedule: IScheduleAttr;
}

// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    handleClose: () => void;
    customerListProp: {
        selectedService: IFilteredService;
        startDate: string,
        endDate: string
    }
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    searchValue: string,
    apiToken: string,
    customerList: ICustomer[],
    customerFilterList: ICustomer[]
    //LOADER
    isListLoading: boolean,
    isLoadingDownloadCSV: boolean,
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class CustomersDashboardController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apiCustomerListCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            searchValue: "",
            apiToken: localStorage.getItem("login_token") || "",
            customerList: [],
            customerFilterList: [],
            //LOADER
            isListLoading: false,
            isLoadingDownloadCSV: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.apiCustomerListCallId) {
                this.customerListResp(responseJson)
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    handleChangeSearch = (value: string) => {
        const trimmedValue = value.trimStart();
        const fieldsToFilter: Partial<keyof ICustomer>[] = [
            "name",
            "phone_number",
            "email",
            "address"
        ];
        const filteredArr = (this.state.customerList).filter((item) =>
            fieldsToFilter.some((field) =>
                item[field as keyof ICustomer]?.toString().toLowerCase().includes(trimmedValue.toLowerCase())
            )
        );
        this.setState({
            searchValue: trimmedValue,
            customerFilterList: filteredArr
        })
    }

    apiCall = async (data: APIModal) => {
        const { contentType, method, endPoint, body, token, formData } = data;
        let header;
        header = {
            "Content-Type": contentType,
        };
        if (token) {
            header = {
                "Content-Type": contentType,
                token: token,
            };
        }

        const requestedMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestedMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestedMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestedMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestedMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );

        formData &&
            requestedMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formData
            );
        runEngine.sendMessage(requestedMessage.id, requestedMessage);
        return requestedMessage.messageId;
    };

    async componentDidMount() {
        this.getCustomerList();
    }

    getCustomerList = async () => {
        const { selectedService, startDate, endDate } = this.props.customerListProp;
        const establishment_ID = await getStorageData("establishment_ID");
        this.setState({ isListLoading: true });
        this.apiCustomerListCallId = await this.apiCall({
            method: webConfigJSON.validationApiMethodType,
            endPoint: `${webConfigJSON.ENDPOINTS.GET_CUSTOMER_LIST}start_date=${startDate}&end_date=${endDate}&establishment_id=${establishment_ID}&service_id=${selectedService.service_id}`,
            token: this.state.apiToken,
        });
    };

    customerListResp = (responseJson: ICustomerList[]) => {
        this.setState({ isListLoading: false })
        if (responseJson && responseJson.length) {
            const convertList: ICustomer[] = responseJson.map(({ customer }) => ({
                photo: customer.vendor_image,
                name: `${customer.first_name} ${customer.last_name}`,
                phone_number: customer.full_phone_number,
                email: customer.email,
                address: customer.address,
                id: customer.id
            }));
            this.setState({ customerList: convertList, customerFilterList: convertList })
        }
    }

    downloadCSV = async () => {
        const { startDate, selectedService } = this.props.customerListProp;
        const token = await getStorageData("login_token");
        this.setState({ isLoadingDownloadCSV: true });
        const pathURL = `${baseURL}/${webConfigJSON.ENDPOINTS.DOWNLOAD_CSV_CUSTOMER_LIST}${selectedService.service_id}`;
        downloadFile({ pathURL, token, filename: `${selectedService.name}(${startDate}).csv`, callback: () => this.setState({ isLoadingDownloadCSV: false }) })
    }

    goToViewCustomer = (customerId: number) => {
        const NavigationData = {
            serviceInfo: this.props.customerListProp.selectedService
        }
        if (!customerId) return
        const NavigateMsg: Message = new Message(getName(MessageEnum.NavigationMessage));
        NavigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ViewCustomer"); // ROUTE
        NavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        NavigateMsg.addData(getName(MessageEnum.NavigationScreenNameMessage), customerId); // PARAM
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), NavigationData); // DATA
        NavigateMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(NavigateMsg);
    }

    transCustomerList = (cKey: string) => {
        return i18n.t(cKey, { ns: "serviceDashboard" });
    }
    // Customizable Area End
}
