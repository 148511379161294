// Customizable Area Start
import React from "react";
import {
  Button,
  Box,
  Typography,
  Avatar,
  Tabs,
  Tab,
  Switch,
  InputLabel,
  FormHelperText,
  Grid,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { ReactSVG } from 'react-svg';
import PhoneInput from "react-phone-input-2";

import InputField from "../../../components/src/InputField";
import { createTheme, styled } from "@material-ui/core/styles";
import MenuIcon from '@material-ui/icons/Menu';
import { add, calendar, paw, settings, wallet } from "./assets";
import { AppFooter } from "../../../components/src/AppFooter/appFooter";
import { FieldInputProps, Formik, FormikErrors, FormikTouched, FormikValues } from "formik";
import CommonDatePicker from "../../../components/src/Calendar.web";
import moment from "moment";
import { CustomSelectField } from "../../../components/src/CustomSelectField";
import CustomerAvatar from "./components/CustomerAvatar";
import FieldsRow from "../../../components/src/FieldsRow.web";
import GenericModal from "../../../components/src/GenericModal";
import VerticalTabs from "../../../components/src/VerticalTabs.web";
import { formatDate } from "../../../components/src/helpers/getDatePass";
import PaymentTab from "./components/PaymentTab";
import ResetPasswordModal from "./components/ResetPasswordModal";
import ResetEmailModal from "./components/ResetEmailModal";
import DeleteModal from "./components/DeleteModal";
import CalendarTab from "./components/CalendarTab";
import { AppHeader } from "../../../components/src/AppHeader.web";
import EndSessionModal from "./components/EndSessionModal";
import PetInfoHeader from "./components/PetInfoHeader";
import CancelationModal from "./components/CancelationModal";
import ChangeRequestModal from "./components/ChangeRequestModal";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuDrawer from "./components/MenuDrawer";
import { generalValidationSchema, medicalValidationSchema, personalValidationShema, treatsValidationSchena } from "../../../components/src/validators/validatiors";


type EditFields = 'isEditMedical' | 'isEditTreats' | 'isEditGeneral' | 'isEditPersonal' | 'isEditRequestChange';

export interface ICustomSelect {
  field: string,
  getFieldProps: (val: string) => FieldInputProps<string>,
  handleBlur: (e: React.ChangeEvent<any>) => void,
  handleChange: (e: React.FormEvent<HTMLFormElement> | undefined) => void,
  errors: FormikErrors<any>,
  touched: FormikTouched<any>,
  values: FormikValues,
  disabledField: EditFields,
  list?: { label: string; value: string | number | undefined, flag?: string }[],
  editLabel?: { enabled: boolean, styles?: any };
}

interface FormErrors {
  [key: string]: string;
}

interface FormTouched {
  [key: string]: boolean;
}



export const configJSON = require("./config");
import { IPet, Shedule } from "./CustomisableUserProfilesController";
import CustomerWallet from "./components/CustomerWallet";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});



import CustomisableUserProfilesController, {
  Props,
  IField
} from "./CustomisableUserProfilesController";
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  update() {
    throw new Error("Method not implemented.");
  }
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getLabelStyling = () => {
    return (!this.state.isEditPersonal ? styles.secondaryLabel : styles.filledLabel)
  }

  getErrorAndHelperText = (
    field: string,
    errors: FormErrors,
    touched: FormTouched
  ) => {
    const isError: boolean = Boolean(errors[field]) && Boolean(touched[field]);
    const errorText: string = this.transProfile(isError ? errors[field] ?? "" : "");
    const helperText = this.transProfile(errorText);
    return { isError, helperText };
  };

  renderPhoneInput = <T,>(
    field: string,
    getFieldProps: (val: string) => FieldInputProps<string>,
    errors: FormikErrors<T>,
    touched: FormikTouched<T>,
    values: FormikValues,
    setFieldError: (ield: string, message: string | undefined) => void,
    setFieldValue: (field: string, val: string) => void,
    editField: EditFields,
    customLabel: boolean = false
  ) => {
    return (
      <Box style={{ width: '100%' }}>
        {this.renderPhoneLabel({ custom: customLabel, enabled: this.state[editField] })}
        <PhoneInput
          {...getFieldProps(field)}
          disabled={!this.state[editField]}
          inputClass="customer-phone-input"
          country={this.state.isoCountryName.toString().toLowerCase() || "pt"}
          buttonStyle={{ border: "none", background: "transparent" }}
          placeholder={this.transProfile("Phone number")}
          inputStyle={{
            ...styles.medicalFormPhone,
            borderBottom: this.getPhoneErrors(errors, touched, field).style,
            ...(customLabel && this.state[editField] ? styles.inputStyleGeneral : styles.inputStyleDefault),
            ...(!values[field] && this.state[editField] ? { color: '#A6A6A6', fontSize: '13px' } : {}),
          }}
          dropdownStyle={{ fontSize: "13px" }}
          value={values[field]}
          inputProps={{
            maxLength: 16,
            readOnly: !this.state[editField]
          }}
          onChange={(phone, data: { dialCode: string }) => {
            setFieldValue(field, phone);
            setFieldValue("country_code", data.dialCode);
            if (setFieldError) {
              setFieldError(field, undefined)
            }
          }}
          data-test-id={field}
        />
        {this.getPhoneErrors(errors, touched, field).childrens && (
          <FormHelperText style={{ color: "#f44336" }}>
            {this.getPhoneErrors(errors, touched, field).childrens}
          </FormHelperText>
        )}
      </Box>
    )
  }

  renderMenu = (isDrawer: boolean = false) => {
    let menuItems = [
      {
        title: this.transProfile(configJSON.mainMenuCalendarLabel),
        icon: calendar,
        disabled: false,
        id: "0"
      },
      {
        title: this.transProfile(configJSON.mainMenuPetsLabel),
        icon: paw,
        disabled: false,
        id: "1"
      },
      {
        title: this.transProfile(configJSON.mainMenuWalletLabel),
        icon: wallet,
        disabled: false,
        id: "2"
      },
      {
        title: this.transProfile(configJSON.mainMenuSettingsLabel),
        icon: settings,
        disabled: false,
        id: "3"
      }
    ]
    const handleClick = (idx: string, disabled: boolean): void => {
      if (!disabled) this.handleSaveActiveMenu(idx.toString());
    };

    return (
      <StyledMenuBox style={{ ...styles.menu.box, ...(isDrawer && { paddingTop: '36px' }) }} className="main-menu-wrapper setting-menu">
        <Box style={styles.menu.content as React.CSSProperties}>
          <CustomerAvatar isEdit={this.state.isEditPersonal || isDrawer} updateAvatar={this.updateUserAvatar} />
          <Typography style={styles.menu.name as React.CSSProperties}>
            {localStorage.getItem("login_user_name")}
          </Typography>
          <Box style={{ ...styles.general.flexCol, gap: '6px', width: '100%' } as React.CSSProperties}>
            {menuItems.map((item, idx) => {
              const isActive = this.state.currentMenuItem === idx.toString()
              if (item.disabled) return null;
              return (
                <>
                  <Box
                    key={idx}
                    style={{
                      ...styles.menu.item,
                    }}
                    data-test-id={`menu-${idx}`}
                    onClick={() => handleClick(item.id.toString(), item.disabled)}
                  >
                    <ReactSVG src={item.icon} className={`custom-svg ${isActive && 'active'}`} />
                    <Typography style={isActive ? styles.menu.activeTitle : styles.menu.title}>
                      {item.title}
                    </Typography>
                    {item.disabled && <Box className="soon-wrapper"><Typography className="soon-text">Soon</Typography></Box>}

                  </Box>
                  {this.state.currentMenuItem === "2" && isActive &&
                    <Box style={{ paddingLeft: '30px' }}>
                      <VerticalTabs
                        value={this.state.currentWalletTab}
                        data-test-id="settings-tabs-menu"
                        onChange={(_, idx) => this.setState({ currentWalletTab: idx })}
                      >
                        <Tab label={this.translate("My points")} className="vertical-tab" />
                        <Tab label={this.translate("My awards")} className="vertical-tab" />
                        <Tab label={this.translate("Trade points")} className="vertical-tab" />
                      </VerticalTabs>
                    </Box>
                  }
                </>
              )
            })}
            {this.state.currentMenuItem === "3" &&
              <Box style={{ paddingLeft: '30px' }}>
                <VerticalTabs
                  value={this.state.currentSettingsTab}
                  data-test-id="settings-tabs-menu"
                  onChange={(_, idx) => this.setState({ currentSettingsTab: idx, isDrawerOpen: false })}
                >
                  <Tab label={this.transProfile(configJSON.personal)} className="vertical-tab" />
                  <Tab label={this.transProfile(configJSON.security)} className="vertical-tab" />
                  <Tab label={this.transProfile(configJSON.payment)} className="vertical-tab" />
                </VerticalTabs>
              </Box>
            }
          </Box>
        </Box>
      </StyledMenuBox>
    )
  }

  renderPhoneLabel = ({ custom, enabled }: { custom: boolean, enabled: boolean }) => {
    let style = styles.filledLabel;
    if (custom) {
      style = enabled ? styles.filledLabel : styles.secondaryLabel;
    }
    return (
      <InputLabel style={{ ...style, marginBottom: "8px" }}>
        {this.transProfile(configJSON.phone)}
      </InputLabel>
    )
  }

  renderCustomSelectField = ({
    disabledField,
    errors,
    field,
    getFieldProps,
    handleBlur,
    handleChange,
    touched,
    values,
    list,
    editLabel
  }: ICustomSelect) => {
    return (
      <Box style={{ width: '100%' }}>
        <CustomSelectField
          {...getFieldProps(field)}
          id={field}
          name={field}
          data-test-id={field}
          editLabel={editLabel}
          onBlur={handleBlur}
          placeholder={this.transProfile(configJSON.selectPlaceholder)}
          label={this.transProfile(this.getLabel(field))}
          onChange={handleChange}
          value={values[field]}
          disabled={!this.state[disabledField]}
          list={list ? list : [
            { label: "None", value: 0 },
            { label: "1", value: 1 },
            { label: "2", value: 2 },
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 },
          ]}
          fullWidth
          error={
            this.getErrorAndHelperText(
              field,
              errors as FormErrors,
              touched as FormTouched
            ).isError
          }
          helperText={
            this.getErrorAndHelperText(
              field,
              errors as FormErrors,
              touched as FormTouched
            ).helperText
          }
        />
      </Box>
    )
  }

  renderPetMedicalForm = () => {

    const defaultInputProps = {
      style: this.state.isEditMedical ? styles.inputStyleGeneral : styles.inputStyleDefault, readOnly: !this.state.isEditMedical
    }

    return (
      <MedicalWrapper style={{ width: '100%' }} className={this.state.isEditMedical ? 'edit-open' : ''}>
        <Typography style={{ color: '#6F05EA', fontWeight: 600, fontSize: '24px', marginBottom: '35px' }}>{this.transProfile("Health bulletin")}</Typography>
        <Formik
          initialValues={this.state.medical}
          enableReinitialize
          validationSchema={medicalValidationSchema}
          onSubmit={(values) => {
            this.sendMedicalForm(values)
          }}
          data-test-id="medical-form"
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            getFieldProps,
            setFieldValue,
            resetForm,
            setFieldError
          }) => {
            return (
              <form onSubmit={handleSubmit} style={{ textAlign: 'left' }}>
                <Box style={{ marginBottom: "35px", display: 'flex', flexDirection: 'column', gap: '35px' }}>
                  <Box style={{ width: '100%' }}>
                    <InputField
                      {...getFieldProps("medical_conditions")}
                      {...this.getInputProps("medical_conditions", errors, touched, values, this.state.isEditMedical)}
                      label={this.transProfile(configJSON.medicalConditions)}
                      placeholder={this.transProfile(configJSON.medicalConditionsPlaceholder)}
                      inputProps={defaultInputProps}
                      onChange={(event) => this.handleOnlyLettersAndSpaces(event, setFieldValue, "medical_conditions")}
                      withoutStartAdornment
                      data-test-id="InputField"
                    />
                  </Box>
                  <Box style={{ width: '100%' }}>
                    <InputField
                      {...getFieldProps("allergies")}
                      {...this.getInputProps("allergies", errors, touched, values, this.state.isEditMedical)}
                      inputProps={defaultInputProps}
                      label={this.transProfile(configJSON.allergies)}
                      placeholder={this.transProfile(configJSON.allergiesPlaceholder)}
                      onChange={(event) => this.handleOnlyLettersAndSpaces(event, setFieldValue, "allergies")}
                      withoutStartAdornment
                      data-test-id="InputField"
                    />
                  </Box>
                  <Box style={{ width: '100%' }}>
                    <InputField
                      {...getFieldProps("usual_medication")}
                      {...this.getInputProps("usual_medication", errors, touched, values, this.state.isEditMedical)}
                      inputProps={defaultInputProps}
                      label={this.transProfile(configJSON.usualMedication)}
                      placeholder={this.transProfile(configJSON.usualMedicationPlaceholder)}
                      onChange={(event) => this.handleOnlyLettersAndSpaces(event, setFieldValue, "usual_medication")}
                      withoutStartAdornment
                      data-test-id="InputField"
                    />
                  </Box>
                </Box>
                <Box style={{ position: "relative" }}>
                  <CommonDatePicker
                    disabled={!this.state.isEditMedical}
                    selectedDate={
                      this.state.medical.last_health_check_up
                        ? moment(this.state.medical.last_health_check_up, "DD-MM-YYYY").toDate()
                        : null
                    }
                    maxDate={new Date(Date.now())}
                    handleDateChange={(date: Date | null) => {
                      setFieldValue(
                        "last_health_check_up",
                        moment(date).format("DD/MM/YYYY")
                      );
                    }}
                    data-test-id="commondatepicker"
                  />
                  <InputField
                    {...getFieldProps("last_health_check_up")}
                    {...this.getInputProps("last_health_check_up", errors, touched, values, this.state.isEditMedical)}
                    label={this.transProfile(configJSON.lastCheckup)}
                    placeholder={this.transProfile(configJSON.lastCheckupPlaceholder)}
                    inputProps={defaultInputProps}
                    style={{ marginBottom: "57px" }}
                    withoutStartAdornment
                  />
                </Box>
                <Box style={{ display: 'flex', gap: '22px', alignItems: 'center', marginBottom: '22px' }}>
                  <Switch
                    {...getFieldProps("cheap")}
                    id="cheap"
                    name="cheap"
                    disabled={!this.state.isEditMedical}
                    checked={values.cheap}
                    onChange={(event) => {
                      setFieldValue('cheap', event.target.checked);
                    }}
                    data-test-id="switch"
                  />
                  <Typography style={{ fontWeight: 500, fontSize: '16px' }}>{this.transProfile("Microchip")}</Typography>
                </Box>
                {values.cheap &&
                  <InputField
                    {...getFieldProps("microchip")}
                    {...this.getInputProps("microchip", errors, touched, values, this.state.isEditMedical)}
                    label={this.transProfile(configJSON.microcheap)}
                    placeholder={this.transProfile(configJSON.microcheapPlaceholder)}
                    inputProps={defaultInputProps}
                    style={{ marginBottom: "22px" }}
                    withoutStartAdornment
                    data-test-id="InputField"
                  />
                }
                <Box style={{ display: 'flex', gap: '22px', alignItems: 'center', marginBottom: '22px' }}>
                  <Switch
                    {...getFieldProps("vaccines_up_to_date")}
                    id="vaccines_up_to_date"
                    name="vaccines_up_to_date"
                    disabled={!this.state.isEditMedical}
                    checked={values.vaccines_up_to_date}
                    onChange={(event) => {
                      setFieldValue('vaccines_up_to_date', event.target.checked);
                    }}
                    data-test-id="switch"
                  />
                  <Typography style={{ fontWeight: 500, fontSize: '16px' }}>{this.transProfile("Vaccines up to date")}</Typography>
                </Box>
                <Box style={{ display: 'flex', gap: '22px', alignItems: 'center', marginBottom: '57px' }}>
                  <Switch
                    {...getFieldProps("sterilized")}
                    id="sterilized"
                    name="sterilized"
                    checked={values.sterilized}
                    disabled={!this.state.isEditMedical}
                    onChange={(event) => {
                      setFieldValue('sterilized', event.target.checked);
                    }}
                    data-test-id="switch"
                  />
                  <Typography style={{ fontWeight: 500, fontSize: '16px' }}>{this.transProfile("Sterilized")}</Typography>
                </Box>
                <Typography style={{ color: '#6F05EA', fontWeight: 600, fontSize: '24px', marginBottom: '35px' }}>{this.transProfile("Usual veterinary")}</Typography>
                <Box className="medical-row-wrapper" style={{ marginBottom: '27px' }}>
                  <Box style={{ width: '100%' }}>
                    <InputField
                      {...getFieldProps("hospital")}
                      {...this.getInputProps("hospital", errors, touched, values, this.state.isEditMedical)}
                      label={this.transProfile(configJSON.clinic)}
                      placeholder={this.transProfile(configJSON.clinic)}
                      style={{ marginBottom: "22px" }}
                      inputProps={defaultInputProps}
                      onChange={(event) => this.handleOnlyLettersAndSpaces(event, setFieldValue, "hospital")}
                      withoutStartAdornment
                      data-test-id="InputField"
                    />
                  </Box>
                  {this.renderPhoneInput("hospital_number", getFieldProps, errors, touched, values, setFieldError, setFieldValue, 'isEditMedical', true)}
                </Box>
                <Box className="medical-row-wrapper" style={{ marginBottom: '57px' }}>
                  <Box style={{ width: '100%' }}>
                    <InputField
                      {...getFieldProps("hospital_address")}
                      inputProps={defaultInputProps}
                      {...this.getInputProps("hospital_address", errors, touched, values, this.state.isEditMedical)}
                      label={this.transProfile(configJSON.clinicAddress)}
                      placeholder={this.transProfile(configJSON.clinicAddress)}
                      style={{ marginBottom: "22px" }}
                      withoutStartAdornment
                      data-test-id="InputField"
                    />
                  </Box>
                  <Box style={{ width: '100%' }}>
                    <InputField
                      {...getFieldProps("doctor_name")}
                      {...this.getInputProps("doctor_name", errors, touched, values, this.state.isEditMedical)}
                      label={this.transProfile(configJSON.doctor)}
                      placeholder={this.transProfile(configJSON.doctor)}
                      inputProps={defaultInputProps}
                      onChange={(event) => this.handleOnlyLettersAndSpaces(event, setFieldValue, "doctor_name")}
                      style={{ marginBottom: "22px" }}
                      withoutStartAdornment
                      data-test-id="InputField"
                    />
                  </Box>
                </Box>
                <Typography style={{ color: '#6F05EA', fontWeight: 600, fontSize: '24px', marginBottom: '35px' }}>{this.transProfile(configJSON.insurance)}</Typography>
                <Box className="medical-row-wrapper" style={{ marginBottom: '57px' }}>
                  <Box style={{ width: '100%' }}>
                    <InputField
                      {...getFieldProps("company")}
                      {...this.getInputProps("company", errors, touched, values, this.state.isEditMedical)}
                      label={this.transProfile(configJSON.company)}
                      placeholder={this.transProfile(configJSON.company)}
                      inputProps={defaultInputProps}
                      onChange={(event) => this.handleOnlyLettersAndSpaces(event, setFieldValue, "company")}
                      style={{ marginBottom: "22px" }}
                      withoutStartAdornment
                      data-test-id="InputField"
                    />
                  </Box>
                  <Box style={{ width: '100%' }}>
                    <InputField
                      {...getFieldProps("policy_number")}
                      {...this.getInputProps("policy_number", errors, touched, values, this.state.isEditMedical)}
                      label={this.transProfile(configJSON.policy)}
                      placeholder={this.transProfile(configJSON.policy)}
                      inputProps={defaultInputProps}
                      withoutStartAdornment
                      data-test-id="InputField"
                    />
                  </Box>
                </Box>
                <Typography style={{ color: '#6F05EA', fontWeight: 600, fontSize: '24px', marginBottom: '35px' }}>Emergency contacts</Typography>
                <Box className="medical-row-wrapper" style={{ marginBottom: '35px' }}>
                  <Box style={{ width: '100%' }}>
                    <InputField
                      {...getFieldProps("primary_name")}
                      label={this.transProfile(configJSON.primaryContact)}
                      placeholder={this.transProfile(configJSON.primaryContact)}
                      {...this.getInputProps("primary_name", errors, touched, values, this.state.isEditMedical)}
                      inputProps={defaultInputProps}
                      style={{ marginBottom: "22px" }}
                      onChange={(event) => this.handleOnlyLettersAndSpaces(event, setFieldValue, "primary_name")}
                      withoutStartAdornment
                      data-test-id="InputField"
                    />
                  </Box>
                  {this.renderPhoneInput("primary_contact", getFieldProps, errors, touched, values, setFieldError, setFieldValue, 'isEditMedical', true)}
                </Box>
                <Box className="medical-row-wrapper" style={{ marginBottom: '100px' }}>
                  <Box style={{ width: '100%' }}>
                    <InputField
                      {...getFieldProps("secondary_name")}
                      {...this.getInputProps("secondary_name", errors, touched, values, this.state.isEditMedical)}
                      onChange={(event) => this.handleOnlyLettersAndSpaces(event, setFieldValue, "secondary_name")}
                      style={{ marginBottom: "22px" }}
                      label={this.transProfile(configJSON.secondaryContact)}
                      placeholder={this.transProfile(configJSON.secondaryContact)}
                      inputProps={defaultInputProps}
                      withoutStartAdornment
                      data-test-id="InputField"
                    />
                  </Box>
                  {this.renderPhoneInput("secondary_contact", getFieldProps, errors, touched, values, setFieldError, setFieldValue, 'isEditMedical', true)}
                </Box>
                {this.renderActionButtons('isEditMedical', resetForm)}
              </form>
            )
          }}
        </Formik>
      </MedicalWrapper>
    )
  }

  renderActionButtons = (editField: EditFields, resetCallback: () => void) => {
    const btnText = this.state.medical.id ? configJSON.saveBtn : 'Next'
    const btnCondition = editField === 'isEditMedical' ? btnText : configJSON.saveBtn;
    return (
      <Box>
        {!this.state[editField]
          ? <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              data-test-id='edit-btn'
              style={{
                ...styles.actionButtonDefault,
                border: '1px solid #6F05EA',
                color: '#6F05EA',
              } as React.CSSProperties}
              onClick={() => this.setState({ [editField as 'isEditMedical']: true })}>{this.transProfile(configJSON.editBtn)}</Button>
          </Box>
          : <Box className="general-pet-buttons">
            <Button
              data-test-id='cancel-btn'
              className="action-btn"
              style={{
                border: '1px solid #4B4B4B',
                color: '#4B4B4B',
              }}
              onClick={() => this.cancelEditForm(editField, resetCallback)}>{this.transProfile(configJSON.cancelBtn)}</Button>
            <Button
              data-test-id='save-btn'
              className="action-btn"
              style={{
                border: '1px solid #6F05EA',
                color: '#6F05EA',
              }}
              disabled={this.state.isLoadingMedicalForm || this.state.isLoadingTreatsForm}
              type="submit">
              {this.transProfile(btnCondition)}
            </Button>
          </Box>
        }
      </Box>
    )
  }

  renderRangeSelect = (rangeSelectItem: {
    labelStart: string,
    labelEnd: string,value: string | undefined,field: string}, setFieldValue: (field: string, value: number) => void) => {
    const isActive = !!rangeSelectItem.value;

    return (
      <Box className="range-select-wrapper" key={rangeSelectItem.field}>
        <Typography className="treat-label desktop" style={{ color: isActive ? '#000000' : '#777777' }}>
          {rangeSelectItem.labelStart}
        </Typography>
        <Box className="treat-mobile-wrapper">
          <Typography className="treat-label mobile" style={{ color: isActive ? '#000000' : '#777777' }}>
            {rangeSelectItem.labelStart}
          </Typography>
          <Typography className="treat-label mobile" style={{ color: isActive ? '#000000' : '#777777' }}>
            {rangeSelectItem.labelEnd}
          </Typography>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center', minHeight: '20px' }}>
          <Box className="range-dot-item first">
            <Box data-test-id={`range-select-1-${rangeSelectItem.field}`} style={this.getDotStyle(rangeSelectItem.value, 0)} onClick={() => this.state.isEditTreats && setFieldValue(rangeSelectItem.field, 0)} />
          </Box>
          <Box className="range-dot-item">
            <Box data-test-id={`range-select-2-${rangeSelectItem.field}`} style={this.getDotStyle(rangeSelectItem.value, 1)} onClick={() => this.state.isEditTreats && setFieldValue(rangeSelectItem.field, 1)} />
          </Box>
          <Box className="range-dot-item last">
            <Box data-test-id={`range-select-3-${rangeSelectItem.field}`} style={this.getDotStyle(rangeSelectItem.value, 2)} onClick={() => this.state.isEditTreats && setFieldValue(rangeSelectItem.field, 2)} />
          </Box>
        </Box>
        <Typography className="treat-label desktop" style={{ color: isActive ? '#000000' : '#777777' }}>
          {rangeSelectItem.labelEnd}
        </Typography>
      </Box>
    )
  }

  renderPetTraits = () => {
    const rangeSelects = [
      {
        labelStart: this.transProfile(configJSON.calm),
        labelEnd: this.transProfile(configJSON.energetic),
        field: 'calm'
      }, {
        labelStart: this.transProfile(configJSON.relaxed),
        labelEnd: this.transProfile(configJSON.anxious),
        field: 'relaxed'
      }, {
        labelStart: this.transProfile(configJSON.easyGoing),
        labelEnd: this.transProfile(configJSON.shy),
        field: 'easy_going'
      }, {
        labelStart: this.transProfile(configJSON.friendly),
        labelEnd: this.transProfile(configJSON.aggressive),
        field: 'friendly'
      }, {
        labelStart: this.transProfile(configJSON.gentle),
        labelEnd: this.transProfile(configJSON.strong),
        field: 'gentle'
      }, {
        labelStart: this.transProfile(configJSON.obedient),
        labelEnd: this.transProfile(configJSON.rebbelious),
        field: 'obedient'
      }
    ]

    const fields = rangeSelects.map(select => select.field);
    const defaultInputProps = {
      style: this.state.isEditTreats ? styles.inputStyleGeneral : styles.inputStyleDefault, readOnly: !this.state.isEditTreats
    }


    return (

      <Box style={{ width: '100%' }}>
        <Typography style={{ color: '#6F05EA', fontWeight: 600, fontSize: '24px', marginBottom: '35px' }}>{this.transProfile(configJSON.personality)}</Typography>
        <Typography style={{ fontWeight: 500, fontSize: '18px', marginBottom: '35px' }}>{this.transProfile(configJSON.personalityDesc)}</Typography>
        <Typography style={{ color: '#6F05EA', fontWeight: 600, fontSize: '24px', marginBottom: '35px' }}>{this.transProfile(configJSON.habits)}</Typography>
        <Formik
          initialValues={this.state.treats}
          enableReinitialize
          validationSchema={treatsValidationSchena}
          onSubmit={(values) => {
            this.sendTreatsForm(values)
          }}
          data-test-id="treatsform"
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            getFieldProps,
            setFieldValue,
            resetForm,
          }) => {
            return (
              <form onSubmit={handleSubmit} style={{ overflowX: 'hidden' }}>
                <Box style={{ display: 'flex', flexDirection: 'column', gap: '35px', marginBottom: '60px' }}>
                  {rangeSelects.map(item => this.renderRangeSelect({ ...item, value: values[item.field as 'calm'] }, setFieldValue))}
                </Box>
                <TreatsWrapper>
                  <Box>
                    {fields.some(field => field in errors) &&
                      fields.some(field => field in touched) &&
                      <FormHelperText style={{ color: '#f44336' }}>{this.transProfile("*All fields above are required")}</FormHelperText>}
                  </Box>
                  <Box className="treats-row-item">
                    {this.renderCustomSelectField({
                      field: 'walks',
                      disabledField: 'isEditTreats',
                      errors,
                      touched,
                      getFieldProps,
                      handleBlur,
                      handleChange,
                      values,
                      editLabel: { enabled: this.state.isEditTreats },
                    })}
                    <Box style={{ width: '100%' }}>
                      <InputField
                        {...getFieldProps("walking_schedule")}
                        {...this.getInputProps("walking_schedule", errors, touched, values, this.state.isEditTreats)}
                        label={this.transProfile(configJSON.walksShedules)}
                        placeholder={this.transProfile(configJSON.enterPlaceholder)}
                        inputProps={defaultInputProps}
                        withoutStartAdornment
                      />
                    </Box>
                  </Box>
                  <Box className="treats-row-item">
                    {this.renderCustomSelectField({
                      field: 'potty',
                      disabledField: 'isEditTreats',
                      touched,
                      errors,
                      getFieldProps,
                      handleChange,
                      handleBlur,
                      values,
                      editLabel: { enabled: this.state.isEditTreats },
                    })}
                    <Box style={{ width: '100%' }}>
                      <InputField
                        {...getFieldProps("potty_schedule")}
                        {...this.getInputProps("potty_schedule", errors, touched, values, this.state.isEditTreats)}
                        label={this.transProfile(configJSON.pottyShedules)}
                        placeholder={this.transProfile(configJSON.enterPlaceholder)}
                        inputProps={defaultInputProps}
                        withoutStartAdornment
                      />
                    </Box>
                  </Box>
                  <Box className="treats-row-item" style={{ marginBottom: '100px' }}>
                    {this.renderCustomSelectField({
                      field: 'meal',
                      disabledField: 'isEditTreats',
                      values,
                      touched,
                      getFieldProps,
                      errors,
                      handleChange,
                      handleBlur,
                      editLabel: { enabled: this.state.isEditTreats },
                    })}
                    <Box style={{ width: '100%' }}>
                      <InputField
                        {...getFieldProps("meal_schedule")}
                        {...this.getInputProps("meal_schedule", errors, touched, values, this.state.isEditTreats)}
                        label={this.transProfile(configJSON.mealsShedule)}
                        placeholder={this.transProfile(configJSON.enterPlaceholder)}
                        inputProps={defaultInputProps}
                        withoutStartAdornment
                      />
                    </Box>
                  </Box>
                  {this.renderActionButtons('isEditTreats', resetForm)}
                </TreatsWrapper>
              </form>
            )
          }}
        </Formik>

      </Box>
    )
  }

  renderBithdaySelect = (
    values: IPet,
    getFieldProps: (val: string) => FieldInputProps<string>,
    errors: FormikErrors<IPet>,
    touched: FormikTouched<IPet>,
    setFieldValue: (field: string, val: string) => void,
  ) => {
    return (
      <Box style={{ position: "relative" }}>
        <CommonDatePicker
          disabled={!this.state.isEditGeneral}
          selectedDate={
            this.state.selectedPet?.birthdate
              ? moment(this.state.selectedPet?.birthdate, "DD-MM-YYYY").toDate()
              : null
          }
          maxDate={moment().subtract(1, 'days').toDate()}
          handleDateChange={(date: Date | null) => {
            this.updatePetField('birthdate', moment(date).format("DD/MM/YYYY"))
            setFieldValue(
              "birthdate",
              moment(date).format("DD/MM/YYYY")
            );
          }}
          data-test-id="renderbirthday"
        />
        <InputField
          {...getFieldProps("birthdate")}
          {...this.getInputProps("birthdate", errors, touched, values, this.state.isEditGeneral)}
          label={this.transProfile("Birthdate")}
          withoutStartAdornment
          placeholder={this.transProfile("Birthdate")}
          inputProps={{
            style: this.state.isEditGeneral ? styles.inputStyleGeneral : styles.inputStyleDefault, readOnly: !this.state.isEditGeneral
          }}
        />
      </Box>
    )
  }

  renderCatDogForm = (
    values: IPet,
    getFieldProps: (val: string) => FieldInputProps<string>,
    handleBlur: (e: React.ChangeEvent<any>) => void,
    handleChange: (e: React.FormEvent<HTMLFormElement> | undefined) => void,
    errors: FormikErrors<any>,
    touched: FormikTouched<any>,
    setFieldValue: (field: string, val: string) => void,
  ) => {
    const catDog = ['cat', 'dog'];
    if (catDog.includes(values.pet_category)) {
      return (
        <>
          <Box className="table-row-wrapper">
            <Box style={{ width: '100%' }}>
              <Autocomplete
                disableClearable
                {...getFieldProps("breed")}
                id="breed"
                data-test-id="dog-cat-breed"
                className={`breed-select breed-auto`}
                options={this.getBreedList(values.pet_category)}
                getOptionLabel={(option) => option}
                value={values.breed}
                onChange={(_event, newValue) => {
                  this.updatePetField('breed', newValue as string)
                  setFieldValue("breed", newValue as string);
                }}
                disabled={!this.state.isEditGeneral}
                popupIcon={<ExpandMoreRoundedIcon style={{ color: this.state.isEditGeneral ? '#6F05EA' : '#A6A6A6' }} />}
                renderInput={(params) => (
                  <>
                    <InputLabel
                      style={{ ...this.getLabelStyle(this.state.isEditGeneral) }}
                    >
                      {this.transProfile("Breed")}
                    </InputLabel>
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={this.transProfile("Your pet's Breed")}
                      disabled={!this.state.isEditGeneral}
                      InputProps={{
                        ...params.InputProps,
                        style: this.state.isEditGeneral ? { ...styles.inputStyleGeneral, marginBottom: 0, paddingLeft: '8px' } : { ...styles.inputStyleDefault, paddingLeft: '8px' }, readOnly: !this.state.isEditGeneral,
                      }}
                      fullWidth
                      error={
                        this.getErrorAndHelperText(
                          "breed",
                          errors as FormErrors,
                          touched as FormTouched
                        ).isError
                      }
                      helperText={
                        this.getErrorAndHelperText(
                          "breed",
                          errors as FormErrors,
                          touched as FormTouched
                        ).helperText
                      }
                    />
                  </>

                )}
              />
            </Box>
            <Box style={{ width: '100%' }}>
              {this.renderBithdaySelect(values, getFieldProps, errors, touched, setFieldValue)}
            </Box>
          </Box>
          <Box className="table-row-wrapper">
            <Box style={{ width: '100%' }}>
              {this.renderCustomSelectField({
                field: 'weight',
                disabledField: 'isEditGeneral',
                handleChange,
                errors,
                editLabel: { enabled: this.state.isEditGeneral },
                handleBlur,
                values,
                touched,
                getFieldProps,
                list: values.pet_category === 'dog' ? [
                  { label: "Less then 5 kg", value: "Less then 5 kg" },
                  { label: "5-14 kg", value: "5-14 kg" },
                  { label: "15-24 kg", value: "15-24 kg" },
                  { label: "25-44 kg", value: "25-44 kg" }
                ] : [
                  { label: "Small(≤ 4kg)", value: "≤ 4kg" },
                  { label: "Medium(4kg - 8kg)", value: "4kg - 8kg" },
                  { label: "Large(≥ 8kg)", value: "≥ 8kg" },
                ]
              })}
            </Box>
            <Box style={{ width: '100%' }}>
              {this.renderCustomSelectField({
                handleBlur,
                values,
                field: 'size',
                editLabel: { enabled: this.state.isEditGeneral },
                disabledField: 'isEditGeneral',
                handleChange,
                errors,
                touched,
                getFieldProps,
                list: [
                  { label: "Toy(Less than 30 cm)", value: "Toy (Less then 30 cm)" },
                  { label: "Small(30-44 cm)", value: "Small (30-44 cm)" },
                  { label: "Medium(45-64 cm)", value: "Medium (45-64 cm)" },
                  { label: "Large(65-89 cm)", value: "Large (65-89 cm)" },
                ]
              })}
            </Box>
          </Box>
          <Box className="table-row-wrapper">
            <Box style={{ width: '100%' }}>
              {this.renderCustomSelectField({
                handleBlur,
                values,
                handleChange,
                errors,
                field: 'fur',
                editLabel: { enabled: this.state.isEditGeneral },
                disabledField: 'isEditGeneral',
                list: [
                  { label: "Hairless", value: "Hairless" },
                  { label: "Short Coat", value: "Short Coat" },
                  { label: "Medium Coat", value: "Medium Coat" },
                  { label: "Long Coat", value: "Long Coat" },
                ],
                touched,
                getFieldProps,
              })}
            </Box>
            <Box width={'100%'}></Box>
          </Box>
        </>
      )
    }
    return null;
  }

  renderOtherForm = (
    values: IPet,
    getFieldProps: (val: string) => FieldInputProps<string>,
    handleBlur: (e: React.ChangeEvent<any>) => void,
    handleChange: (e: React.FormEvent<HTMLFormElement> | undefined) => void,
    errors: FormikErrors<any>,
    touched: FormikTouched<any>,
    setFieldValue: (field: string, val: string) => void,
  ) => {
    const other = ['rabbit/rodent', 'reptile'];
    if (other.includes(values.pet_category)) {
      return (
        <>
          <Box className="table-row-wrapper">
            <Box style={{ width: '100%' }}>
              {this.renderCustomSelectField({
                handleBlur,
                errors,
                field: 'species',
                values,
                disabledField: 'isEditGeneral',
                list: this.getSpiecesList(values.pet_category),
                touched,
                handleChange,
                getFieldProps,
                editLabel: { enabled: this.state.isEditGeneral },
              })}
            </Box>
            <Box style={{ width: '100%' }}>
              {this.renderBithdaySelect(values, getFieldProps, errors, touched, setFieldValue)}
            </Box>
          </Box>
          <Box className="table-row-wrapper">
            <Box style={{ width: '100%' }}>
              {values.pet_category === 'rabbit/rodent' && values.species === 'Rabbit' &&
                this.renderCustomSelectField({
                  handleBlur,
                  errors,
                  field: 'weight',
                  values,
                  editLabel: { enabled: this.state.isEditGeneral },
                  disabledField: 'isEditGeneral',
                  list: [
                    { label: "Small(≤ 2kg)", value: "≤ 2kg" },
                    { label: "Medium(2kg - 4kg)", value: "2kg - 4kg" },
                    { label: "Large(≥ 4kg)", value: "≥ 4kg" },
                  ],
                  touched,
                  handleChange,
                  getFieldProps,
                })
              }
            </Box>
            <Box style={{ width: '100%' }}></Box>
          </Box>
        </>
      )
    }

    return null
  }

  getColors = (isDisabled: boolean) => {
    if (isDisabled) {
      return { border: '1px solid #4B4B4B', color: '#4B4B4B' }
    }

    return { border: '1px solid #6F05EA', color: '#6F05EA' }
  }

  renderPetGeneralActions = (reset: () => void, pet_category: string) => {
    if (this.state.selectedPet) {
      if (this.state.isEditGeneral && !this.state.selectedPet.id) {
        const isDisabled = this.state.isLoadingCreatePet;
        const btnName = ['cat', 'dog', ""].includes(pet_category) ? 'Next' : 'Create pet'
        return (
          <Box style={{ marginTop: '47px' }}>
            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                data-test-id='next-btn'
                disabled={isDisabled}
                style={{
                  ...styles.actionButtonDefault,
                  border: this.getColors(isDisabled).border,
                  color: this.getColors(isDisabled).color,
                } as React.CSSProperties}
                type="submit"
              >
                {this.state.isLoadingCreatePet ? <CircularProgress style={{ color: '#4B4B4B' }} size="18px" /> : btnName}
              </Button>
            </Box>
          </Box>
        )
      }

      if (!this.state.isEditGeneral) {
        return (
          <Box style={{ marginTop: '47px' }}>
            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                data-test-id='edit-btn_view'
                style={{
                  ...styles.actionButtonDefault,
                  border: '1px solid #6F05EA',
                  color: '#6F05EA',
                } as React.CSSProperties}
                onClick={() => this.setState({ isEditGeneral: true })}>{this.transProfile(configJSON.editBtn)}</Button>
            </Box>
          </Box>
        )
      } else {
        return (
          <Box style={{ marginTop: '47px' }} data-test-id="existing-pet-actions">
            <Box className="general-pet-buttons">
              <Button
                data-test-id='cancel-btn-rendergeneral'
                className="action-btn"
                onClick={() => this.cancelEditForm('isEditGeneral', reset)}>
                {this.transProfile(configJSON.cancelBtn)}
              </Button>
              <Button
                data-test-id='delete-btn-rendergeneral'
                className="delete-btn"
                onClick={() => this.handleDeletePet()}>
                {this.transProfile(configJSON.deleteBtn)}
              </Button>
              <Button
                data-test-id='save-btn-rendergeneral'
                className="action-btn"
                style={{
                  border: '1px solid #6F05EA',
                  color: '#6F05EA',
                }}
                disabled={this.state.isLoadingGeneralForm}
                type="submit">
                {this.transProfile(configJSON.saveBtn)}
              </Button>
            </Box>
          </Box>
        )
      }
    }
  }

  renderPetGeneral = () => {
    if (this.state.selectedPet) {
      const birdFish = ['bird', 'fish'];
      const { name, ...rest } = this.state.selectedPet

      return (
        <TreatsWrapper>
          <Formik
            initialValues={{
              ...rest,
              pet_category: this.state.selectedPet?.pet_category?.toLocaleLowerCase() === 'rabbit'
                ? 'rabbit/rodent'
                : this.state.selectedPet?.pet_category?.toLocaleLowerCase()
            }}
            enableReinitialize
            validationSchema={generalValidationSchema}
            onSubmit={(values) => {
              this.updatePetGeneral(values)
            }}
            data-test-id="general-form"
          >
            {({
              errors,
              values,
              touched,
              handleChange: formikChange,
              handleBlur,
              handleSubmit,
              getFieldProps,
              setFieldValue,
              resetForm,
            }) => {
              if (this.state.selectedPet?.photo_blob?.name !== values.photo_blob?.name) {
                setFieldValue('photo_blob', this.state.selectedPet?.photo_blob)
              }
              const handleChange = (event: React.FormEvent<HTMLFormElement> | undefined) => {
                if (event) {
                  const { name, value } = event.target as HTMLFormElement;
                  this.updatePetField(name as keyof IPet, value);
                  formikChange(event);
                }

              }
              return (
                <form onSubmit={handleSubmit}>
                  <Box style={{ display: 'flex', gap: '42px', flexDirection: 'column', width: '100%' }}>
                    <Box className="table-row-wrapper">
                      <Box style={{ width: '100%' }}>
                        {this.renderCustomSelectField({
                          handleBlur,
                          errors,
                          field: 'pet_category',
                          values,
                          disabledField: 'isEditGeneral',
                          list: [
                            { label: "Cat", value: "cat" },
                            { label: "Dog", value: "dog" },
                            { label: "Rabbit/Rodent", value: "rabbit/rodent" },
                            { label: "Fish", value: "fish" },
                            { label: "Bird", value: "bird" },
                            { label: "Reptile", value: "reptile" },
                          ],
                          touched,
                          handleChange,
                          getFieldProps,
                          editLabel: { enabled: this.state.isEditGeneral }
                        })}
                      </Box>
                      <Box style={{ width: '100%' }}>
                        {this.renderCustomSelectField({
                          handleBlur,
                          errors,
                          field: 'gender',
                          values,
                          disabledField: 'isEditGeneral',
                          list: [
                            { label: "Male", value: "Male" },
                            { label: "Female", value: "Female" },
                          ],
                          touched,
                          handleChange,
                          getFieldProps,
                          editLabel: { enabled: this.state.isEditGeneral }
                        })}
                      </Box>
                    </Box>
                    {this.renderCatDogForm(values, getFieldProps, handleBlur, handleChange, errors, touched, setFieldValue)}
                    {this.renderOtherForm(values, getFieldProps, handleBlur, handleChange, errors, touched, setFieldValue)}
                    {birdFish.includes(values.pet_category) &&
                      <Box className="table-row-wrapper">
                        <Box style={{ width: '100%' }}>
                          {this.renderBithdaySelect(values, getFieldProps, errors, touched, setFieldValue)}
                        </Box>
                        <Box style={{ width: '100%' }}></Box>
                      </Box>
                    }
                  </Box>
                  {this.renderPetGeneralActions(resetForm, values.pet_category)}
                </form>
              )
            }}

          </Formik>
        </TreatsWrapper>
      )
    }
  }

  renderPetsTabsContent = () => {
    switch (this.state.currentTab) {
      case 0:
        return this.renderPetGeneral();
      case 1:
        return this.renderPetMedicalForm();
      case 2:
        return this.renderPetTraits();
      default:
        return <>Not released</>
    }
  }

  renderPetsContent = () => {
    if (this.state.selectedPet) {
      const selectedPet = this.state.selectedPet;
      const hasInfo = ['cat', 'dog', "Dog", "Cat", "Dogs", "Cats"].includes(this.state.selectedPet.pet_category);
      return (
        <ResponsiveBox data-test-id="pets-content">
          <style>
            {`
              
              .MuiFormHelperText-root {
                position: absolute;
              }
             .MuiFormHelperText-root.Mui-error{
               bottom: -22px
              }
              
            `}
          </style>
          <Box style={styles.pets.content as React.CSSProperties}>
            <PetInfoHeader selectedPet={this.state.selectedPet} isLoadingPhoto={this.state.isLoadingPhoto} onUpdatePet={this.updatePetGeneral} onUpdateField={this.updatePetField} onRemovePhoto={this.handleRemovePhoto} />
            <Tabs
              value={this.state.currentTab}
              data-test-id="pet-tabs"
              onChange={(_, idx) => this.setState({
                currentTab: idx,
              })}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#6F05EA',
                },
              }}
            >
              <Tab style={{ maxWidth: "33%", borderBottom: "1px solid #B7B7B7" }} label={this.transProfile(configJSON.general)} className="custom-tab" />
              <Tab style={{ maxWidth: "33%", borderBottom: "1px solid #B7B7B7" }} label={this.transProfile(configJSON.medical)} className="custom-tab" disabled={!hasInfo} />
              <Tab style={{ maxWidth: "34%", borderBottom: "1px solid #B7B7B7" }} label={this.transProfile(configJSON.traits)} className="custom-tab" disabled={!hasInfo} />
            </Tabs>
            {this.renderPetsTabsContent()}
          </Box>
          <Box className="pets-list">
            <img data-test-id="handleaddimage" src={add} style={{ width: '75px', height: '75px', cursor: "pointer" }} onClick={this.handleAddPet} />
            {this.state.petsList.map((pet, idx) => (

              <Avatar
                data-test-id={`pets-list-avatar-${pet.id}`}
                onClick={() => this.changeCurrentPet(pet)}
                key={pet.id}
                style={pet.id === selectedPet.id ? styles.selectedPet : styles.notSelectedPet}
                src={pet.pet_photo || undefined}>
                {!pet.pet_photo && pet.name && pet.name[0]}
              </Avatar>
            ))}
          </Box>
        </ResponsiveBox>
      )
    }

    return (
      <Box style={styles.pets.notSelectedWrapper} data-test-id="pets-list-page">
        {this.state.petsList.map((pet, idx) => (
          <Box className="pet-box-item" style={{ cursor: "pointer" }} key={idx} onClick={() => this.setState({ selectedPet: pet })} data-test-id={`pet-${idx}`}>
            <Avatar style={{ width: '123px', height: '123px' }} src={pet.pet_photo || undefined}>{!pet.pet_photo && pet.name && pet.name[0]}</Avatar>
            <Box style={{ ...styles.general.flexCol, gap: '9px' }}>
              <Typography style={styles.pets.name}>
                {pet.name}
              </Typography>
            </Box>
            <Box style={styles.pets.photoBox}>
              <img src={this.getGenderIconPath(pet.gender)} />
              <Typography style={{ fontSize: '14px', fontWeight: 300 }}>
                {this.formatDateAsYearsOld(pet.birthdate)}
              </Typography>
            </Box>
          </Box>
        ))}
        <Box className="pet-box-item" data-test-id="addpetimg" id="addpetimg" style={{ ...styles.pets.addPetWrapper, cursor: "pointer" }} onClick={this.handleAddPet}>
          <img src={add} />
          <Typography style={styles.pets.addText}>{this.transProfile(configJSON.addNewPet)}</Typography>
        </Box>
      </Box>
    )
  }

  renderPersonalTab = () => {
    const defaultInputProps = {
      style: this.state.isEditPersonal ? styles.inputStyleGeneral : styles.inputStyleDefault, readOnly: !this.state.isEditPersonal
    }

    const initialData = {
      address: this.state.personal.address,
      first_name: this.state.personal.first_name,
      last_name: this.state.personal.last_name,
      language: this.state.personal.language.toLocaleLowerCase(),
      nationality: this.state.personal.nationality.toLocaleLowerCase(),
      full_phone_number: this.state.personal.full_phone_number || "",
      date_of_birth: this.state.personal.date_of_birth,
      country: this.state.personal.country,
      city: this.state.personal.city,
      zipcode: this.state.personal.zipcode,
      phone_number: this.state.personal.phone_number,
      country_code: this.state.personal.country_code,
      id: this.state.personal.id || ''
    }

    return (
      <Box className="personal-tab-wrapper">
        <Formik
          initialValues={initialData}
          enableReinitialize
          validationSchema={personalValidationShema}
          onSubmit={(values) => {
            this.updateUserProfile(values)
          }}
          data-test-id="personal-form"
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            getFieldProps,
            setFieldValue,
            setFieldError,
            resetForm,
          }) => {
            return (
              <>
                <GenericModal id='language-modal' isOpen={this.state.isOpenLanguageModal} title="" data-test-id="languageModalOpen" onClose={() => this.setState({ isOpenLanguageModal: false })}>
                  <Box style={styles.settings.modalBody}>
                    <Typography style={styles.settings.modalText}>{this.transProfile("Are you sure you want to")} <span style={{ color: '#f4d144' }}>switch languages</span>? <br /> {this.transProfile("You will need to login again")}</Typography>
                    <Typography style={styles.settings.modalText}>{this.transProfile(configJSON.wish)}</Typography>
                    <Box style={{ display: 'flex', gap: '24px' }}>
                      <Button
                        data-test-id='cancel-btn-modal'
                        style={{
                          ...styles.actionButtonDefault,
                          border: '1px solid #4B4B4B',
                          color: '#4B4B4B',
                        } as React.CSSProperties}
                        onClick={() => {
                          setFieldValue("country", this.state.personal.country);
                          this.setState({ isOpenLanguageModal: false })
                        }}>{this.transProfile(configJSON.cancelBtn)}
                      </Button>
                      <Button
                        data-test-id='continue-btn'
                        style={{
                          ...styles.actionButtonDefault,
                          border: '1px solid #6F05EA',
                          color: '#6F05EA',
                        } as React.CSSProperties}
                        onClick={() => {
                          this.setState({ isOpenLanguageModal: false })
                        }}
                      >
                        {this.transProfile(configJSON.continueBtn)}
                      </Button>
                    </Box>
                  </Box>
                </GenericModal>
                <form onSubmit={handleSubmit}>
                  <FieldsRow
                  data-test-id="first_name"
                    left={
                      <InputField
                      dat-test-id="leftfirstname"
                        {...getFieldProps("first_name")}
                        {...this.getInputProps("first_name", errors, touched, values, this.state.isEditPersonal)}
                        label={this.transProfile(configJSON.firstName)}
                        onChange={(event) => this.handleOnlyLettersAndSpaces(event, setFieldValue, "first_name")}
                        placeholder={this.transProfile(configJSON.firstName)}
                        inputProps={defaultInputProps}
                        disabled={!this.state.isEditPersonal}
                        labelStyle={this.getLabelStyling()}
                        withoutStartAdornment
                      />
                    }
                    right={
                      <InputField
                      data-test-id="last_name"
                        {...getFieldProps("last_name")}
                        {...this.getInputProps("last_name", errors, touched, values, this.state.isEditPersonal)}
                        onChange={(event) => this.handleOnlyLettersAndSpaces(event, setFieldValue, "last_name")}
                        label={this.transProfile(configJSON.lastName)}
                        placeholder={this.transProfile(configJSON.lastName)}
                        inputProps={defaultInputProps}
                        labelStyle={this.getLabelStyling()}
                        disabled={!this.state.isEditPersonal}
                        withoutStartAdornment
                      />
                    }
                  />
                  <FieldsRow
                  data-test-id="language"
                 
                    left={this.renderCustomSelectField({
                      handleBlur,
                      errors,
                      field: 'language',
                      values,
                      disabledField: 'isEditPersonal',
                      list: [
                        { label: "Portuguese", value: "portuguese", flag: 'pt' },
                        { label: "English", value: "english", flag: 'us' },
                        { label: "Spanish", value: "spanish", flag: 'es' },
                        { label: "French", value: "french", flag: 'fr' },
                      ],
                      touched,
                      handleChange: (event: any) => {
                        setFieldValue("language", event.target.value);
                        this.setState({ isOpenLanguageModal: true });
                      },
                      getFieldProps,
                      editLabel: {
                        enabled: this.state.isEditPersonal,
                        styles: this.getLabelStyling()
                      }
                    })}
                    right={
                      this.renderCustomSelectField({
                        handleBlur,
                        errors,
                        field: 'nationality',
                        values,
                        disabledField: 'isEditPersonal',
                        list: [
                          { label: "Portuguese", value: "portuguese" },
                          { label: "Indian", value: "indian" }
                        ],
                        touched,
                        handleChange,
                        getFieldProps,
                        editLabel: {
                          enabled: this.state.isEditPersonal,
                          styles: this.getLabelStyling()
                        }
                      })
                    }
                  />
                  <FieldsRow
                  data-test-id="full_phone_number"
                    left={
                      this.renderPhoneInput("full_phone_number", getFieldProps, errors, touched, values, setFieldError, setFieldValue, 'isEditPersonal', true)
                    }
                    right={
                      <Box style={{ position: "relative" }}>
                        <CommonDatePicker
                          data-test-id="date_of_birth"
                          disabled={!this.state.isEditPersonal}
                          selectedDate={
                            this.state.personal.date_of_birth
                              ? moment(this.state.personal.date_of_birth, "DD/MM/YYYY").toDate()
                              : null
                          }
                          maxDate={new Date(Date.now())}
                          handleDateChange={(date: Date | null) => {
                            setFieldValue(
                              "date_of_birth",
                              moment(date).format("DD/MM/YYYY")
                            );
                          }}
                        />
                        <InputField
                          {...getFieldProps("date_of_birth")}
                          {...this.getInputProps("date_of_birth", errors, touched, values, this.state.isEditPersonal)}
                          label={this.transProfile(configJSON.birthdate)}
                          placeholder={this.transProfile(configJSON.birthdate)}
                          inputProps={defaultInputProps}
                          disabled={!this.state.isEditPersonal}
                          labelStyle={this.getLabelStyling()}
                          withoutStartAdornment
                        />
                      </Box>
                    }
                  />
                  <FieldsRow
                   data-test-id="address"
                    left={
                      <InputField
                        {...getFieldProps("address")}
                        {...this.getInputProps("address", errors, touched, values, this.state.isEditPersonal)}
                        label={this.transProfile(configJSON.address)}
                        disabled={!this.state.isEditPersonal}
                        placeholder={this.transProfile(configJSON.address)}
                        inputProps={defaultInputProps}
                        labelStyle={this.getLabelStyling()}
                        withoutStartAdornment
                      />
                    }
                    right={
                      this.renderCustomSelectField({
                        handleBlur,
                        errors,
                        field: 'country',
                        values,
                        disabledField: 'isEditPersonal',
                        list: this.state.countries,
                        touched,
                        handleChange: (event: any) => {
                          setFieldValue("country", event.target.value);
                          this.handleCountryNameChange(event.target.value);
                        },
                        getFieldProps,
                        editLabel: {
                          enabled: this.state.isEditPersonal,
                          styles: this.getLabelStyling()
                        }
                      })
                    }
                  />
                  <FieldsRow
                   data-test-id="city"
                    left={
                      this.renderCustomSelectField({
                        handleBlur,
                        errors,
                        field: 'city',
                        values,
                        disabledField: 'isEditPersonal',
                        list: this.state.cities,
                        touched,
                        handleChange,
                        getFieldProps,
                        editLabel: {
                          enabled: this.state.isEditPersonal,
                          styles: this.getLabelStyling()
                        }
                      })
                    }
                    right={
                      <InputField
                        withoutStartAdornment
                        {...getFieldProps("zipcode")}
                        {...this.getInputProps("zipcode", errors, touched, values, this.state.isEditPersonal)}
                        label={this.transProfile(configJSON.zipcode)}
                        labelStyle={this.getLabelStyling()}
                        disabled={!this.state.isEditPersonal}
                        placeholder={this.transProfile(configJSON.zipcode)}
                        inputProps={defaultInputProps}
                      />
                    }
                  />
                  <Box style={{ marginTop: '80px' }}>
                    {this.renderActionButtons("isEditPersonal", resetForm)}
                  </Box>
                </form>
              </>
            )
          }}
        </Formik>
      </Box>
    )
  }

  renderLoggedSessionCard = () => {
    const this_device = localStorage.getItem('device_uuid') || '';
    return (
      <Grid container className="logged-in-card-container">
        {this.getSortedSessionsList().map((device,index) => (
          <Grid item xs={12} className="logged-in-card" key={device.id} data-test-id={`renderLoggedSessionCard-${index}`}>
            <Box style={{ display: 'flex', gap: '34px' }}>
              <img src={this.getDeviceImage(device.device_name)} alt="device-img" className="device-img" />
              <Box className="device-name-container">
                <Box style={{ display: 'flex', flexDirection: 'column', paddingRight: '10px', gap: '6px' }}>
                  <Typography className="device-name">
                    {`${device.device_name}`}
                  </Typography>
                  <Typography className="device-address">
                    {`${device.location}`}
                  </Typography>
                  {this_device === device.device_id ? <Typography className="device-current">
                    {this.transProfile("This device")}
                  </Typography> : <Typography className="device-date">
                    {formatDate(device.last_active)}
                  </Typography>}
                </Box>
              </Box>
            </Box>
            {this_device !== device.device_id && <Button data-test-id="end-session-btn1" className="end-session-btn desktop" onClick={() => this.handleOpenDeleteModal(device)}>{this.transProfile("End Session")}</Button>}
          </Grid>
        ))}
      </Grid>
    );
  };

  renderSecurityTab = () => {
    return (
      <SettingsWrapper data-test-id='security-tab'>
        <Typography style={{ color: '#6F05EA', fontWeight: 600, fontSize: '24px' }}>{this.transProfile("Password and email reset")}</Typography>
        <Box className="security-buttons">
          <Button
            data-test-id='reset-password'
            style={{
              ...styles.actionButtonDefault,
              border: '1px solid #6F05EA',
              color: '#6F05EA',
              width: 'auto',
              paddingLeft: '15px',
              paddingRight: '15px'
            } as React.CSSProperties}
            onClick={() => this.setState({ isOpenResetModal: true })}
          >
            {this.transProfile("Reset Password")}
          </Button>
          <Button
            data-test-id='reset-email'
            style={{
              ...styles.actionButtonDefault,
              border: '1px solid #6F05EA',
              color: '#6F05EA',
              width: 'auto',
              paddingLeft: '15px',
              paddingRight: '15px'
            } as React.CSSProperties}
            onClick={() => this.setState({ isOpenResetEmailModal: true })}
          >
            {this.transProfile("Reset Email")}
          </Button>
        </Box>
        <Typography style={{ color: '#6F05EA', fontWeight: 600, fontSize: '24px' }}>
          {this.transProfile("Logged sessions")}
        </Typography>
        <Typography className="helper-text">
          {this.transProfile("You're currently logged in on these devices")}:
        </Typography>
        {this.renderLoggedSessionCard()}
        <Box style={{ marginTop: '80px', display: 'flex', gap: '35px', flexDirection: 'column' }}>
          <Typography style={{ color: '#6F05EA', fontWeight: 600, fontSize: '24px' }}>Delete account</Typography>
          <Box className="delete-section">
            <Button
              data-test-id='delete-account'
              className="end-session-btn"
              onClick={() => this.setState({ isOpenDeleteModal: true })}
            >
              {this.transProfile("Delete account")}
            </Button>
            <Typography style={{ fontSize: '14px', fontWeight: 400, color: '#808080' }}>
              {this.transProfile("Permanently remove your Petzai account. You will lose access to your account data, your previous reservations, your favorite establishments or your wallet.")}
            </Typography>
          </Box>
        </Box>
      </SettingsWrapper>
    )
  }

  renderPaymentTab = () => {
    return (
      <PaymentTab
        data-testid="payment-setting-tab"
        cardList={this.state.paymentCardList}
        isAddCardModalOpen={this.state.addCardModal} // STATE
        openAddCardModal={this.openAddCardModal} // OPEN FUNCTION 
        onCloseAddCardModal={this.closeAddCardModal} // CLOSE FUNCTION 
        onSubmitAddCardModal={this.onSubmitAddCardModal} // SAVE FUNCTION - API FUNCTION
        addCardModalLoading={this.state.addCardLoading} // SAVE LOADING
        addCardModalError={this.state.addCardModalError} // ADD CARD MODAL ERROR
        clearAddCardModalError={this.clearAddCardModalError} // ERROR CLEAR FUNCTION
        addCardModalDefault={this.state.setDefaultAddCard} // SAVE AS DEFAULT VALUE IN ADD CARD
        changeAddCardModalDefault={this.changeAddCardModalDefault} // CHANGE SAVE AS DEFAULT FUNCTION
        handleSetAsDefault={this.handleSetAsDefault} // SET CARD AS DEFAULT - API FUNCTION
        handleDeleteCard={this.handleDeleteCard} // DELETE CARD - API FUNCTION
      />
    )
  }


  renderSettingsTabContent = () => {
    switch (this.state.currentSettingsTab) {
      case 0:
        return this.renderPersonalTab();
      case 1:
        return this.renderSecurityTab();
      case 2:
        return this.renderPaymentTab();
      default:
        break;
    }
  }

  renderSettingsBlock = () => {
    return (
      <ResponsiveWrappper data-test-id="settings-wrapper" style={{ width: '100%', textAlign: 'left' }}>
        <Box className="settings-header">
          <Typography style={styles.settings.header}>{this.transProfile(configJSON.settings)}</Typography>
          <Box className="menu-button" data-test-id="menu_button" onClick={() => this.toggleDrawer()}>
            <MenuIcon />
          </Box>
        </Box>
        <Box className="mobile-settings-tabs">
          <VerticalTabs
            value={this.state.currentSettingsTab}
            data-test-id="settings-tabs-menu-three"
            onChange={(_, idx) => this.setState({ currentSettingsTab: idx })}
          >
            <Tab label={this.transProfile(configJSON.personal)} className="vertical-tab" />
            <Tab label={this.transProfile(configJSON.security)} className="vertical-tab" />
            <Tab label={this.transProfile(configJSON.payment)} className="vertical-tab" />
          </VerticalTabs>
        </Box>
        <Box className="desktop-settings-tabs">
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={this.state.currentSettingsTab}
            data-test-id="settings-tabs"
            onChange={(_, idx) => this.setState({ currentSettingsTab: idx })}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#6F05EA',
                height: "3px"
              },
            }}
          >
            <Tab style={{ maxWidth: "33%", fontSize: "20px", fontWeight: 700, borderBottom: "1px solid #B7B7B7" }} label={this.transProfile(configJSON.personal)} className="custom-tab" />
            <Tab style={{ maxWidth: "33%", fontSize: "20px", fontWeight: 700, borderBottom: "1px solid #B7B7B7" }} label={this.transProfile(configJSON.security)} className="custom-tab" />
            <Tab style={{ maxWidth: "33%", fontSize: "20px", fontWeight: 700, borderBottom: "1px solid #B7B7B7" }} label={this.transProfile(configJSON.payment)} className="custom-tab" />
          </Tabs>
        </Box>
        {this.renderSettingsTabContent()}
      </ResponsiveWrappper>
    );
  };

  renderCalendarBlock = () => {
    return (
      <CalendarTab
        data-test-id="calendar-block"
        isLoading={this.state.isLoadingChangeStatus}
        recurranceData={this.state.recurranceData}
        selectedEvent={this.state.selectedEvent}
        shedules={this.state.shedules}
        calendarView={this.state.calendarView}
        calendarDate={this.state.calendarDate}
        changeView={this.handleSetView}
        changeDate={this.handleChangeDate}
        fetchAppointments={this.getAppointmentsList}
        toggleCancelation={() => this.setState({ isOpenCancelationModal: !this.state.isOpenCancelationModal })}
        toggleRequestChange={() => this.setState({ isOpenChangeModal: !this.state.isOpenChangeModal })}
        selectEvent={(event: Shedule | null) => this.setState({ selectedEvent: event })}
        changeRequestStatus={this.handleProceedRequestStatus}
      />
    )
  }

  renderContentItems = () => {
    switch (this.state.currentMenuItem) {
      case "0":
        return this.renderCalendarBlock();
      case "1":
        return this.renderPetsContent();
      case "2":
        return (
          <CustomerWallet 
            currentTabValue={this.state.currentWalletTab}
            setCurrentTab={(value: number) => this.setState({ currentWalletTab: value })}
            translate={this.translate}
            tradePointsData={this.state.coupons} 
            myAwardsData={this.state.awards}
            myPointsData={this.state.points}    
            
            />
        )
      case "3":
        return this.renderSettingsBlock();
      default:
        return <>Not released</>
    }
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (

      <Box style={{ width: '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column' }} data-test-id="main-container">
        <AppHeader
          navigation={this.props.navigation}
          handleCustomerHeaderSelect={this.handleCustomerHeaderSelect}
          isProfile={true}
          data-test-id="app-header"
        />
        {this.state.isLoadingApp
          ? <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }} data-test-id="loading-indicator">
            <CircularProgress style={{ color: '#6F05EA' }} />
          </Box>
          : <PageWrapper data-test-id='page-wrapper' className="customer-user-profile">
            {this.renderMenu()}
            <Box className="content-box" data-test-id="content-box">
              <Box className="content" data-test-id="content">
                {this.renderContentItems()}
              </Box>
            </Box>
          </PageWrapper>
        }
        <MenuDrawer
          renderMenu={this.renderMenu}
          isOpen={this.state.isDrawerOpen}
          onToggle={this.toggleDrawer}
          data-test-id="menu-drawer"
        />
        <ResetPasswordModal
          isOpen={this.state.isOpenResetModal}
          goToForgotPassword={this.goToForgotPassword}
          onClose={() => this.setState({ isOpenResetModal: false })}
          onSubmit={this.updatePassword}
          title={this.transProfile("Password Reset")}
          getInputProps={this.getInputProps}
          error={this.state.settingsError}
          data-test-id="reset-password-modal"
        />
        <EndSessionModal
          isOpen={this.state.isOpenEndSession}
          title=""
          onClose={this.handleCloseDeleteModal}
          device={this.state.endSessionData}
          onSubmit={this.handleEndSession}
          data-test-id="end-session-modal"
        />
        <ResetEmailModal
          isOpen={this.state.isOpenResetEmailModal}
          goToForgotPassword={this.goToForgotPassword}
          onClose={() => this.setState({ isOpenResetEmailModal: false })}
          onSubmit={this.updateEmail}
          title={this.transProfile("Email Reset")}
          getInputProps={this.getInputProps}
          error={this.state.settingsError}
          data-test-id="reset-email-modal"
        />
        <DeleteModal
          isOpen={this.state.isOpenDeleteModal}
          onClose={() => this.setState({ isOpenDeleteModal: false })}
          onSubmit={this.deleteAccount}
          data-test-id="delete-modal"
        />
        <CancelationModal
          isReccurence={this.state.selectedEvent?.repeat_weekly}
          isOpen={this.state.isOpenCancelationModal}
          onClose={() => this.setState({ isOpenCancelationModal: false })}
          onSubmit={this.handleRequestCancelation}
          isLoading={this.state.isLoadingCancelation}
          data-test-id="cancelation-modal"
        />
        <ChangeRequestModal
          recurranceData={this.state.recurranceData}
          isOpen={this.state.isOpenChangeModal}
          onClose={() => this.setState({ isOpenChangeModal: false, errorResheduleRequest: '' })}
          onSubmit={this.handleRequestChange}
          event={this.state.selectedEvent}
          isLoading={this.state.isLoadingChangeRequest}
          renderCustomField={this.renderCustomSelectField}
          apiError={this.state.errorResheduleRequest}
          removeError={() => this.setState({ errorResheduleRequest: '' })}
          responseData={undefined}
          data-test-id="change-request-modal"
        />
        <AppFooter navigation={this.props.navigation} data-test-id="app-footer" />
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const styles: {
  general: {
    flexCol: CSSProperties;
  };
  selectedPet: CSSProperties;
  notSelectedPet: CSSProperties;
  actionButtonDefault: CSSProperties;
  medicalFormPhone: CSSProperties;
  filledLabel: CSSProperties;
  secondaryLabel: CSSProperties;
  customLabel: CSSProperties;
  inputStyleGeneral: CSSProperties;
  inputStyleDefault: CSSProperties;
  header: NestedCSSProperties;
  menu: NestedCSSProperties;
  pets: NestedCSSProperties & { addPetWrapper: CSSProperties; addText: CSSProperties };
  settings: NestedCSSProperties & { header: CSSProperties; modalText: CSSProperties; modalBody: CSSProperties };
} = {
  general: {
    flexCol: {
      display: 'flex', flexDirection: 'column'
    },
  },
  settings: {
    header: {
      fontWeight: 600,
      fontSize: '40px',
      color: '#000000',
      marginBottom: '10px'
    },
    modalText: {
      fontSize: '24px', fontWeight: 700
    },
    modalBody: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      flexDirection: 'column',
      gap: '30px',
      paddingTop: '20px',
      paddingBottom: '20px'
    }
  },
  selectedPet: {
    width: '75px',
    height: '75px',
    border: '2px solid rgba(111, 5, 234, 1)',
    transition: 'filter 0.3s ease, opacity 0.3s ease',
    backgroundColor: '#F0E5FF',
    color: '#6F05EA',
    cursor: "pointer"
  },
  notSelectedPet: {
    width: '75px',
    height: '75px',
    filter: 'grayscale(100%)',
    opacity: 0.5,
    transition: 'filter 0.3s ease, opacity 0.3s ease',
    cursor: "pointer"
  },
  actionButtonDefault: {
    height: '40px',
    textTransform: 'none',
    borderRadius: '100px',
    width: '146px',
    fontSize: '18px',
    fontFamily: 'Montserrat',
    fontWeight: 700
  },
  medicalFormPhone: {
    width: "100%",
    height: "32px",
    border: "none",
    borderRadius: "0px",
    paddingLeft: "40px",
  },
  filledLabel: {
    fontSize: '12px',
    fontWeight: 400,
    color: '#6F05EA',
    fontFamily: 'Montserrat',
    lineHeight: '18px',
    marginBottom: '8px',
  },
  secondaryLabel: {
    fontSize: '18px',
    color: '#777777',
    fontWeight: 600,
    fontFamily: 'Montserrat',
  },
  customLabel: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: '12px',
    color: '#777777',
    lineHeight: '18px',
  },
  inputStyleGeneral: {
    minHeight: '34px',
    fontSize: '18px',
    fontWeight: 600,
    fontFamily: 'Montserrat',
    marginTop: '5px',
    marginBottom: '4px'
  },
  inputStyleDefault: {
    minHeight: '34px',
    fontSize: '14px',
    fontWeight: 500,
    color: '#A6A6A6',
    fontFamily: 'Montserrat',
  },
  header: {
    wrapper: {
      minHeight: '127px',
      paddingLeft: '16px',
      paddingRight: '16px',
      display: 'flex',
      alignItems: 'center',
      background: "linear-gradient(#6928F2, #884FFF)",
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    user: {
      display: 'flex',
      alignItems: 'center',
      gap: '30px'
    },
    profile: {
      display: 'flex',
      alignItems: 'center',
      gap: '20px',
      color: '#fff'
    }
  },
  menu: {
    box: {
      paddingTop: '96px',
      paddingBottom: '96px',
      border: '1px solid #E2E8F0',
      width: '16%',
      maxWidth: '20%',
      minWidth: '290px',
      background: '#F6F6F6',
      justifyContent: 'flex-end',
      paddingLeft: '16px'
    },
    content: {
      width: '240px',
      maxWidth: '264px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    name: {
      fontWeight: 600,
      fontSize: '20px',
      color: "#000",
      textAlign: 'center',
      marginBottom: '16px',
      paddingRight: '16px',
      wordBreak: 'break-word'
    },
    pointsBox: {
      padding: '0 11px',
      borderRadius: '35px',
      background: '#FFCE00',
      height: '30px',
      marginTop: '8px',
      marginBottom: '20px',
      display: 'flex',
      alignItems: 'center'
    },
    pointsText: {
      fontWeight: 600, fontSize: '14px', color: '#000'
    },
    item: {
      display: 'flex',
      width: '100%',
      gap: '10px',
      height: '50px',
      borderRadius: '8px',
      padding: '0 20px',
      alignItems: 'center',
      cursor: 'pointer'
    },
    activeTitle: { fontWeight: 600, color: '#6F05EA', fontSize: '16px' },
    title: { fontWeight: 400, color: '#0F172A', fontSize: '16px' }
  },
  pets: {
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '32px',
      textAlign: 'left'
    },
    notSelectedWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '35px',
      width: '100%'
    },
    name: {
      fontWeight: 600, fontSize: '24px', color: '#000', background: 'white', wordBreak: 'break-word'
    },
    description: {
      fontWeight: 600, fontSize: '16px', color: '#B7B7B7'
    },
    photoBox: {
      display: 'flex', gap: '13px', width: '100%', justifyContent: 'center'
    },
    addPetWrapper: {
      background: "#F6F6F6",
      justifyContent: 'center',
      gap: '50px',
      minHeight: '300px'
    },
    addText: {
      color: '#B7B7B7', fontSize: '24px', fontWeight: 600
    }
  },
}

const ResponsiveWrappper = styled(Box)({
  "& .settings-header": {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer'
  },

  "& .menu-button": {
    [theme.breakpoints.up("md")]: {
      display: 'none'
    },
  },

  "& .mobile-settings-tabs": {
    [theme.breakpoints.up(660)]: {
      display: 'none'
    },
  },
  "& .desktop-settings-tabs": {
    [theme.breakpoints.down(660)]: {
      display: 'none'
    },
  },
  "& .personal-tab-wrapper": {
    textAlign: 'left',
    marginTop: '100px',
    [theme.breakpoints.down(660)]: {
      marginTop: '40px',
    },
  },
  "& .security-buttons": {
    display: 'flex', gap: '14px', marginBottom: '56px', marginTop: '31px', flexDirection: 'column',
    [theme.breakpoints.up(500)]: {
      flexDirection: 'row'
    },
  },
  "& .helper-text": {
    color: '#000', fontWeight: 500, fontSize: '18px', marginTop: '30px',
    [theme.breakpoints.down(500)]: {
      fontSize: '12px'
    },
  }
})

const SettingsWrapper = styled(Box)({
  paddingTop: '40px',
  "& .logged-in-card-container": {
    marginTop: "10px",
  },
  "& .delete-section": {
    display: 'flex', gap: '15px', alignItems: 'normal', flexDirection: 'column-reverse',
    [theme.breakpoints.up(600)]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  "& .logged-in-card": {
    display: "flex",
    gap: "34px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "36px",
    marginTop: "20px",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 7.5px 0px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down(600)]: {
      padding: "16px",
      gap: "16px",
      flexDirection: 'column',
      alignItems: 'normal'
    },
  },
  "& .device-name-container": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  "& .device-img": {
    [theme.breakpoints.down("sm")]: {
      height: "40px",
      width: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "30px",
      width: "30px",
    },
  },
  "& .device-date": {
    fontSize: "16px",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  "& .device-current": {
    fontSize: "16px",
    fontWeight: 600,
    color: '#6F05EA',
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  "& .device-address": {
    fontSize: "16px",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  "& .device-name": {
    fontSize: "20px",
    fontWeight: 600,
    color: '#424242',
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  "& .end-session-btn": {
    border: "1px solid #DC2626",
    borderRadius: "100px",
    padding: "5px 45px",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "none",
    color: "#DC2626",
    backgroundColor: "transparent",
    boxShadow: "none",
    minWidth: "unset",
    whiteSpace: "nowrap",
    "&:hover": {
      border: "1px solid #6F05EA",
      color: "#6F05EA"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px 25px",
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "5px 10px",
      fontSize: "10px",
    },
  },
})

const TreatsWrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  gap: '27px',
  flexDirection: 'column',


})

const MedicalWrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  gap: '27px',
  flexDirection: 'column',

  "&.edit-open": {
    "& .react-tel-input": {
      marginTop: '16px'
    }
  },

  "& .react-tel-input": {
    marginTop: '7px'
  }
})

const PageWrapper = styled(Box)({
  display: 'flex',
  flex: 1,

  "& .pet-box-item": {
    width: '100%',
    boxShadow: '0px 0px 7.5px 0px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    padding: '29px',
    alignItems: 'center',
    gap: '24px',
    [theme.breakpoints.up(735)]: {
      width: '297px',
      maxWidth: '297px',
    },
  },

  "& .treats-row-item": {
    width: '100%', display: 'flex', gap: '27px', flexDirection: 'column',
    [theme.breakpoints.up(600)]: {
      flexDirection: 'row',
      alignItems: 'flex-end'
    },
  },

  "& .treat-label": {
    fontSize: '18px',
    fontWeight: 500,
    [theme.breakpoints.up(600)]: {
      minWidth: '110px',
    },
  },

  "& .treat-label.desktop": {
    [theme.breakpoints.down(600)]: {
      display: 'none'
    },
  },

  "& .range-dot-item": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',


    [theme.breakpoints.up(600)]: {
      width: '104px',
    },
    [theme.breakpoints.down(600)]: {
      flex: 1,

      '&.first': {
        justifyContent: 'flex-start',
      },
      '&.last': {
        justifyContent: 'flex-end',
      },
    },
  },

  "& .range-select-wrapper": {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    [theme.breakpoints.up(600)]: {
      flexDirection: 'row',
    },
  },

  "& .treat-mobile-wrapper": {
    display: 'flex', justifyContent: 'space-between',
    [theme.breakpoints.up(600)]: {
      display: 'none'
    },
  },

  "& .table-row-wrapper": {
    display: 'flex',
    gap: '27px',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [theme.breakpoints.up(600)]: {
      flexDirection: 'row'
    },
  },

  "& .general-pet-buttons": {
    display: 'flex',
    flexDirection: 'column',
    gap: '17px',
    [theme.breakpoints.up(600)]: {
      justifyContent: 'flex-end',
      flexDirection: 'row'
    },
  },

  "& .action-btn": {
    ...styles.actionButtonDefault,
    border: '1px solid #4B4B4B',
    color: '#4B4B4B',
    [theme.breakpoints.down(600)]: {
      width: '100%'
    },
  },

  "& .delete-btn": {
    ...styles.actionButtonDefault,
    border: '1px solid #4B4B4B',
    width: "310px",
    color: '#4B4B4B',
    [theme.breakpoints.down(600)]: {
      width: '100%'
    },
  },

  "& .medical-row-wrapper": {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(600)]: {
      flexDirection: 'row',
      gap: '27px',
    },
  },

  "& *": {
    boxSizing: 'border-box',
  },

  "& .MuiTypography-root.MuiTypography-body1": {
    fontFamily: 'Montserrat',
  },

  '& .MuiInput-underline.Mui-disabled': {
    '&::before': {
      borderBottomStyle: 'solid'
    }
  },

  '& .MuiInputBase-root.Mui-disabled': {
    color: 'inherit'
  },

  '& .MuiInputBase-input.MuiInput-input': {
    "&::placeholder": {
      color: '#A6A6A6',
      fontSize: '13px',
      opacity: 1,
      fontWeight: 400,
      fontFamily: 'Montserrat',
    }
  },

  "& .custom-tab": {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    color: '#B7B7B7',
    fontWeight: 500,
    flex: 1,
    textTransform: 'none'
  },
  "& .custom-tab.Mui-selected": {
    color: '#6F05EA',
    fontWeight: 700,
  },

  "& .soon-wrapper": {
    background: '#FFB800',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '73px',
    height: '17px',
    borderRadius: '3px'
  },
  "& .soon-text": {
    color: '#fff',
    fontSize: '12px',
    fontWeight: 700
  },
  "& .vertical-tab": {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    color: '#808080',
    fontWeight: 600,
    flex: 1,
    textTransform: 'none',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '1px',
      backgroundColor: '#000',
    },
  },
  "& .vertical-tab.Mui-selected": {
    color: '#6F05EA',
    fontWeight: 600,
  },

  "& .MuiListItem-button": {
    fontFamily: 'Montserrat',

    '&:hover': {
      textTransform: 'none',
      backgroundColor: 'rgba(111, 5, 234, 0.1)',
    },
  },

  "& .MuiSwitch-root": {
    width: 54,
    height: 26,
    padding: 0,
  },

  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(27px)',
      color: 'rgba(111, 5, 234, 1)',
      '& + .MuiSwitch-track': {
        backgroundColor: 'rgba(111, 5, 234, 0.2)',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
  },

  "& .MuiSwitch-thumb": {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },

  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },

  "& .main-menu-wrapper": {
    display: 'none',
    [theme.breakpoints.up("md")]: {
      display: 'flex',
    },
  },
  "& .content-box": {
    flex: 1,
    paddingTop: '25px',
    paddingBottom: '25px',
    paddingLeft: '16px',
    paddingRight: '16px',
    background: 'white',
    textAlign: 'center',
    overflow: 'hidden',

    [theme.breakpoints.up("md")]: {
      paddingLeft: '35px',
      paddingRight: '35px',
      paddingTop: '50px',
      paddingBottom: '50px'
    },

    [theme.breakpoints.up("lg")]: {
      paddingLeft: '100px',
      paddingRight: '100px',
      paddingTop: '96px',
      paddingBottom: '96px'
    },

    "& .content": {
      maxWidth: '100%',
    }
  },

  "& .custom-svg.active": {
    "& path": {
      fill: '#6F05EA'
    }
  }
})

const ResponsiveBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '35px',
  flexDirection: 'column-reverse',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    gap: '20px',
  },
  "& .pets-list": {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      paddingLeft: '16px',
    }
  }
}))

type CSSProperties = React.CSSProperties;
type NestedCSSProperties = { [key: string]: CSSProperties | NestedCSSProperties };

const StyledMenuBox = styled(Box)({
  height: "100%",
})
// Customizable Area End
