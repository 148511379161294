// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Views } from "react-big-calendar";
import { defaultScroll, isValidEmail, returnTruthyString } from "../../../../components/src/HelperUtils";
import { IEmployee, IScheduleInfoAttrib, IServiceSubService, ISubService, IUserRole } from "../../../../components/src/interfaces.web";
import i18n from "../../../../components/src/i18next/i18n";
import { getAppointmentParams } from "../../../../components/src/helpers";
import moment from "moment";
import { EmployeeEvent } from "../../../../blocks/customisableuserprofiles/src/types";
import { IPet } from "../../../../blocks/customisableuserprofiles/src/CustomisableUserProfilesController";
export const configJSON = require("../config.js");
type DayKey = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
interface IAvailability {
    day: DayKey,
    start_time: string,
    end_time: string,
    checked: boolean
}
type TViewKey = keyof typeof Views

interface IFormDetails {
    photo: {
        file: File | null,
        url: string,
    };
    name: string;
    roleId: string;
    email: string;
    phone: string;
    serviceId: string;
    subServiceIds: string[];
    availability: IAvailability[]
}

const initialFormValues: IFormDetails = {
    photo: {
        url: "",
        file: null,
    },
    name: "",
    roleId: "",
    email: "",
    phone: "",
    serviceId: "",
    subServiceIds: [],
    availability: configJSON.initialAvailability
}
interface TEmployeeFormError {
    photo: string;
    name: string;
    roleId: string;
    email: string;
    phone: string;
    serviceId: string;
    subServiceIds: string;
    availability: Record<DayKey, string>
}

type AppointmentParams = {
    filter_type?: string;
    start_date?: string;
    end_date?: string;
    establishment_id: string;
    employee_id: string;
  }

const initialErrors: TEmployeeFormError = {
    photo: "",
    name: "",
    roleId: "",
    email: "",
    phone: "",
    subServiceIds: "",
    serviceId: "",
    availability: {
        Monday: "",
        Tuesday: "",
        Wednesday: "",
        Thursday: "",
        Friday: "",
        Saturday: "",
        Sunday: ""
    }
}
type IEditEmployeeResponse = {
    id?: string;
    errors?: string[];
};

export interface Schedule {
  id: number,
  shift: string,
  start_date: string;
  end_date: string;
  price: string;
  total_price: string;
  medication: string;
  duration: null | string;
  start_time: string | null;
  transportation: string;
  repeat_weekly?: boolean;
  repeat_every?: number | null;
  medication_request: boolean;
  transportation_request: boolean;
  search_end_date?: string;
  search_start_date?: string;
  recurrences?: number;
  week_days?: string[];
  sub_service: {
    id: number;
    title: string;
    location: string;
    images: string[];
    duration: string | null;
  };
  service: {
    id: number;
    service_type: string;
  };
  sub_services_price: {
    id: number;
    price: number;
    title?: string;
  };
  service_provider: {
    id: number;
    name: string;
    email: string;
  };
  customer: {
    id: number;
    name: string;
    email: string;
  };
  requests: {
    request_details: Request[];
  };
  pet: {
    data: {
      id: number;
      attributes: IPet;
    }
  };
  name?:string,
  events:IEvent[]
}

export interface IEvent {
  id:string,
  type:string,
  attributes: IEventAttr
}

interface IEventAttr {
  id: number,
  name: string,
  start_date: string,
  end_date: string,
  employee: {
    id: number,
    establishment_id: number,
    service_id: number,
    role_id: number,
    name: string,
    email: string,
    phone: string,
    created_at: string,
    updated_at: string,
    country_code: null,
    sub_service_ids: number[]
  }
}
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    apiToken: string,
    establishmentId: string,
    // FORM
    isEditOn: boolean,
    initialEmployeeForm: IFormDetails,
    initialSubServiceList: ISubService[],
    employeeForm: IFormDetails,
    formErrors: TEmployeeFormError,
    // API
    getEmployeeLoading: boolean,
    employeeDetails: IEmployee,
    userRoleList: IUserRole[],
    userRoleListLoading: boolean,
    serviceList: IServiceSubService[],
    serviceListLoading: boolean,
    subServiceList: ISubService[],
    editEmployeeLoading: boolean,
    // MODAL
    openModal: boolean,
    msgModal: string,
    openAddEvent: boolean,
    eventName: string,
    eventStartDate: null | Date;
    eventEndDate: null | Date;
    // CALENDAR
    calendarView: (typeof Views)[TViewKey];
    events: Schedule[];
    calendarDate: Date;
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class ViewEmployeeController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start    
    getUserRolesApiCallId: string = ""
    getServicesListApiCallId: string = ""
    getEmployeeApiCallId: string = ""
    editEmployeeApiCallId: string = ""
    getCalendarEventApiCallId: string = ""
    postCalendarEventApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            apiToken: returnTruthyString(localStorage.getItem("login_token")),
            establishmentId: returnTruthyString(localStorage.getItem("establishment_ID")),
            // FORM
            isEditOn: false,
            initialEmployeeForm: initialFormValues,
            initialSubServiceList: [],
            employeeForm: initialFormValues,
            formErrors: { ...initialErrors },
            // API
            getEmployeeLoading: false,
            employeeDetails: {} as IEmployee,
            userRoleList: [],
            userRoleListLoading: false,
            serviceList: [],
            serviceListLoading: false,
            subServiceList: [],
            editEmployeeLoading: false,
            // MODAL
            openModal: false,
            msgModal: "",
            openAddEvent: false,
            eventName: "",
            eventStartDate: new Date(),
            eventEndDate: null,
            // CALENDAR
            calendarView: "week",
            events: [],
            calendarDate: moment().toDate(),
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const receivedData = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
            if (receivedData && receivedData.isEditOn) {
                this.setState({
                    isEditOn: true
                })
            }
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId) {
                switch (apiRequestCallId) {
                    case this.getUserRolesApiCallId:
                        return this.getUserRolesResp(responseJson)
                    case this.getServicesListApiCallId:
                        return this.getServicesListResp(responseJson)
                    case this.getEmployeeApiCallId:
                        return this.getEmployeeApiResp(responseJson)
                    case this.editEmployeeApiCallId:
                        return this.editEmployeeApiResp(responseJson)
                    case this.getCalendarEventApiCallId:
                        return this.getEmployeeCalendarEvent(responseJson)
                    case this.postCalendarEventApiCallId:
                        return this.addEmployeeCalendarEvent(responseJson)    
                }

            }
        }
        // Customizable Area End
    }
    // Customizable Area Start

    getUserRolesResp = (response: IUserRole[]) => {
        this.setState({ userRoleListLoading: false })
        if (response && response.length) {
            this.setState({
                userRoleList: response
            })
        } else {
            this.setState({
                userRoleList: []
            })
        }
    }
    getServicesListResp = (response: IServiceSubService[]) => {
        this.setState({ serviceListLoading: false })
        if (response && response.length) {
            this.setState({
                serviceList: response,
                subServiceList: [],
            })
        } else {
            this.setState({
                serviceList: [],
                subServiceList: [],
            })
        }
    }
    getEmployeeApiResp = (responseJson: IEmployee) => {
        this.setState({ getEmployeeLoading: false })
        if (responseJson && 'id' in responseJson) {
            this.setEmployeeData(responseJson)
        } else {
            this.setState({
                employeeForm: initialFormValues
            })
        }
    }
    editEmployeeApiResp = (responseJson: IEditEmployeeResponse) => {
        this.setState({ editEmployeeLoading: false })
        if (responseJson && 'id' in responseJson) {
            const employeeId = this.props.navigation.getParam("navigationBarTitleText")
            this.getEmployeeById(employeeId)
            this.setState({
                isEditOn: false
            })
        }
        if (responseJson?.errors && responseJson?.errors.length) {
            const firstError = responseJson?.errors[0]
            this.handleSetMsgModal(true, firstError)
        }
    }

    getEmployeeCalendarEvent = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            let combinedEvents: any[] = [];

            if (responseJson.schedules && responseJson.schedules.length > 0) {
                const Schedules = responseJson.schedules.map(
                    (Schedule: { attributes: Schedule }) => Schedule.attributes
                );
                combinedEvents = [...combinedEvents, ...Schedules];
            }

            if (responseJson.events && responseJson.events.length > 0) {
                const Events = responseJson.events.map(
                    (event: { attributes: Schedule }) => event.attributes
                );
                combinedEvents = [...combinedEvents, ...Events];
            }
            this.setState({ events: combinedEvents });
        }
    }

    addEmployeeCalendarEvent = (responseJson: any) => {
        if (responseJson && responseJson.meta?.message === 'Employee Event created successfully') {
            const employeeId = this.props.navigation.getParam("navigationBarTitleText")
            this.setState({ openAddEvent: false })
            this.getCalendarEvent(employeeId)
        }
    }

    handleChangeDate = (date: Date) => {
        this.setState({ calendarDate: date });
    }
    
    async componentDidMount() {
        defaultScroll()
        const employeeId = this.props.navigation.getParam("navigationBarTitleText")
        this.getUserRoles()
        this.getServicesList()
        this.setState({ getEmployeeLoading: true })
        this.getEmployeeById(employeeId)
        this.getCalendarEvent(employeeId)
    }

    async componentDidUpdate(prevProps: Props, prevState: S) {
        if(prevState.calendarDate !== this.state.calendarDate || prevState.calendarView !== this.state.calendarView) {
            const employeeId = this.props.navigation.getParam("navigationBarTitleText")
            this.getCalendarEvent(employeeId)
        }
    }

    getUserRoles = () => {
        this.setState({ userRoleListLoading: true })

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getUserRolesApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.ENDPOINTS.USER_ROLE);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.GET);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getServicesList = () => {
        const { establishmentId } = this.state
        if (!establishmentId) return
        this.setState({ serviceListLoading: true })
        const header = {
            "token": this.state.apiToken
        };
        const endpoint = `${configJSON.ENDPOINTS.GET_SERVICE_SUB_SERVICES}?establishment_id=${establishmentId}`

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getServicesListApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.GET);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getEmployeeById = (employeeId: string) => {
        const { establishmentId } = this.state
        if (!establishmentId || !employeeId) return
        const header = {
            "token": this.state.apiToken
        };
        const endpoint = `bx_block_navmenu/employees/${employeeId}?establishment_id=${establishmentId}`

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getEmployeeApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.GET);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    getCalendarEvent = (employeeId: string) => {
        const { establishmentId, calendarDate, calendarView } = this.state
        const params: AppointmentParams = { establishment_id: establishmentId, employee_id: employeeId };
        const header = {
                  "Content-Type": "application/json",
                  token: this.state.apiToken,
        };
        if(calendarView === Views.WEEK) {
            params.start_date = getAppointmentParams(calendarDate, 'week').start;
            params.end_date = getAppointmentParams(calendarDate, 'week').end;
        } else {
            params.start_date = getAppointmentParams(calendarDate, 'month').start;
            params.end_date = getAppointmentParams(calendarDate, 'month').end;
        }
        const queryString = new URLSearchParams(params as Record<string, string>).toString();
        const endpoint = `bx_block_navmenu/employee_calendars/?${queryString}`
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCalendarEventApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    addCalendarEvent = () => {
        const { establishmentId, employeeDetails } = this.state
        const inputStartDate = this.state.eventStartDate
        const formattedStartDate = this.formatDate(inputStartDate)
        const inputEndDate = this.state.eventEndDate
        const formattedEndDate = this.formatDate(inputEndDate)

        const header = {
                  "Content-Type": "application/json",
                  token: this.state.apiToken,
                };
        const httpBody = {
                "establishment_id": establishmentId,
                "employee_id": employeeDetails.attributes.id,
                "event": {
                    "name": this.state.eventName,
                    "start_date": formattedStartDate,
                    "end_date": formattedEndDate
                     }            
                };  
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.postCalendarEventApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_navmenu/employee_calendars");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    setAvailability = (availabilityResp: IScheduleInfoAttrib[]): IAvailability[] => {
        const updatedAvailability = (configJSON.initialAvailability as IAvailability[]).map((day) => {
            const match = availabilityResp.find((data) => data.day === day.day);
            return {
                ...day,
                start_time: returnTruthyString(match?.start_time),
                end_time: returnTruthyString(match?.end_time),
                checked: !!match,
            };
        });
        return updatedAvailability
    }

    setEmployeeData = (responseJson: IEmployee) => {
        const { attributes } = responseJson

        const subServiceIdsArr = attributes?.sub_service_ids.map((serviceId) => String(serviceId))
        const serviceId = returnTruthyString(attributes?.service?.id)
        const updatedAvailability = this.setAvailability(attributes?.schedule_informations)
        const updatedValues: IFormDetails = {
            photo: {
                file: null,
                url: returnTruthyString(attributes.employee_image),
            },
            name: returnTruthyString(attributes?.name),
            roleId: returnTruthyString(attributes?.role?.id),
            email: returnTruthyString(attributes?.email),
            phone: returnTruthyString(attributes?.phone),
            serviceId: serviceId,
            subServiceIds: subServiceIdsArr,
            availability: updatedAvailability
        }
        const subService = this.state.serviceList.find((service) => String(service.service_id) == String(serviceId))
        const subServiceList = subService && subService.sub_services.length ? subService.sub_services : []
        this.setState({
            initialEmployeeForm: updatedValues,
            initialSubServiceList: subServiceList,
            subServiceList: subServiceList,
            employeeDetails: responseJson,
            employeeForm: updatedValues,
        })
    }
    setFieldPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        if (files && files[0]) {
            const file = files[0];
            const fileSize = file.size / (1024 * 1024); // File size in MB
            const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];


            if (!validTypes.includes(file.type)) {
                this.setError('photo', configJSON.DEFAULT_MSG.INVALID_PHOTO_TYPE)
                return;
            }

            if (fileSize > 5) {
                this.setError('photo', configJSON.DEFAULT_MSG.INVALID_PHOTO_SIZE)
                return;
            }
            this.setError('photo', "")
            this.setState((prevState) => ({
                employeeForm: {
                    ...prevState.employeeForm,
                    photo: {
                        ...prevState.employeeForm.photo,
                        file: file,
                        url: URL.createObjectURL(file),
                    },
                },
            }));
        }
    };
    setFieldName = (nameValue: string) => {
        const value = nameValue.trimStart()
        this.setState({
            employeeForm: {
                ...this.state.employeeForm,
                name: value
            },
        })
        this.setError("name", this.setNameError(value))
    }
    setFieldRole = (roleId: string) => {
        this.setState({
            employeeForm: {
                ...this.state.employeeForm,
                roleId: roleId
            }
        })
        this.setError("roleId", this.setRoleError(roleId))
    }
    setFieldEmail = (emailValue: string) => {
        const value = emailValue.trimStart()
        this.setState({
            employeeForm: {
                ...this.state.employeeForm,
                email: value
            }
        })
        this.setError("email", this.setEmailError(value))
    }
    setFieldPhone = (phoneValue: string) => {
        const value = phoneValue.trimStart()
        this.setState({
            employeeForm: {
                ...this.state.employeeForm,
                phone: value
            }
        })
        this.setError("phone", this.setPhoneError(value))
    }
    setFieldService = (serviceId: string) => {
        const subService = this.state.serviceList.find((service) => String(service.service_id) == String(serviceId))
        const subServiceList = subService && subService.sub_services.length ? subService.sub_services : []
        this.setState({
            employeeForm: {
                ...this.state.employeeForm,
                serviceId: serviceId,
                subServiceIds: []
            },
            subServiceList: subServiceList
        })
        this.setState({
            formErrors: {
                ...this.state.formErrors,
                serviceId: this.setServiceError(serviceId),
                subServiceIds: ""
            }
        })
    }
    setFieldSubService = (event: React.ChangeEvent<{ value: unknown }>) => {
        const valueArr = event.target.value as string[]
        this.setState({
            employeeForm: {
                ...this.state.employeeForm,
                subServiceIds: valueArr
            }
        })
        this.setError("subServiceIds", this.setSubServiceError(valueArr))
    }
    setFieldAvailability = (checkedValue: boolean, Day: DayKey) => {
        const availabilityClone = [...this.state.employeeForm.availability]
        const updatedAvailability = availabilityClone.map((record) => {
            if (record.day == Day) {
                return {
                    day: Day,
                    start_time: "",
                    end_time: "",
                    checked: checkedValue,
                }
            }
            return record
        })

        this.setState({
            formErrors: {
                ...this.state.formErrors,
                availability: {
                    ...this.state.formErrors.availability,
                    [Day]: ""
                }
            },
            employeeForm: {
                ...this.state.employeeForm,
                availability: updatedAvailability
            },
        })
    };
    setFieldTimes = (value: string, day: DayKey, type: "start_time" | "end_time") => {
        const availabilityCopy = [...this.state.employeeForm.availability]

        const updatedAvailabilityArr = availabilityCopy.map((object) => {
            if (object.day == day && type == "start_time") {
                return {
                    ...object,
                    start_time: value,
                    end_time: "",
                }
            }
            if (object.day == day && type == "end_time") {
                return {
                    ...object,
                    end_time: value,
                }
            }
            return object
        })

        this.setState({
            employeeForm: {
                ...this.state.employeeForm,
                availability: updatedAvailabilityArr
            },
            formErrors: {
                ...this.state.formErrors,
                availability: {
                    ...this.state.formErrors.availability,
                    [day]: ""
                }
            }
        })
    }

    validateForm1 = () => {

        const { photo, name, roleId, email, phone, serviceId, subServiceIds } = this.state.employeeForm
        let photo_error_msg = ""
        if (photo.file) {
            photo_error_msg = this.setPhotoError(photo.file)
        }
        let name_error_msg = this.setNameError(name.trim())
        let role_error_msg = this.setRoleError(roleId)
        let email_error_msg = this.setEmailError(email)
        let phone_error_msg = this.setPhoneError(phone)
        let service_error_msg = this.setServiceError(serviceId)
        let sub_service_error_msg = ""

        if (this.isShowSubService()) {
            sub_service_error_msg = this.setSubServiceError(subServiceIds)
        }

        const errorObj = {
            photo: photo_error_msg,
            name: name_error_msg,
            roleId: role_error_msg,
            email: email_error_msg,
            phone: phone_error_msg,
            serviceId: service_error_msg,
            subServiceIds: sub_service_error_msg,
        }
        this.setState({
            formErrors: {
                ...this.state.formErrors,
                ...errorObj
            }
        })

        const formHasErrors = Object.values(errorObj).some(value => Boolean(value));

        return !formHasErrors
    }
    validateForm2 = () => {
        let errorObj: Record<DayKey, string> = {
            Monday: "",
            Tuesday: "",
            Wednesday: "",
            Thursday: "",
            Friday: "",
            Saturday: "",
            Sunday: ""
        }

        const { employeeForm } = this.state
        const availabilityCopy = [...employeeForm.availability]

        availabilityCopy.forEach((record) => {
            if (record.checked) {
                if (!record.start_time) {
                    errorObj[record.day] = configJSON.DEFAULT_MSG.START_TIME_EMPTY
                    return
                }
                if (!record.end_time) {
                    errorObj[record.day] = configJSON.DEFAULT_MSG.END_TIME_EMPTY
                    return
                }
            } else {
                errorObj[record.day] = ""
            }
        })
        this.setState({
            formErrors: {
                ...this.state.formErrors,
                availability: errorObj
            }
        })

        const hasErrors = Object.values(errorObj).some(value => Boolean(value));
        return !hasErrors
    }

    editEmployee = () => {
        const employeeId = this.props.navigation.getParam("navigationBarTitleText")
        if (!employeeId) return
        if (this.validateForm1() && this.validateForm2()) {
            this.setState({ editEmployeeLoading: true })
            const header = {
                "token": this.state.apiToken
            };
            const bodyData = this.getBody()
            const endpoint = `${configJSON.ENDPOINTS.ADD_EMPLOYEE}/${employeeId}`
            const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.editEmployeeApiCallId = reqMessage.messageId;
            reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
            reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.API_METHOD.PATCH);
            reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), bodyData);
            runEngine.sendMessage(reqMessage.id, reqMessage);
        }
    }

    getBody = () => {
        const { establishmentId } = this.state

        const { photo, name, roleId,
            email, phone, serviceId,
            subServiceIds, availability
        } = this.state.employeeForm
        const reqForm = new FormData()

        reqForm.append("establishment_id", establishmentId)

        if (photo.file) {
            reqForm.append("employee[image]", photo.file)
        }
        reqForm.append("employee[name]", name)
        reqForm.append("employee[role_id]", roleId)
        reqForm.append("employee[email]", email)
        reqForm.append("employee[phone]", phone)
        reqForm.append("employee[service_id]", serviceId)

        if (subServiceIds.length) {
            subServiceIds.forEach((subServiceId) => {
                reqForm.append("employee[sub_service_ids][]", subServiceId)
            })
        } else {
            reqForm.append("employee[sub_service_ids][]", "")
        }
        const selectedDays = availability.filter((record) => record.checked)

        if (selectedDays.length) {
            selectedDays.forEach((eachDay) => {
                reqForm.append(`schedule_information[days][${eachDay.day}][start_time]`, eachDay.start_time)
                reqForm.append(`schedule_information[days][${eachDay.day}][end_time]`, eachDay.end_time)
            })
        }
        return reqForm
    }

    setError = (key: keyof TEmployeeFormError, errorMsg: string) => {
        this.setState({
            formErrors: {
                ...this.state.formErrors,
                [key]: errorMsg
            }
        })
    }
    setPhotoError = (photo: File | null) => {
        if (!photo) {
            return configJSON.DEFAULT_MSG.PHOTO_EMPTY
        }
        const fileSize = photo.size / (1024 * 1024); // File size in MB
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

        if (!allowedTypes.includes(photo.type)) {
            return configJSON.DEFAULT_MSG.INVALID_PHOTO_TYPE
        }

        if (fileSize > 5) {
            return configJSON.DEFAULT_MSG.INVALID_PHOTO_SIZE
        }
        return ""
    }
    setNameError = (nameValue: string) => {
        if (!nameValue) {
            return configJSON.DEFAULT_MSG.NAME_EMPTY
        }
        return ""
    }
    setRoleError = (roleValue: string) => {
        if (!roleValue) {
            return configJSON.DEFAULT_MSG.ROLE_EMPTY
        }
        return ""
    }
    setEmailError = (emailValue: string) => {
        if (!emailValue || !isValidEmail(emailValue)) {
            return configJSON.DEFAULT_MSG.EMAIL_EMPTY
        }
        return ""
    }
    setPhoneError = (phoneValue: string) => {
        if (!phoneValue) {
            return configJSON.DEFAULT_MSG.PHONE_EMPTY
        }
        return ""
    }
    setServiceError = (serviceValue: string) => {
        if (!serviceValue) {
            return configJSON.DEFAULT_MSG.SERVICE_EMPTY
        }
        return ""
    }
    setSubServiceError = (subServiceValue: string[]) => {
        if (!subServiceValue.length) {
            return configJSON.DEFAULT_MSG.SUBSERVICE_EMPTY
        }
        return ""
    }
    handleSetMsgModal = (open: boolean, msg: string) => {
        this.setState({
            openModal: open,
            msgModal: msg
        })
    }
    handleCloseMsgModal = () => {
        this.setState({
            openModal: false,
            msgModal: ""
        })
    }
    isShowSubService = () => {
        const { employeeForm, serviceList } = this.state
        const selectedService = serviceList.find((service) => String(service.service_id) == String(employeeForm.serviceId))
        const allowedServices: string[] = configJSON.SUB_SERVICES_FOR_EMPLOYEES

        return selectedService && allowedServices.includes(selectedService.service_name)
    }
    handleEdit = () => {
        this.setState({
            isEditOn: true
        })
    }
    handleCancel = () => {
        this.setState({
            isEditOn: false,
            employeeForm: this.state.initialEmployeeForm,
            subServiceList: this.state.initialSubServiceList,
            formErrors: { ...initialErrors }
        })
    }

    goToListEmployee = () => {
        const NavigationData = {
            showEmployeeManagement: true
        }

        const NavigateMsg: Message = new Message(getName(MessageEnum.NavigationMessage));
        NavigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "CustomisableUserProfilesVender"); // ROUTE
        NavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), NavigationData); // DATA
        NavigateMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

        this.send(NavigateMsg);
    }
    handleOpenAddEvent = () => {
        this.setState({
            openAddEvent: true
        })
    }
    handleClosAddEvent = () => {
        this.setState({
            openAddEvent: false,
            // RESET
            eventName: "",
            eventStartDate: new Date(),
            eventEndDate: null
        })
    }
    setEventName = (value: string) => {
        const filteredValue = value.trimStart()
        this.setState({
            eventName: filteredValue
        })
    }
    setEventDate = (date: Date | null, key: keyof Pick<S, "eventStartDate" | "eventEndDate">) => {
        this.setState({
            [key]: date
        } as Pick<S, "eventStartDate" | "eventEndDate">);
    }
    changeCalendarView = (view: (typeof Views)[TViewKey]) => {
        this.setState({ calendarView: view })
    }
    formatDate(dateString: any) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      }    

    transViewEmp = (viewKey: string) => {
        return i18n.t(viewKey, { ns: "employeeManagement" })
    }
    // Customizable Area End
}