export const enCustomerUserProfile = {
    //Calendar
    "Calendar":"Calendar",
    "Appointments":"Appointments",
    "Past appointments":"Past appointments",
    "Requests":"Requests",
    "No appointments":"No appointments",
    "No requests":"No requests",
    "No past appointments":"No past appointments",
    "Hide all":"Hide all",
    "View all":"View all",
    "Mon":"Mon",
    "Tues":"Tues",
    "Wed":"Wed",
    "Thu":"Thu",
    "Fri":"Fri",
    "Sat":"Sat",
    "Sun":"Sun",
    //Request
        "Appointment Details":"Appointment Details",
        "`s Details":"`s Details",
        "Start Date":"Start Date",
        "Date":"Date",
        "Recurrence":"Recurrence",
        "Day Of The Week":"Day Of The Week",
        "End Date":"End Date",
        "Time":"Time",
        "Service Type":"Service Type",
        "Change Request":"Change Request",
        "Sent":"Sent",
        "Received":"Received",
        "Every":"Every",
        "week":"week",
        "Request":"Request",
        "Request a change":"Request a change",
        "We will wait for vendor approval for the change request.":"We will wait for vendor approval for the change request.",
        "All appointment":"All appointment",
        "Change Request Sent":"Change Request Sent",
        "Cancellation request sent":"Cancellation request sent",
        "Transportation request sent":"Transportation request sent",
        "approved":"approved",
        "is on approval":"is on approval",
        "rejected":"rejected",
        //Field
        "Updated Start Date":"Updated Start Date",
        "Updated Recurrence":"Updated Recurrence",
        "Updated Time":"Updated Time",
        "Updated End Date":"Updated End Date",
        "Old Start Date":"Old Start Date",
        "Old Date":"Old Date",
        "Old End Date":"Old End Date",
        "Old Time":"Old Time",
        "Updated Date":"Updated Date",
        "Price":"Price",
        "Animal":"Animal",
        "Breed":"Breed",
        "Weight":"Weight",
        "Fur":"Fur",
        "Gender":"Gender",
        "Birthdate":"Birthdate",
        "Size":"Size",
        "Updated Appointment Date":"Updated Appointment Date",
        "This appointment has been deleted by the vendor":"This appointment has been deleted by the vendor",
        "Only this appointment":"Only this appointment",
        //Placeholder
        "Select date":"Select date",
        //Error
        "*Please enter new appointment date":"*Please enter new appointment date",
        "*Please enter new end date":"*Please enter new end date",
        //Modal
        "You're requesting a booking cancellation, are you sure?":"You're requesting a booking cancellation, are you sure?",
        "This does not guarantee full refund, we will wait for vendor approval for the cancellation request.":"This does not guarantee full refund, we will wait for vendor approval for the cancellation request.",
        "No, don't request cancellation":"No, don't request cancellation",
        "Yes, I'm sure":"Yes, I'm sure",
        "Only this booking":"Only this booking",
        "All bookings":"All bookings",
        "End sessionIf you remove your account, we will cancel your appointments and NOT refund you.":"End sessionIf you remove your account, we will cancel your appointments and NOT refund you.",
        "All your data will be deleted and you will be logged out.":"All your data will be deleted and you will be logged out.",
        "Are you sure you want to proceed?":"Are you sure you want to proceed?",
        //Button
        "Request change":"Request change",
        "Request cancellation":"Request cancellation",
        "Reject":"Reject",
        "Accept":"Accept",
        "Cancel":"Cancel",
        "Delete account":"Delete account",
        "End session":"End session",
    //Setting
        "Password and email reset":"Password and email reset",
        "Logged sessions":"Logged sessions",
        "Reset Password":"Reset Password",
        "Reset Email":"Reset Email",
        "You're currently logged in on these devices":"You're currently logged in on these devices",
        "This device":"This device",
        "End Session":"End Session",
        "Are You Sure you want to End Session with":"Are You Sure you want to End Session with",
        "Password Reset":"Password Reset",
        "Email Reset":"Email Reset",
        "Card details":"Card details",
        "Update your payment cards":"Update your payment cards",
        "Add new payment method":"Add new payment method",
        "Set as default":"Set as default",
        "Settings":"Settings",
        "Personal":"Personal",
        "Security & Privacy":"Security & Privacy",
        "Payment":"Payment",
        "Forgot your password? Click":"Forgot your password? Click",
        "here":"here",
        "Permanently remove your Petzai account. You will lose access to your account data, your previous reservations, your favorite establishments or your wallet.":"Permanently remove your Petzai account. You will lose access to your account data, your previous reservations, your favorite establishments or your wallet.",
        //Field
        "First Name":"First Name",
        "Last Name":"Last Name",
        "Language":"Language",
        "Nationality":"Nationality",
        "Phone":"Phone",
        "Address":"Address",
        "Country":"Country",
        "City":"City",
        "Zip Code":"Zip Code",
        "Current password":"Current password",
        "New password":"New password",
        "Confirm New password":"Confirm New password",
        "New email":"New email",
        "Confirm New email":"Confirm New email",
        "Password":"Password",
        //Placeholder
        "Insert new email":"Insert new email",
        "Phone number":"Phone number",
        //Error
        "*Current password is required":"*Current password is required",
        "*Confirm password is required":"*Confirm password is required",
        "*New password is required":"*New password is required",
        "*Passwords do not match.":"*Passwords do not match.",
        "*Invalid email address.":"*Invalid email address.",
        "*Email is required":"*Email is required",
        "*Email do not match.":"*Email do not match.",
        "*Your password must be different from the last password ":"*Your password must be different from the last password ",
        "*Please enter first name":"*Please enter first name",
        "*Please enter last name":"*Please enter last name",
        "*Please enter address":"*Please enter address",
        "*Please enter zipcode":"*Please enter zipcode",
        "*Email is required.":"*Email is required.",
        "*Please enter phone number":"*Please enter phone number",
        //Button
        "Clear":"Clear",
        "Save":"Save",
        "Next":"Next",
        "Edit":"Edit",
        "Delete pet":"Delete pet",
    //My pet
        "Pets":"Pets",
        "Add new pet":"Add new pet",
        "My Pets":"My Pets",
        "View photo":"View photo",
        "Remove photo":"Remove photo",
        "General":"General",
        "Medical":"Medical",
        "Traits":"Traits",
        "Name":"Name",
        "Choose":"Choose",
        "Change":"Change",
        "photo":"photo",
        "Enter pet name":"Enter pet name",
        "Species":"Species",
        //Error
        "Pet name is required":"Pet name is required",
        "Please select pet category":"Please select pet category",
        "Please select pet gender":"Please select pet gender",
        "Breed is required":"Breed is required",
        "Birthdate is required":"Birthdate is required",
        "Weight is required":"Weight is required",
        "Size is required":"Size is required",
        "Fur is required":"Fur is required",
        "Species is required":"Species is required",
        //Placeholder
        "Select":"Select",
        "Your pet's Breed":"Your pet's Breed",
        //Medical
        "Health bulletin":"Health bulletin",
        "Medical conditions":"Medical conditions",
        "Allergies":"Allergies",
        "Usual medication":"Usual medication",
        "Last health check up":"Last health check up",
        "Microchip":"Microchip",
        "Microchip number":"Microchip number",
        "Vaccines up to date":"Vaccines up to date",
        "Sterilized":"Sterilized",
        "Usual veterinary":"Usual veterinary",
        "Clinic Address":"Clinic Address",
        "Doctor":"Doctor",
        "Clinic/Hospital":"Clinic/Hospital",
        "Your contact number":"Your contact number",
        "Insurance":"Insurance",
        "Company":"Company",
        "Policy number":"Policy number",
        "Emergency contacts":"Emergency contacts",
        "Primary Contact":"Primary Contact",
        "Secondary Contact":"Secondary Contact",
        "Your pet's microchip number":"Your pet's microchip number",
        //Trait
        "Personality":"Personality",
        "How would you describe your pet in unknown environments?":"How would you describe your pet in unknown environments?",
        "Habits & Routines":"Habits & Routines",
        "How many walks":"How many walks",
        "Walking schedules":"Walking schedules",
        "How many potty":"How many potty",
        "Potty schedules":"Potty schedules",
        "How many meals":"How many meals",
        "Meal schedules":"Meal schedules",
        "Goldie":"Goldie",
        "*All fields above are required":"*All fields above are required",
        "Calm":"Calm",
        "Energetic":"Energetic",
        "Relaxed":"Relaxed",
        "Anxious":"Anxious",
        "Easy-going":"Easy-going",
        "Shy":"Shy",
        "Friendly":"Friendly",
        "Aggressive":"Aggressive",
        "Gentle":"Gentle",
        "Strong":"Strong",
        "Obedient":"Obedient",
        "Rebbelious":"Rebbelious",
        //Placeholder
        "Please insert any illness that conditions your pet’s daily life":"Please insert any illness that conditions your pet’s daily life",
        "Please insert any allergies your pet may have":"Please insert any allergies your pet may have",
        "Please insert medicine your pet is taking":"Please insert medicine your pet is taking",
        "Your pet's last health check up":"Your pet's last health check up",
        "Enter":"Enter"
}

export const ptCustomerUserProfile = {
    //Calendar
    "Calendar":"Calendário",
    "Appointments":"Compromissos",
    "Past appointments":"Compromissos anteriores",
    "Requests":"Solicitações",
    "No appointments":"Sem compromissos",
    "No requests":"Nenhuma solicitação",
    "No past appointments":"Sem compromissos anteriores",
    "Hide all":"Ocultar tudo",
    "View all":"Ver tudo",
    "Sun": "Dom",
    "Mon": "Seg",
    "Tue": "Ter",
    "Wed": "Que",
    "Thu": "Qui",
    "Fri": "Sex",
    "Sat": "Sáb",
    //Request
        "Appointment Details":"Detalhes do compromisso",
        "`s Details":"`Detalhes",
        "Start Date":"Data de início",
        "Date":"Data",
        "Recurrence":"Recorrência",
        "Day Of The Week":"Dia da semana",
        "End Date":"Data de término",
        "Time":"Tempo",
        "Service Type":"Tipo de serviço",
        "Change Request":"Solicitação de alteração",
        "Sent":"Enviado",
        "Received":"Recebida",
        "Every":"Toda",
        "week":"semana",
        "Request":"Solicitar",
        "Request a change":"Solicite uma alteração",
        "We will wait for vendor approval for the change request.":"Aguardaremos a aprovação do fornecedor para a solicitação de alteração.",
        "All appointment":"Todos os compromissos",
        "Change Request Sent":"Solicitação de alteração enviada",
        "Cancellation request sent":"Solicitação de cancelamento enviada",
        "Transportation request sent":"Solicitação de transporte enviada",
        "This appointment has been deleted by the vendor":"Este compromisso foi excluído pelo fornecedor",
        "approved":"aprovado",
        "is on approval":"está em aprovação",
        "rejected":"rejeitado",
        //Field
        "Updated Start Date":"Data de início atualizada",
        "Updated Recurrence":"Recorrência atualizada",
        "Updated Time":"Hora atualizada",
        "Updated End Date":"Data de término atualizada",
        "Old Start Date":"Data de início antiga",
        "Old Date":"Data antiga",
        "Old End Date":"Data de término antiga",
        "Old Time":"Velhos tempos",
        "Updated Date":"Data atualizada",
        "Price":"Preço",
        "Animal":"Animal",
        "Breed":"Raça",
        "Weight":"Peso",
        "Fur":"Pelagem",
        "Gender":"Gênero",
        "Birthdate":"Data de nascimento",
        "Size":"Tamanho",
        "Updated Appointment Date":"Data de Compromisso Atualizada",
        "Only this appointment":"Somente este compromisso",
        //Placeholder
        "Select date":"Selecione a data",
        //Error
        "*Please enter new appointment date":"*Por favor insira a nova data do agendamento",
        "*Please enter new end date":"*Insira uma nova data de término",
        //Modal
        "You're requesting a booking cancellation, are you sure?":"A solicitação de reagendamento está em aprovação Você está solicitando o cancelamento de uma reserva, tem certeza?",
        "This does not guarantee full refund, we will wait for vendor approval for the cancellation request.":"Isso não garante o reembolso total, aguardaremos a aprovação do fornecedor para a solicitação de cancelamento.",
        "No, don't request cancellation":"Não, não solicite cancelamento",
        "Yes, I'm sure":"Sim, tenho certeza",
        "Only this booking":"Somente esta reserva",
        "All bookings":"Todas as reservas",
        "End sessionIf you remove your account, we will cancel your appointments and NOT refund you.":"Encerrar sessãoSe você remover sua conta, cancelaremos seus agendamentos e NÃO reembolsaremos você.",
        "All your data will be deleted and you will be logged out.":"Todos os seus dados serão excluídos e você será desconectado.",
        "Are you sure you want to proceed?":"Tem certeza de que deseja prosseguir?",
        //Button
        "Request change":"Solicitar alteração",
        "Request cancellation":"Solicitar cancelamento",
        "Reject":"Rejeitar",
        "Accept":"Aceitar",
        "Cancel":"Cancelar",
        "Delete account":"Excluir conta",
        "End session":"Encerrar sessão",
        //Setting
        "Password and email reset":"Redefinição de senha e e-mail",
        "Logged sessions":"Sessões registradas",
        "Reset Password":"Redefinir senha",
        "Reset Email":"Redefinir e-mail",
        "You're currently logged in on these devices":"Você está conectado nestes dispositivos",
        "This device":"Este dispositivo",
        "End Session":"Encerrar sessão",
        "Are You Sure you want to End Session with":"Tem certeza de que deseja encerrar a sessão com",
        "Password Reset":"Redefinição de senha",
        "Email Reset":"Redefinir e-mail",
        "Card details":"Detalhes do cartão",
        "Update your payment cards":"Atualize seus cartões de pagamento",
        "Add new payment method":"Adicionar nova forma de pagamento",
        "Set as default":"Definir como padrão",
        "Settings":"Configurações",
        "Personal":"Pessoal",
        "Security & Privacy":"Segurança e privacidade",
        "Payment":"Pagamento",
        "Forgot your password? Click":"Esqueceu sua senha? Clique",
        "here":"aqui",
        "Permanently remove your Petzai account. You will lose access to your account data, your previous reservations, your favorite establishments or your wallet.":"Remova permanentemente sua conta Petzai. Você perderá o acesso aos dados da sua conta, às suas reservas anteriores, aos seus estabelecimentos favoritos ou à sua carteira.",
        //Field
        "First Name":"Primeiro nome",
        "Last Name":"Sobrenome",
        "Language":"Linguagem",
        "Nationality":"Nacionalidade",
        "Phone":"Telefone",
        "Address":"Endereço",
        "Country":"País",
        "City":"Cidade",
        "Zip Code":"CEP",
        "Current password":"Senha atual",
        "New password":"Nova Senha",
        "Confirm New password":"Confirmar nova senha",
        "New email":"Novo e-mail",
        "Confirm New email":"Confirmar novo e-mail",
        "Password":"Senha",
        //Placeholder
        "Insert new email":"Inserir novo e-mail",
        "Phone number":"Número de telefone",
        //Error
        "*Current password is required":"*É necessária senha atual",
        "*Confirm password is required":"*É necessária confirmação de senha",
        "*New password is required":"*Nova senha é necessária",
        "*Passwords do not match.":"*As senhas não coincidem.",
        "*Invalid email address.":"*Endereço de e-mail inválido.",
        "*Email is required":"*E-mail é obrigatório",
        "*Email do not match.":"*E-mail não coincidem.",
        "*Your password must be different from the last password ":"*Sua senha deve ser diferente da última senha",
        "*Please enter first name":"*Insira o primeiro nome",
        "*Please enter last name":"*Insira o sobrenome",
        "*Please enter address":"*Insira o endereço",
        "*Please enter zipcode":"*Insira o CEP",
        "*Email is required.":"*E-mail é obrigatório.",
        "*Please enter phone number":"*Insira o número de telefone",
        //Button
        "Clear":"Clara",
        "Save":"Salvar",
        "Next":"Próxima",
        "Edit":"Editar",
        "Delete pet":"Apagar animal de estimação",
    //My pet
        "Pets":"Animais de estimação",
        "Add new pet":"Adicionar novo animal de estimação",
        "My Pets":"Meus animais de estimação",
        "View photo":"Ver foto",
        "Remove photo":"Remover foto",
        "General":"Em geral",
        "Medical":"Médico",
        "Traits":"Características",
        "Name":"Nome",
        "Choose":"Escolher",
        "Change":"Mudar",
        "photo":"foto",
        "Enter pet name":"Digite o nome do animal de estimação",
        "Species":"Espécies",
        //Error
        "Pet name is required":"O nome do animal de estimação é obrigatório",
        "Please select pet category":"Selecione a categoria do animal de estimação",
        "Please select pet gender":"Selecione o sexo do animal de estimação",
        "Breed is required":"A raça é obrigatória",
        "Birthdate is required":"A data de nascimento é obrigatória",
        "Weight is required":"O peso é obrigatório",
        "Size is required":"O tamanho é obrigatório",
        "Fur is required":"Pele é necessária",
        "Species is required":"A espécie é obrigatória",
        //Placeholder
        "Select":"Selecione",
        "Your pet's Breed":"A raça do seu animal de estimação",
        //Medical
        "Health bulletin":"Boletim de saúde",
        "Medical conditions":"Condições médicas",
        "Allergies":"Alergias",
        "Usual medication":"Medicação habitual",
        "Last health check up":"Último exame de saúde",
        "Microchip":"Microchip",
        "Microchip number":"Número do microchip",
        "Vaccines up to date":"Vacinas atualizadas",
        "Sterilized":"Esterilizado",
        "Usual veterinary":"Veterinário habitual",
        "Clinic Address":"Morada da clínica",
        "Doctor":"Médico",
        "Clinic/Hospital":"Clínica/Hospital",
        "Your contact number":"O teu número de contacto",
        "Insurance":"Seguro",
        "Company":"Empresa",
        "Policy number":"Número da apólice",
        "Emergency contacts":"Contactos de Emergência",
        "Primary Contact":"Contacto principal",
        "Secondary Contact":"Contacto Secundário",
        "Your pet's microchip number":"O número do microchip do seu animal de estimação",
        //Trait
        "Personality":"Personalidade",
        "How would you describe your pet in unknown environments?":"Como descreverias o teu animal de estimação em ambientes desconhecidos?",
        "Habits & Routines":"Hábitos e rotinas",
        "How many walks":"Quantos passeios",
        "Walking schedules":"Horários das caminhadas",
        "How many potty":"Quantas vezes vai fazer as necessidades",
        "Potty schedules":"Quais os horários para fazer as necessidade",
        "How many meals":"Quantas refeições",
        "Meal schedules":"Horário das refeições",
        "Goldie":"Goldie",
        "*All fields above are required":"*Todos os campos acima são obrigatórios",
        "Calm":"Calmo",
        "Energetic":"Energético",
        "Relaxed":"Descontraído",
        "Anxious":"Ansioso",
        "Easy-going":"Fácil de lidar",
        "Shy":"Tímido",
        "Friendly":"Amigável",
        "Aggressive":"Agressivo",
        "Gentle":"Delicado",
        "Strong":"Forte",
        "Obedient":"Obediente",
        "Rebbelious":"Rebelde",
        //Placeholder
        "Please insert any illness that conditions your pet’s daily life":"Por favor, indica qualquer doença que condicione a vida quotidiana do teu animal de estimação",
        "Please insert any allergies your pet may have":"Inserir quaisquer alergias que o animal possa ter",
        "Please insert medicine your pet is taking":"Inserir os medicamentos que o animal está a tomar",
        "Your pet's last health check up":"O último exame de saúde do seu animal de estimação",
        "Enter":"Digitar",
}