import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { returnTruthyString } from "../../../components/src/HelperUtils";
import { IFilteredService } from "../../../components/src/interfaces.web";
import { downloadFile, getAppointmentParams, getLatestRequest } from "../../../components/src/helpers";
import { Views } from "react-big-calendar";
import moment from "moment";
import { AppointemntDetailsData, Appointment, AvailablityData, AvailablityParams, AvailablityResponse, BookingAvailabilityParams, CalendarListItem, Employee, SubAvailability } from "./types";
import { FormError, FormErrorTouched } from "./generalInfo/Services.web";
import i18n from "../../../components/src/i18next/i18n";

export const baseURL = require("../../../framework/src/config.js").baseURL;

type Keys = keyof typeof Views;
interface IApiModels {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string | null;
  isJsonStringify?: boolean;
}
interface IAddBookingModel{
    email:string
}

type AppointmentParams = {
  service_id?: string;
  start_date?: string;
  end_date?: string;
  type?: string;
  filter_type?: string;
  employee_id?: string;
}
export interface EstablishmentAttributes {
    id: number,
    status:string
    request_type: string
    start_date: string | null,
    end_date:string  | null,
    shift:  string  | null,
    duration:string  | null,
    created_by: string
    schedule_detail: {
        id: number,
      start_date: string
        end_date:string
        shift: string
    },
    service:{service_id:number,service_type:string},
    customer: {
        customer_detail: {
            id: number,
            first_name: string,
            last_name:string,
            full_phone_number: string,
            country_code: string  | null,
            phone_number: number,
            activated: boolean,
            email: string,
            
            device_id: null,
            unique_auth_id: string,
            password_digest: string,
            created_at: string,
            platform: null,
            user_type: null,
            updated_at:string,
            user_name: null,
            
            app_language_id: null,
            last_visit_at: null,
            is_blacklisted: boolean,
            suspend_until: null,
            status: string,
            role_id:number,
            gender: null,
            date_of_birth: null,
            age: null,
            gpa: null,
            country: string,
            city: string,
            language: string,
            nationality: string,
            confirm_email: null,
            stripe_id: string,
            vendor_type: string,
            address: string,
            zipcode: string,
            tax_number: string,
            company_name: string,
            services_type: string[],
            facebook_url:  string | null,
            instagram_url: string | null,
            linkedin_url: string | null,
            tiktok_url: string | null,
            client_id: string,
            iban: null
        },
        image_url: string,
    },
    pet: {
        pet_detail: {
            id: number,
            pet_category: string,
            name:string,
            gender: string,
            size: string,
            breed: string,
            birthdate: string,
            weight: string,
            fur:string,
            species: string,
            account_id: number,
            created_at:string,
            updated_at: string,
        },
        image_url: string,
    },
    service_provider: {
        service_provider_detail: {
            id: number,
            first_name: string,
            country_code: number,
            phone_number: number,
            last_name:string,
            full_phone_number: string,
            email: string,
            activated: boolean,
            device_id: null,
            unique_auth_id:string,
            password_digest:string,
            user_name: null,
            created_at: string,
            updated_at:string,
            platform: null,
            user_type: null,
            app_language_id: null,
            last_visit_at: null,
            is_blacklisted: boolean,
            suspend_until: null,
            status:string,
            role_id: number,
            gender: null,
            date_of_birth: null,
            age: null,
            gpa: null,
            country:string,
            city: string,
            language: string,
            nationality:string,
            address: string,
            zipcode:string,
            confirm_email: null,
            stripe_id: string,
            vendor_type:string,
            tax_number: string,
            facebook_url: string,
            instagram_url: string,
            linkedin_url: string,
            tiktok_url: string,
            company_name: string,
            services_type: string[],
            client_id: string,
            iban: string,
        },
        image_url: string,
    }
}
const EstablishmentListErrorDetails=[{
  account: "Email invalid",
  token: "Invalid token",
  detail: "Invalid value",
  error: 'Invalid Data',
}]
interface ListEstablishment {
  id: string;
  type: string;
  attributes: EstablishmentAttributes;
}
export interface EstablishmentListErrorDetail {
  detail?: string;
  token?: string;
  account?: string;
  error?:string;
}
interface MetaMessage{
  message:string
}
 interface RequestDataList{
  data: ListEstablishment[];
  meta:MetaMessage;
  errors: EstablishmentListErrorDetail[];
  error: EstablishmentListErrorDetail[];
}
const defaultRequestArrayListData={
  data:[{
   id: "",
   type: "",
   attributes: {
       id: 0,
       status: "",
       start_date: "",
     request_type: "",
       end_date: "",
       shift: "",
       duration: "",
       created_by: "",
       schedule_detail: {
           id: 19,
         start_date: "2024-09-05",
           end_date: "2024-09-14",
           shift: "12.30"
       },
       service:{service_id:44,service_type:"Hotels"},
       customer: {
           customer_detail: {
               id: 366,
               first_name: "Deepika Sept",
               last_name: "",
               full_phone_number: "8754219632",
               country_code: "",
               phone_number: 8754219632,
               email: "test6@yopmail.com",
               device_id: null,
               activated: true,
               unique_auth_id: "WRIU92WFa6cPC3sPd651gQtt",
               password_digest: "",
               created_at: "2024-09-05T06:37:06.668Z",
               updated_at: "2024-09-05T06:40:24.544Z",
               user_name: null,
               platform: null,
               user_type: null,
               app_language_id: null,
               last_visit_at: null,
               is_blacklisted: false,
               suspend_until: null,
               status: "regular",
               role_id: 2,
               gender: null,
               date_of_birth: null,
               age: null,
               gpa: null,
               country: "AF",
               city: "indore",
               language: "portuguese",
               nationality: "portuguese",
               address: "indore",
               zipcode: "5555",
               confirm_email: null,
               stripe_id: "cus_Qn9cnLZZJmiuuR",
               vendor_type: "company",
               tax_number: "778",
               company_name: "RK Tech",
               services_type: [
                   '',
                   ''
               ],
               facebook_url: "",
               instagram_url: "",
               linkedin_url:"",
               tiktok_url: "",
               client_id: "",
               iban: null
           },
           image_url: "https://minio.b436904.dev.eastus.az.svc/sbucket/nwhxbvfzf7hy6089f0esb753qavn?response-content-disposition=inline%3B%20filename%3D%22image_product-image.png%22%3B%20filename%2A%3DUTF-8%27%27image_product-image.png&response-content-type=image%2Fpng&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=hello%2F20240906%2Fbuilder-1%2Fs3%2Faws4_request&X-Amz-Date=20240906T122348Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=ae80338e7b3e90a5cb2ba626f1d73f8c2b13ddfc1a0c47c05005c16c4118e967"
       },
       pet: {
           pet_detail: {
               id: 156,
               pet_category: "",
               name: "",
               birthdate: "",
               breed: "",
               gender: "",
               size: "",
               weight: "",
               fur: '',
               species: "",
               account_id: 0,
               created_at: "",
               updated_at: ""
           },
           image_url: "https://i.ibb.co/XJpjGkz/Profile-PNG-File.png"
       },
       service_provider: {
           service_provider_detail: {
               id: 0,
               first_name: "",
               full_phone_number: "",
               last_name: "",
               country_code: 56895,
               phone_number: 17234567,
               email: "",
               activated: true,
               device_id: null,
               unique_auth_id: "",
               password_digest: "",
               created_at: "",
               updated_at: "",
               user_name: null,
               platform: null,
               user_type: null,
               app_language_id: null,
               last_visit_at: null,
               is_blacklisted: false,
               suspend_until: null,
               status: "",
               role_id: 1,
               gender: null,
               date_of_birth: null,
               age: null,
               gpa: null,
               country: "Albania",
               city: "Bajram Curri",
               language: "portuguese",
               nationality: "portuguese",
               address: "202-Maple-St,-Gotham,-IL-61550",
               zipcode: "54567",
               confirm_email: null,
               stripe_id: "cus_QcgdgE0kBiyGoC",
               vendor_type: "company",
               tax_number: "-",
               company_name: "-",
               services_type: [
                   '',
                   ""
               ],
               facebook_url: "",
               instagram_url: "",
               tiktok_url: "",
               linkedin_url: "",
               client_id: "",
               iban: ""
           },
           image_url: "https://minio.b436904.dev.eastus.az.svc/sbucket/uatqex3qf4zyosf1hyiy79ugchw1?response-content-disposition=inline%3B%20filename%3D%2213-gallery%202.png%22%3B%20filename%2A%3DUTF-8%27%2713-gallery%25202.png&response-content-type=image%2Fpng&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=hello%2F20240906%2Fbuilder-1%2Fs3%2Faws4_request&X-Amz-Date=20240906T122348Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=bb29dc419ff2cfeadc9e87be412534652286d76ff94b31ad7e775c407aa78ba3"
       }
   
 }
  }],
  meta:{
  message: "Request retrieved successfully"
  },
  error:EstablishmentListErrorDetails,
  errors:EstablishmentListErrorDetails
 }

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isShowrequestscreen :boolean,
  showRequestScreen :() => void,
  selectedService: IFilteredService,
  selectedRequestId: {
    name: string,
    service_id: string,
  },
  handleSetRequestId: (selectedRequestId: string) => void,
  handleSetAppointmentsData: (data: AppointemntDetailsData) => void,
  calendarDate: Date;
  calendarView: (typeof Views)[Keys];
  handleChangeCalendarDate: (date: Date) => void;
  handleChangeCalendarView: (view: (typeof Views)[Keys]) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  establishmentId: string,
  tabValue:number,
  isAddBookingOpen:boolean,
  isShowSuccessfullyBookingEmail:boolean,
  EmployeesList: Employee[];
  AppointmentArrayList: Appointment[];
  RequestArrayList:RequestDataList
  calendarData: CalendarListItem[];
  isLoadingCalendarData: boolean;
  isOpenAvailabilityModal: boolean;
  isOpenBookingDetailsModal: boolean;
  availablityData: AvailablityData;
  subDetailsData: SubAvailability[] | undefined;
  isLoadingAvailabilityData: boolean;
  isLoadingSubDetails: boolean;
  isLoadingAddBooking: boolean;
  isLoadingDownloadCSV: boolean;
  addBookingApiError: string;
  availabilityApiError: string;
  subDetailsApiError: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableUserCalendarVendorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Starts
  getAppointmentListApi: string = "";
  getRequestListApi: string = "";
  getCalendarDataApi: string = "";
  getAvailablityCallId: string = "";
  postBookingEmailCallId: string = "";
  getBookingAvailabilityCalId: string = "";
  getEmployeesList: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        establishmentId: returnTruthyString(localStorage.getItem("establishment_ID")),
        tabValue:0,
        isAddBookingOpen:false,
        isShowSuccessfullyBookingEmail:false,
        AppointmentArrayList:[],
        EmployeesList: [],
        RequestArrayList:defaultRequestArrayListData,
        calendarData: [],
        isLoadingCalendarData: false,
        isOpenAvailabilityModal: false,
        isOpenBookingDetailsModal: false,
        availablityData: { date: '', items: [] },
        subDetailsData: undefined,
        isLoadingAddBooking: false,
        isLoadingAvailabilityData: false,
        isLoadingSubDetails: false,
        isLoadingDownloadCSV: false,
        addBookingApiError: '',
        availabilityApiError: '',
        subDetailsApiError: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      )

    switch (apiRequestCallId) {
      case this.getAppointmentListApi:
        this.handleAppointmentList(responseJson);
        break;
      case this.getRequestListApi:
        this.handleRequestList(responseJson);
        break;
      case this.getCalendarDataApi:
        this.handleCalendarData(responseJson);
        break;
      case this.getAvailablityCallId:
        this.handleAvailablityResponse(responseJson);
        break;
      case this.postBookingEmailCallId:
        this.handleBookingSendResponse(responseJson, errorJson);
        break;
      case this.getBookingAvailabilityCalId: 
        this.handleBookingAvailablity(responseJson);
        break;
      case this.getEmployeesList:
        this.handleEmployeeList(responseJson);
        break;
      default:
        break;
    }
  }
    // Customizable Area End
  }

  // Customizable Area Start

  clearApiError = () => {
    this.setState({ addBookingApiError: '' })
  }

  handleBookingSendResponse = (response: any, errorJson: any) => {
    this.setState({ isLoadingAddBooking: false });
    if(response && response.message === 'Booking email sent successfully!') {
       this.setState({ isShowSuccessfullyBookingEmail:true })
    }
    if(response && response.error) {
      this.setState({ addBookingApiError: response.error })
    }
  }

  handleAvailablityResponse = (response: AvailablityResponse) => {
    this.setState({ isLoadingAvailabilityData: false });
    if(response && response.message && response.message === 'Availability retrieved successfully!') {
      this.setState(prevState => ({ availablityData: { date: prevState.availablityData?.date, items: response.data }}))
    }
    if(response && response.message === 'No availabilities found') {
      this.setState({ availabilityApiError: response.message })
    }
  }

  handleBookingAvailablity = (response: any) => {
    this.setState({ isLoadingSubDetails: false });
    if(response && Array.isArray(response.availability) && response.availability.length) {
      this.setState({ subDetailsData: response.availability })
    } else {
      this.setState({ subDetailsApiError: response.message || response.error || 'Error occured! Try again later!' })
    }

  }

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({ tabValue: Number(newValue) });
  };
  handleAppointmentList(responseJson:any){
    if(responseJson.data){
      const appointments = responseJson.data.map((item: any)=> item.attributes);
      this.setState({ AppointmentArrayList:appointments })
    }
  }
  handleEmployeeList(responseJson:any){
    if(responseJson && !responseJson?.message){
      const employees = responseJson.map((item: any)=> item.attributes);
      this.setState({ EmployeesList: employees })
    }
  }
  handleRequestList(responseJson:RequestDataList){
    if(responseJson){
      this.setState({RequestArrayList:responseJson})
    }
  }
  handleAddBookingClick = () => {
    this.setState({isAddBookingOpen:true})
  }
  async componentDidMount() {
    this.getEmployeesListArray();
    if(this.props.selectedService.service_id) {
      this.getRequestListArray();
      this.getAppointmentListArray();
      this.getCalendarData();
    }
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if(prevProps.selectedService.service_id !== this.props.selectedService.service_id) {
      this.getCalendarData();
      this.getAppointmentListArray();
      this.getRequestListArray();
    }
    if(prevProps.calendarDate !== this.props.calendarDate || prevProps.calendarView !== this.props.calendarView) {
      this.getCalendarData();
      this.getAppointmentListArray();
      this.getRequestListArray();
    }
  }

  getEmployeesListArray = async () => {
    const TokenForAddress = localStorage.getItem("login_token");
    const { establishmentId } = this.state;
    if(establishmentId && TokenForAddress) {
      this.getEmployeesList = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.httpGetMethod,
        endPoint: `${configJSON.ENDPOINTS.GET_CALENDAR_EMPLOYEE}${establishmentId}`,
        token: TokenForAddress,
      });
    }
  }

  getEmployeeCalendarData = async (employee_id?: string) => {
    this.setState({ calendarData: [], AppointmentArrayList: []})
    this.getCalendarData(employee_id);
    this.getAppointmentListArray(employee_id);
  }

  getAppointmentListArray = async (employee_id?: string) => {
    const params: AppointmentParams = {};
    if(this.props.calendarView === Views.MONTH) {
      params.start_date = getAppointmentParams(this.props.calendarDate, 'month').start;
      params.end_date = getAppointmentParams(this.props.calendarDate, 'month').end;
    }
    if(this.props.calendarView === Views.WEEK) {
      params.start_date = getAppointmentParams(this.props.calendarDate, 'week').start;
      params.end_date = getAppointmentParams(this.props.calendarDate, 'week').end;
    }
    if(employee_id) {
      params.employee_id = employee_id
    }
    params.service_id = this.props.selectedService.service_id;
    params.type = 'appointment';
    params.filter_type = this.props.calendarView;
    const queryString = new URLSearchParams(params as Record<string, string>).toString();
    const TokenForAddress = localStorage.getItem("login_token");
    this.getAppointmentListApi = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getVendorCalendarEndpoint}${queryString}`,
      token: TokenForAddress,
    });
  };
  getRequestListArray = async () => {
    const TokenForAddress = localStorage.getItem("login_token");
    const params: AppointmentParams = {};
    params.type = 'request'
    if(this.props.calendarView === Views.WEEK) {
      params.filter_type = Views.WEEK;
      params.start_date = getAppointmentParams(this.props.calendarDate, 'week').start;
      params.end_date = getAppointmentParams(this.props.calendarDate, 'week').end;
    } else {
      params.filter_type = Views.MONTH;
      params.start_date = getAppointmentParams(this.props.calendarDate, 'month').start;
      params.end_date = getAppointmentParams(this.props.calendarDate, 'month').end;
    }
    params.service_id = this.props.selectedService.service_id;
    const queryString = new URLSearchParams(params as Record<string, string>).toString();
    this.getRequestListApi = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint:`${configJSON.RequestList}${queryString}`,
      token: TokenForAddress
    });
  };
  apiCall = async (data: IApiModels) => {
    const {endPoint, contentType, method, body, token, isJsonStringify } =
      data;
    let header;
    if (token) {
      header = {
        "Content-Type": contentType,
        token: token,
      };
    } else {
      header = {
        "Content-Type": contentType,
      };
    }
    const requestMessaged = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    body &&
      requestMessaged.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body
      );

    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessaged.id, requestMessaged);
    return requestMessaged.messageId;
  };
  handleAddBookingClose = () => {
    this.setState({isAddBookingOpen:false, isShowSuccessfullyBookingEmail:false})
  }

  handleAddBookingDialogSave = async (value:IAddBookingModel) =>{
    this.setState({ isLoadingAddBooking: true });
    const data = new FormData();
    data.append("booking_email", value.email);

    this.postBookingEmailCallId = await this.apiCall({
      method: configJSON.httpPostMethod,
      endPoint: `${configJSON.sendBookingEmailEndpoint}`,
      token: localStorage.getItem("login_token") || '',
      body: data,
    });
  }
  selectListItem = (service: any) => {
    const {  id } = service
    const serviceId = returnTruthyString(id)
    this.props.handleSetRequestId(serviceId)
  }

  getCalendarData = async (employee_id?: string) => {
    this.setState({ isLoadingCalendarData: true, calendarData: []});
    const params: AppointmentParams = {};
    const TokenForAddress = localStorage.getItem("login_token");

    if(this.props.calendarView === Views.WEEK) {
      params.start_date = getAppointmentParams(this.props.calendarDate, 'week').start;
      params.end_date = getAppointmentParams(this.props.calendarDate, 'week').end;
    } else {
      params.start_date = getAppointmentParams(this.props.calendarDate, 'month').start;
      params.end_date = getAppointmentParams(this.props.calendarDate, 'month').end;
    }
    if(employee_id) {
      params.employee_id = employee_id
    }
    params.service_id = this.props.selectedService.service_id;
    const queryString = new URLSearchParams(params as Record<string, string>).toString();

    this.getCalendarDataApi = await this.apiCall({
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getVendorCalendarEvents}${queryString}`,
      token: TokenForAddress,
    });
  }

  handleCalendarData = (responseJson: any) => {
    if(responseJson.sub_services && responseJson.sub_services.length) {
      this.setState({ calendarData: responseJson.sub_services })
    } else {
      this.setState({ calendarData: []})
    }
    this.setState({ isLoadingCalendarData: false });
  }

  handleToogleAvailabilityModal = (date?: string) => {
    if(date) {
      this.getAvailablityData(date)
      this.setState({ availablityData: { date: date, items: []}});
    } else {
      this.setState({ availablityData: { date: '', items: []} });
    }
    this.setState(prevState => ({ isOpenAvailabilityModal: !prevState.isOpenAvailabilityModal, availabilityApiError: '' }))
  }

  handleToogleBooklingDetailsModal = (sub_service_id?: string, date?: string, isValid?: boolean) => {
    if(!isValid) {
      this.setState({isOpenBookingDetailsModal: false, subDetailsData: undefined });
      return;
    }
    if(sub_service_id && date) {
      this.getSubDetailsData({ sub_service_id, date })
    } else {
      this.setState({ subDetailsData: undefined });
    }
    this.setState(prevState => ({ isOpenBookingDetailsModal: !prevState.isOpenBookingDetailsModal, isOpenAvailabilityModal: false, subDetailsApiError: '' }))
  }

  getSubDetailsData = async ({ sub_service_id, date }: { sub_service_id: string, date: string }) => {
    this.setState({ isLoadingSubDetails: true });
    const TokenForAddress = localStorage.getItem("login_token");
    const params: BookingAvailabilityParams = {} as BookingAvailabilityParams;
    params.date = date;
    params.sub_service_id = sub_service_id
    const queryString = new URLSearchParams(params as Record<string, string>).toString();
    this.getBookingAvailabilityCalId = await this.apiCall({
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getBookingAvailablityEndpoint}?${queryString}`,
      token: TokenForAddress,
    });
  }

  getAvailablityData = async (date: string) => {
    this.setState({ isLoadingAvailabilityData: true });
    const TokenForAddress = localStorage.getItem("login_token");
    const EstablishmentInfo = localStorage.getItem("establishmentInfo");
    const params: AvailablityParams = {};
    params.date = moment(date).format('YYYY/MM/DD');
    params.establishment_id = EstablishmentInfo && JSON.parse(EstablishmentInfo).id || '';
    params.service_type = this.props.selectedService.name;
    const queryString = new URLSearchParams(params as Record<string, string>).toString();
    this.getAvailablityCallId = await this.apiCall({
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getAvailablityEndpoint}?${queryString}`,
      token: TokenForAddress,
    });
  }

  donwloadCSV = async (sub_service_id: string, date: string) => {
    this.setState({ isLoadingDownloadCSV: true });
    const params: BookingAvailabilityParams = {} as BookingAvailabilityParams;
    params.start_date = date;
    params.sub_service_id = sub_service_id
    const queryString = new URLSearchParams(params as Record<string, string>).toString();
    const pathURL = `${baseURL}/${configJSON.downloadCSVEndpoint}?${queryString}`;
    const token = localStorage.getItem("login_token");
    downloadFile( { pathURL, token, filename: `${sub_service_id}(${date}).csv`, callback: () => this.setState({ isLoadingDownloadCSV: false }) })
  }

  handleNullString = (value: string | null | undefined) => {
    if(!value) {
      return "";
    }
    return value;
  }

  handleSetAppointmentsData1 = (data: Appointment) => {
    localStorage.setItem("schedule_id",data.id.toString())
    const request = getLatestRequest(data.requests?.request_details || null)
    const newData = {
      data: {
        attributes: {
          created_by: request ? request.created_by : '',
          week_days: data.week_days,
          request_type: request ? request.request_type : '',
          status: request ? request.status : '',
          shift: request ? request.shift : '',
          search_start_date: data.search_start_date,
          search_end_date: data.search_end_date,
          start_time: data.start_time,
          customer: {
            customer_detail: {
              address: this.handleNullString(data.customer.address),
              city: this.handleNullString(data.customer.city),
              country: this.handleNullString(data.customer.country),
              first_name: this.handleNullString(data.customer.first_name),
              phone_number: this.handleNullString(data.customer.full_phone_number),
              zipcode: this.handleNullString(data.customer.zipcode)
            },
            image_url: data.customer_image
          },
          end_date: request ? request.end_date : '',
          id: request ? request.id : '',
          pet: {
            image_url: data.pet.data.attributes.pet_photo,
            pet_detail: {
              ...data.pet.data.attributes,
              birthdate: moment(data.pet.data.attributes.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD")
            }
          },
          pet_medical: data.medical.length > 0 ? data.medical[data.medical.length - 1] : undefined,
          schedule_detail: {
            price: Number(data.price),
            id: data.id,
            shift: this.handleNullString(data.shift),
            start_date: data.start_date,
            end_date: data.end_date,
            week_days: data.week_days,
            search_start_date: data.search_start_date,
            search_end_date: data.search_end_date,
            start_time: data.start_time
          },
          start_date: request ? request.start_date : '',
          pet_trait: data.traits.length > 0 ? data.traits[data.traits.length - 1] : undefined
        }
      }
    } as unknown as AppointemntDetailsData;
    this.props.handleSetAppointmentsData(newData)
  }

  transCalendar = (cKey: string) => {
    if (cKey) {
      return i18n.t(`vendorProfile.${cKey}`, { ns: "establishmentService" });
    }
  }

  getErrorAndHelperText = (
    field: string,
    errors: FormError,
    touched: FormErrorTouched
  ) => {
    const isError: boolean = Boolean(errors[field]) && Boolean(touched[field]);
    const errorText = isError ? errors[field] ?? "" : ""
    const helperText: string = returnTruthyString(this.transCalendar(errorText));
    return { isError, helperText };
  };
  // Customizable Area End
}
