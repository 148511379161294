
import { BlockComponent } from "../../../framework/src/BlockComponent";


// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IFilteredService, IService } from "../../../components/src/interfaces.web";
import { checkCondition, defaultScroll, returnTruthyString } from "../../../components/src/HelperUtils";
import { AppointemntDetailsData } from "./types";
import moment from "moment";
import { Views } from "react-big-calendar";
import i18n from "../../../components/src/i18next/i18n";

export const configJSON = require("./config");

type Keys = keyof typeof Views;
export interface EstablishmentInfo{
  establishment_name:string;
  establishment_photo:string
}
// Customizable Area End



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  apiToken: string;
  currentSettingsTab:number;
  isSideBarOpen:boolean;
  servicesList: IService[];
  filtredServicesList: IFilteredService[];
  selectedService: IFilteredService;
  addServiceModalOpen: boolean;
  tempSelectedService: IFilteredService;
  openSetting:boolean;
  calendarDate: Date;
  calendarView: (typeof Views)[Keys];
  openRequestDetails:boolean;
  selectedRequestId:IFilteredService;
  appointmentData?: AppointemntDetailsData;
  //EstablishmentInfo
  establishmentInfo:EstablishmentInfo;
  openEmployeeManagement: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesVenderController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiServicesListCallId: string = ""; 

  // Customizable Area End
  constructor(props: Props) {
    super(props);
   
      // Customizable Area Start
      
      this.receive = this.receive.bind(this);
      this.subScribedMessages = [
        getName(MessageEnum.AlertMessage),
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.NavigationPayLoadMessage),
        getName(MessageEnum.AccoutLoginSuccess),
        getName(MessageEnum.SessionSaveMessage),
      ];
      // Customizable Area End
   

    this.state = {
      // Customizable Area Start
      apiToken: localStorage.getItem("login_token") || "" ,
      calendarDate: moment().toDate(),
      calendarView: Views.WEEK,
      currentSettingsTab:0,
      isSideBarOpen:false,
      servicesList: [],
      filtredServicesList: [],
      selectedService: {
        name: "",
        service_id: "",
      },
      addServiceModalOpen: false,
      tempSelectedService: {
        name: "",
        service_id: "",
      },
      openSetting:false,
      openRequestDetails:false,
      selectedRequestId:{
        name: "",
        service_id: "",
      },
      //Establishmentinfo
      establishmentInfo: {
        establishment_name: '',
        establishment_photo: ''
      },
      openEmployeeManagement: false
        // Customizable Area End
    };
    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
   
  }

    // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const receivedData = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
      if (receivedData && receivedData.showEmployeeManagement) {
        this.setState({ openEmployeeManagement: true })
        this.handleSetting()        
      }
      if (receivedData && receivedData.tabValue && receivedData.serviceInfo) {
        this.setState({
          currentSettingsTab: receivedData.tabValue, selectedService: {
            service_id: receivedData.serviceInfo.service_id,
            name: receivedData.serviceInfo.name
          }
        })
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiServicesListCallId) {
        this.handleServiceListResp(responseJson)
      }
      
    }
  }
    // Customizable Area End
  


  // Customizable Area Start

  handleChangeCalendarDate = (date: Date) => {
    this.setState({ calendarDate: date })
  }

  handleChangeCalendarView = (view: (typeof Views)[Keys]) => {
    this.setState({ calendarView: view })
  }

  handleServiceListResp = (responseJson: { data: IService[] }) => {
    if (responseJson && responseJson.data && responseJson.data.length) {
      this.setState({
        servicesList: responseJson.data,
        filtredServicesList: responseJson.data.map(record => {
          return {
            name: returnTruthyString(record.attributes.service_type),
            service_id: returnTruthyString(record.attributes.id),
          }
        })
      })
      if (this.state.selectedService.name) {
        const newServiceob = responseJson.data.find((record) => String(record.attributes.service_type) == String(this.state.selectedService.name))
        this.setState({
          selectedService: {
            name: this.state.selectedService.name,
            service_id: String(newServiceob?.attributes.id)
          }
        })
      }
      if (!this.state.selectedService.name && !this.state.selectedService.service_id) {
        this.setState({
          selectedService: {
            name: returnTruthyString(responseJson.data[0].attributes.service_type),
            service_id: returnTruthyString(responseJson.data[0].attributes.id),
          }
        })
      }
    } else {
      this.setState({
        servicesList: [],
        filtredServicesList: [],
        selectedService: {
          name: "",
          service_id: ""
        }
      })
    }
  }


  async componentDidMount() {
    defaultScroll()
    const establishment_id = checkCondition(Boolean(localStorage.getItem("establishment_ID")),localStorage.getItem("establishment_ID"),"")as string
    this.getServicesList(establishment_id)
  }



  // Customizable Area End



  // Customizable Area Start
  setTabId=(id:number)=>{
    this.setState({ currentSettingsTab: id })
  }
  handleSetting = () => {
    this.setState({
      openSetting: true,
      // RESET OTHER STATES
      openRequestDetails: false,
      selectedService: {
        name: "",
        service_id: ""
      }
    });
    this.closeDrawer();
  }
  handleRequestScreen = () =>{
    this.setState({openRequestDetails:!this.state.openRequestDetails, appointmentData: undefined })
  }


  toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    this.setState({ isSideBarOpen: open });
  };

  getServicesList = (establishment_id: string) => {
    if (!establishment_id) return
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const header = {
      token: this.state.apiToken,
    };

    const endpoint = `account_block/services?service[establishment_id]=${establishment_id}`
    this.apiServicesListCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };
 
  handleSetService = (service_name: string, service_id: string) => {
    this.setState({
      selectedService: {
        name: service_name,
        service_id: service_id,
      }, openRequestDetails:false,
      openSetting:false
    })
    
    this.setTabId(0);
  }
  handleSetRequestId=(selectedRequestId:string)=>{
    this.setState({
      selectedRequestId:{
        name:'',
        service_id:selectedRequestId
      },
      openRequestDetails:!this.state.openRequestDetails,
      openSetting:false
    })
  }


  openServiceModal = () => {
    this.setState({
      addServiceModalOpen: true
    })
  }
  closeServiceModal = () => {
    this.setState({
      addServiceModalOpen: false
    })
  }
  nextButtonModal = (tempService: IFilteredService) => {
    const newServiceList = [...this.state.filtredServicesList, { ...tempService }]
    this.setState({
      filtredServicesList: newServiceList,
      selectedService: tempService
    })
    this.setTabId(3);
  }

  handleSetAppointmentsData = (data: AppointemntDetailsData) => {
    this.setState({ openRequestDetails: true, appointmentData: data });
  }
 
  handleEstablishmentInfo=(establishment_name:string,establishment_photo:string)=>{
    this.setState({establishmentInfo:{
      establishment_name:establishment_name,
      establishment_photo:establishment_photo
    }})
  }

  transProfile = (pKey: string) => {
    return i18n.t(`vendorProfile.${pKey}`, { ns: "establishmentService" });
  }

  closeDrawer = () => {
    this.setState({ isSideBarOpen: false })
  }
  // Customizable Area End
}
