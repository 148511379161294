import React from "react";
// Customizable Area Start
import CustomersDashboardController, { Props } from "./CustomersDashboardController.web";
import {
    Dialog, styled, Box, IconButton, Typography, InputAdornment,
    TextField,
    Avatar,
    CircularProgress
} from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";
import { downloadIcon, NoCustomer, NoResult } from "../assets";
import { checkCondition, getRangeLabel } from "../../../../components/src/HelperUtils";
import { LoadingButton } from "../../../../components/src/HelperComponents";

export interface ICustomer {
    photo: string,
    name: string,
    phone_number: string,
    email: string,
    address: string,
    id: number
}
// Customizable Area End

export default class CustomersDashboard extends CustomersDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    searchField = () => {
        return (
            <SearchBox>
                <SearchField fullWidth
                    placeholder={this.transCustomerList("Search")}
                    value={this.state.searchValue}
                    onChange={(event) => this.handleChangeSearch(event.target.value)}
                    data-testid="search-field"
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </SearchBox>
        )
    }
    getDayLine = (day: string) => {
        return (
            <DayLine>{day}</DayLine>
        )
    }

    renderDefaultSection = (message: string, img: string) => {
        return (
            <DefaultWrapper>
                <img src={img} />
                <NoRecordFont>{this.transCustomerList(message)}</NoRecordFont>
            </DefaultWrapper>
        )
    }

    renderCustomerList = () => {
        const { customerFilterList, isListLoading, customerList, searchValue } = this.state;
        const { startDate, endDate } = this.props.customerListProp;
        const label = getRangeLabel(startDate, endDate);
        const dayLineLabel = checkCondition(Boolean(label.includes("Date Range")), `${this.transCustomerList("Date Range")} ${startDate} ${this.transCustomerList("to")} ${endDate}`,this.transCustomerList(label)) as string;
        
        return (
            <>
                {customerFilterList.length > 0 && !isListLoading ? (
                    <ListWrapper>
                        {this.getDayLine(dayLineLabel)}
                        {this.customerRows()}
                    </ListWrapper>) : (<></>)}
                {!customerFilterList.length &&
                    isListLoading ? (
                    this.renderLoader()
                ) : (
                    <></>
                )}
                {!customerFilterList.length && searchValue &&
                    !isListLoading ? (
                    this.renderDefaultSection("No results found!", NoResult)

                ) : (
                    <></>
                )}
                {!customerList.length &&
                    !isListLoading ? (
                    this.renderDefaultSection("Has no customers yet", NoCustomer)
                ) : (
                    <></>
                )}
            </>
        )
    }

    renderLoader = () => {
        return (
          <LoaderContainer>
            <CustomLoader />
          </LoaderContainer>
        )
      }
    
    customerRows = () => {
        const { customerFilterList } = this.state;
        return (
            <RowWrapper>
                {customerFilterList.map((record) => {
                    const address = checkCondition(record.address === null, "-", record.address) as string;
                    return (
                        <CustomerBox
                            onClick={() => { this.goToViewCustomer(record.id) }}
                            data-test-id={`customer-row-${record.id}`}
                        >
                            <StyledAvatar src={record.photo}>{record.name.charAt(0)}</StyledAvatar>
                            <CenterBox maxWidth={90} width={90}>
                                <RowFont noWrap title={record.name}>{record.name}</RowFont>
                            </CenterBox>
                            <CenterBox maxWidth={92} width={92}>
                                <RowFont noWrap title={record.phone_number}>{record.phone_number}</RowFont>
                            </CenterBox>
                            <CenterBox maxWidth={160} width={160}>
                                <RowFont noWrap title={record.email}>{record.email}</RowFont>
                            </CenterBox>
                            <CenterBox maxWidth={140} width={140}>
                                <RowFont title={record.address} className="custom-address">{address}</RowFont>
                            </CenterBox>
                        </CustomerBox>
                    )
                })}
            </RowWrapper>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                <StyledDialog
                    open
                    fullWidth
                    maxWidth={"lg"}
                    onClose={this.props.handleClose}
                >
                    <MainWrapper>
                        <CloseButtonBox>
                            <CloseIconButton onClick={this.props.handleClose}>
                                <Close />
                            </CloseIconButton>
                        </CloseButtonBox>

                        <TitleBox>
                            <TitleFont>{this.transCustomerList("Customers")}</TitleFont>
                            <DownloadButton
                                buttonText={this.transCustomerList("Download in CSV")}
                                className="addModalBtn purpleBtn"
                                isLoading={this.state.isLoadingDownloadCSV}
                                data-testid="add-btn"
                                onClick={() => this.downloadCSV()}
                                data-test-id="download-csv-btn"
                                startIcon={!this.state.isLoadingDownloadCSV && <img src={downloadIcon} />}
                            />
                        </TitleBox>
                        {this.searchField()}
                        {this.renderCustomerList()}
                    </MainWrapper>
                </StyledDialog>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledDialog = styled(Dialog)({
    "& .MuiPaper-root": {
        maxWidth: "905px",
        borderRadius: "8px"
    },
})
const MainWrapper = styled(Box)({
    padding: "24px"
})
const CloseButtonBox = styled(Box)({
    display: "flex",
    justifyContent: "end",
    alignItems: "end"
})
const CloseIconButton = styled(IconButton)({
    padding: 0,
    "& .MuiSvgIcon-root": {
        color: "#090909"
    }
})
const TitleBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 0 26px 0",
    padding: "0 48px",
    "@media(max-width:600px)": {
        flexDirection:"column",
        gap:0,
        alignItems:"flex-start",
        padding:"0",
        margin: "5 0 26px 0",
    }
})
const TitleFont = styled(Typography)({
    fontWeight: 600,
    fontSize: "36px",
    lineHeight: "40px",
    fontFamily: "Montserrat",
    color: "#6F05EA",
    "@media(max-width:600px)": {
        fontSize: "32px",
    },
    "@media(max-width:450px)": {
        fontSize: "18px",
    }
})
const DownloadButton = styled(LoadingButton)({
    height: 40,
    width: "100%",
    maxWidth: "164px",
    borderRadius: "24px",
    border: '0.5px solid #6F05EA',
    textTransform: "none",
    fontWeight: 500,
    fontSize: "12px",
    fontFamily: "Montserrat",
    lineHeight: "15px",
    color: "#6F05EA",
    boxShadow: "0px 4px 8px 0px #00000008",
    "& .MuiButton-startIcon": {
        margin: "0 6px 0 0"
    },
    "@media(max-width:600px)": {
        maxWidth:"100%",
    },
})

const SearchBox = styled(Box)({
    padding: "0 48px",
    marginBottom:26,
    "@media(max-width:600px)": {
        padding: "0",
    },
})
const SearchField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        height: 44,
        borderColor: "#CBD5E1",
        paddingLeft: "8",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CBD5E1",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CBD5E1",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CBD5E1",
        },
    },
    "& .MuiInputAdornment-root": {
        margin: "0"
    },
    "& .MuiOutlinedInput-input": {
        padding: "10px 8px",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Montserrat",
        "&::placeholder": {
            color: "#94A3B8",
            opacity: 1
        },
    }
})
const SearchIcon = styled(Search)({
    color: "#94A3B8"
})
const DayLine = styled(Box)({
    margin: "26px 0",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "8px",
    fontFamily: "Montserrat",
    color: "#6F05EA",
    "&:first-child": {
        marginBottom: "26px",
        marginTop:"0px" 
    }
})

const ListWrapper = styled(Box)({
    padding: "0 48px",
    maxHeight: "400px",
    overflowY: "auto",
    "@media(max-width:600px)": {
        padding: "0",
    },
    "&::-webkit-scrollbar": {
        height: "0 !important", 
    },
})
const CustomerBox = styled(Box)({
    borderRadius: "4px",
    boxShadow: "0px 2px 8px 0px #0000000F",
    backgroundColor: "#FFFFFF",
    padding: "4px 8px",
    display: "flex",
    flexDirection: "row",
    gap: 20,
    cursor:"pointer"
})

const StyledAvatar = styled(Avatar)({
    height: "32px",
    width: "32px",
    border: "1px solid #6F05EA"
})

const RowWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: 10,
})
const CenterBox = styled(Box)({
    display: "flex",
})
const RowFont = styled(Typography)({
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    fontFamily: "Montserrat",
    color: "#000000",
    width:"inherit",
    "&.custom-address": { // Target only when the class is `custom-address`
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
})
const NoResultBox = styled(Box)({
    display:"flex",
    alignItems:"center",
    width:"100%",
    justifyContent:"center",
    gap:7,
    margin:"100px 0px 100px 0px"
})
const NoResultTypo = styled(Typography)({
    fontSize:"20px",
    fontWeight:600,
    color:"#B7B7B7"
})

const CustomLoader = styled(CircularProgress)({
  color: "#6F05EA"
});

const LoaderContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "50px",
    marginBottom: "50px"
});

const NoRecordFont = styled(Typography)({
    fontWeight: 600,
    fontSize: "20px",
    fontFamily: "Montserrat",
    color: "#B7B7B7",
    padding: "0 8px"
})

const DefaultWrapper = styled(Box)({
    padding: "106px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
})
// Customizable Area End
