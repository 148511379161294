import { Box, styled, SwipeableDrawer } from '@material-ui/core';
import React from 'react';


const Wrapper = styled(Box)(({ theme }) => ({
    height:"100%",
    overflow:"hidden",
    "@media (max-height: 502px)": {
        overflow:"auto"
    },
    "& .vertical-tab": {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        color: '#808080',
        fontWeight: 600,
        flex: 1,
        textTransform: 'none',
        position: 'relative',
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: '1px', 
            backgroundColor: '#000',
        },
    },
    "& .vertical-tab.Mui-selected": {
        color: '#6F05EA',
        fontWeight: 600,
    },
}))

type Props = {
    renderMenu: (isDrawer?: boolean) => JSX.Element,
    isOpen: boolean,
    onToggle: () => void;
}

const MenuDrawer = ({ renderMenu, isOpen, onToggle }: Props) => {
    const iOS =
        typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
        
    return (
        <SwipeableDrawer
            data-test-id='drawer'
            disableBackdropTransition={!iOS} 
            disableSwipeToOpen={false}
            disableDiscovery={iOS}
            anchor={iOS ? 'right' : 'left'}
            open={isOpen}
            onClose={() => onToggle()}
            onOpen={() => onToggle()}
        >
            <Wrapper>
                {renderMenu(true)}
            </Wrapper>
        </SwipeableDrawer>
    )

}


export default MenuDrawer;