import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { userProfile } from "./assets";
import { Linking } from "react-native";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { checkCondition } from "../../../components/src/HelperUtils";
import { LanguageCtx } from "../../../components/src/context/LanguageContext";
import i18n from "../../../components/src/i18next/i18n";

interface IApiModel {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string | null;
  isJsonStringify?: boolean;
}
export interface UnreadCountInterface {
  total_unread_count:number | null;  
}

interface LanguageOption {
  label: string;
  image: string;
}

interface LanguageData {
  [key: string]: LanguageOption; // Permite cualquier clave (código de idioma)
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  handleCustomerHeaderSelect?: (selectedValue: string) => void;
  isProfile?: boolean;
  isChat?:boolean;
  onLogoClick?: () => void,
  isProfileUpdate?: boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  anchorEl:any;
  selectMenuForLoggedInUser:string;
  openForLoggedInUser:boolean;
  isLoggedIn:string | null;
  userProfile:string 
  userName:string | null;
  currenMenuItem:string | null;
  isLoggedOutOpen:boolean;
  unReadMessageCount:number | null,
  selectLang:LanguageData,
  dropdownOpen:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  getunreadcountApi: string='';
  static contextType = LanguageCtx;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage)
 
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: true,
      token: "",
      drawerItems: [],
      anchorEl: null,
      selectMenuForLoggedInUser: "",
      openForLoggedInUser: false,
      isLoggedIn : 'false',
      userProfile:'https://ibb.co/pKVHLGW',
      userName:'',
      currenMenuItem:'',
      isLoggedOutOpen:false,
      unReadMessageCount:0,
      selectLang: {
        "en": {
          "label": "Select Service Type",
          "image": "https://flagsapi.com/GB/flat/64.png"
        },
        "pt": {
          "label": "Selecionar Tipo de Serviço",
          "image": "https://flagsapi.com/PT/flat/64.png"
        },
        
      },
      dropdownOpen:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token }, () => {
        this.getMenuItems();
      });
    } 
    if (apiRequestCallId === this.getunreadcountApi) {
      this.handleCountmessageRespons(responseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSelectLanguage = (lang: string) => {
    const {alternateLanguage} = this.context;
    alternateLanguage(lang)
    this.setState({dropdownOpen:false});
    
  };

  setDropdownOpen(){
    this.setState({dropdownOpen:!this.state.dropdownOpen})
  }

  onPressMenuItem = (menuItem: any) => {
    let path = menuItem.url;

    var tarea_regex = /^(http|https)/;
    if (tarea_regex.test(String(path).toLowerCase())) {
      if (this.isPlatformWeb()) {
        window.open(path);
      } else {
        Linking.openURL(path);
      }
    } else {
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  };

 

  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    super.componentDidMount();
    // this.setState({unReadMessageCount:this.props.unReadMessageCount})
    this.getTotalUnreadMessages()
    const logedin=localStorage.getItem("isUserLogin")
    const userprofile=localStorage.getItem("login_user_profile") as string
    const username=localStorage.getItem("login_user_name");
    const selectedValue = checkCondition(Boolean(localStorage.getItem("selectedValueVendor")), localStorage.getItem("selectedValueVendor"), "4") as string;
    localStorage.setItem("selectedValueVendor", selectedValue)
    this.setState({ selectMenuForLoggedInUser: this.dropDownValue(),isLoggedIn:logedin,userProfile:userprofile,userName:username });
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.userProfile !== localStorage.getItem("login_user_profile")){
      const userprofile= localStorage.getItem("login_user_profile")  as string
      this.setState({userProfile:userprofile})
    }
  }

  getMenuItems = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMenuItemsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  handleMenuOpen = (event:any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuOpenForLoggedInUser = () => {
    this.setState({ openForLoggedInUser: !this.state.openForLoggedInUser });
  };
  handleSelectForLoggedInUser = async (value:any) => {
    let selectedValue:string = await getStorageData("selectedValueVendor") || "4";
    switch (value) {
      case "Establishment":
        setStorageData("selectedValueVendor", 4);
        selectedValue = "0";
        break;
      case "Management":
        setStorageData("selectedValueVendor", 1);
        selectedValue = "1";
        break;
      case "Document":
        setStorageData("selectedValueVendor", 2);
        selectedValue = "2";
        break;
      case "Logout":
        this.setState({isLoggedOutOpen:true})
        break;
      default:
        setStorageData("selectedValueVendor", 4);
        selectedValue = "1";
        break;
    }
    if (value !== "Logout") {
      this.setState({ selectMenuForLoggedInUser: value });
    }
    
    if (this.props.isProfile) {
      if(this.props.handleCustomerHeaderSelect !== undefined){
        this.props.handleCustomerHeaderSelect(selectedValue);
      }
    }else if (value !== "Logout") {
      this.gotoEstablishment()
    }
    this.setState({anchorEl:null})
  };
  gotoEstablishment=()=>{
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Cfentrepriseaccount");
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  }
  gotoHome=()=>{
    if (this.props.onLogoClick) {
      this.props.onLogoClick()
    }
    localStorage.setItem("selectedValueVendor","4")
    const login_user_role=localStorage.getItem('login_user_role');
    if(login_user_role!=='customer'){
      const userNavMsg: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Cfentrepriseaccount");
      userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(userNavMsg);
    }else{
      const userNavMsg: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
      userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(userNavMsg);
    }
   
  }
  gotoLogin=()=>{
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  }
  gotoLogout=()=>{
    
      sessionStorage.removeItem("login_token");
      sessionStorage.removeItem("login_user_name");
      sessionStorage.removeItem("login_user_id");
      sessionStorage.removeItem(
        "login_refresh_token"
      );
      localStorage.removeItem("login_token");
      localStorage.removeItem("login_user_name");
      localStorage.removeItem("login_user_id");
      localStorage.removeItem("login_refresh_token");
      localStorage.removeItem("isUserLogin");
      localStorage.removeItem("login_user_role");
      localStorage.removeItem("login_email");
      localStorage.removeItem("login_user_profile");
      removeStorageData('selectedValueVendor');
      const userNavMsg: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
      userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(userNavMsg);
    
  }
  
  gotoSignUp=()=>{
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountRegistration");
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  }
  gotoVendorSignUp=()=>{
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountVendorRegistration");
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  }
  dropDownValue = () => {
    switch (this.state.currenMenuItem) {
      case "0":
        return "Establishment";
      case "1":
        return "Management";
      case "2":
        return "Document";
      case "3":
        return "Logout";
      default:
        return "";
    }
  };

  handleSureLogout = () => {
    this.gotoLogout();
  }

  handleCancelLogout = () => {
    this.setState({isLoggedOutOpen:false})
  }
  gotoChat(){
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ChatWeb");
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  }
  getTotalUnreadMessages = async () => {
    const userInfoToken = localStorage.getItem("login_token");
    this.getunreadcountApi = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethodType,
      endPoint: configJSON.getunreadCount,
      token: userInfoToken
    });
  };
  apiCall = async (data: IApiModel) => {
    const { method, endPoint, body, token, isJsonStringify } =data;
    let header;
    header = {
      token: token,
    };
    const requestMessaged = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessaged.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body
      );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    
    runEngine.sendMessage(requestMessaged.id, requestMessaged);
    return requestMessaged.messageId;
  };
  handleCountmessageRespons(response:UnreadCountInterface){
    if(response){
      if(response.total_unread_count){
        this.setState({unReadMessageCount:response.total_unread_count})
        const unreadCount = response.total_unread_count ?? 0; // Fallback if undefined
        localStorage.setItem('total_unread_count', JSON.stringify(unreadCount));
        this.forceUpdate()
      }else{
        localStorage.setItem('total_unread_count', JSON.stringify(0));
        this.forceUpdate()
      }

    }
  }

  transNav = (nKey:string) => {
    return i18n.t(nKey, { ns: "navigationHeader" });
  }
  // Customizable Area End
}
