
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { APIModal } from "./ServiceDashboardController.web";
import { getStorageData } from "../../../../framework/src/Utilities";
import i18n from "../../../../components/src/i18next/i18n";

const webConfigJSON = require("../config.js");

interface IMedicalAttr {
    id: number,
    medical_conditions: string,
    allergies: string,
    usual_medication: string,
    last_health_check_up: string | null,
    microchip: number | null,
    vaccines_up_to_date: boolean,
    sterilized: boolean,
    hospital: string,
    hospital_address: string,
    hospital_number: string,
    doctor_name: string,
    company: string,
    policy_number: string,
    primary_name: string,
    primary_contact: string,
    secondary_name: string,
    secondary_contact: string,
    created_at: string,
    updated_at: string,
    animal_type: string
}
interface IMedicalData {
    id: string,
    type: string,
    attributes: IMedicalAttr
}
interface IMedicalResp {
    data: IMedicalData
}

interface ITraitsAttr {
    id: number,
    calm: string,
    relaxed: string,
    easy_going: string,
    friendly: string | null,
    gentle: string | null,
    obedient: string | null,
    walks: number,
    walking_schedule: string,
    potty: number,
    potty_schedule: string,
    meal: number,
    meal_schedule: string,
    created_at: string,
    updated_at: string,
    animal_type: string
}

interface ITraitsData {
    id: string,
    type: string,
    attributes: ITraitsAttr
}
interface ITraitsResp {
    data: ITraitsData
}

interface IGeneralAttr {
    pet_category: string,
    id: number,
    name: string,
    gender: string,
    breed: string,
    birthdate: string,
    weight: string,
    size: string | null,
    account_id: number,
    fur: string | null,
    species: string | null,
    created_at: string,
    updated_at: string,
    pet_photo: string;
}

interface IGeneralData {
    id: string,
    type: string,
    attributes: IGeneralAttr
}
interface IGeneralResp {
    data: IGeneralData
}

// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    tab: 1 | 2 | 3,
    //LOADER
    isMedicalLoading: boolean,
    isTraitsLoading: boolean,
    isGeneralLoading: boolean
    //RESPONSE
    medicalInfo: IMedicalAttr,
    traitsInfo: ITraitsAttr,
    generalInfo: IGeneralAttr
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class ViewPetController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apiMedicalCallId: string = '';
    apiTraitsCallId: string = '';
    apiGeneralCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            tab: 1,
            //LOADER
            isMedicalLoading: false,
            isTraitsLoading: false,
            isGeneralLoading: false,
            //RESPONSE
            medicalInfo: {} as IMedicalAttr,
            traitsInfo: {} as ITraitsAttr,
            generalInfo: {} as IGeneralAttr,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId) {
                switch (apiRequestCallId) {
                    case this.apiMedicalCallId:
                        this.handleMedicalResp(responseJson);
                        break;
                    case this.apiTraitsCallId:
                        this.handleTraitsResp(responseJson);
                        break;
                    case this.apiGeneralCallId:
                        this.handleGeneralResp(responseJson);
                        break;
                    default:
                        break;
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start

    async componentDidMount() {
        this.getGeneralInfo();
    }

    apiCall = async (data: APIModal) => {
        const { contentType, method, endPoint, body, token, formData } = data;
        let header;
        header = {
            "Content-Type": contentType,
        };
        if (token) {
            header = {
                "Content-Type": contentType,
                token: token,
            };
        }

        const apiRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        apiRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        apiRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            apiRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );

        formData &&
            apiRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formData
            );
        runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
        return apiRequestMessage.messageId;
    };

    getMedicalInfo = async () => {
        const petId = this.props.navigation.getParam("navigationBarTitleText");
        const apiToken = await getStorageData("login_token");
        this.setState({ isMedicalLoading: true });
        this.apiMedicalCallId = await this.apiCall({
            method: webConfigJSON.validationApiMethodType,
            endPoint: `${webConfigJSON.ENDPOINTS.GET_PET_MEDICAL_DATA}${petId}`,
            token: apiToken,
        });
    };

    getTraitsInfo = async () => {
        const petId = this.props.navigation.getParam("navigationBarTitleText");
        const apiToken = await getStorageData("login_token");
        this.setState({ isTraitsLoading: true });
        this.apiTraitsCallId = await this.apiCall({
            method: webConfigJSON.validationApiMethodType,
            endPoint: `${webConfigJSON.ENDPOINTS.GET_PET_TRAITS_DATA}${petId}`,
            token: apiToken,
        });
    };

    handleMedicalResp = (responseJson: IMedicalResp) => {
        this.setState({ isMedicalLoading: false })
        if (responseJson && responseJson.data && responseJson.data.attributes) {
            this.setState({ medicalInfo: responseJson.data.attributes })
        }
    }

    handleTraitsResp = (responseJson: ITraitsResp) => {
        this.setState({ isTraitsLoading: false })
        if (responseJson && responseJson.data && responseJson.data.attributes) {
            this.setState({ traitsInfo: responseJson.data.attributes })
        }
    }

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: 1 | 2 | 3) => {
        this.setState({ tab: newValue });
        switch (newValue) {
            case 1:
                this.getGeneralInfo();
                break;
            case 2:
                this.getMedicalInfo();
                break;
            case 3:
                this.getTraitsInfo();
                break;
            default:
                break;
        }
    }

    goBackToCustomer = () => {
        this.props.navigation.goBack();
    }

    getGeneralInfo = async () => {
        const petId = this.props.navigation.getParam("navigationBarTitleText");
        const apiToken = await getStorageData("login_token");
        this.setState({ isGeneralLoading: true });
        this.apiGeneralCallId = await this.apiCall({
            method: webConfigJSON.validationApiMethodType,
            endPoint: `${webConfigJSON.ENDPOINTS.GET_PET_GENERAL_DATA}${petId}`,
            token: apiToken,
        });
    };

    handleGeneralResp = (responseJson: IGeneralResp) => {
        this.setState({ isGeneralLoading: false })
        if (responseJson && responseJson.data && responseJson.data.attributes) {
            this.setState({ generalInfo: responseJson.data.attributes })
        }
    }

    transViewPet = (pKey: string) => {
        return i18n.t(pKey, { ns: "serviceDashboard" });
    }
    // Customizable Area End
}

