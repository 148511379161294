import React, { useState } from 'react';
import { Box, Button, styled, Typography } from '@material-ui/core';
import GenericModal from '../../../../../components/src/GenericModal';

export type TradeItem = {
    coupon_image: string | undefined;
    id: number, 
    imgSrc: string,
    title: string,
    desc: string,
    amount: number,
    disabled: boolean,
    attributes: TradeItem,
    description:string,
    points:number,

}

type Props = {
    myPoints: {
        id: number;
        points: number;
        total_points: number;
        account_id: number;
        created_at: Date;
        updated_at: Date;
      },
    tradeItems: any,
    translate: (value: string) => string;
}

type ItemProps = {
    item: TradeItem,
    onSelect: (item: TradeItem) => void;
    translate: (value: string) => string;
}

const TradeItem1 = ({ item, onSelect, translate }: ItemProps) => {

    return (
        <Box className='item-box'>
            <img style={{width:"100%",height:"50%"}} src={item.coupon_image} className='box-img'/>
            <Typography className='card-title'>{item.title}</Typography>
            <Typography className='card-desc'>{item.description}</Typography>
            <Button className='card-btn' onClick={() => onSelect(item)}>{`${item.points} pts`}</Button>
        </Box> 
    )
}

const TradePointsPage = ({ myPoints, tradeItems, translate}: Props) => {

    const [selectedCard, setSelectedCard] = useState<TradeItem | undefined>(undefined);
    const [isReedem, setIsReedem] = useState(false);

    const handleClaimItems = (item: TradeItem) => {
        setSelectedCard(item)
    }

    const handleOnClosee = () => {
        setIsReedem(false)
        setSelectedCard(undefined)
    }

    return (
    <Wrapper>
        <Box className='points-wrapper'><Typography className='points-number'>{`${myPoints?.points || myPoints } ${translate('points')}`}</Typography></Box>
        <Typography className='points-tip'>{translate('tip')}</Typography>
        <Typography className='header-text'>{translate("Trade your points")}</Typography>
        <Box className='awards-box-wrapper' data-test-id='prev-awards'>
                {tradeItems.map((item: TradeItem,index : number) => (
                    <TradeItem1 item={item.attributes} key={index} data-test-id='claim-item' translate={translate} onSelect={handleClaimItems}/>
                ))}
        </Box>
        <GenericModal
            id='reedem' 
            title=''
            isOpen={!!selectedCard} 
            onClose={handleOnClosee} 
            data-test-id='close-btn'
        >
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {isReedem ? 
                <ModalWrapper>
                    <Typography className='main-text'>
                        <span className='yellow-text'>{`${selectedCard?.amount} points `}</span>
                        {translate("will be discounted from your wallet.")}
                    </Typography>
                    <Box className='gap-item-wrapper' style={{ marginTop: '32px'}}>
                        <Box className='gap-item'>Current wallet</Box>
                        <Box className='gap-item'>
                            <Box className='active-box'>
                                <Typography className='active-text'>
                                    {`${myPoints} points`}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className='gap-item-wrapper' style={{ marginTop: '16px'}}>
                        <Box className='gap-item'>After redeeming</Box>
                        <Box className='gap-item'>123</Box>
                    </Box>
                </ModalWrapper> : 
                <ModalWrapper>
                    <Typography className='modal-header'>{selectedCard?.title}</Typography>
                    <Typography className='modal-desc'>{selectedCard?.desc}</Typography>
                    <Typography className='modal-price'>{selectedCard?.amount} pts</Typography>
                    <Box className='modal-img-wrapper'>
                        <img style={{ maxWidth: '300px', height: 'auto'}} src={selectedCard?.imgSrc}/>
                    </Box>
                    <Typography className='desc-text'>{translate("Hint")}</Typography>
                    <Button className='modal-btn' onClick={() => setIsReedem(!isReedem)}>{translate("Redeem offer")}</Button>
                </ModalWrapper>}
                
            </Box>
        </GenericModal>
    </Wrapper>)
}

const ModalWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '580px',
    paddingBottom: '16px',

    "& .active-text": {
        fontSize: '20px',
        fontWeight: 600
    },

    "& .active-box": {
        padding: '2px 24px',
        background: '#FFCE00',
        color: '#000',
        borderRadius: '20px'
    },

    "& .gap-item-wrapper": {
        display: 'flex', 
    },

    "& .gap-item": {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },

    "& .main-text": {
        fontSize: '24px',
        fontWeight: 700,
    },

    "& .yellow-text": {
        fontSize: '24px',
        fontWeight: 700,
        color: '#FFCE00'
    },

    "& .modal-btn": {
        marginTop: '16px',
        borderRadius: '40px',
        border: '1px solid #6F05EA',
        fontSize: '24px',
        fontWeight: 600,
        padding: '4px 36px',
        color: '#6F05EA',
        textTransform: 'none',
    },

    "& .desc-text": {
        color: '#B7B7B7',
        fontSize: '16px',
        fontWeight: 400
    },

    "& .modal-img-wrapper": {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    "& .modal-price": {
        fontSize: '24px',
        color: '#6F05EA',
        fontWeight: 700
    },
    "& .modal-desc": {
        fontSize: '24px',
        color: '#B7B7B7',
        fontWeight: 700
    },

    "& .modal-header": {
        fontSize: '36px',
        color: '#000',
        fontWeight: 700
    }
}))

const Wrapper = styled(Box)(({ theme }) => ({
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    "& .card-btn": {
        marginTop: '40px',
        borderRadius: '35px',
        border: '1px solid #6F05EA',
        fontSize: '14px',
        fontWeight: 700,
        padding: '8px 36px',
        color: '#6F05EA',
        textTransform: 'none'
    },

    "& .item-box": {
        width: '304px',
        maxWidth: '304px',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 18px',
        alignItems: 'center',
        boxShadow: '0px 0px 7.5px 0px rgba(0, 0, 0, 0.1)'
    },
    "& .card-desc": {
        color: '#B7B7B7',
        fontSize: '16px',
        fontWeight: 600
    },

    "& .card-title": {
        marginTop: '8px',
        fontSize: '24px',
        fontWeight: 600,
        textAlign: 'center'
    },
    "& .awards-box-wrapper": {
        marginTop: '16px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '42px',
        [theme.breakpoints.down(660)]: {
            justifyContent: 'center'
        },
    },

    "& .header-text": {
        marginTop: '16px',
        alignSelf: 'flex-start',
        fontWeight: 600,
        fontSize: '30px'
    },

    "& .points-wrapper": {
        border: '2px solid #FFCE00',
        padding: '10px 40px',
        display: 'flex',
        borderRadius: '35px',
        alignItems: 'center',
        justifyContent: 'center'
    },
    "& .points-tip": {
        color: "#6F05EA",
        fontSize: '20px',
        marginTop: '18px',
        fontWeight: 600,
    },
    "& .points-number": {
        fontWeight: 600,
        fontSize: '32px',
        color: "#000"
    },
}))

export default TradePointsPage;