// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { defaultScroll, returnTruthyString } from "../../../../components/src/HelperUtils";
import { ICreateBookingMeta, ICustomerPet, IPaymentCard, ICreatedPaymentCard, Price } from "../../../../components/src/interfaces.web";
import moment from "moment";
import i18n from "../../../../components/src/i18next/i18n";

interface IComplementary {
    checked: boolean,
    service: null | Price
}
type TDayvalue = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday"
interface IRepeatDays {
    label: string,
    value: TDayvalue,
    checked: boolean
}
interface IBookingData {
    pet_details: ICustomerPet | undefined,
    start_date: Date | null | string,
    end_date: Date | null | string,
    service_name: string,
    sub_service: Price | null,
    show_complementary: boolean,
    medication: IComplementary | null,
    transportation: IComplementary | null,
    total_price: number,
    hotel_nights: number | undefined,
    establishment_name: string,
    is_repeat_weekly: boolean,
    createBookingResponse: ICreateBookingMeta | null,
    selected_repeat_days: IRepeatDays[]
}
interface ICardError {
    error: string;
}
interface ICardMessage {
    message: string;
}
interface IStripeCard {
    "errors": [
        { "stripe": string }
    ]
}

interface Coupon {
    id: number;
    code: string;
    discount: string;
    validity: string;
  }

type ICreatePaymentCardResponse = ICreatedPaymentCard | ICardMessage | ICardError | IStripeCard
interface IPaymentCardResponse {
    payment_methods: IPaymentCard[]
}

interface ICancelPaymentResponse {
    message:string,
    errors?:string
}
// Customizable Area End
export const webConfigJSON = require("../config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    apiToken: string,
    selectedPaymentCard: IPaymentCard | null
    // MODALS
    openAddCard: boolean,
    errorAddCard: string,
    setAsDefault: boolean,
    openCancelModal: boolean,
    msgModalOpen: boolean,
    msgModalText: string,
    openTimeoutModal: boolean,
    openPopup:boolean,
    couponCode:string
    // API
    createPaymentCardLoading: boolean,
    paymentCardsListLoading: boolean,
    paymentCardsList: IPaymentCard[],
    makePaymentApiLoading: boolean,
    paymentSuccess: boolean | null,
    paymentFailure: boolean | null,
    // BOOKING  
    bookingDetailsLoading: boolean,
    bookingData: any | null,
    bookingNotFound: boolean,
    // LOADER
    cancelConfirmLoading: boolean
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class BookingCheckoutController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getBookingDetailsApiCallId: string = ""
    getPaymentCardsApiCallId: string = ""
    createPaymentCardApiCallId: string = ""
    setDefaultCardApiCallId: string = ""
    makePaymentApicallId: string = ""
    cancelPaymentApiCallId: string = ""
    autoCancelApiCallId: string = ""
    timerId: NodeJS.Timeout | null = null;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            apiToken: localStorage.getItem("login_token") || "",
            selectedPaymentCard: null,
            // MODALS
            openAddCard: false,
            errorAddCard: "",
            setAsDefault: true,
            openCancelModal: false,
            msgModalOpen: false,
            msgModalText: "",
            openTimeoutModal: false,
            openPopup:false,
            couponCode:"",
            // API
            createPaymentCardLoading: false,
            paymentCardsListLoading: false,
            paymentCardsList: [],
            makePaymentApiLoading: false,
            paymentSuccess: null,
            paymentFailure: null,
            // BOOKING  
            bookingDetailsLoading: false,
            bookingData: null,
            bookingNotFound: false,
            // LOADER
            cancelConfirmLoading: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.getBookingDetailsApiCallId:
                        this.getBookingDetailsApiResp(responseJson)
                        break;
                    case this.getPaymentCardsApiCallId:
                        this.getPaymentCardsApiResp(responseJson)
                        break;
                    case this.createPaymentCardApiCallId:
                        this.createPaymentCardApiResp(responseJson)
                        break;
                    case this.setDefaultCardApiCallId:
                        this.setDefaultCardApiResp(responseJson)
                        break;
                    case this.makePaymentApicallId:
                        this.makePaymentApiResp(responseJson, errorResponse)
                        break;
                    case this.cancelPaymentApiCallId:
                        this.cancelPaymentApiResp(responseJson)
                        break;
                    case this.autoCancelApiCallId:
                        this.autoCancelApiResp(responseJson)
                        break;
                    default:
                        break;
                }
            }
            if (errorResponse) {
                this.resetPaymentStates()
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    getBookingDetailsApiResp = (response: any) => {
        this.setState({ bookingDetailsLoading: false })
        if('errors' in response){
            this.setState({
                bookingNotFound: true,
                bookingData: null
            })
        }
        if('data' in response && response.data?.id){
            this.setState({
                bookingNotFound: false,
                bookingData: response.data
            })
            // TIMEOUT CASE - 10 min
            this.timerId = setTimeout(this.handleTimeOut, webConfigJSON.getMiliSeconds(10));
        }
    }
    getPaymentCardsApiResp = (responseJson: IPaymentCardResponse) => {
        this.setState({ paymentCardsListLoading: false })
        if (responseJson && responseJson.payment_methods && responseJson.payment_methods.length) {
            const paymentCards = responseJson.payment_methods
            const findDefaultCard = paymentCards.find((paymentCard) => paymentCard.is_default)
            this.setState({
                paymentCardsList: paymentCards,
                selectedPaymentCard: findDefaultCard ? findDefaultCard : null
            })
        } else {
            this.setState({
                paymentCardsList: []
            })
        }
    }
    createPaymentCardApiResp = (responseJson: ICreatePaymentCardResponse) => {
        this.setState({ createPaymentCardLoading: false })
        if (responseJson && 'id' in responseJson) {
            if (this.state.setAsDefault) {
                this.setDefaultPaymentCard(responseJson.id)
            } else {
                this.getPaymentCards()
                this.handleCloseAddCard()
            }
        } else if ('error' in responseJson) {
            this.setState({
                errorAddCard: responseJson.error
            })
        } else if ('message' in responseJson) {
            this.setState({
                errorAddCard: responseJson.message
            })
        } else if (responseJson?.errors && responseJson?.errors.length) {
            this.setState({
                errorAddCard: responseJson.errors[0]?.stripe
            })
        }
    }
    setDefaultCardApiResp = (responseJson: ICardMessage) => {
        if (responseJson.message.includes("card updated successfully")) {
            this.getPaymentCards()
            this.handleCloseAddCard()
        }
    }
    makePaymentApiResp = (responseJson: any, errorResponse: any) => {
        if (responseJson && responseJson.invoice) {
            defaultScroll()
            this.resetSuccessStates()
        }

        if ('error' in responseJson) {
            this.setMsgModal(true, responseJson.error)
            this.resetFailureStates()
        }
        if (errorResponse) {
            this.resetFailureStates()
        }
    }

    async componentDidMount() {
        defaultScroll()
        this.getBookingDetails()
        this.getPaymentCards()


        window.history.pushState(null, "", window.location.pathname);
        window.addEventListener("popstate", this.handlePopState);
        window.addEventListener("beforeunload", this.handleBeforeUnload);
    };

    getBookingDetails = () => {
        const bookingId = this.props.navigation.getParam("navigationBarTitleText")
        if (!bookingId) return

        this.setState({ bookingDetailsLoading: true })

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        const header = { token: this.state.apiToken };

        const endpoint = `${webConfigJSON.ENDPOINTS.GET_BOOKING_DETAILS}/${bookingId}`
        this.getBookingDetailsApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), webConfigJSON.API_METHOD.GET);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    getPaymentCards = () => {
        this.setState({ paymentCardsListLoading: true })
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        const header = { token: this.state.apiToken };

        const endpoint = webConfigJSON.ENDPOINTS.GET_PAYMENT_CARD_LIST
        this.getPaymentCardsApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), webConfigJSON.API_METHOD.GET);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    createPaymentCard = (card_token: string) => {
        this.setState({ createPaymentCardLoading: true })

        const header = {
            'Content-Type': 'application/json',
            token: this.state.apiToken
        };

        const bodyData = { card_token }
        const endpoint = webConfigJSON.ENDPOINTS.CREATE_PAYMENT_CARD

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.createPaymentCardApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(bodyData));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), webConfigJSON.API_METHOD.POST);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    setDefaultPaymentCard = (payment_method_id: string) => {
        const header = {
            'Content-Type': 'application/json',
            token: this.state.apiToken
        };

        const bodyData = { payment_method_id }
        const endpoint = webConfigJSON.ENDPOINTS.SET_DEFAULT_CARD

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.setDefaultCardApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(bodyData));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), webConfigJSON.API_METHOD.PUT);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    setNewCard = (paymentCard: IPaymentCard) => {
        this.setState({
            selectedPaymentCard: paymentCard,
            paymentSuccess: null,
            paymentFailure: null,
        })
    }

    getBodyData = () => {
        const { bookingData, selectedPaymentCard } = this.state
        const { attributes } = bookingData ?? {}

        const scheduleId = this.props.navigation.getParam("navigationBarTitleText")
        const paymentId = returnTruthyString(selectedPaymentCard?.id)
        const customerId = returnTruthyString(selectedPaymentCard?.customer)
        const totalPrice = returnTruthyString(attributes?.total_price)
        const service_name = returnTruthyString(attributes?.service?.service_type)
        const is_repeat_weekly = !!(attributes?.repeat_weekly)
        const start_date = returnTruthyString(attributes?.start_date)
        const start_date_formatted = moment(start_date).format('YYYY-MM-DD')
        const end_date = returnTruthyString(attributes?.end_date)
        const end_date_formatted = moment(end_date).format('YYYY-MM-DD')

        const reqFormData = new FormData()

        reqFormData.append("currency", "EUR");
        reqFormData.append("payment_method_id", paymentId);
        reqFormData.append("customer_id", customerId);
        reqFormData.append("schedules_ids[]", scheduleId);
        reqFormData.append("total_price", totalPrice);
        reqFormData.append("description", service_name);
        reqFormData.append("start_date", start_date_formatted);
        reqFormData.append("end_date", end_date_formatted);

        if (is_repeat_weekly) {
            reqFormData.append("recurring", String(true));
        }

        return reqFormData
    }

    makePaymentCall = () => {
        const reqBody = this.getBodyData()
        const { selectedPaymentCard } = this.state

        if (!selectedPaymentCard) return

        this.setState({
            makePaymentApiLoading: true,
            paymentSuccess: null,
            paymentFailure: null,
        })
        const header = {
            token: this.state.apiToken
        };
        const endpoint = webConfigJSON.ENDPOINTS.MAKE_PAYMENT

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.makePaymentApicallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), reqBody);
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), webConfigJSON.API_METHOD.POST);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    handleOpen=()=>{
        this.setState({openPopup: true});
    }

    handleClose=()=>{
        this.setState({openPopup:false})
    }
 
    handleOpenAddCard = () => {
        this.setState({
            openAddCard: true,
            errorAddCard: "",
            setAsDefault: true
        })
    }
    handleCloseAddCard = () => {
        this.setState({
            openAddCard: false,
            errorAddCard: ""
        })
    }
    clearAddCardError = () => {
        this.setState({
            errorAddCard: ""
        })
    }
    goToHomePage = () => {
        this.props.navigation?.navigate("Home")
    }

    resetSuccessStates = () => {
        this.setState({
            paymentSuccess: true,
            paymentFailure: null,
            createPaymentCardLoading: false,
            makePaymentApiLoading: false
        })
    }
    resetPaymentStates = () => {
        this.setState({
            createPaymentCardLoading: false,
            makePaymentApiLoading: false,
            paymentSuccess: null,
        })
    }
    resetFailureStates = () => {
        this.setState({
            paymentSuccess: null,
            paymentFailure: true,
            createPaymentCardLoading: false,
            makePaymentApiLoading: false,
        })
    }
    handleChangeDefault = (checked: boolean) => {
        this.setState({
            setAsDefault: checked
        })
    }

    handleOpenCancel = () => {
        this.setState({
            openCancelModal: true
        })
    }
    handleConfirmCancel = () => {
        this.bookingCancelAPICall();
    }
    handleCloseCancel = () => {
        this.setState({
            openCancelModal: false
        })
    }

    setMsgModal = (open: boolean, message: string) => {
        this.setState({
            msgModalOpen: open,
            msgModalText: message,
        })
    }

    bookingCancelAPICall = () => {
        const scheduleId = this.props.navigation.getParam("navigationBarTitleText")
        if (!scheduleId) return

        this.setState({ cancelConfirmLoading: true });

        const header = {
            'Content-Type': 'application/json',
            token: this.state.apiToken
        };

        const endPoint = `${webConfigJSON.ENDPOINTS.CANCEL_PAYMENT}${scheduleId}`

        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.cancelPaymentApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), webConfigJSON.API_METHOD.DELETE);
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    autoCancelBooking = () => {
        const scheduleId = this.props.navigation.getParam("navigationBarTitleText")
        if (!scheduleId) return

        this.setState({ cancelConfirmLoading: true });

        const header = {
            'Content-Type': 'application/json',
            token: this.state.apiToken
        };

        const endPoint = `${webConfigJSON.ENDPOINTS.CANCEL_PAYMENT}${scheduleId}`
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.autoCancelApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), webConfigJSON.API_METHOD.DELETE);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    cancelPaymentApiResp = (responseJson: ICancelPaymentResponse) => {
        this.setState({ cancelConfirmLoading: false });
        this.handleCloseCancel()
        if (responseJson.errors) {
            this.setMsgModal(true, responseJson.errors)
        }
        if (responseJson.message) {
            const id = localStorage.getItem("service_id");
            const NavigateMsg: Message = new Message(getName(MessageEnum.NavigationMessage));
            NavigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Booking"); 
            NavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            NavigateMsg.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
            this.send(NavigateMsg);
        }
    }
    autoCancelApiResp = (responseJson: ICancelPaymentResponse) => {
        if (responseJson.message) {
            this.setState({ cancelConfirmLoading: false });
            this.handleCloseCancel()
        }
    }
    isPaymentAlreadyDone = () => {

        let isSuccess = false
        const { bookingData, paymentSuccess } = this.state
        const payment_status = returnTruthyString(bookingData?.attributes?.payment_status)
        isSuccess = payment_status === "succeeded"

        return isSuccess || !!paymentSuccess
    }

    async componentWillUnmount() {
        if (!this.isPaymentAlreadyDone()) {
            this.autoCancelBooking()
        }
        if (this.timerId) {
            clearTimeout(this.timerId);
        }
        window.removeEventListener("popstate", this.handlePopState);
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
    }

    handlePopState = () => {
        window.history.pushState(null, "", window.location.pathname);
    };

    handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = "";
        return "";
    };
    handleTimeOut = () => {
        if (!this.isPaymentAlreadyDone()) {
            this.setState({
                openTimeoutModal: true
            })
            this.autoCancelBooking()
        }
    }
    handleCloseTimeoutModal = () => {
        // CLOSE MODAL
        this.setState({ openTimeoutModal: false })

        // NAVIGATE BACK TO BOOKING PAGE 
        const { bookingData } = this.state
        const { attributes } = bookingData ?? {}
        const service_id = returnTruthyString(attributes?.service?.id)

        if (service_id) {
            const NavigateMsg: Message = new Message(getName(MessageEnum.NavigationMessage));
            NavigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Booking"); // ROUTE
            NavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            NavigateMsg.addData(getName(MessageEnum.NavigationScreenNameMessage), service_id); // PARAM
            this.send(NavigateMsg);
        } else {
            this.props.navigation?.navigate("Home")
        }
    }

    transCheckOut = (cKey: string) => {
        return i18n.t(cKey, { ns: "scheduling" })
    }
    // Customizable Area End
}

