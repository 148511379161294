import React from "react";
// Customizable Area Start
import BookingDetailsController, { Props } from "./BookingDetailsController.web";
import { Box, Dialog, styled, Typography, IconButton, Divider, CircularProgress } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { checkCondition, returnTruthyString } from "../../../../components/src/HelperUtils";
// Customizable Area End

export default class BookingDetails extends BookingDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderLoader = () => {
        return (
            <LoaderBox>
                <StyledLoader />
            </LoaderBox>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { bookingDetailsInfo, isBookingDetailsLoading } = this.state;
        const { name } = this.props.bookingDetailsProp.selectedService;
        const clientName = returnTruthyString(bookingDetailsInfo.client_name);
        const petName = returnTruthyString(bookingDetailsInfo.pet_name);
        const price = returnTruthyString(bookingDetailsInfo.price);
        const totalPrice = returnTruthyString(bookingDetailsInfo.total_price);
        const totalDay = bookingDetailsInfo.day_count;
        const transportation = bookingDetailsInfo.transportation;
        const medication = bookingDetailsInfo.medication;
        const isMedication = checkCondition(Boolean(bookingDetailsInfo.medication === "0.0"), false, true);
        const isTransportation = checkCondition(Boolean(bookingDetailsInfo.transportation === "0.0"), false, true);
        const isComplementaryService = checkCondition(Boolean(isMedication || isTransportation), true, false);
        const serviceName = checkCondition(Boolean(name === "Hotels"), `${totalDay} x € ${price} nights `, name) as string;
        const servicePrice = (+price * (totalDay))
        return (
            <StyledDialog
                open
                fullWidth
                maxWidth={"lg"}
                onClose={this.props.handleClose}
            >
                {isBookingDetailsLoading ? (<MainWrapper>{this.renderLoader()}</MainWrapper>) : (<></>)}
                {!isBookingDetailsLoading ? (
                    <MainWrapper>
                        <CloseButtonBox>
                            <CloseIconButton onClick={this.props.handleClose}>
                                <Close />
                            </CloseIconButton>
                        </CloseButtonBox>
                        <TitleBox>
                            <TitleFont>{this.transDetails("Booking Details")}</TitleFont>
                        </TitleBox>

                        <DetailsWrapper>
                            <RowBox>
                                <StyledFont>{this.transDetails("Client Name")}</StyledFont>
                                <StyledFont>{clientName}</StyledFont>
                            </RowBox>
                            <RowBox>
                                <StyledFont>{this.transDetails("Pet Name")}</StyledFont>
                                <StyledFont>{petName}</StyledFont>
                            </RowBox>
                            <RowBox marginTop={"42px"}>
                                <StyledFont>{serviceName}</StyledFont>
                                <StyledFont>€ {servicePrice}</StyledFont>
                            </RowBox>
                            {isComplementaryService ? (
                                <>
                                    <RowBox marginTop={"18px"}>
                                        <StyledFont className="bold-title">{this.transDetails("Complementary services")}</StyledFont>
                                    </RowBox>
                                    {isMedication ?
                                        (
                                            <RowBox>
                                                <StyledFont>{this.transDetails("Medication")}</StyledFont>
                                                <StyledFont>€ {medication}</StyledFont>
                                            </RowBox>
                                        ) : (<></>)
                                    }
                                    {isTransportation ?
                                        (
                                            <RowBox>
                                                <StyledFont>{this.transDetails("Transportation")}</StyledFont>
                                                <StyledFont>€ {transportation}</StyledFont>
                                            </RowBox>
                                        ) : (<></>)
                                    }
                                </>
                            ) : (<></>)}
                            <CustomHR />
                            <RowBox>
                                <StyledFont className="small-title">{this.transDetails("Total")}</StyledFont>
                                <TitleFont>€ {totalPrice}</TitleFont>
                            </RowBox>
                        </DetailsWrapper>
                    </MainWrapper>
                ) : (<></>)}
            </StyledDialog>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledDialog = styled(Dialog)({
    "& .MuiPaper-root": {
        maxWidth: "905px",
        borderRadius: "8px"
    },
})
const MainWrapper = styled(Box)({
    padding: "24px",
})
const CloseButtonBox = styled(Box)({
    display: "flex",
    justifyContent: "end",
    alignItems: "end"
})
const CloseIconButton = styled(IconButton)({
    padding: 0,
    "& .MuiSvgIcon-root": {
        color: "#090909"
    }
})
const TitleBox = styled(Box)({
    display: "flex",
    padding: "48px 24px 42px 24px",
    "@media(max-width:600px)": {
        padding: "38px 0px 32px 0px",
    },
    "@media(max-width:450px)": {
        padding: "28px 0px 22px 0px",
    }
})
const TitleFont = styled(Typography)({
    fontWeight: 600,
    fontSize: "36px",
    lineHeight: "40px",
    fontFamily: "Montserrat",
    color: "#6F05EA",
    "@media(max-width:600px)": {
        fontSize: "32px",
    },
    "@media(max-width:450px)": {
        fontSize: "18px",
    }
})
const RowBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap:10
})
const StyledFont = styled(Typography)({
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    fontFamily: "Montserrat",
    color: "#000000",
    "&.bold-title": {
        fontWeight: 600,
    },
    "&.small-title": {
        fontWeight: 600,
        fontSize: "24px",
        "@media(max-width:600px)": {
            fontSize: "20px",
        },
        "@media(max-width:450px)": {
            fontSize: "18px",
        }
    },
    "@media(max-width:600px)": {
        fontSize: "14px",
    }
})
const DetailsWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "0 24px 72px 24px",
    "@media(max-width:600px)": {
        padding: "0 0px 52px 0px"
    },
    "@media(max-width:450px)": {
        padding: "0 0px 42px 0px"
    }
})
const CustomHR = styled(Divider)({
    margin: "42px 0",
    color: "#B7B7B7",
    "@media(max-width:600px)": {
        margin: "30px 0",
    },
    "@media(max-width:450px)": {
        margin: "20px 0",
    }
})

const StyledLoader = styled(CircularProgress)({
  color: "#6F05EA"
});

const LoaderBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "50px",
    marginBottom: "50px"
});
// Customizable Area End
