import React from "react";
// Customizable Area Start
import { StyleSheet, Dimensions, Platform } from "react-native";
import {
  Box,
  Container,
  Modal,
  Button, Typography,styled

} from "@material-ui/core";
import {
  smallarrow,crossButton,Cellout,MarkerSmall
} from "./assets";
import Filters from "./components/FilterSection";
import {SearchResultItem} from './MapsController';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
// Customizable Area End

const viewportHeight = Dimensions.get("window").height;
const viewportWidth = Dimensions.get("window").width;

import MapView from "react-native-maps";

import MapsController, { Props, configJSON } from "./MapsController";

export default class Maps extends MapsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  onMapLoad = (map: google.maps.Map) => {
    map.addListener('click', () => {
      this.setState({ selectedMarker: null });
    });
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const ContainerBox = styled("div")({
      display: "flex",
      flexWrap: "wrap",
      width:'103%',
      gap:'10px'
    });
    const Column = styled("div")(({ theme }) => ({
      flex: "1 1 33%", // 3 columns
      padding: theme.spacing(2),
      boxSizing: "border-box",
      [theme.breakpoints.down(960)]: {
        flex: "1 1 100%", // 1 column on small screens
      },
    }));
    const PageWrapper = styled(Container)(({ theme }) => ({

      maxHeight:' 90%',
      width:'85%',
      display:'flex',
      background:'#F6F4F9',
      flexDirection: "column",
      overflow: 'scroll',
      top:'5%',
      left:'5%',
      marginTop:54,
      alignItems: 'flex-start',
      borderRadius:4,
    
      [theme.breakpoints.down("md")]: {
        width: "calc(100% - 532px)",
      },
      [theme.breakpoints.up(962)]: {
        width: "calc(100% - 300px)",
      },
      [theme.breakpoints.down("xs")]: {
        width: "calc(100% - 40px)",
      },
      "& *": {
        boxSizing: "border-box",
      },
    
      "& .MuiTypography-root.MuiTypography-body1": {
        fontFamily: "Montserrat",
      },
   
      "& .filters": {
        width: "100%",
        maxWidth:'315px',
        background: "#fff",
        display: "flex",
        borderRadius: "8px",
        height: '531px',
        boxShadow:' 0px 4px 8px 0px #00000008',
        marginTop:30
      },
      "& .sort-wrapper": {
        marginTop: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      "& .result-text": {
        color: "#6F05EA",
        fontWeight: 700,
        fontSize: "16px",
      },
      "& .view-map-btn": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "#fff",
        padding: "10px 16px",
        borderRadius: "8px",
        color: "#6F05EA",
        fontSize: "16px",
        fontWeight: 600,
        cursor: "pointer",
      },
    
      "& .map-filter-block": {
        width: "103%",
        flexDirection: "row",
        gap: "24px",
      },
    
      "& .map-wrapper": {
        position: "relative",
        width:'100%',
        marginLeft:-22,
        marginTop:-17
      },
      "& .content":{
      width:'103%',
      height:'100%',
      background:'#F6F4F9',
      flexDirection: "column",
      overflow: 'scroll',
      alignItems: 'flex-start',
      borderRadius:4,
    },

      "& .list-block": {
        display: "flex",
        gap: "20px",
        flexDirection: "column",
        marginTop:20,
        maxWidth:'305px'
      },
      "& .Modal-container": {
        background:'#F6F4F9',
        width:'80%'
       },
       "& .smallArrow-box":{
        position:'absolute',
  width: '44px',
  height: '44px',
  marginLeft: 563,
  zIndex: 1,
  marginTop: '-9px',
 
  [theme.breakpoints.down(963)]: {
    visibility: 'collapse'
      },
       },
       "& .crossButton-box":{
        position:'absolute',
  width: '44px',
  height: '44px',
  marginLeft: 563,
  zIndex: 1,
  marginTop: '-9px',
  right:0,
 marginRight: 112,
  
    [theme.breakpoints.up(1900)]: {
      marginRight: 137,
        },
        [theme.breakpoints.up(1240)]: {
          marginRight:158,
          marginTop:-23
            },
            [theme.breakpoints.down(1040)]: {
              visibility: 'collapse'
                },
                [theme.breakpoints.up(1040)]: {
                 marginTop:-26,
                 marginRight:145
                    },
       },
       crossButton
    }));
    const ItemWrapper = styled(Box)(({theme}) => ({
      boxShadow: "0px 0px 9.5px -1px rgba(0, 0, 0, 0.25)",
      borderTopLeftRadius:16,
      borderBottomLeftRadius:16,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      width:'305px',
      "& .from-tag": {
          color: '#B7B7B7',
          fontSize: '16px',
          fontWeight: 500,
          whiteSpace: 'nowrap',
          fontFamily:'Montserrat',
      },
      "& .image-container": {
          width: '100%',
          height: '250px',
          overflow: 'hidden',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
      },
      "& .main-content": {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '14px',

    },
      "& .image": {
          position: 'absolute',
          width: 'auto',
          height: 'auto',
          minWidth: '100%',
          minHeight: '100%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
      },
      
     
      "& .content-line": {
          display: 'flex',
          gap: '12px',
          fontSize: '12px',
          fontWeight: 300,
          color: '#000',
          alignItems: 'flex-start',
          fontFamily: 'Montserrat'
  
      },
      "& .title": {
        color: '#000',
        fontSize: '18px',
        fontWeight: 600,
        fontFamily: 'Montserrat'
    },
    
      "& .fees-text": {
          marginTop: '8px',
          marginBottom: '20px',
          color: '#777777',
          fontSize: '12px',
          fontWeight: 500
      },
      "& .address-style": {
          fontSize: '12px',
          fontWeight: 300,
          color: '#000',
          fontFamily: 'Montserrat'
      },
   
      "& .warning-text": {
          color: '#FFCE00',
          fontSize: '14px',
          fontWeight: 600
      },
      "& .price-block": {
          marginTop:-10,
          marginBottom: '30px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
   alignItems:'center',
        
      },
      "& .link": {
        color: '#909090',
        fontSize: '12px',
        fontWeight: 700,
        textDecoration: 'underline',
    },
      "& .offer-btn": {
          color: '#fff',
          textTransform: 'none',
          backgroundColor: '#6F05EA',
          backround: '#6F05EA',
          padding: '10px 36px',
          borderRadius: '35px',
          fontSize: '18px',
          lineHeight: '14px',
          fontWeight: 700,
          fontFamily: 'Montserrat',
          letterSpacing: 0,
          border: '1px solid #6F05EA',
          whiteSpace: 'nowrap',
          width: '188px',
    height:' 53px'
      },
      "& .price-section": {
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.up(1600)]: {
            alignItems: 'end',
        },
    },
      "& .price-text": {
          color: '#777777',
          fontSize: '14px',
          fontWeight: 500,
          marginBottom: '21px'
      },
     
      "& .price-list": {
          display: 'flex',
          alignItems: 'center',
      },
      "& .no-tax-price": {
          color: '#777777',
          fontSize: '14px',
          fontWeight: 400,
          whiteSpace: 'nowrap',
      },
  
      "& .main-price": {
          color: '#6F05EA',
          fontSize: '16px',
          fontWeight: 500,
          marginLeft: '10px',
          whiteSpace: 'nowrap',
          fontFamily:'Montserrat'
      }
  
  }))
    const containerStyle = {
      width: '100%',
      height: '100vh',
    };
    
   
    const { markers } = this.props;
    const { selectedMarker } = this.state;
    return (
      <>
      {!this.props.open && 
      <button style={{
        fontFamily: 'Montserrat',
        padding: '0 28px',
        fontWeight: 700,
        fontSize: '12px',
        color: '#909090',
        textDecoration: 'underline',
        background: 'transparent',
        border: 'none',
        cursor: 'pointer'
      }} 
      data-test-id="viewmap" 
      onClick={() => this.handleClose()}>
        Show in map
      </button>}
      {this.state.enableModal && (
      <Modal
      style={{
     }}
      open={this.state.enableModal}
      onClose={() => this.handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-test-id="handleModal"
      hideBackdrop={false}
      >
      <PageWrapper maxWidth="xl" data-test-id="advanced-search-wrapper">
        <ContainerBox>
        <Column className="filters">
            <Filters
              type={this.state.searchTab}
              values={this.state.filters}
              onChange={this.changeFilterValue}
            />
          </Column>
          <Column className="list-block">
          {this.results.length>0 && <Box>
            {this.state.searchItems.map((item:SearchResultItem) => (
              <ItemWrapper  key={item.id}>
              <Box className='image-container' style={{ maxWidth: '100%', backgroundImage: `url(${item.establishment_photo})` }} >
              </Box>
              <Box className='main-content'>
                  <Typography className='title'>{item.establishment_name}</Typography>
                  <Box className='content-line'>
                      <img src={MarkerSmall}/>
                      <Typography className='address-style'>{item.address}</Typography>
                  </Box>
                  <Box className='price-list'>
                          <Typography className='from-tag'>from</Typography><Typography className='main-price'>{item.service_price}€/night</Typography>
                      </Box>
              </Box>
                  <Box className='price-block'>
                  <Button data-test-id='handleSearch' onClick={() => this.props.onSearch(item)} className='offer-btn'>Book</Button>
              </Box>
          </ItemWrapper>
          
          ))}
            </Box>}
          
        </Column>
       <Box className="smallArrow-box"><img src={smallarrow}/></Box>
          <Column className="map-wrapper">
            <GoogleMap
    mapContainerStyle={containerStyle}
    center={{
      lat: this.state.searchItems[0]?.latitude,
      lng: this.state.searchItems[0]?.longitude,
    }}
    zoom={12}
    onLoad={this.onMapLoad}
    options={{
      disableDefaultUI: true,
      zoomControl: true,
    }}
  >
    {markers.map((marker) => (
      <Marker
        key={marker.id}
        position={{
          lat: marker.latitude || 0,
          lng: marker.longitude || 0,
        }}
        icon={Cellout} 
        onClick={() => this.setState({selectedMarker:marker})} 
      />
    ))}

    {selectedMarker && selectedMarker.latitude && selectedMarker.longitude && (
      <InfoWindow
        position={{
          lat: selectedMarker.latitude,
          lng: selectedMarker.longitude,
        }}
        options={{ disableAutoPan: true }} 
        onCloseClick={() => this.setState({selectedMarker:null})} 
      >
        <ItemWrapper>
            <Box className='image-container' style={{ maxWidth: '100%', backgroundImage: `url(${selectedMarker.establishment_photo})` }} >
            </Box>
            <Box className='main-content'>
                <Typography className='title'>{selectedMarker.establishment_name}</Typography>
                <Box className='content-line'>
                    <img src={MarkerSmall}/>
                    <Typography className='address-style'>{selectedMarker.address}</Typography>
                </Box>
                <Box className='price-list'>
                        <Typography className='from-tag'>from</Typography><Typography className='main-price'>{selectedMarker.service_price}€/night</Typography>
                    </Box>
            </Box>
                <Box className='price-block'>
                <Button data-test-id='searchbtn' onClick={() => this.props.onSearch(selectedMarker)} className='offer-btn'>Book</Button>
            </Box>
        </ItemWrapper>
      </InfoWindow>
    )}
  </GoogleMap>


          </Column>
          <Box onClick={()=>{this.handleClose()}} data-test-id="closemap" className="crossButton-box"><img src={crossButton}/></Box>
          </ContainerBox>
    </PageWrapper>
    </Modal>
      )}
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = {
  map: {
    width:' 749px',
    height: '2114px',
    position: "absolute",
    top: 0,
    bottom: 0,
    right:0
  },
}
// Customizable Area End
