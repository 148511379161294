import React, { Component } from 'react';
import { Box, IconButton, styled } from "@material-ui/core";
import { previousArrowIcon, nextArrowIcon } from "../../../../components/src/calendarWeb/assets";
import DatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import CommonBackdrop from '../../../../components/src/BackDrop';
import { IRangePickerProp } from './ServiceDashboardController.web';

interface State {
  startDate: Date | null;
  endDate: Date | null;
  yearDropdownVisible: boolean;
}

interface Props {
  onChangeDate: (startDate: Date | null, endDate: Date | null) => void,
  rangeDatePicker: IRangePickerProp
}

class CustomRangePicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: this.props.rangeDatePicker.startDate,
      endDate: this.props.rangeDatePicker.endDate,
      yearDropdownVisible: false,
    };
  }

  onChange = (dates: [Date | null, Date | null] | null) => {
    if (dates) {
      const [start, end] = dates;
      this.setState({ startDate: start, endDate: end});
      if(start && end)
      this.props.onChangeDate(start,end);
    }
  };

  renderCustomHeader = ({
    date,
    changeYear,
    decreaseMonth,
    increaseMonth,
  }: ReactDatePickerCustomHeaderProps) => {
    const { yearDropdownVisible } = this.state;
    const currentYear = date.getFullYear();
    const years = Array.from({ length: 150 }, (_, i) => currentYear - i);

    return (
      <StyledCustomHeader data-test-id="custom-header">
        <Box className="month-year-info">
          <IconButton onClick={decreaseMonth}>
            <img src={previousArrowIcon} alt="prev-arrow" />
          </IconButton>
          <span className="react-datepicker__current-month">
            {date.toLocaleString("default", { month: "long" })}{" "}
            <Box className="year-month-container">
              <span
                className="year-span"
                onClick={() => this.setState({ yearDropdownVisible: !yearDropdownVisible })}
                data-test-id="year-span"
              >
                {date.getFullYear()}
              </span>
              {yearDropdownVisible && (
                <ul className="year-list" data-test-id="year-list">
                  {years.map((year) => (
                    <li
                      key={year}
                      style={{
                        color: year === currentYear ? "#6F05EA" : "#3C3E49",
                        fontWeight: year === currentYear ? 600 : 400,
                        backgroundColor: year === currentYear ? "#E1CCFF54" : "transparent",
                      }}
                      className="year-option"
                      data-test-id="year-option"
                      onClick={() => {
                        changeYear(year);
                        this.setState({ yearDropdownVisible: false });
                      }}
                    >
                      {year}
                    </li>
                  ))}
                </ul>
              )}
            </Box>
          </span>
          <IconButton onClick={increaseMonth}>
            <img src={nextArrowIcon} alt="next-arrow" />
          </IconButton>
        </Box>
      </StyledCustomHeader>
    );
  };

  render() {
    const { startDate, endDate} = this.state;
    return (
       (
        <CommonBackdrop open={true}>
        <StyledBox>
          <DatePicker
            selected={startDate}
            onChange={this.onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
            data-test-id="range-picker"
            renderCustomHeader={this.renderCustomHeader}
          />
          </StyledBox>
        </CommonBackdrop>
      )
    );
  }
}

export default CustomRangePicker;

const StyledCustomHeader = styled(Box)({
  borderBottom: "1px solid #B7B7B7",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: "16px",
  "& .month-year-info": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .react-datepicker__current-month": {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#6200ee",
    borderBottom: "none",
    paddingBottom: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  "& .year-month-container": {
    position: "relative",
  },
  "& .year-span": {
    fontSize: "36px",
    fontWeight: "600",
    border: "none",
    cursor: "pointer",
    color: "black",
    borderBottom: "2px solid black",
    "@media only screen and (max-width: 600px) and (min-width: 350px)": {
      fontSize: "16px"
    }
  },
  "& .year-list": {
    position: "absolute",
    top: "100%",
    left: "-12px",
    width: "118px",
    maxHeight: "240px",
    overflowY: "auto",
    backgroundColor: "white",
    padding: 0,
    marginTop: "5px",
    listStyleType: "none",
    zIndex: 1,
    borderRadius: "8px",
    boxShadow: "0px 0px 9px 0px rgba(0, 0, 0, 0.2)",
    "& ::-webkit-scrollbar": {
      width: 6,
      borderRadius: 6,
    },
    "& ::-webkit-scrollbar-thumb": {
      background: "#6F05EA",
      borderRadius: 4,
      width: 6,
    },
    "& ::-webkit-scrollbar-track": {
      background: "#CBD5E1",
      borderRadius: 6,
    },
    "@media only screen and (max-width: 600px) and (min-width: 350px)": {
      width: "unset",
      left: "-18px"
    }
  },
  "& .year-option": {
    padding: "14px 25px",
    cursor: "pointer",
    fontSize: "16px",
    lineHeight: "19.5px",
    "@media only screen and (max-width: 600px) and (min-width: 350px)": {
      fontSize: "12px",
    }
  },
});

const StyledBox = styled(Box)({
  "& .day--selected": {
    backgroundColor: "#E1CCFF",
    color: "#000",
    border: "0px solid"
  },
  "& .react-datepicker__day:hover": {
    backgroundColor: "#E1CCFF !important",
    color: "#000"
  },
  "& .react-datepicker__day--today": {
    backgroundColor: "transparent !important",
    color: "#000"
  },
  "& .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range)": {
    backgroundColor: "#E1CCFF !important",
    color: "#000"
  },
  "& .react-datepicker__day--selected:after": {
    content: 'none'
  },
  "& .react-datepicker__day--in-range": {
    backgroundColor: "#E1CCFF !important",
    color: "#000"
  },
  "& .react-datepicker__day--selected":{
    border:"none"
  }
})
