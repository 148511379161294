Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";

exports.btnExampleTitle = "CLICK ME";


exports.filterMenuList = [
  {
    label: "Today",
    value: "Today"
  },
  {
    label: "This Week",
    value: "This Week"
  },
  {
    label: "This Month",
    value: "This Month"
  },
  {
    label: "This Year",
    value: "This Year"
  },
  {
    label: "Custom Date Range",
    value: "Custom Date Range"
  }
]
exports.chartLabels = [
  {
    label: "Day walking",
    value: "30%",
    color: "#6200EA"
  },
  {
    label: "Individual walking",
    value: "20%",
    color: "#EC4899"
  },
  {
    label: "Group walking",
    value: "20%",
    color: "#60A5FA"
  },
  {
    label: "Individual walking 2",
    value: "20%",
    color: "#F59E0B"
  },
  {
    label: "Group walking 2",
    value: "10%",
    color: "#2563EB"
  }
]

exports.petTraits = [
  {
    left: "Calm",
    right: "Energetic",
    key: "calm",
    value: "2",
  },
  {
    left: "Relaxed",
    right: "Anxious",
    key: "relaxed",
    value: "",
  },
  {
    left: "Easy-going",
    right: "Shy",
    key: "easy_going",
    value: "",
  },
  {
    left: "Friendly",
    right: "Aggressive",
    key: "friendly",
    value: "1",
  },
  {
    left: "Gentle",
    right: "Strong",
    key: "gentle",
    value: "",
  },
  {
    left: "Obedient",
    right: "Rebbelious",
    key: "obedient",
    value: "",
  }

]

exports.ENDPOINTS = {
  GET_SERVICE_POPULARITY: "bx_block_analytics7/appointment/service_popularity?",
  GET_BOOKING_LIST: "bx_block_analytics7/appointment/booking_list?",
  GET_APPOINTMENT_LIST: "bx_block_analytics7/appointment/appointment_list?",
  GET_CUSTOMER_LIST: "bx_block_analytics7/appointment/customer_list?",
  DOWNLOAD_CSV_CUSTOMER_LIST:"bx_block_analytics7/appointment/download_customer_csv?service_id=",
  GET_VIEW_CUSTOMER_DATA: "bx_block_analytics7/appointment/booking_history?customer_id=",
  GET_PET_MEDICAL_DATA: "/bx_block_categories/pet_medicals/",
  GET_PET_TRAITS_DATA: "/bx_block_categories/traits/",
  GET_PET_GENERAL_DATA: "/account_block/animal_types/",
  GET_BOOKING_DETAILS_DATA: "/bx_block_analytics7/appointment/booking_details?"
}
// Customizable Area End