import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { FormikErrors, FormikTouched } from "formik";
import { getCountryCode } from "countries-list";
import i18n from "../../../components/src/i18next/i18n"; 
import { returnTruthyString } from "../../../components/src/HelperUtils";

const styles = {
  filledLabel: {
    fontSize: "13px",
    fontWeight: 400,
    color: "#6F05EA",
    fontFamily: "Montserrat",
  },
  secondaryLabel: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: '13px',
    color: '#6F05EA'
  },
};
const defaultPersonal = {
  establishment_name: "",
  phone_number: '',
  country_code:'',
  city: "",
  country: "",
  zipcode: "",
  address: "Portugal",
  email: "",
  facebook: "",
  tiktok: "",
  linkedin:"",
  instagram: "",
  establishment_photo:''

}


interface IApiModel {
  contentType?: string;
  method: string;
  endPoint2: string;
  body2?: object;
  token2?: string | null;
  isJsonStringify?: boolean;
}
interface IPersonalInfo {
  establishment_name: string,
  phone_number: string,
  country_code:string ,
  city: string,
  country: string,
  zipcode: string,
  address: string,
  email: string,
  facebook: string,
  instagram: string,
  tiktok: string,
  linkedin:string,
  establishment_photo:string
}
 interface EstablishmentListErrorDetail {
  detail?: string;
  account?: string;
  token?: string;
  error?:string;
}
interface GeneralInfoUserApiResponse {
  data: {
    id: string;
    type: string;
    attributes: {
            id:string;
            establishment_name:  string;
            address: string;
            country:  string;
            city:  string;
            zipcode: string;
            email: string;
            phone_number: number,
            facebook_url:  string | '';
            instagram_url:  string | '';
            linkedin_url: string | '';
            tiktok_url:  string | '';
            establishment_photo:string;
    };
  };
  errors?: EstablishmentListErrorDetail[];
  error: EstablishmentListErrorDetail[];
}
interface ICountryModel {
  label: string;
  value: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleEstablishmentInfo:(establishment_name:string,establishment_photo:string)=>void
  openEmployeeManagement?: boolean
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  tabValue:number;
  isSideBarOpen:boolean;
  currentSettingsTab:number
  isEditPersonal: boolean;
  personal: IPersonalInfo;
  uploadedImagePreview:any;
  serverFile: File | null;
  referenceImage:any;
  isdata:boolean;
  countries: {
    label: string;
    value: string | number | undefined;
  }[];
  cities: {
    label: string;
    value: string | number | undefined;
  }[];
  isoCountryName: string | boolean;
  generalInfoError:EstablishmentListErrorDetail[];
  demoaddress:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiUpdateAccountCallId: string = "";
  apiGetEstablishmentCallId: string = "";
  private debounceSearchTimeout: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
      
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      tabValue:0,
      isSideBarOpen:false,
      currentSettingsTab:0,
      isEditPersonal: false,
      countries: [],
      cities: [],
      isoCountryName: "",
      personal: defaultPersonal,
      uploadedImagePreview: "",
      serverFile:null,
      referenceImage:'',
      generalInfoError: [],
      isdata:false,
      demoaddress:'',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if(apiRequestCallId===this.apiUpdateAccountCallId){
        this.handleUpdateAccountResponse(responseJson, errorJson);
      }
      if(apiRequestCallId===this.apiGetEstablishmentCallId){
          this.handleGeneralInfoUserResponse(responseJson);
      }

      }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps2 = {
    ...this.txtInputWebProps,
    keyboardType: "email-address",
    autoCompleteType: "email",
  };

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
      this.txtInputProps.secureTextEntry = !this.state.enableField;
    },
  };
  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps2;

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  // Customizable Area Start
  async componentDidMount(): Promise<void>  {
    // Customizable Area Start
    super.componentDidMount();
    this.getEstablishmentGeneralInfo();
    this.getCountryListData();
    this.handleCountryNameChange(configJSON.defaultCountry);
    // OPEN EMPLOYEE MANAGEMENT
    if (this.props.openEmployeeManagement) {
      this.setTabId(2)
    }
    // Customizable Area End
  }
  componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.currentSettingsTab === 0 && prevState.currentSettingsTab !== 0) {
      this.setState({ isdata: false,isEditPersonal:false });
      this.debounceTimeSearch(
        async () => {
          this.getEstablishmentGeneralInfo();
      this.getCountryListData();
        },
        500
      )();
    }
  }
  debounceTimeSearch(func: (...args: any[]) => void, delay: number) {
    return (...args: any[]) => {
      if (this.debounceSearchTimeout) {
        clearTimeout(this.debounceSearchTimeout);
      }
      this.debounceSearchTimeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }
  openSideBar = () => {
    this.setState({ isSideBarOpen: !this.state.isSideBarOpen });
  };
  setTabId=(id:number)=>{
    this.setState({ currentSettingsTab: id })
  }
  getInputProps = <T extends object>(
    field: keyof T,
    errors: FormikErrors<T>,
    touched: FormikTouched<T>,
    values: T,
  ) => {
    return {
      id: field,
      name: field,
      fullWidth: true,
      error: Boolean(errors[field]) && touched[field],
      helperText: this.transInfo(returnTruthyString((touched[field] && errors[field])?.toString())),
      labelStyle: values[field] ? styles.filledLabel : styles.secondaryLabel,
    };
  };
  getLabel = (string: string) => {
    const key = string.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
    return key
  }
  getCountryListData = () => {
    const countriesWithCities: string[] =
      require("countries-cities").getCountries();
    const countryList: ICountryModel[] = countriesWithCities
      .filter((country: string) => {
        const cities: string[] = require("countries-cities").getCities(country || "Portugal");
        return cities && cities.length > 0;
      })
      .map((country: string) => ({
        label: country,
        value: country,
      }));
    this.setState({ countries: countryList });
  };
  handleCountryNameChange = (country: string) => {
    const cityList = require("countries-cities").getCities(country|| "Portugal").map((city: string) => ({
        label: city,
        value: city,
      }));
      
      this.setState({
      cities: cityList,
      isoCountryName: getCountryCode(country || "Portugal"),
    });
   
  };
  
  getPhoneErrors = <T,>(
    errors: FormikErrors<any>,
    touched: FormikTouched<any>,
    field: keyof T
  ) => {
    if (Boolean(errors[field]) && touched[field]) {
      return {
        style: "2px solid #EF4444",
        childrens: this.transInfo(returnTruthyString(touched[field] && errors[field]?.toString())),
      };
    } else {
      return {
        style: "1px solid #B7B7B7",
      };
    }
  };
  getEstablishmentGeneralInfo = async () => {
    this.setState({isEditPersonal:false})
    const userInfoToken = sessionStorage.getItem("login_token");
    const establishmentId = localStorage.getItem("establishment_ID");
    this.apiGetEstablishmentCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint2: `${configJSON.accountsetting}/${establishmentId}`,
      token2: userInfoToken,
    });
  };
  handleGeneralInfoUserResponse = (responseJson: GeneralInfoUserApiResponse) => {
    if (responseJson.errors) {
      if(responseJson.errors.some(error => error.token)){
        this.goToLoginPage();
      }
      else{
        this.setState({ generalInfoError: responseJson.error });
      }
    } else {
      const responseData = responseJson.data.attributes
      this.setState({ uploadedImagePreview: responseData.establishment_photo || "https://i.ibb.co/XJpjGkz/Profile-PNG-File.png" });
      this.handleCountryNameChange(responseData.country || "")
      this.setState({
       
      }, () => {
        setTimeout(() => {
          this.setState({isdata:true})
          this.setState({
            personal: {
              establishment_name: responseData.establishment_name,
              address: responseData.address,
              zipcode: responseData.zipcode,
              city: responseData.city,
              country: responseData.country,
              email: responseData.email,
              phone_number:responseData.phone_number.toString(),
              country_code:'',
              facebook: responseData.facebook_url || "",
              instagram: responseData.instagram_url || "",
              tiktok: responseData.tiktok_url || "",
              linkedin: responseData.linkedin_url || "",
              establishment_photo:responseData.establishment_photo
            },
            demoaddress:responseJson.data.attributes.address
          })
        }, 2000);
        
      });
    }
  };
  goToLoginPage = () => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };
  updateUserProfile = async (data: IPersonalInfo) => {
    this.setState({demoaddress:data.address})
    let generalInfoForm = new FormData();
    generalInfoForm.append("establishment[establishment_name]", data.establishment_name);
    generalInfoForm.append("establishment[address]", data.address);
    generalInfoForm.append("establishment[country]", data.country);
    generalInfoForm.append("establishment[country_code]",data.country_code);
    generalInfoForm.append("establishment[zipcode]", data?.zipcode);
    generalInfoForm.append("establishment[phone_number]", data.country_code+data.phone_number.toString());
    generalInfoForm.append("establishment[facebook_url]", data.facebook);
    generalInfoForm.append("establishment[linkedin_url]", data.linkedin);
    generalInfoForm.append("establishment[instagram_url]", data.instagram);
    generalInfoForm.append("establishment[tiktok_url]", data.tiktok);
    generalInfoForm.append("establishment[email]", data.email);
    generalInfoForm.append("establishment[city]", data.city);
    generalInfoForm.append("establishment[activated]",'true');
    if (this.state.serverFile) {
      generalInfoForm.append(
        "establishment[establishment_photo]",
        this.state.serverFile
      );
    }
    const generalInfoToken = localStorage.getItem("login_token");
    const establishmentId = localStorage.getItem("establishment_ID");
    this.apiUpdateAccountCallId = await this.apiCall({
      method: configJSON.httpPutMethod,
      endPoint2: `${configJSON.accountsetting}/${establishmentId}`,
      token2: generalInfoToken,
      body2:generalInfoForm,
      isJsonStringify: false,
    })
  }
  apiCall = async (data: IApiModel) => {
    const { method, endPoint2, body2, token2, isJsonStringify } =
      data;
    let header;
      header = {
        token: token2,
      };
    const requestedMessaged = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestedMessaged.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestedMessaged.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint2
    );
    requestedMessaged.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body2 &&
      requestedMessaged.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body2) : body2
      );
    runEngine.sendMessage(requestedMessaged.id, requestedMessaged);
    return requestedMessaged.messageId;
  };
  handleUpdateAccountResponse = (responseJson: any, errorJson: any) => {
    this.handleResponseMessage({
      responseJson,
      errorJson,
      onSuccess: async () => {
        this.setState({isEditPersonal:false})
        this.props.handleEstablishmentInfo(responseJson?.data?.attributes.establishment_name,responseJson?.data?.attributes.establishment_photo)
        window.scrollTo(0, 0);
      },
      onFail: () => {
        this.showAlert(
          `Failed update personal data`,
          JSON.stringify(errorJson)
        );
      },
    });
  }

  handleResponseMessage<T>(input: {
    responseJson: {
      data?: T;
      errors?: T;
      error: T;
    };
    errorJson?: T;
    onSuccess: () => void;
    onFail: () => void;
  }) {
    const { responseJson, onSuccess, onFail, errorJson } = input;

    if (
      responseJson &&
      !responseJson.error &&
      !responseJson.errors &&
      responseJson.data
    ) {
      onSuccess();
    }

    if (
      responseJson.error ||
      errorJson ||
      responseJson.errors ||
      !responseJson.data
    ) {
      onFail();
    }
  }
  cancelEditForm = (field: string, resetCallback: () => void) => {
    this.setState({ isEditPersonal: false });
    resetCallback();
  };

  transInfo = (generalInfoKey: string) => {
    return i18n.t(generalInfoKey, { ns: "establishmentSetting" });
  }
  // Customizable Area End
}
