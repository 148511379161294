// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { SearchData } from "../../../components/src/LandingPageHeaderWeb/LandingPageHeader.web";
import { ICommodities, ICreateBookingMeta, ICustomerPet, IPropHeaderData, Price } from "../../../components/src/interfaces.web";
import { checkCondition, getDayDifference, isValidValue, returnTruthyString, scrollToSection } from "../../../components/src/HelperUtils";
import moment from "moment";
import i18n from "../../../components/src/i18next/i18n";
import { createRef } from "react";

type TDayvalue = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday"
interface IRepeatDays {
    label: string,
    value: TDayvalue,
    checked: boolean
}
const getTabValue = (ServiceName: string) => {
    const mapped: Record<string, number> = {
        'Hotels': 0,
        'Dog Walking': 1,
        'Grooming': 2,
        'Pet Sitting': 3,
        'Day Care': 4,
        'Training': 5,
    }
    return mapped[ServiceName]
}
const initialRepeatDays: IRepeatDays[] = [
    {
        label: "Sun",
        value: "Sunday",
        checked: false
    },
    {
        label: "Mon",
        value: "Monday",
        checked: false
    },
    {
        label: "Tue",
        value: "Tuesday",
        checked: false
    },
    {
        label: "Wed",
        value: "Wednesday",
        checked: false
    },
    {
        label: "Thu",
        value: "Thursday",
        checked: false
    },
    {
        label: "Fri",
        value: "Friday",
        checked: false
    },
    {
        label: "Sat",
        value: "Saturday",
        checked: false
    }
]
const initialHeaderData: IPropHeaderData = {
    "selectedCountry": "",
    "selectedCity": "",
    "SelectedStartDate": null,
    "SelectedEndDate": null,
    "SelectedPet": ""
}
interface IApiModel {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string | null;
    isJsonStringify?: boolean;
}

interface IServiceGalleriesUrl {
    image_url: string,
    file_name?: string,
}

interface IEstablishmentGallery {
    id: null | string,
    file_name: string,
    file_url: string
}

interface IScheduleInfo {
    id: number;
    day: string;
    start_time: string;
    end_time: string;
}

interface IEstablishment {
    id: number;
    establishment_name: string;
    address: string;
    country: string;
    city: string;
    zipcode: string;
    email: string;
    phone_number: number;
    facebook_url: string;
    instagram_url: string;
    linkedin_url: string;
    tiktok_url: string;
    account_id: number;
    created_at: string;
    updated_at: string;
    activated: boolean;
    latitude: number;
    longitude: number;
}

interface ISubServicePrice {
    id: number;
    title: string;
    capacity: number;
    allows_pet: string;
    description: string;
    service_id: number;
    duration: string | null;
    shift: string | null;
    created_at: string;
    updated_at: string;
    prices: Price[];
}

interface IDetailsAttr {
    id: number;
    service_type: string;
    about: string;
    dog: boolean;
    fish: boolean;
    cats: boolean;
    bird: boolean;
    rabbit: boolean;
    reptile: boolean;
    rodents: boolean;
    pool: boolean;
    outdoor_hotel: boolean;
    veterinary_support: boolean;
    indoor_hotel: boolean;
    toys_at_display: boolean;
    litter_boxes: boolean;
    fresh_water: boolean;
    natural_food: boolean;
    poop_spaces: boolean;
    comfortable_beds: boolean;
    individual_room: boolean;
    cat_trees: boolean;
    daily_walks: boolean;
    group_rooms: boolean;
    catio: boolean;
    garden: boolean;
    socializing_activities: boolean;
    account_id: number;
    establishment_id: number;
    cancellation_policy: string;
    created_at: string;
    updated_at: string;
    service_galleries_urls: IServiceGalleriesUrl[];
    schedule_informations: IScheduleInfo[];
    establishment: IEstablishment;
    establishment_image: string;
    sub_services_with_prices: ISubServicePrice[];
    establishment_galleries_urls:IEstablishmentGallery[];
}

interface IDetailsResponse {
    data: {
        id: string;
        type: string;
        attributes: IDetailsAttr;
    };
    meta: {
        message: string;
    };
    errors?: string
}
interface IPetListResp {
    data: ICustomerPet[];
}

interface ICreateBookingResponse {
    data: {
        id: string;
    };
    meta: ICreateBookingMeta
    errors?: string
}
export type SearchItem = {
    id: number,
    establishment_name: string,
    country: string,
    city: string,
    zipcode: string,
    address: string,
    longitude: number,
    email: string,
    phone_number: number,
    facebook_url: string,
    instagram_url: string,
    linkedin_url: string,
    latitude: number,
    tiktok_url: string,
    activated: boolean,
    updated_at: string,
    vandor_name: string,
    service_price: number,
    created_at: string,
    establishment_photo: string | null,
    service_details: {
      "id": number | string,
      "service_type": string
    },
    galleries_urls: string[],
  };
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
    id: string;
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    apiToken: string,
    // HEADER
    tabValue: number,
    headerData: IPropHeaderData,
    // BOOKING SUMMARY
    isRepeatWeekly: boolean,
    repeatDays: IRepeatDays[],
    // FORM
    selectedPetId: string,
    selectedStartDate: null | Date,
    selectedEndDate: null | Date,
    selectedShiftTime: string,
    selectedSubService: null | Price,
    // GROOMING FIELDS
    selectedGroomingTime: string,
    selectedRepeatEvery: string,
    selectedTransportaion: {
        checked: boolean,
        service: null | Price
    },
    selectedMedication: {
        checked: boolean,
        service: null | Price
    },
    error: {
        selectedPetId: string,
        selectedStartDate: string,
        selectedEndDate: string,
        selectedShiftTime: string,
        repeatDays: string,
        selectedGroomingTime: string,
        selectedRepeatEvery: string,
    },
    // MODALS
    openSimpleModal: boolean,
    messageSimpleModal: string,
    openCapacityModal: boolean,
    isGalleryOpen:boolean,
    // API  
    isDetailsLoading: boolean,
    getDetailsData: IDetailsResponse,
    petList: ICustomerPet[],
    createBookingLoading: boolean,
    //ViewMap
    searchItems:SearchItem[],
    open:boolean,
    mapCenter:{lat:number,lng:number};
    shouldRecenterinfo:boolean;
    enableModal:boolean;
    lowestPrice:number
    //VIEW IMAGE
    viewImgId:string
    //SEARCH QUERY
    searchQuery: SearchData;
    isAdvancePage: boolean
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class BookingController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getDetailsApiCallId: string = "";
    getPetListApiCallId: string = "";
    createBookingApiCallId: string = "";
    //ViewMap
    mapInstance: google.maps.Map | null = null;
    //Ref
    scrollContainerRef = createRef<HTMLDivElement>();
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            apiToken: localStorage.getItem("login_token") || "",
            tabValue: Number(localStorage.getItem('searchTab')) || 0,
            headerData: initialHeaderData,
            // BOOKING SUMMARY
            isRepeatWeekly: false,
            repeatDays: initialRepeatDays,
            // FORM
            selectedPetId: "",
            selectedStartDate: new Date(),
            selectedEndDate: null,
            selectedShiftTime: "",
            selectedSubService: null,
            selectedGroomingTime: "",
            selectedRepeatEvery: "1",

            selectedTransportaion: {
                checked: false,
                service: null
            },
            selectedMedication: {
                checked: false,
                service: null
            },
            error: {
                selectedPetId: "",
                selectedStartDate: "",
                selectedEndDate: "",
                selectedShiftTime: "",
                repeatDays: "",
                selectedGroomingTime: "",
                selectedRepeatEvery: "",
            },
            // MODALS
            openSimpleModal: false,
            messageSimpleModal: "",
            openCapacityModal: false,
            isGalleryOpen:false,
            // API
            getDetailsData: {} as IDetailsResponse,
            isDetailsLoading: false,
            petList: [],
            createBookingLoading: false,
            //ViewMap
            searchItems:[],
            open:false,
            mapCenter:{lat:0,lng:0},
            shouldRecenterinfo:false,
            enableModal:false,
            lowestPrice:0,
            //VIEW IMAGE
            viewImgId:"",
            //SEARCH QUERY
            searchQuery: {},
            isAdvancePage: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {

            const navigationData = message.getData(
                getName(MessageEnum.NavigationPayLoadMessage)
            );
            if (navigationData && navigationData.searchFilterData) {
                const { city, country, start_date, end_date, your_pet, service_type } = navigationData.searchFilterData
                const startDate = checkCondition(Boolean(start_date),start_date,null) as Date | null;
                const endDate = checkCondition(Boolean(end_date), end_date, null) as Date | null;
                const endAfter = checkCondition(Boolean(navigationData.searchFilterData?.repeatWeekly && navigationData.searchFilterData?.endAfter), navigationData.searchFilterData?.endAfter , null) as Date | null
                const finalEndDate = checkCondition((service_type as string).startsWith(webConfigJSON.SERVICES_ENUM.HOTELS), endDate, endAfter) as Date | null
                const everyDay = navigationData.searchFilterData?.everyDay
                const copyInitialDay = [...initialRepeatDays]
                const selectedRepeatDays = copyInitialDay.map(day => ({
                    ...day,
                    checked: everyDay.includes(day.label)
                }));
                const petId = your_pet.split(' ')[1];

                const data = {
                    "selectedCountry": country,
                    "selectedCity": city,
                    "SelectedStartDate": start_date,
                    "SelectedEndDate": end_date,
                    "SelectedPet": your_pet
                }
                
                const searchData: SearchData = {
                    country: country,
                    city: city,
                    start_date: start_date,
                    end_date: end_date,
                    your_pet: your_pet,
                    everyDay: navigationData.searchFilterData.everyDay,
                    endAfter: navigationData.searchFilterData.endAfter,
                    service_type: service_type,
                    repeatWeekly: Boolean(navigationData.searchFilterData?.repeatWeekly)
                }

                this.setState({
                    headerData: data,
                    tabValue: getTabValue(service_type),
                    selectedStartDate: startDate,
                    selectedEndDate: finalEndDate,
                    repeatDays: selectedRepeatDays,
                    isRepeatWeekly: Boolean(navigationData.searchFilterData?.repeatWeekly),
                    selectedPetId:returnTruthyString(petId),
                    searchQuery: searchData,
                    isAdvancePage: navigationData.isAdvanceSearch
                })
            }
            if (navigationData && navigationData.landingPageTabValue) {
                this.setState({ tabValue: navigationData.landingPageTabValue as number})
                //viewMap
                this.setState({ lowestPrice: navigationData.lowestPrice as number})
            }
           //ViewMap
           this.getLowestPrice(navigationData)
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.getDetailsApiCallId:
                        this.getDetailsApiResp(responseJson);
                        break;
                    case this.getPetListApiCallId:
                        this.getPetListApiResp(responseJson);
                        break;
                    case this.createBookingApiCallId:
                        this.createBookingApiResp(responseJson);
                        break;
                    default:
                        break;
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        this.setState({ tabValue: Number(newValue) });
        this.goTOHomePage(newValue);
    };

    goToAdvancedSearchPage = (data: SearchData) => {
        const NavigateMsg: Message = new Message(getName(MessageEnum.NavigationMessage))
        NavigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), 'AdvancedSearch');

        // PASS DATA
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), data);

        NavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        NavigateMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(NavigateMsg);
    }

    updateHeaderData = () => {
        const savedData = sessionStorage.getItem("searchData");
        const parsedData = savedData ? JSON.parse(savedData) : undefined;
        if(parsedData) {
            const everyDay = parsedData.everyDay
            const copyInitialDay = [...initialRepeatDays]
            const selectedRepeatDays = copyInitialDay.map(day => ({
                ...day,
                checked: everyDay.includes(day.label)
            }));
            const data = {
                "selectedCountry": parsedData.country,
                "selectedCity": parsedData.city,
                "SelectedStartDate": moment(parsedData.start_date).toDate(),
                "SelectedEndDate": parsedData.end_date? moment(parsedData.end_date).toDate() : null,
                "SelectedPet": parsedData.your_pet
            }
            this.setState({
                headerData: data,
                tabValue: getTabValue(parsedData.service_type),
                selectedStartDate: moment(parsedData.start_date).toDate(),
                selectedEndDate: parsedData.end_date? moment(parsedData.end_date).toDate() : null,
                repeatDays: selectedRepeatDays,
                isRepeatWeekly: Boolean(parsedData.repeatWeekly),
                selectedPetId: returnTruthyString(parsedData.your_pet.split(' ')[1])
            })
        }
        
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getDetailsApiCall();
        this.getUserPetList();
        this.updateHeaderData();
        window.addEventListener("popstate", this.handlePopState);
        window.history.pushState(null, "", window.location.href);
    };

    handlePopState = () => {
        if (this.state.isAdvancePage) {
            this.setState({ isAdvancePage: false })
            this.goToAdvancedSearchPage(this.state.searchQuery)
        }
    };

    apiCall = async (data: IApiModel) => {
        const {
            contentType,
            method,
            endPoint,
            body,
            token,
            isJsonStringify,
        } = data;
        let header;
        if (token) {
            header = {
                "Content-Type": contentType,
                token: token,
            };
        } else {
            header = {
                "Content-Type": contentType,
            };
        }
        const requestedMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestedMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestedMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestedMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestedMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                isJsonStringify ? JSON.stringify(body) : body
            );
        runEngine.sendMessage(requestedMessage.id, requestedMessage);
        return requestedMessage.messageId;
    };

    getDetailsApiCall = async () => {
        const serviceId = this.props.navigation.getParam("navigationBarTitleText")
        { this.setState({ isDetailsLoading: true }) }
        this.getDetailsApiCallId = await this.apiCall({
            method: webConfigJSON.validationApiMethodType,
            endPoint: `${webConfigJSON.ENDPOINTS.GET_SERVICE_DETAILS}/${serviceId}`,
            token: this.state.apiToken,
        });
    };
    getUserPetList = async () => {
        this.getPetListApiCallId = await this.apiCall({
            method: webConfigJSON.API_METHOD.GET,
            endPoint: webConfigJSON.ENDPOINTS.GET_PET_LIST,
            token: this.state.apiToken,
        });
    };

    createBooking = async () => {
        this.setState({ createBookingLoading: true })
        const bodyData = this.getBodyData()
        this.createBookingApiCallId = await this.apiCall({
            method: webConfigJSON.API_METHOD.POST,
            endPoint: webConfigJSON.ENDPOINTS.CREATE_BOOKING,
            token: this.state.apiToken,
            body: bodyData
        });
    };

    getDetailsApiResp = (responseJson: IDetailsResponse) => {
        { this.setState({ isDetailsLoading: false }) }
        if (responseJson.data && responseJson.data.attributes && !responseJson.errors) {
            const serviceType = responseJson.data.attributes.service_type
            const isEnable = [webConfigJSON.SERVICES_ENUM.DAY_CARE, webConfigJSON.SERVICES_ENUM.DOG_WALKING].includes(serviceType)
            this.setState({ 
                getDetailsData: responseJson,
                isRepeatWeekly: checkCondition(isEnable, isEnable, this.state.isRepeatWeekly) as boolean
            })
            //viewMap
            this.setState({mapCenter:{
                lat:this.state.getDetailsData.data.attributes.establishment.latitude,
                lng:this.state.getDetailsData.data.attributes.establishment.longitude
            }})
        }
        if (responseJson.errors) {
            this.simpleModalOpen(responseJson.errors)
            this.resetStates()
        }
    }
    getPetListApiResp = (responseJson: IPetListResp) => {
        if (responseJson && responseJson.data && responseJson.data.length) {
            this.setState({
                petList: responseJson.data
            })
        } else {
            this.setState({
                petList: []
            })
        }
    }
    createBookingApiResp = (responseJson: ICreateBookingResponse) => {
        this.setState({ createBookingLoading: false })
        if (responseJson && responseJson.meta && responseJson.data && !responseJson.errors) {
            const bookingId = returnTruthyString(responseJson.meta.first_schedule_id) || returnTruthyString(responseJson.meta.schedule_id)
            this.goToCheckoutPage(bookingId)
        }
        if (responseJson.errors) {
            this.handleBookingErrors(responseJson.errors)
        }
    }
    goToCheckoutPage = (bookingId: string) => {
        if (!bookingId) return
        const NavigateMsg: Message = new Message(getName(MessageEnum.NavigationMessage));
        NavigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "BookingCheckout"); // ROUTE
        NavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        NavigateMsg.addData(getName(MessageEnum.NavigationScreenNameMessage), bookingId); // PARAM
        this.send(NavigateMsg);
    }

    handleBookingErrors = (errorMsg: string) => {
        if (errorMsg.startsWith(webConfigJSON.DEFAULT_MSG.CAPACITY_FULL_ERROR)) {
            this.handleOpenCapacityModal()
            return
        }
        this.simpleModalOpen(errorMsg)
    } 
    resetStates = () => {
        this.setState({
            // BOOKING SUMMARY
            isRepeatWeekly: false,
            repeatDays: initialRepeatDays,
            // FORM
            selectedPetId: "",
            selectedStartDate: new Date(),
            selectedEndDate: null,
            selectedShiftTime: "",
            selectedSubService: null,
            selectedMedication: {
                checked: false,
                service: null
            },
            selectedTransportaion: {
                checked: false,
                service: null
            },
            error: {
                selectedPetId: "",
                selectedStartDate: "",
                selectedEndDate: "",
                selectedShiftTime: "",
                repeatDays: "",
                selectedGroomingTime: "",
                selectedRepeatEvery: "",
            },
        })
    }
    getComplementaryService = () => {
        const subServicesWithPrices = this.state.getDetailsData?.data?.attributes?.sub_services_with_prices || [];
        const complementaryServices = subServicesWithPrices.flatMap((subService) =>
            subService.prices ? subService.prices.filter((price) => webConfigJSON.COMPLEMENTARY_SERVICE.includes(price.title)) : []
        );
        return complementaryServices;
    };

    convertCommoditiesAttributes = (
        attributes: ICommodities,
    ) => {
        return Object.entries(attributes)
            .filter(([, value]) => value === true)
            .map(([key, value],) => ({
                label: key.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase()),
                key: key,
                value: value,
            }));
    };

    getCommoditiesList = () => {
        const attribute = this.state.getDetailsData?.data?.attributes;
        if (attribute) {
            const {
                pool,
                outdoor_hotel,
                veterinary_support,
                indoor_hotel,
                toys_at_display,
                litter_boxes,
                fresh_water,
                natural_food,
                poop_spaces,
                comfortable_beds,
                individual_room,
                cat_trees,
                daily_walks,
                group_rooms,
                catio,
                garden,
                socializing_activities,
            } = attribute;

            const commodities = {
                pool,
                outdoor_hotel,
                veterinary_support,
                indoor_hotel,
                toys_at_display,
                litter_boxes,
                fresh_water,
                natural_food,
                poop_spaces,
                comfortable_beds,
                individual_room,
                cat_trees,
                daily_walks,
                group_rooms,
                catio,
                garden,
                socializing_activities,
            };
            return this.convertCommoditiesAttributes(commodities);
        }
        return []
    }

    serviceName = () => returnTruthyString(this.state.getDetailsData?.data?.attributes?.service_type)

    // SET FORM VALUES
    setSubService = (subServicePrice: Price | null) => {
        this.setState({
            selectedSubService: subServicePrice
        })
    }
    setPetId = (petId: string | number) => {
        this.setState({
            selectedPetId: String(petId),
            error: {
                ...this.state.error,
                selectedPetId: checkCondition(isValidValue(petId), "", this.transScheduling(webConfigJSON.FIELDS_ERROR.PET)) as string
            }
        })
    }
    setStartEndDate = (date: Date | null, start_end: "start" | "end") => {
        if (start_end == "start") {
            this.setState({
                selectedStartDate: date,
                selectedEndDate: null,
                error: {
                    ...this.state.error,
                    selectedStartDate: checkCondition(isValidValue(date), "", this.transScheduling(webConfigJSON.FIELDS_ERROR.START_DATE)) as string,
                    selectedEndDate: ""
                }
            })
        } else {
            this.setState({
                selectedEndDate: date,
                error: {
                    ...this.state.error,
                    selectedEndDate: checkCondition(isValidValue(date), "", this.transScheduling(webConfigJSON.FIELDS_ERROR.END_DATE)) as string
                }
            })
        }
    }
    setShiftTime = (shiftTime: string) => {
        this.setState({
            selectedShiftTime: shiftTime,
            error: {
                ...this.state.error,
                selectedShiftTime: checkCondition(isValidValue(shiftTime), "", this.transScheduling(webConfigJSON.FIELDS_ERROR.SHIFT_TIME)) as string
            }
        })
    }
    setGroomingTime = (timeValue: string) => {
        this.setState({
            selectedGroomingTime: timeValue,
            error: {
                ...this.state.error,
                selectedGroomingTime: checkCondition(isValidValue(timeValue), "", this.transScheduling(webConfigJSON.FIELDS_ERROR.TIME_VALUE)) as string
            }
        })
    }
    setRepeatEvery = (weekValue: string) => {
        this.setState({
            selectedRepeatEvery: weekValue,
        })
    }

    setComplementaryService = (checkedValue: boolean, serviceType: "medication" | "transportation", service: Price) => {
        if (serviceType == "medication") {
            if (checkedValue) {
                this.setState({
                    selectedMedication: {
                        checked: checkedValue,
                        service: service
                    }
                })
            } else {
                this.setState({
                    selectedMedication: {
                        checked: false,
                        service: null
                    }
                })
            }
        }
        if (serviceType == "transportation") {
            if (checkedValue) {
                this.setState({
                    selectedTransportaion: {
                        checked: checkedValue,
                        service: service
                    }
                })
            } else {
                this.setState({
                    selectedTransportaion: {
                        checked: false,
                        service: null
                    }
                })
            }
        }
    }
    handleRepeatBtn = () => {
        this.setState({
            isRepeatWeekly: !this.state.isRepeatWeekly
        })
        if (!this.state.isRepeatWeekly) {
            this.setState({
                repeatDays: initialRepeatDays,
                selectedEndDate: null,
                selectedRepeatEvery: "1",
                error: {
                    ...this.state.error,
                    repeatDays: "",
                    selectedEndDate: "",
                    selectedRepeatEvery: "",
                }
            })
        }
    }
    setRepeatDay = (dayIndex: number) => {
        this.setState((prevState) => ({
            repeatDays: prevState.repeatDays.map((day, index) =>
                index === dayIndex ? { ...day, checked: !day.checked } : day
            ),
        }), () => {
            // CHECK IF ANY DAY SELECTED AND SET ERROR
            const isAnyDayChecked = this.state.repeatDays.find(day => day.checked);
            this.setState({
                error: {
                    ...this.state.error,
                    repeatDays: checkCondition(Boolean(isAnyDayChecked), "", this.transScheduling(webConfigJSON.FIELDS_ERROR.REPEAT_DAYS)) as string,
                }
            })
            const needToValidateRepeat = [webConfigJSON.SERVICES_ENUM.DAY_CARE, webConfigJSON.SERVICES_ENUM.DOG_WALKING].includes(this.serviceName())
            if(needToValidateRepeat) { this.validateRepeatDays()}
        });
    }

    onSave = () => {
        const { selectedSubService } = this.state
        if (!localStorage.getItem("isUserLogin")) {
            this.props.navigation.navigate("EmailAccountLoginBlock");
            return
        }
        
        if (!selectedSubService) {
            this.simpleModalOpen(this.transScheduling(webConfigJSON.DEFAULT_MSG.SELECT_SUB_SERVICE_MSG))
            return
        }
        const needToValidateRepeat = [webConfigJSON.SERVICES_ENUM.DAY_CARE, webConfigJSON.SERVICES_ENUM.DOG_WALKING].includes(this.serviceName())
        const isRepeatDaysValidated = needToValidateRepeat ? this.validateRepeatDays() : true

        const validationPassed = this.checkValidation() && isRepeatDaysValidated
        if (validationPassed) {
            this.createBooking()
        }
    }
    validateRepeatDays = () => {
        const { isRepeatWeekly, repeatDays, selectedSubService } = this.state

        if (isRepeatWeekly && selectedSubService?.id) {
            const checkedDaysList = repeatDays.filter(day => day.checked);
            const totalCheckedDays = checkedDaysList.length

            // CHECK NUMBER OF DAYS ARE SAME AS SELECTED RECURRENCE
            const isValidated = (webConfigJSON.RECURRENCE_KEY)[totalCheckedDays - 1] === selectedSubService.title
            if (!isValidated) {
                const customMsg = `${this.transScheduling("Please select the same number of days as you have selected")} ${this.transScheduling(selectedSubService.title?.toLowerCase())}.`
                this.simpleModalOpen(customMsg)
            }
            return isValidated
        }
    }
    checkValidation = () => {
        const { 
            selectedPetId, 
            selectedStartDate, 
            selectedEndDate,
            selectedShiftTime, 
            isRepeatWeekly, 
            repeatDays, selectedGroomingTime, selectedRepeatEvery
        } = this.state
        const serviceName = this.serviceName()
        // IS GROOMING
        const groomingService = [webConfigJSON.SERVICES_ENUM.GROOMING]
        const isServiceGrooming = groomingService.includes(serviceName)

        // CHECK IF ANY DAY SELECTED
        const isAnyDayChecked = !!repeatDays.find(day => day.checked);
        
        let validateRepeatDays = ""
        if (isRepeatWeekly && !isAnyDayChecked) {
            validateRepeatDays = webConfigJSON.FIELDS_ERROR.REPEAT_DAYS
        }

        let validateEndDate = ""
        if (serviceName == webConfigJSON.SERVICES_ENUM.HOTELS || isRepeatWeekly) {
            validateEndDate = checkCondition(isValidValue(selectedEndDate), "", this.transScheduling(webConfigJSON.FIELDS_ERROR.END_DATE)) as string
        }

        let validateShiftTime = ""
        if (serviceName == webConfigJSON.SERVICES_ENUM.DOG_WALKING) {
            validateShiftTime = checkCondition(isValidValue(selectedShiftTime), "", this.transScheduling(webConfigJSON.FIELDS_ERROR.SHIFT_TIME)) as string
        }

        let validateGroomingTime = ""
        if (isServiceGrooming) {
            validateGroomingTime = checkCondition(isValidValue(selectedGroomingTime), "", this.transScheduling(webConfigJSON.FIELDS_ERROR.TIME_VALUE)) as string
        }
        let validateRepeatEvery = ""
        if (isRepeatWeekly) {
            validateRepeatEvery = checkCondition(isValidValue(selectedRepeatEvery), "", this.transScheduling(webConfigJSON.FIELDS_ERROR.REPEAT_EVERY)) as string
        }

        let errorObj = {
            selectedPetId: checkCondition(isValidValue(selectedPetId), "", this.transScheduling(webConfigJSON.FIELDS_ERROR.PET)) as string,
            selectedStartDate: checkCondition(isValidValue(selectedStartDate), "", this.transScheduling(webConfigJSON.FIELDS_ERROR.START_DATE)) as string,
            selectedEndDate: validateEndDate,
            selectedShiftTime: validateShiftTime,
            repeatDays: validateRepeatDays,
            selectedGroomingTime: validateGroomingTime,
            selectedRepeatEvery: validateRepeatEvery,
        }
        this.setState({
            error: errorObj
        })

        const isAllPass = Object.values(errorObj).every(value => value == "");
        return isAllPass
    }
    getBodyData = () => {
        const {
            selectedPetId,
            selectedStartDate,
            selectedEndDate,
            selectedShiftTime,
            selectedSubService,
            selectedMedication,
            selectedTransportaion,
            isRepeatWeekly,
            repeatDays,
            selectedGroomingTime,
            selectedRepeatEvery
        } = this.state
        const serviceName = this.serviceName()

        const formatedStartDate = moment(selectedStartDate).format('YYYY-MM-DD') 
        const formData =  new FormData()
        
        formData.append("schedule[sub_services_price_id]", returnTruthyString(selectedSubService?.id));
        formData.append("schedule[pet_id]", selectedPetId);
        formData.append("schedule[start_date]", formatedStartDate);

        if (serviceName == webConfigJSON.SERVICES_ENUM.HOTELS || isRepeatWeekly) {
            const formatedEndDate = moment(selectedEndDate).format('YYYY-MM-DD')
            formData.append("schedule[end_date]", formatedEndDate);
        }

        if (serviceName == webConfigJSON.SERVICES_ENUM.DOG_WALKING) {
            formData.append("schedule[shift]", selectedShiftTime);
        }

        if (selectedMedication.checked && selectedMedication.service !== null && selectedMedication.service.price) {
            formData.append("schedule[medication]", returnTruthyString(selectedMedication.service.price));
        }

        if (selectedTransportaion.checked && selectedTransportaion.service !== null && selectedTransportaion.service.price) {
            formData.append("schedule[transportation]", returnTruthyString(selectedTransportaion.service.price));
        }

        if (isRepeatWeekly) {
            // FILTER CHECKED DAYS
            const checkedDays = repeatDays.filter(day => day.checked);
            checkedDays.forEach((eachDay) => {
                formData.append("schedule[week_days][]", eachDay.value);
            })
            if(checkedDays.length){
                formData.append("recurrence_day", checkedDays.length.toString());
                formData.append("schedule[repeat_weekly]", "true");
            }
        }
        // GROOMING FIELDS
        const groomingService = [webConfigJSON.SERVICES_ENUM.GROOMING]
        const isServiceGrooming = groomingService.includes(serviceName)
        if (isServiceGrooming && selectedGroomingTime) {
            formData.append("schedule[start_time]", selectedGroomingTime);
        }
        if (isServiceGrooming && isRepeatWeekly && selectedRepeatEvery) {
            formData.append("schedule[repeat_every]", selectedRepeatEvery);
        }
        
        return formData

    }
    calculateHotelNights = () => {
        const { selectedStartDate, selectedEndDate } = this.state
        const serviceName = this.serviceName()
        if ((serviceName === webConfigJSON.SERVICES_ENUM.HOTELS) && selectedStartDate && selectedEndDate) {
            const noOfDays = getDayDifference(selectedStartDate, selectedEndDate)
            return noOfDays
        }
    }
    calculateTotalPrice = () => {
        const { selectedSubService } = this.state

        let total = 0;
        if (selectedSubService) {
            let SubServicePrice = Number(selectedSubService.price)
            const noOfNights = this.calculateHotelNights()
            if (noOfNights) {
                SubServicePrice = (SubServicePrice) * (noOfNights)
            }
            total = total + SubServicePrice
        }
        total = total + this.calculateMedication() + this.calculateTransportation()
        return total
    }

    calculateMedication = () => {
        const { selectedMedication } = this.state
        if (selectedMedication.checked && selectedMedication.service !== null) {
            let medication_price = Number(selectedMedication.service.price)
            return medication_price
        }
        return 0
    }
    calculateTransportation = () => {
        const { selectedTransportaion } = this.state
        if (selectedTransportaion.checked && selectedTransportaion.service !== null) {
            let transportation_price = Number(selectedTransportaion.service.price)
            return transportation_price
        }
        return 0
    }
    selectedRepeatDays = () => {
        const { isRepeatWeekly, repeatDays } = this.state
        const checkedDays = repeatDays.filter(day => day.checked);
        if (isRepeatWeekly && checkedDays.length) {
            return checkedDays
        }
        return []
    }

    simpleModalOpen = (message: string) => {
        this.setState({
            openSimpleModal: true,
            messageSimpleModal: message
        })
    }
    simpleModalClose = () => {
        this.setState({
            openSimpleModal: false,
            messageSimpleModal: ""
        })
    }

    handleOpenCapacityModal = () => {
        this.setState({
            openCapacityModal: true,
        })
    }
    handleCloseCapacityModal = () => {
        this.setState({
            openCapacityModal: false,
        })
    }
    getRepeatDayDisable = (day: IRepeatDays) => {
        let disable = true

        const ScheduleInfo = this.state.getDetailsData?.data?.attributes?.schedule_informations
        if (ScheduleInfo && ScheduleInfo.length) {
            const matchingRecord = ScheduleInfo.find((daySchedule) => daySchedule.day == day.value)
            if (matchingRecord) {
                disable = false
            }
        }
        return disable
    } 
    //View map
    navigateToPage = (targetPage: string) => {
        const userNavMsg: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), targetPage);
        userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(userNavMsg);
    };
    onMapLoad = (mapInstance: google.maps.Map) => {
        this.mapInstance = mapInstance; // Save map instance reference
    };

    handleCloseInfoWindow = () => {
        this.setState({
        }, () => {
            this.setState({ shouldRecenterinfo: false })
        })

    };
    handleMarkerClick = (marker: any) => {
        this.setState({}, () => {
            this.mapInstance?.panTo({
                lat: marker.lat,
                lng: marker.lat,
            });
            this.setState({ shouldRecenterinfo: !this.state.shouldRecenterinfo })
        });
    };
    handleOpen = () => {
        this.setState({ open: !this.state.open, enableModal: true, shouldRecenterinfo: true });
    };
    handleCloseModal(reason: string) {
        if (reason === 'backdropClick') {
            return;
        }
        return this.setState({ open: !this.state.open, enableModal: false })
    }

    handleViewMore = () => {
        this.setState({ isGalleryOpen: true })
    }

    handleCloseViewMore = () => {
        this.setState({ isGalleryOpen: false })
    }
    getLowestPrice(navigationData: any) {
        if (navigationData && navigationData.lowestPrice) {
            this.setState({ lowestPrice: navigationData.lowestPrice as number })
        }
    }

    getServiceGallery = () => {
        const attr = this.state.getDetailsData?.data?.attributes;
        const combinedGalleries = [
          ...(attr?.service_galleries_urls || []).filter(gallery => gallery.file_name !== "no_image.png"),
          ...(attr?.establishment_galleries_urls || []).filter(gallery => gallery.file_name !== "no_image.png").map(gallery => ({
            image_url: gallery.file_url || ""
          })),
        ];
        return combinedGalleries;
    }

    goTOHomePage = (newValue:string) => {
        const NavigateMsg: Message = new Message(getName(MessageEnum.NavigationMessage));
        NavigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Home"); // ROUTE
        NavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {
            landingPageTabValue: newValue,
        });
        NavigateMsg.addData(
            getName(MessageEnum.NavigationRaiseMessage),
            raiseMessage
        );
        this.send(NavigateMsg);
    };

    transScheduling = (schedulingKey: string) => {
        return i18n.t(schedulingKey, { ns: "scheduling" });
    }

    handleServiceImgClick = (imgId:string) => {
        this.setState({viewImgId:imgId})
    }

    handleBackSliderClick = () => {
        this.setState({viewImgId:""})
    }
    
    getMinPrice = () => {
        const priceList = this.state.getDetailsData?.data?.attributes?.sub_services_with_prices;
        const filteredPrices = priceList
            .flatMap(service => service.prices)
            .filter(
                price => price.title !== webConfigJSON.MEDICATION_SERVICE && price.title !== webConfigJSON.TRANSPORTATION_SERVICE
            ).map(price => price.price);
        const minPrice = Math.min(...filteredPrices);
        return minPrice;
    }

    componentDidUpdate(prevProps: Props, prevState: S) {
        if (prevState.getDetailsData !== this.state.getDetailsData) {
            scrollToSection("mainHeader");
        }
    }

    async componentWillUnmount(): Promise<void> {
        window.removeEventListener("popstate", this.handlePopState);
    }
    
    // Customizable Area End
}

