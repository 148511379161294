import React from 'react';
import { Box, Button, Typography } from "@material-ui/core"
import Field from "./Field"
import { Request, Shedule } from "../CustomisableUserProfilesController"
import { RequestYellow } from '../assets';
import moment from "moment";
import { LoaderButton } from "../../../../components/src/calendarWeb/LoaderButton";
import DaySelect from './ChangeRequestModals/DaySelect';
import { transPt } from '../../../../components/src/HelperUtils';
const configJSON = require("../config");

type Props = {
    request: Request | null;
    recurranceData:any;
    selectedEvent: Shedule;
    displayFields: Record<any, boolean>;
    changeRequestStatus: (type: 'rejected' | 'accepted') => void;
    isLoading: boolean;
}

const DisplayRequestReccurence = ({ request,recurranceData, selectedEvent, displayFields, changeRequestStatus, isLoading }: Props) => {
    const mockProps = {
        event: {...selectedEvent, week_days: request?.week_days
            || [] }, 
        values: {}, 
        touched: {}, 
        errors: {}, 
        setFieldValue: (field: string, value: any) => Promise.resolve(), 
        isDisabled: true,
      };

    const displayList = ['Dog Walking'];

    if(!request || request.request_type !== 'Reschedule Request' || request.status !== 'pending') {
        return null;
    }

    const getReccurenceLabel = (value: number | string | undefined) => {
        if(value) {
            const element = configJSON.reccurenceList.find((item: any) => item.title === value);
            if(element) {
                return element.title;
            }
        }
    }

    const getTitleById = (id: number) => {
        const service = recurranceData.find((item: { id: number; }) => item.id === id);
        return service ? service.title : 'Not Found';
    }

    return (
        <Box className='section-wrapper' data-test-id="page-wrapper" style={{ background: '#fff'}}>
            <Box className='section-header'>
                <img src={RequestYellow} />
                <Typography className='section-text' style={{ color: '#D4AD2D'}}>{`${transPt("Change Request","customerUserProfile")} ${request.created_by === 'customer' ? transPt("Sent","customerUserProfile") : transPt("Received","customerUserProfile")}`}</Typography>
            </Box>
            <Box className='fields-wrapper'>
                <Box className='field-set'>
                    <Field title={transPt("Updated Start Date","customerUserProfile")} value={moment(request.start_date).format("DD/MM/YYYY")} isYellow />
                    {!displayList.includes(selectedEvent.service.service_type) && displayFields.reccurence && <Field title={transPt("Updated Recurrence","customerUserProfile")} value={getReccurenceLabel(request.recurrences)} isYellow />}
                    {displayList.includes(selectedEvent.service.service_type) && displayFields.time && <Field title={transPt("Updated Time","customerUserProfile")} value={request.shift} isYellow />}
                    {displayFields.timeGrooming && <Field title={transPt("Updated Time","customerUserProfile")} value={moment(request.start_time, "HH:mm").format("hh:mm A")} isYellow /> }
                    {displayFields.days && <DaySelect data-test-id="dayselect" recurranceData={undefined} responseData={undefined} isYellow {...mockProps} label={transPt("Day Of The Week", "customerUserProfile")}/>}
                </Box>
                <Box className='field-set'>
                    <Field title={transPt("Updated End Date","customerUserProfile")} value={moment(request.end_date).isValid() ? moment(request.end_date).format("DD/MM/YYYY") : '---'} isYellow />
                    {selectedEvent.service.service_type === 'Grooming' && displayFields.every && <Field title={transPt("Reccurance","customerUserProfile")} value={`${transPt("Every","customerUserProfile")} ${request.repeat_every} ${transPt("week","customerUserProfile")}`} isYellow />}
                    {selectedEvent.service.service_type === 'Dog Walking' && displayFields.reccurence && <Field title={transPt("Updated Recurrence","customerUserProfile")} 
                    value={getReccurenceLabel(getTitleById(selectedEvent.request_details[0]?.sub_services_price_id))} isYellow />}
                </Box>

            </Box>
            {request.created_by === 'vendor' && 
                <Box className='req-buttons-wrapper'>
                    <Button data-test-id="reject-btn" className='reject-btn' onClick={() => changeRequestStatus('rejected')} disabled={isLoading}>
                        <LoaderButton color="#C10000" size="18px" text={transPt("Reject","customerUserProfile")} isLoading={isLoading} />
                    </Button>
                    <Button data-test-id="accept-btn" className='accept-btn' onClick={() => changeRequestStatus('accepted')} disabled={isLoading}>
                        <LoaderButton color="#6F05EA" size="18px" text={transPt("Accept","customerUserProfile")} isLoading={isLoading} />
                    </Button>
                </Box>
            }
        </Box>  
    )
}

export default DisplayRequestReccurence;