
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { getStorageData } from "../../../../framework/src/Utilities";
import { APIModal } from "./ServiceDashboardController.web";
import { IFilteredService } from "../../../../components/src/interfaces.web";
import i18n from "../../../../components/src/i18next/i18n";

const webConfigJSON = require("../config.js");

interface IBookingDetailsData {
    client_name: string,
    pet_name: string,
    medication: string,
    transportation: string,
    shift: string,
    duration: string,
    price: string,
    total_price: string,
    day_count: number
}
interface IBookingDetailsResp {
    data: IBookingDetailsData,
    meta: {
        message: string
    }
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    handleClose: () => void;
    bookingDetailsProp: {
        selectedService: IFilteredService;
        scheduleId: string
    }
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    //LOADER
    isBookingDetailsLoading: boolean,
    //RESPONSE
    bookingDetailsInfo:IBookingDetailsData
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class BookingDetailsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
        apiBookingDetailsCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            //LOADER
            isBookingDetailsLoading: false,
            //RESPONSE
            bookingDetailsInfo: {} as IBookingDetailsData
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.apiBookingDetailsCallId) {
                this.handleBookingDetailsResp(responseJson)
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        this.getBookingDetails();
    }

    apiCall = async (data: APIModal) => {
        const { contentType, method, endPoint, body, token, formData } = data;
        let header;
        header = {
            "Content-Type": contentType,
        };
        if (token) {
            header = {
                "Content-Type": contentType,
                token: token,
            };
        }

        const apiRequestedMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        apiRequestedMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequestedMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        apiRequestedMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            apiRequestedMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );

        formData &&
            apiRequestedMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formData
            );
        runEngine.sendMessage(apiRequestedMessage.id, apiRequestedMessage);
        return apiRequestedMessage.messageId;
    };

    getBookingDetails = async () => {
        const { selectedService, scheduleId } = this.props.bookingDetailsProp;
        const apiToken = await getStorageData("login_token");
        const establishment_ID = await getStorageData("establishment_ID");
        this.setState({ isBookingDetailsLoading: true });
        this.apiBookingDetailsCallId = await this.apiCall({
            method: webConfigJSON.validationApiMethodType,
            endPoint: `${webConfigJSON.ENDPOINTS.GET_BOOKING_DETAILS_DATA}establishment_id=${establishment_ID}&service_id=${selectedService.service_id}&schedule_id=${scheduleId}`,
            token: apiToken,
        });
    };

    handleBookingDetailsResp = (responseJson: IBookingDetailsResp) => {
        this.setState({ isBookingDetailsLoading: false });
        if (responseJson && responseJson.data) {
            this.setState({ bookingDetailsInfo: responseJson.data })
        }
    }

    transDetails = (detailKey: string) => {
        return i18n.t(detailKey, { ns: "serviceDashboard" });
    }
    // Customizable Area End
}

