
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { APIModal } from "./ServiceDashboardController.web";
import { getStorageData } from "../../../../framework/src/Utilities";
import { IFilteredService } from "../../../../components/src/interfaces.web";
import i18n from "../../../../components/src/i18next/i18n";

const webConfigJSON = require("../config.js");

interface ICustomerAttr {
    "id": number,
    "activated": boolean,
    "country_code": number,
    "email": string,
    "first_name": string,
    "full_phone_number": string,
    "date_of_birth": string | null,
    "last_name": string,
    "phone_number": number,
    "type": string,
    "created_at": string,
    "updated_at": string,
    "device_id": string | null,
    "unique_auth_id": string,
    "nationality": string,
    "address": string | null,
    "language": string,
    "country": string,
    "city": string,
    "confirm_email": string | null,
    "company_name": string | null,
    "iban": string | null,
    "tax_number": string | null,
    "client_id": string | null,
    "zipcode": string | null,
    "facebook_url": string | null,
    "instagram_url": string | null,
    "linkedin_url": string | null,
    "tiktok_url": string | null,
    "extra_details": {
        "ISO": string,
        "role": string
    },
    "vendor_image": string
}

interface IPetAttr {
    "pet_category": string,
    "id": number,
    "name": string,
    "gender": string,
    "breed": string,
    "birthdate": string,
    "weight": string,
    "size": string,
    "account_id": number,
    "fur": string,
    "species": string | null,
    "created_at": string,
    "updated_at": string,
    "pet_photo": string,
}

interface IBookingHistoryAttr {
    "id": number,
    "shift": string | null,
    "start_date": string,
    "end_date": string | null,
    "price": string,
    "total_price": string,
    "repeat_every": string | null,
    "start_time": string | null,
    "week_days": string[],
    "repeat_weekly": boolean,
    "status": string | null,
    "created_at": string,
    "service": {
        "id": number,
        "service_type": string
    },
    "sub_service": {
        "id": number,
        "title": string
    },
    "customer": {
        "id": number,
        "name": string,
        "email": string,
        "full_phone_number": string
    },
    "appointment_status": string
}

interface ICustomer {
    first_name: string,
    last_name: string;
    language: string,
    nationality: string,
    full_phone_number: string,
    date_of_birth: string | null,
    address: string | null,
    country: string,
    city: string,
    zipcode: string | null,
    customer_img: string | null
}

interface ICustomerResp {
    customer: ICustomerAttr;
    pets: IPetAttr[];
    booking_history: IBookingHistoryAttr[]
}

interface IHistory {
    service_date: string
    service_type: string
    customer_name: string
    customer_phone: string
    service_status: "Completed" | "Booked" | "Cancelled"
    service_charge: string
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    customerInfo: ICustomer,
    petInfo: IPetAttr[],
    historyInfo: IHistory[],
    serviceInfo:IFilteredService,
    //LOADER
    isLoading: boolean,
    // Customizable Area End
}
interface SS { }

// Customizable Area Start
// Customizable Area End

export default class ViewCustomerController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apiViewCustomerCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.AlertMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            customerInfo: {} as ICustomer,
            petInfo: [],
            historyInfo: [],
            serviceInfo:{} as IFilteredService,
            //LOADER
            isLoading: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const receivedData = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
            if (receivedData && receivedData.serviceInfo) {
                this.setState({ serviceInfo: receivedData.serviceInfo });
            }
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.apiViewCustomerCallId) {
                this.handleCustomerInfoResp(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    apiCall = async (data: APIModal) => {
        const { contentType, method, endPoint, body, token, formData } = data;
        let header;
        header = {
            "Content-Type": contentType,
        };
        if (token) {
            header = {
                "Content-Type": contentType,
                token: token,
            };
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );

        formData &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formData
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    async componentDidMount() {
        this.getCustomerInfo();
    }

    getCustomerInfo = async () => {
        const customerId = this.props.navigation.getParam("navigationBarTitleText");
        const apiToken = await getStorageData("login_token");
        this.setState({ isLoading: true });
        this.apiViewCustomerCallId = await this.apiCall({
            method: webConfigJSON.validationApiMethodType,
            endPoint: `${webConfigJSON.ENDPOINTS.GET_VIEW_CUSTOMER_DATA}${customerId}`,
            token: apiToken,
        });
    };

    handleCustomerInfoResp = (responseJson: ICustomerResp) => {
        this.setState({ isLoading: false })
        const { customer, pets, booking_history } = responseJson;
        if (responseJson && responseJson.customer) {
            const customerData: ICustomer = {
                first_name: customer.first_name,
                last_name: customer.last_name,
                language: customer.language,
                nationality: customer.nationality,
                full_phone_number: customer.full_phone_number,
                date_of_birth: customer.date_of_birth,
                address: customer.address,
                country: customer.country,
                city: customer.city,
                zipcode: customer.zipcode,
                customer_img: customer.vendor_image
            }
            this.setState({ customerInfo: customerData })
        }
        if (responseJson && responseJson.pets && responseJson.pets.length) {
            this.setState({ petInfo: pets })
        }
        if (responseJson && responseJson.booking_history && responseJson.booking_history.length) {
            const convertBookingList: IHistory[] = responseJson.booking_history.map((attributes) => ({
                service_date: attributes.start_date,
                service_type: attributes.service.service_type,
                customer_name: attributes.customer.name,
                customer_phone: attributes.customer.full_phone_number.toString(),
                service_status: attributes.appointment_status as "Completed" | "Booked" | "Cancelled",
                service_charge: attributes.total_price,
            }));
            this.setState({ historyInfo: convertBookingList })
        }
    }

    goToDashboard = () => {
        const NavigationData = {
            tabValue: 2,
            serviceInfo: this.state.serviceInfo
        }
        const NavigateMsg: Message = new Message(getName(MessageEnum.NavigationMessage));
        NavigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "CustomisableUserProfilesVender"); // ROUTE
        NavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), NavigationData); // DATA
        NavigateMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

        this.send(NavigateMsg);
    }

    goToPet = (petId: number) => {
        if (!petId) return
        const NavigateMsg: Message = new Message(getName(MessageEnum.NavigationMessage));
        NavigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ViewPet"); // ROUTE
        NavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        NavigateMsg.addData(getName(MessageEnum.NavigationScreenNameMessage), petId);
        this.send(NavigateMsg);
    }

    transViewCustomer = (cKey: string) => {
        return i18n.t(cKey, { ns: "serviceDashboard" });
    }
    // Customizable Area End
}

