export const enEstablishmentSetting = {
    //GeneralInfo
        "Contacts":"Contacts",
        "Phone":"Phone",
        "Social Media Link":"Social Media Link",
        "Email":"Email",
        //Placeholder
        "Establishment name":"Establishment name",
        "Facebook link":"Facebook link",
        "Instagram link":"Instagram link",
        "LinkedIn link":"LinkedIn link",
        "Tiktok link":"Tiktok link",
        //Error
        "*Please enter establishment name":"*Please enter establishment name",
        "*Please enter phone number":"*Please enter phone number",
        "*Please enter city":"*Please enter city",
        "*Please enter country":"*Please enter country",
        "*Please enter zipcode":"*Please enter zipcode",
        "*Please enter address":"*Please enter address",
        "*Please enter email":"*Please enter email",
    //Gallery
        "Please upload here images of the general space (inside and outsides).":"Please upload here images of the general space (inside and outsides).",
        "This gallery will appear like a preview of your establishment, so the user can get to know the general appearance and conditions of the space.":"This gallery will appear like a preview of your establishment, so the user can get to know the general appearance and conditions of the space.",
        "No images added yet.":"No images added yet.",
    //Button
        "Edit":"Edit",
        "Cancel":"Cancel",
        "Save":"Save",
        "Upload":"Upload",
}

export const ptEstablishmentSetting = {
    //GeneralInfo
        "Contacts":"Contatos",
        "Phone":"Telefone",
        "Social Media Link":"Link de mídia social",
        "Email":"E-mail",
        //Placeholder
        "Establishment name":"Nome do estabelecimento",
        "Facebook link":"Link do Facebook",
        "Instagram link":"Link do Instagram",
        "LinkedIn link":"Link do LinkedIn",
        "Tiktok link":"Link do TikTok",
        //Error
        "*Please enter establishment name":"*Por favor insira o nome do estabelecimento",
        "*Please enter phone number":"*Insira o número de telefone",
        "*Please enter city":"*Por favor insira a cidade",
        "*Please enter country":"*Por favor, insira o país",
        "*Please enter zipcode":"*Insira o CEP",
        "*Please enter address":"*Insira o endereço",
        "*Please enter email":"*Insira o e-mail",
     //Gallery
        "Please upload here images of the general space (inside and outsides).":"Por favor carregue aqui imagens do espaço geral (interior e exterior).",
        "This gallery will appear like a preview of your establishment, so the user can get to know the general appearance and conditions of the space.":"Esta galeria aparecerá como uma prévia do seu estabelecimento, para que o usuário possa conhecer o aspecto geral e as condições do espaço.",
        "No images added yet.":"Nenhuma imagem adicionada ainda.",
    //Button
        "Edit":"Editar",
        "Cancel":"Cancelar",
        "Save":"Salvar",
        "Upload":"Carregar"
}