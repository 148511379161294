export const enCustomerSignUp = {
    "Welcome to Petzai":"Welcome to Petzai",
    "Explore and access all kinds of service":"Explore and access all kinds of service",
    "fit best for your pet.":"fit best for your pet.",
    "Please tell us about yourself": "Please tell us about yourself",
    "Now let's talk about your pet":"Now let's talk about your pet",
    "Your animal is a:":"Your animal is a:",
    //FIELD
    "Name":"Name",
    "Country":"Country",
    "City":"City",
    "Email":"Email",
    "Phone":"Phone",
    "Password":"Password",
    "Confirm Password":"Confirm Password",
    "Pet name":"Pet name",
    "Breed":"Breed",
    "Birth Date":"Birth Date",
    "Gender":"Gender",
    "Fur":"Fur",
    "Size":"Size",
    "Weight":"Weight",
    "Species":"Species",
    //ERROR
    "*Please Enter Name":"*Please Enter Name",
    "User Name should only contain letters":"User Name should only contain letters",
    "*Please Select Country":"*Please Select Country",
    "*Please Select City":"*Please Select City",
    "*Please Enter Email":"*Please Enter Email",
    "Invalid email address":"Invalid email address",
    "*Your Enter Phone":"*Your Enter Phone",
    "*Please Enter Password.":"*Please Enter Password.",
    "Password should include at least 8 characters, one number, and one letter.":"Password should include at least 8 characters, one number, and one letter.",
    "*Please Enter Confirm Password.":"*Please Enter Confirm Password.",
    "*Passwords does not match.":"*Passwords does not match.",
    "*Please Enter Pet's Name":"*Please Enter Pet's Name",
    "*Please Enter Your Pet's Breed":"*Please Enter Your Pet's Breed",
    "*Please Enter Your Pet's BirthDate":"*Please Enter Your Pet's BirthDate",
    "*Please Select Gender":"*Please Select Gender",
    "*Please Select Fur":"*Please Select Fur",
    "*Please Select Size":"*Please Select Size",
    "*Please Select Weight":"*Please Select Weight",
    "*Please Select Species":"*Please Select Species",
    //PLACEHOLDER
    "Your name":"Your name",
    "Your country":"Your country",
    "Your city":"Your city",
    "Your email address":"Your email address",
    "Your pet's name":"Your pet's name",
    "Your pet's Breed":"Your pet's Breed",
    "Fur length":"Fur length",
    "Select a range":"Select a range",
    "Your pet's birthdate":"Your pet's birthdate",
    "Your pet's species":"Your pet's species",
    //ANIMAL
    "Dog":"Dog",
    "Cat":"Cat",
    "Other":"Other",
    "Rabbit/Rodent":"Rabbit/Rodent",
    "Reptile":"Reptile",
    "Bird":"Bird",
    "Fish":"Fish",
    //DROPDOWN
    "Male":"Male",
    "Female":"Female",
    "Hairless":"Hairless",
    "Short Coat":"Short Coat",
    "Medium Coat":"Medium Coat",
    "Long Coat":"Long Coat",
    "Toy (Less than 30 cm)":"Toy (Less than 30 cm)",
    "Small (30-44 cm)":"Small (30-44 cm)",
    "Medium (45-64 cm)":"Medium (45-64 cm)",
    "Large (65-89 cm)":"Large (65-89 cm)",
    "Less then 5 kg":"Less then 5 kg",
    "Rabbit":"Rabbit",
    "Genui Pig":"Genui Pig",
    "Hamster":"Hamster",
    "Rat":"Rat",
    "Gerbil":"Gerbil",
    "Dragon":"Dragon",
    "Snake":"Snake",
    "Gecko":"Gecko",
    "Turtles":"Turtles",
    //BUTTON
    "Next":"Next",
    "Cancel":"Cancel",
    "Back":"Back",
    "Register":"Register"
}

export const ptCustomerSignUp = {
    "Welcome to Petzai":"Bem-vindo à PETZAI",
    "Explore and access all kinds of service":"Explorar e aceder a todos os tipos de serviços",
    "fit best for your pet.":"mais adequado para o teu animal de estimação",
    "Please tell us about yourself": "Por favor, fala-nos mais sobre ti",
    "Now let's talk about your pet":"Agora vamos falar do teu animal de estimação",
    "Your animal is a:":"O teu animal é",
    //FIELD
    "Name":"Nome",
    "Country":"País",
    "City":"Cidade",
    "Email":"E-mail",
    "Phone":"Telemóvel",
    "Password":"Palavra-passe",
    "Confirm Password":"Confirma a palavra-passe",
    "Pet name":"Nome do animal",
    "Breed":"Raça",
    "Birth Date":"Data de nascimento",
    "Gender":"Género",
    "Fur":"Pelo",
    "Size":"Tamanho",
    "Weight":"Peso",
    "Species":"Espécies",
    //ERROR
    "*Please Enter Name":"*Por favor introduzir o nome",
    "User Name should only contain letters":"O nome de usuário deve conter apenas letras",
    "*Please Select Country":"*Por favor selecionar o país",
    "*Please Select City":"*Por favor selecionar a cidade",
    "*Please Enter Email":"*Por favor introduzir o e-mail",
    "*Your Enter Phone":"*Por favor introduzir o número de contacto",
    "*Please Enter Password.":"*Por favor, digite a senha.",
    "Password should include at least 8 characters, one number, and one letter.":"A palavra-passe deve incluir no mínimo 8 caracteres, um número e uma letra",
    "*Please Enter Confirm Password.":"*Por favor, insira a confirmação da senha.",
    "*Passwords does not match.":"*As palavras passe não combinam",
    "*Please Enter Pet's Name":"*Introduzir o nome do animal de estimação",
    "*Please Enter Your Pet's Breed":"*Introduzir a raça do teu animal de estimação",
    "*Please Enter Your Pet's BirthDate":"*Introduzir a data de nascimento do teu animal de estimação",
    "*Please Select Gender":"*Selecionar o género",
    "*Please Select Fur":"*Selecionar a tipo de pelo",
    "*Please Select Size":"*Selecionar o tamanho",
    "*Please Select Weight":"*Selecionar o peso",
    "*Please Select Species":"*Selecione a espécie",
    //PLACEHOLDER
    "Your name":"O teu nome",
    "Your country":"O teu país",
    "Your city":"A tua cidade",
    "Your email address":"O teu endereço de e-mail",
    "Your pet's name":"O nome do teu animal",
    "Your pet's Breed":"A raça do teu animal de estimação",
    "Fur length":"Comprimento do pelo",
    "Select a range":"Selecionar um intervalo",
    "Your pet's birthdate":"Data de nascimento do teu animal de estimação",
    "Your pet's species":"A espécie do seu animal de estimação",
    //ANIMAL
    "Dog":"Cão",
    "Cat":"Gato",
    "Other":"Outro",
    "Rabbit/Rodent":"Coelho/Roedores",
    "Reptile":"Réptil",
    "Bird":"Pássaro",
    "Fish":"Peixe",
    //DROPDOWN
    "Male":"Macho",
    "Female":"Fêmea",
    "Hairless":"Hairless",
    "Short Coat":"Short Coat",
    "Medium Coat":"Medium Coat",
    "Long Coat":"Long Coat",
    "Toy (Less than 30 cm)":"Toy (Less than 30 cm)",
    "Small (30-44 cm)":"Small (30-44 cm)",
    "Medium (45-64 cm)":"Medium (45-64 cm)",
    "Large (65-89 cm)":"Large (65-89 cm)",
    "Less then 5 kg":"Less then 5 kg",
    "Rabbit":"Rabbit",
    "Genui Pig":"Genui Pig",
    "Hamster":"Hamster",
    "Rat":"Rat",
    "Gerbil":"Gerbil",
    "Dragon":"Dragon",
    "Snake":"Snake",
    "Gecko":"Gecko",
    "Turtles":"Turtles",
    //BUTTON
    "Next":"Próximo",
    "Cancel":"Cancelar",
    "Back":"Atrás",
    "Register":"Cadastre-se"
}