import { Box, Button, styled, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { noAwards } from '../../assets';

type Award = {
    coupon_image: string | undefined;
    title: string;
    description: string;
    code: string;
    created_at: string;
    attributes: any;
    CurrentAwards: any

}

type MyAwardsData = {
    awards: CurrentAwards[],
    previous_awards: PastAwards[]
}

export type CurrentAwards = {
    attributes: CouponAttributes;
}

export type CouponAttributes = {
    title: string;
    description: string;
    code: string;
    points: number;
    disclaimer: string;
    activated: boolean;
    created_at: string;
    coupon_image: string;
}

export type PastAwards = {

}

type Props = {
    currentAwards: Award[],
    pastAwards: Award[],
    translate: (value: string) => string;
}

type AwardProps = {
    award: Award,
    translate: (value: string) => string;
    handleCopyToClipboard: (val: string) => void;
}

const AwardItem = ({ award, translate, handleCopyToClipboard }: AwardProps) => {
    return (
        <Box className='award-box'>
            <img src={award.coupon_image} className='box-img' />
            <Typography className='card-title'>{award.title}</Typography>
            <Typography className='card-desc'>{award.description}</Typography>
            <Typography className='card-code'>{award.code}</Typography>
            <Button className='card-copy' data-test-id="handleCopy" onClick={() =>
                handleCopyToClipboard(award.code)
            }>{translate("Copy code")}</Button>
            <Typography className='card-valid'>{moment(award.created_at).format("DD/MM/YYYY")}</Typography>
        </Box>
    )
}

const MyAwardsPage = ({ currentAwards, pastAwards, translate }: Props) => {


    const handleCopyToClipboard = (value: string) => {
        navigator.clipboard.writeText(value);
    }
    return (
        <Wrapper>
            <Typography className='header-text'>{translate("Your awards")}</Typography>
            <Box className='awards-box-wrapper'>
                {Array.isArray(currentAwards) && currentAwards.length > 0 ? (
                    currentAwards.map(award => (
                        <AwardItem award={award?.attributes} handleCopyToClipboard={handleCopyToClipboard} translate={translate} data-test-id="award-item" />
                    ))
                ) : (
                    <Box className='no-awards-box'>
                        <img src={noAwards} />
                        <Typography className='no-awards-text'>{translate("No awards")}</Typography>
                    </Box>
                )}
            </Box>

            <Typography className='header-text'>{translate("Previous awards")}</Typography>
            <Box className='awards-box-wrapper' data-test-id='prev-awards'>
                {Array.isArray(pastAwards) && pastAwards.length > 0 ? (
                    pastAwards.map(award => (
                        <AwardItem award={award} handleCopyToClipboard={handleCopyToClipboard} translate={translate} />
                    ))
                ) : (
                    <Box className='no-awards-box'>
                        <img src={noAwards} />
                        <Typography className='no-awards-text'>{translate("No awards")}</Typography>
                    </Box>
                )}
            </Box>
        </Wrapper>
    )
}

const Wrapper = styled(Box)(({ theme }) => ({
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',

    "& .no-awards-box": {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        alignItems: 'center',
        justifyContent: 'center',
    },

    "& .no-awards-text": {
        fontSize: '18px',
        fontWeight: 600,
        color: '#B7B7B7'
    },

    "& .box-img": {
        width: '100%',
        height: 'auto'
    },

    "& .card-code": {
        marginTop: '16px',
        color: '#6F05EA',
        fontWeight: 700,
        fontSize: '24px'
    },

    "& .card-copy": {
        color: '#fff',
        fontWeight: 600,
        fontSize: '16px',
        cursor: 'pointer'
    },

    "& .card-valid": {
        marginTop: '16px',
        color: '#6F05EA',
        fontWeight: 600,
        fontSize: '14px'
    },

    "& .card-desc": {
        color: '#fff',
        fontSize: '16px',
        fontWeight: 600
    },

    "& .card-title": {
        marginTop: '8px',
        fontSize: '24px',
        fontWeight: 600
    },

    "& .header-text": {
        fontWeight: 600,
        fontSize: '30px'
    },

    "& .awards-box-wrapper": {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '42px',
        [theme.breakpoints.down(660)]: {
            justifyContent: 'center'
        },
    },

    "& .award-box": {
        width: '304px',
        maxWidth: '304px',
        background: '#E2CDFB',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 18px',
        alignItems: 'center'
    },

    [theme.breakpoints.down(660)]: {
        marginTop: '20px'
    },
}))

export default MyAwardsPage;