import { Box } from '@material-ui/core';
import React from 'react'
import Field from '../Field';
import { GenericFormikProps } from '../../types';
export const configJSON = require("../../config.js");


const RecurrenceChange = ({ 
    event = {
        id: 0,
        sub_services_price_id: undefined,
        shift: '',
        start_date: '',
        end_date: '',
        price: '',
        total_price: '',
        medication: '',
        duration: null,
        start_time: null,
        transportation: '',
        medication_request: false,
        transportation_request: false,
        sub_service: {
            id: 0,
            title: '',
            location: '',
            images: [],
            duration: null
        },
        service: {
            id: 0,
            service_type: ''
        },
        sub_services_price: {
            id: 0,
            price: 0,
            title: undefined
        },
        service_provider: {
            id: 0,
            name: '',
            email: ''
        },
        customer: {
            id: 0,
            name: '',
            email: ''
        },
        request_details: [],
        pet: {
            data: {
                id: 0,
                attributes: {
                    pet_category: '',
                    gender: '',
                    breed: '',
                    birthdate: '',
                    weight: '',
                    size: '',
                    account_id: 0,
                    fur: '',
                    species: '',
                    pet_photo: null
                }
            }
        },
        employee: {
            id: null,
            name: null
        },
        establishment: {
            id: 0,
            establishment_name: ''
        },
        payment_status: '',
        customer_image: ''
    }, 
    errors, 
    recurranceData, 
    touched, 
    getFieldProps, 
    setFieldValue, 
    values, 
    renderCustomField, 
    handleBlur 
}: GenericFormikProps) => { 
    
    const getOldRecurrence = () => {
        if (event?.week_days?.length) {
            return configJSON.reccurenceList?.[event.week_days.length - 1]?.title || "";
        }
        return "";
    };

    const handleRecurrenceChange = (event: any) => {
        if (!event || !event.target) return;
        const selectedValue = event.target.value;
        const selectedIndex = recurranceData.findIndex((item: { id: any; }) => item.id == selectedValue);
        const reversedIndex = recurranceData.length - selectedIndex;
        setFieldValue("recurrence", selectedValue);
        setFieldValue("reversedIndex", reversedIndex);
    };

    return (
        <Box className="form-row">
            <Box style={{ width: '100%' }}>
                <Field title="Old Recurrence" value={getOldRecurrence()} withOpacity />
            </Box>
            <Box style={{ position: "relative", width: '100%' }}>
                {renderCustomField({
                    handleBlur,
                    errors,
                    field: "recurrence",
                    disabledField: "isEditRequestChange",
                    values,
                    list: recurranceData ? recurranceData.map((item: { title: any; id: any; }, index: any) => {
                        return {
                            label: item.title,
                            value: item.id
                        };
                    }) : [],
                    touched,
                    handleChange: handleRecurrenceChange,
                    getFieldProps,
                    editLabel: {
                        enabled: true,
                        styles: { fontSize: "12px" },
                    },
                })}
            </Box>
        </Box>
    );
};



export default RecurrenceChange;