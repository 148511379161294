import { Box, Checkbox, styled, Typography } from '@material-ui/core';
import React, { CSSProperties, useState } from 'react';
import { checked, unchecked } from '../assets';
import { Filters } from '../AdvancedSearchController';

type Commodity = {
    label: string,
    value: string
}

type Props = {
    list: Commodity[]
    title: string,
    values: string[],
    field: keyof Filters,
    onToggle: (title: keyof Filters, val: string) => void;
    displayAll?: boolean;
}

const styles = {
    item: {
        display: 'flex',
        alignItems: 'center', 
        gap: '8px',
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontWeight: 600
    },
}

const PageWrapper = styled(Box)(({ theme }) => ({

    display: 'flex', 
    flexDirection: 'column', 
    gap: '12px', 
    paddingTop: '20px',
    paddingBottom: '8px',
    borderTop: '1px solid #C4C4C4',

    "& .less-btn": {
        color: '#6F05EA', fontSize: '13px', fontWeight: 600, textDecoration: 'underline', cursor: 'pointer',
        [theme.breakpoints.up(1200)]: {
            fontSize: '16px',
        },
    },

    "& .filter-label": {
        color: '#6F05EA',
        fontSize: '13px',
        fontWeight: 600,

        [theme.breakpoints.up(1200)]: {
            fontSize: '16px',
        },
    },

    "& .checkbox-label": {
        fontSize: '13px',

        [theme.breakpoints.up(1200)]: {
            fontSize: '16px',
        },
    }
}) )

const CheckboxList = ({ list, title, values, onToggle, field, displayAll }: Props) => {
    const [showAll, setShowAll] = useState(false);

    const toggleShowMore = () => {
        setShowAll(prevShowAll => !prevShowAll); 
    };

    const displayedList = (showAll || displayAll) ? list : list.slice(0, 5);

    return(
        <PageWrapper>
            <Typography className='filter-label'>{title}</Typography>
            {displayedList.map((item, idx) => {
                const isActive = values.includes(item.value);
                return (
                    <Box 
                        id={`ch-list-${item.label}`}
                        key={item.value} 
                        style={{...styles.item, color: isActive ? '#6F05EA' : '#000'}} 
                        onClick={() => onToggle(field, item.value)}
                    >
                        <Checkbox
                            disableRipple
                            icon={ <img src={unchecked} alt="unchecked" /> }
                            checkedIcon={ <img src={checked} alt="checkedIcon" /> }
                            style={{ padding: 0 }}
                            checked={isActive}
                        />
                        <Typography className='checkbox-label'>{item.label}</Typography>
                    </Box>
                )
            })}
            {list.length > 5 && !displayAll && (
                <Typography onClick={toggleShowMore} className='less-btn' data-test-id='more-btn'>
                    {showAll ? 'Less' : 'More'}
                </Typography>
            )}
        </PageWrapper>
    )
}

export default CheckboxList;