import React from "react";
// Customizable Area Start
import { Box, Grid, Button, IconButton, Typography, styled, createTheme, Tabs, Tab } from "@material-ui/core";
import { Backarrow, Catprofile, request, image_paw_24px, image_crop_24px, personpic } from "./assets";
import MenuIcon from '@material-ui/icons/Menu';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AppVendorHeader } from "../../../components/src/AppHeaderVendor.web";
import Sidebar from "./Sidebar.web";
export const configJSON = require("./config");
import FieldsRow from "../../../components/src/FieldsRow.web";
import GenericModal from "../../../components/src/GenericModal";

// Customizable Area End
import RequestDetailsController, { Props } from "./RequestDetailsController";


export default class RequestDetails extends RequestDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderHeaderPart = () => {
    return (
      <DashboardBox>
        <Box className="dashboardHeading">
          <IconButton className="menuIconBtn" onClick={() => { this.openSideBar() }}><MenuIcon /></IconButton>
          <Typography className={this.state.isSideBarOpen ? "tabHeading" : "dashHeading"} variant="body1"></Typography></Box>

      </DashboardBox>
    )
  };
  renderRequestSection = () => {
    return (
      <>
        <RequestSection>
          <Box className="Request-content-logo">
            <Box><img src={request} /></Box>
            <Box><Typography className="request-title">Request</Typography></Box>
          </Box>
          <FieldsRow
            left={
              <Box className="about-details-container">
                <Box>
                  <Typography className="about-lable-Title">
                    Type
                  </Typography>
                  <Typography
                    variant="body1"
                    component="div"
                    className="lable-value"
                  >
                    Change Request
                  </Typography>
                </Box></Box>
            }
            right={
              <Box></Box>
            }

          />
          <FieldsRow
            left={
              <Box className="about-details-container">
                <Box>
                  <Typography className="about-lable-Title">
                    Updated Date
                  </Typography>
                  <Typography
                    variant="body1"
                    component="div"
                    className="lable-value"
                  >
                    30/04/2024
                  </Typography>
                </Box></Box>
            }
            right={
              <Box className="about-details-container">
                <Box>
                  <Typography className="about-lable-Title">
                    Updated Time
                  </Typography>
                  <Typography
                    variant="body1"
                    component="div"
                    className="lable-value"
                  >
                    12:30
                  </Typography>
                </Box></Box>
            }

          />
          <Box className="button-container">
            <Box><Button
              data-test-id={"Btn-EmailLogIn"}
              variant="contained"
              fullWidth
              type="submit"
              className="reject-Btn"
            >
              Reject
            </Button></Box>
            <Box>
              <Button
                data-test-id={"Btn-EmailLogIn"}
                variant="contained"
                fullWidth
                type="submit"
                className="accept-Btn"
              >
                Accept
              </Button>
            </Box>
          </Box>
        </RequestSection>
      </>
    )
  }

  renderAboutSectionContainer = () => {
    return (
      <RequestContainer>
        <AboutTitle className="Request-content-logo">
          <Box><img src={image_paw_24px} /></Box>
          <Box><Typography className="request-title">About</Typography></Box>
        </AboutTitle>
        <Box>
          <GenericModal id='language-modal' isOpen={this.state.isOpenLanguageModal} title="" onClose={() => this.closeModal()}>
            <Box style={{ display: 'flex', height: 50 }}></Box>
            <Box style={styles.settings.modalBody}>
              <Typography style={styles.settings.modalText}>You’re cancelling the appointment, are you sure?</Typography>
              <Box style={{ display: 'flex', gap: '24px', marginTop: 30, width: '100%', justifyContent: 'center' }}>
                <Button
                  data-test-id='cancel-btn-modal'
                  style={{
                    ...styles.actionButtonDefault,
                    border: '1px solid #4B4B4B',
                    color: '#4B4B4B',
                    width: '100%', maxWidth: 253,
                  } as React.CSSProperties}
                  onClick={() => {
                    this.GetCancelAppointment()
                  }}>Yes, I’m sure
                </Button>
                <Button
                  data-test-id='continue-btn'
                  style={{
                    ...styles.actionButtonDefault,
                    border: '1px solid #6F05EA',
                    width: '100%', maxWidth: 253,
                    color: '#6F05EA',
                  } as React.CSSProperties}
                  onClick={() => {
                    this.GetRejectCancelAppointment()
                  }}
                >
                  No, don’t cancel
                </Button>
              </Box>
            </Box>
            <Box style={{ display: 'flex', height: 50 }}></Box>
          </GenericModal>
          <form >
            <FieldsRow
              left={
                <Box className="about-details-container">
                  <Box>
                    <Typography className="about-lable-Title">
                      Animal
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className="lable-value"
                    >
                      Cat
                    </Typography>
                  </Box></Box>
              }
              right={
                <Box className="about-details-container">
                  <Box>
                    <Typography className="about-lable-Title">
                      Gender
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className="lable-value"
                    >
                      Famale
                    </Typography>
                  </Box></Box>
              }
            />

            <FieldsRow
              left={
                <Box className="about-details-container">
                  <Box>
                    <Typography className="about-lable-Title">
                      Breed
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className="lable-value"
                    >
                      Orange Tabby Cat
                    </Typography>
                  </Box></Box>
              }
              right={
                <Box className="about-details-container">
                  <Box>
                    <Typography className="about-lable-Title">
                      Birthdate
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className="lable-value"
                    >
                      09/04/2020
                    </Typography>
                  </Box></Box>
              }
            />
            <FieldsRow
              left={
                <Box className="about-details-container">
                  <Box>
                    <Typography className="about-lable-Title">
                      Weight
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className="lable-value"
                    >
                      25 Kg
                    </Typography>
                  </Box></Box>
              }
              right={
                <Box className="about-details-container">
                  <Box>
                    <Typography className="about-lable-Title">
                      Size
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className="lable-value"
                    >
                      Medium (12-28 kg)
                    </Typography>
                  </Box></Box>
              }

            />

            <FieldsRow
              left={
                <Box className="about-details-container">
                  <Box>
                    <Typography className="about-lable-Title">
                      Fur
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className="lable-value"
                    >
                      Medium coat
                    </Typography>
                  </Box></Box>
              }
              right={
                <></>
              }

            />

          </form>
        </Box>
      </RequestContainer>
    )
  }

  renderAboutSection = () => {
    return (
      <>
        <AboutSection>
          {this.renderAboutSectionContainer()}
        </AboutSection>
      </>
    )
  }

  renderOwnerTab = () => {
    return (
      <>
        <RequestContainer>
          <AboutTitle className="Request-content-logo">
            <Box><img src={image_crop_24px} /></Box>
            <Box><Typography className="request-title">Owner Details</Typography></Box>
          </AboutTitle>
          <AboutTitle className="Request-profile-logo" >
            <Box><img style={{ borderRadius: 100 }} src={personpic} /></Box>
            <Box><Typography className="request-title">Cameron Williamson</Typography></Box>
          </AboutTitle>
          <form style={{ marginTop: 20 }}>
            <FieldsRow
              left={
                <Box className="about-details-container">
                  <Box>
                    <Typography className="about-lable-Title">
                      Address
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className="lable-value"
                    >
                      Rua da Liberdade 34 4000-000
                    </Typography>
                  </Box></Box>
              }
              right={
                <Box className="about-details-container">
                  <Box>
                    <Typography className="about-lable-Title">
                      Country
                    </Typography>
                    <Typography
                      component="div"
                      variant="body1"
                      className="lable-value"
                    >
                      Portugal
                    </Typography>
                  </Box></Box>
              }
            />

            <FieldsRow
              left={
                <Box className="about-details-container">
                  <Box>
                    <Typography className="about-lable-Title">
                      City
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className="lable-value"
                    >
                      Porto
                    </Typography>
                  </Box></Box>
              }
              right={
                <Box className="about-details-container">
                  <Box>
                    <Typography className="about-lable-Title">
                      Zipcode
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className="lable-value"
                    >
                      4000-000
                    </Typography>
                  </Box></Box>
              }
            />
            <FieldsRow
              left={
                <Box className="about-details-container">
                  <Box>
                    <Typography className="about-lable-Title">
                      Phone
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className="lable-value" // Ensure no hover effect
                    >
                      (+351) 123 456 789
                    </Typography>
                  </Box></Box>
              }
              right={
                <></>
              }

            />

          </form>
          
        </RequestContainer>
      </>
    )
  }
  renderGeneralTab = () => {
    return (
      <>
        {this.renderRequestSection()}
        <AboutSection>
          {this.renderAboutSectionContainer()}
        </AboutSection>
        <AboutSection>
          {this.renderOwnerTab()}
        </AboutSection>
      </>
    )
  }
  renderMedicalTab = () => {
    return (
      <Typography>Medical</Typography>
    );
  }
  renderTraitsTab = () => {
    return (
      <Typography>Traits</Typography>
    );
  }
  renderSettingsTabContent = () => {
    switch (this.state.currentSettingsTab) {
      case 0:
        return this.renderGeneralTab();
      case 1:
        return this.renderMedicalTab();
      case 2:
        return this.renderTraitsTab();
      default:
        break;
    }
  }
  renderActionButtons = () => {
    return (
      <>
        {!this.state.iscancelAppointment ?
          <Box>
            {!this.state.isEditGeneral
              ? <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  data-test-id='edit-btn'
                  style={{
                    ...styles.actionButtonDefault,
                    border: '1px solid #6F05EA',
                    width: '100%', maxWidth: 146,
                    color: '#6F05EA',
                  } as React.CSSProperties}
                  onClick={() => this.EditAppointment()}>{configJSON.editBtn}</Button>
              </Box>
              : <Box style={{ display: 'flex', justifyContent: 'flex-end', gap: '17px' }}>
                <Button
                  data-test-id='cancel-btn'
                  style={{
                    ...styles.actionButtonDefault,
                    fontSize: 18, fontWeight: 700,
                    fontFamily: 'Montserrat',
                    width: '100%', maxWidth: 253,
                    border: '1px solid #4B4B4B',
                    color: '#4B4B4B',
                  } as React.CSSProperties}
                  onClick={() => this.EditAppointment()}>
                  {configJSON.cancelBtn}
                </Button>
                <Button
                  data-test-id='save-btn'
                  style={{
                    ...styles.actionButtonDefault,
                    border: '1px solid #6F05EA',
                    color: '#6F05EA',
                    fontSize: 18, fontWeight: 700,
                    fontFamily: 'Montserrat',
                  } as React.CSSProperties}
                  type="submit"
                  onClick={() => this.closeModal()}
                >
                  Cancel Appointment
                </Button>
              </Box>
            }
          </Box> : <Box></Box>
        }
      </>
    )
  }
  renderSettingsBlock = () => {
    return (
      <Box style={{ width: '100%', textAlign: 'left', marginTop: 25 }}>
        <Tabs

          variant="scrollable"
          scrollButtons="auto"
          value={this.state.currentSettingsTab}
          data-test-id="settings-tabs"
          onChange={(_, idx) => this.setTabId(idx)}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#6F05EA',
            },
          }}
        >
          <Tab label={configJSON.general} className="custom-tab" />
          <Tab label={configJSON.medical} className="custom-tab" />
          <Tab label={configJSON.traits} className="custom-tab" />
        </Tabs>
        {this.renderSettingsTabContent()}

      </Box>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <><AppVendorHeader onMenuSelect={function (selected: string): void { }} />
        <RequestSectionBox>
          <Box className={this.state.isSideBarOpen ? "sideBarOpen" : "sideBar"}>
            <Box style={{ display: 'flex', width: "100%" }}>
              <Sidebar />
            </Box>
          </Box>;

          <Box className="mainSection">
            <Grid item xs={12} sm={4} md={3} lg={3} xl={3} >
              {this.renderHeaderPart()}
            </Grid>
            <Grid item xs={12} sm={8} md={9} lg={9} xl={9} style={{ width: '100%', display: 'flex' }} >
              <Box className="grid-main-content">

                <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <Box style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    <Typography style={{ display: 'flex', width: 16, height: 27, alignSelf: 'center' }}><img src={Backarrow} /></Typography>
                    <Typography style={{ color: '#0F172A', fontSize: 40, fontWeight: 600, fontFamily: 'Montserrat', marginLeft: 20 }}>Goldie Day Care</Typography>
                  </Box>
                  <Box style={{ width: 437, height: 148, display: 'flex', flexDirection: 'row', marginTop: 25 }}>
                    <Box><img src={Catprofile} /></Box>
                    {!this.state.iscancelAppointment ?
                      <Box style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginTop: 8 }}>
                        <Typography className="profile-title">Goldie</Typography>
                        <Typography className="profile-date">Date: 01/05/2024</Typography>
                        <Typography className="profile-date">Time: 10 am to 11 am</Typography>
                        <Typography className="profile-date">Price: 37€</Typography>
                      </Box> : <Box className="Cancel-Appointment-box">
                        <Typography className="profile-title">Goldie</Typography>
                        <Typography style={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: 16, color: '#C10000' }}>Appointment is cancelled</Typography></Box>
                    }

                  </Box>
                </Box>
                <PageWrapper >
                  <Box>
                    {this.renderSettingsBlock()}
                  </Box>
                  <Box style={{ marginTop: '40px' }}>
                    {this.renderActionButtons()}
                  </Box>
                </PageWrapper>

              </Box>
            </Grid>
          </Box>
        </RequestSectionBox>
      </>

    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

const PageWrapper = styled(Box)({
  "& *": {
    boxSizing: 'border-box',
  },

  "& .MuiTypography-root.MuiTypography-body1": {
    fontFamily: 'Montserrat',
  },

  '& .MuiInput-underline.Mui-disabled': {
    '&::before': {
      borderBottomStyle: 'solid'
    }
  },

  '& .MuiInputBase-root.Mui-disabled': {
    color: 'inherit'
  },

  '& .MuiInputBase-input.MuiInput-input': {
    "&::placeholder": {
      color: '#A6A6A6',
      fontSize: '13px',
      opacity: 1,
      fontWeight: 400,
      fontFamily: 'Montserrat',
    }
  },

  "& .custom-tab": {
    color: '#B7B7B7',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 500,
    flex: 1,
    textTransform: 'none',
    maxWidth: 322, width: '100%', padding: 12, gap: '6px'
  },
  "& .custom-tab.Mui-selected": {
    fontWeight: 700,
    color: '#6F05EA',
    borderBottom: '3px solid #6F05EA'
  },
  "& .vertical-tab.Mui-selected": {
    color: '#6F05EA',
    fontWeight: 600,
  },
  "& .vertical-tab": {
    color: '#808080',
    fontSize: '14px',
    fontWeight: 600,
    flex: 1,
    textTransform: 'none',
    position: 'relative',
    fontFamily: 'Montserrat',

    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      top: 0,
      left: 0,
      width: '1px',
      backgroundColor: '#000',
    },
  },

  "& .MuiSwitch-thumb": {
    boxSizing: 'border-box',
    height: 22,
    width: 22,

  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    transitionDuration: '300ms',
    margin: 2,
    '&.Mui-checked': {
      color: 'rgba(111, 5, 234, 1)',
      transform: 'translateX(27px)',
      '& + .MuiSwitch-track': {
        backgroundColor: 'rgba(111, 5, 234, 0.2)',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
  },
  "& .MuiListItem-button": {
    '&:hover': {
      textTransform: 'none',
      backgroundColor: 'rgba(111, 5, 234, 0.1)',
    },
    fontFamily: 'Montserrat',
  },

  "& .MuiSwitch-root": {
    height: 26,
    width: 54,
    padding: 0,
  },

  "& .MuiSwitch-track": {
    backgroundColor: '#E9E9EA',
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },

  "& .main-menu-wrapper": {
    [theme.breakpoints.up("sm")]: {
      display: 'flex',
    },
    display: 'none',
  },
  "& .custom-svg.active": {
    "& path": {
      fill: '#fff'
    }
  },
  "& .content-box": {
    flex: 1,
    paddingTop: '96px',
    paddingBottom: '96px',
    paddingLeft: '50px',
    paddingRight: '50px',
    background: 'white',
    textAlign: 'center',

    "& .content": {
      maxWidth: '100%',
      [theme.breakpoints.up("sm")]: {
        maxWidth: '965px',
      },
    }
  },


});
type CSSProperties = React.CSSProperties;
type NestedCSSProperties = { [key: string]: CSSProperties | NestedCSSProperties };
const styles: {
  general: {
    flexCol: CSSProperties;
  };
  actionButtonDefault: CSSProperties;
  settings: NestedCSSProperties & { header: CSSProperties; modalText: CSSProperties; modalBody: CSSProperties };
} = {
  general: {
    flexCol: {
      display: 'flex', flexDirection: 'column'
    },
  },
  settings: {
    header: {
      fontWeight: 600,
      fontSize: '40px',
      color: '#000000',
      marginBottom: '10px'
    },
    modalText: {
      fontSize: '24px', fontWeight: 700
    },
    modalBody: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      flexDirection: 'column',
      gap: '30px',
      paddingTop: '20px',
      paddingBottom: '20px',
      maxHeight: 399,
      alignSelf: 'center'
    }
  },

  actionButtonDefault: {
    height: '56px',
    textTransform: 'none',
    borderRadius: '100px',
    width: '265px',
    fontSize: '18px',
    fontFamily: 'Montserrat',
    fontWeight: 700
  }
}

const RequestSectionBox = styled(Box)({
  display: "flex",
  height: "100%",
  flexDirection: 'row',
  background: '#F6F4F9',
  boxSizing: 'border-box',
  "& .Cancel-Appointment-box": {
    marginLeft: 20,
    marginTop: 30
  },
  "& .profile-date": { fontSize: 16, fontWeight: 500 },
  "& .profile-title": { fontSize: 30, fontWeight: 600, fontFamily: 'Montserrat', color: '#000', lineHeight: '36px' }
  , "& .mainSection": {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    overflow: "scroll",
    backgroundColor: "#F6F4F9",
    width: '100%'
  },
  "& .grid-main-content": {
    display: 'flex', width: '100%', flexDirection: 'column',
    margin: 10, padding: 40,
    "@media screen and (max-width:1023px)": {
      marginLeft: '-89px',
      padding: 20,

    },
    "@media screen and (max-width:500px)": {
      margin: 38,
      marginLeft: -69
    },
    "@media screen and (min-width:1025px)": {
      margin: 10,
      padding: 40,
      marginLeft: -133
    },
    "@media screen and (min-width:1440px)": {
      margin: 10,
      padding: 40,
      marginLeft: -170
    },
    "@media screen and (min-width:1650px)": {
      margin: 10,
      padding: 40,
      marginLeft: -241
    }

  },
  "& .tabLogo": {
    display: "none",
    width: "100%",
    paddingTop: "20px"
  },
  "& .tabLogoBox": {
    width: "160px",
    height: "50px"
  },
  "@media screen and (max-width:1024px)": {
    "& .sideBar": {
      display: "none"
    },
    "& .sideBarOpen": {
      display: "block"
    },
    "& .tabLogo": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  },


});
const RequestSection = styled(Box)({
  display: 'flex',
  width: '100%',
  height: 'auto', flexDirection: 'column',
  boxShadow: '0px 4px 8px 0px #00000008',
  marginTop: 20,
  border: '0px 0px 0px 4px',
  padding: '32px 38px 32px 38px',
  gap: '34px',
  borderRadius: 10,
  background: '#fff',
  "& .Request-content-logo": {
    display: 'flex',
    flexDirection: 'row',

  },
  "& .Request-profile-logo": {
    display: 'flex',
    flexDirection: 'row',

  },
  "& .about-lable-Title": {
    color: '#6F05EA',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400
  },
  "& .request-title": {
    fontSize: 18,
    fontFamily: 'Montserrat',
    color: '6F05EA',
    fontWeight: 600, marginLeft: 8, marginTop: -3
  },
  "& .type-title": {
    color: '#6F05EA',
    fontSize: 12,
    fontWeight: 400,
    fontFamily: 'Montserrat'
  },
  "& .lable-value": { marginTop: 10, fontSize: 18, pointerEvents: 'none', fontWeight: 500, fontFamily: 'Montserrat' },

  "& .about-details-container": { flexDirection: 'column', display: 'flex', borderBottom: '1px solid #d9d9d9' }
  ,
  "& .cancel-title": {
    color: '#000',
    fontSize: 18,
    fontWeight: 500,
    fontFamily: 'Montserrat'
  },
  "& .button-container": {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: '10px'
  },
  "& .cancel-box": {
    marginTop: -30
  },
  "& .reject-Btn": {
    border: "1px solid var(--Red, #C10000)",
    borderRadius: "100px",
    padding: "10px 0px 10px 0px",
    height: "40px",
    width: 146,
    fontSize: "18px",
    gap: "10px",
    fontWeight: 700,
    textTransform: "none",
    color: "#C10000",
    backgroundColor: "transparent",
    fontFamily: 'Montserrat'
  },
  "& .accept-Btn": {
    border: '1px solid var(--Primary-color, rgba(111, 5, 234, 1))',
    borderRadius: "100px",
    padding: "10px 0px 10px 0px",
    height: "40px",
    width: 146,
    fontSize: "18px",
    gap: "10px",
    fontWeight: 700,
    textTransform: "none",
    color: "#6F05EA",
    backgroundColor: "transparent",
    fontFamily: 'Montserrat'
  },


})
const AboutSection = styled(Box)({
  width: '100%',
  height: 'auto',
  borderRadius: 10,
  padding: '32px 38px 32px 38px',
  border: '0px 0px 0px 4px',
  gap: '34px',
  background: '#fff',
  boxShadow: '0px 4px 8px 0px #00000008',
  display: 'flex',
  flexDirection: 'row',
  marginTop: 20,
  "& .column-one": {
    display: 'flex',
    flexDirection: 'column'
  },
  "& .column-two": {
    flexDirection: 'column',
    display: 'flex',

  }

})
const AboutTitle = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  "& .Request-content-logo": {
    display: 'flex',
    flexDirection: 'row',

  },
  "& .request-title": {
    fontSize: 18,
    fontFamily: 'Montserrat',
    color: '6F05EA',
    fontWeight: 600,
    marginBottom: 30
  },
});
const RequestContainer = styled(Box)({
  textAlign: 'left', marginTop: '10px', width: '100%',
  "& .about-lable-Title": {
    color: '#6F05EA',
    fontSize: 12,
    fontFamily: 'Montserrat',
    fontWeight: 400
  },
  "& .lable-value": { pointerEvents: 'none', fontSize: 18, fontWeight: 500, fontFamily: 'Montserrat', marginTop: 10 },
  "& .about-details-container": { display: 'flex', flexDirection: 'column', borderBottom: '1px solid #d9d9d9' }

})

const DashboardBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "20px",
  justifyContent: "space-between",
  "& .menuIconBtn": {
    display: "none"
  },
  "& .dashboardHeading": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "& .MuiTypography-body1": {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      fontFamily: "Inter",
      color: "#011342",
    },
  },
  "& .menuIcon": {
    display: "none",
  },
  "& .profileBox": {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    gap: "15px",
    "& .MuiButton-root": {
      fontSize: "16px",
      fontWeight: 700,
      fontFamily: "Inter",
      textTransform: "none",
      color: "white",
      background: "#012275",
      borderRadius: "4px",
      paddingRight: "15px",
      paddingLeft: "15px",
      height: "49px",
    },
  },
  "& .addIcon": {
    paddingRight: "8px",
  },
  "& .bellIconBox": {
    boxShadow: "0px 0px 3px rgba(0,0,0,0.1)",
  },
  "& .profile": {
    boxShadow: "0px 0px 3px rgba(0,0,0,0.1)",
    padding: "8px 25px 8px 8px",
    "& .MuiTypography-body2": {
      fontSize: "14px",
      fontWeight: 600,
      fontFamily: "Inter",
      color: "#011342",
    },
    display: "flex",
    alignItems: "center",
    gap: "5px",
    order: 3,
  },
  "& .userImage": {
    width: "32px",
    height: "32px",
    "& img": {
      borderRadius: "50%",
    },
  },
  "@media screen and (max-width:1024px)": {
    "& .menuIconBtn": {
      display: "block"
    },
    "& .tabHeading": {
      display: "none"
    },
    "& .MuiButton-root": {
      fontSize: "14px",
      order: 2,
    },
    "& .profileBox": {
      "& img": {
        order: 1,
      },
    },
    "& .menuIcon": {
      display: "block",
    },
  },
  "@media screen and (max-width:599px)": {
    "& .dashboardHeading": {
      gap: "5px",
      "& .MuiTypography-body1": {
        fontSize: "20px !important",
        lineHeight: "20px !important",
      },
    },
    "& .profileBox": {
      padding: "5px",
      gap: "10px",
      "& .MuiButton-root": {
        fontSize: "10px !important",
        paddingRight: "5px !important",
        paddingLeft: "5px !important",
        height: "30px !important",
      },
    },
    "& .addIcon": { display: "none" },
    padding: "10px",
    "& .profile": {
      gap: "2px",
      "& .MuiTypography-body2": { display: "none" },
    },
  },
});
// Customizable Area End
