import moment from "moment";
import { Shedule } from "../../../blocks/customisableuserprofiles/src/CustomisableUserProfilesController";
import { Appointment } from "../../../blocks/customisableuserprofiles/src/types";
import { ServiceDurationList } from "../../../blocks/customisableuserprofiles/src/generalInfo/Services.web";
import { Schedule } from "../../../blocks/accountgroups/src/components/ViewEmployeeController.web";

function isNumber(inputStr: string) {
    const regex = /\d+\s*[a-zA-Z]+/;
    
    if (regex.test(inputStr)) {
      return false;  
    } else {
      return true;   
    }
  }

export const getEventStartEndDate = (event: Shedule | Appointment | Schedule) => {
    let start_date = moment().toDate();
    let end_date = moment().toDate();


    if(event) {
        if(event.start_time && (event.duration || event.sub_service.duration)) {
            start_date = moment(event.start_date + "T" + event.start_time, "YYYY-MM-DDTHH:mm").toDate()
            if(event.duration) {
                const isNumberValue = isNumber(event.duration);
                const durationItem = ServiceDurationList.find(el => el.value === event.duration) ;
                end_date = moment(start_date).add(isNumberValue ? event.duration : durationItem?.minutes, 'minutes').toDate();
            } else {
                const durationItem = ServiceDurationList.find(el => el.value === event.sub_service.duration);
                if(durationItem) {
                    end_date = moment(start_date).add(durationItem.minutes, 'minutes').toDate();
                }
            }
        } else {
            start_date = moment(event.start_date, "YYYY-MM-DD").startOf('day').toDate();
            end_date = event.start_date === event.end_date 
                ? moment(event.end_date, "YYYY-MM-DD").startOf('day').toDate() 
                : moment(event.end_date, "YYYY-MM-DD").endOf('day').toDate()
        }
        
    }

    return { start_date, end_date }
}

export const handleGetFTime = (time: Date) => {
    const formattedTime = moment(time).minute() === 0 
    ? moment(time).format("h a")
    : moment(time).format("h.mm a");

    return formattedTime;
}

