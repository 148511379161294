export const enCustomerMenu = {
    // Wallet
    "Wallet": "Wallet",
    "My points":"My points",
    "My awards":"My awards",
    "Trade points": "Trade points",
    "points": "points",
    "tip": "1€ = 1 point",
    "You gained": "You gained",
    "Point history": "Point history",
    "Type": "Type",
    "Title": "Title",
    "Date": "Date",
    "Points": "Points",
    "Your awards": "Your awards",
    "Copy code": "Copy code",
    "Valid until": "Valid until",
    "No awards": "No awards",
    "Trade your points": "Trade your points",
    "Redeem offer": "Redeem offer",
    "will be discounted from your wallet.": "will be discounted from your wallet.",
    "Current wallet": "Current wallet",
    "After redeeming": "After redeeming",
    "Cancel": "Cancel",
    "Proceed": "Proceed",
    "Do you wish to proceed?": 'Do you wish to proceed?',
    "Hint": "This coupon is valid for reservations of services in the categories of Hotel and Daycare. Once redeemed, it is valid for one year. You will receive a discount code that can be applied at checkout, given the conditions already listed."
} 

export const ptCustomerMenu = {
    // Wallet
    "Wallet": "Wallet",
    "My points":"My points",
    "My awards":"My awards",
    "Trade points": "Trade points",
    "points": "points",
    "tip": "1€ = 1 point",
    "You gained": "You gained",
    "Point history": "Point history",
    "Type": "Type",
    "Title": "Title",
    "Date": "Date",
    "Points": "Points",
    "Your awards": "Your awards",
    "Copy code": "Copy code",
    "Valid until": "Valid until",
    "No awards": "No awards",
    "Trade your points": "Trade your points",
    "Redeem offer": "Redeem offer",
    "Current wallet": "Current wallet",
    "After redeeming": "After redeeming",
    "Cancel": "Cancel",
    "Proceed": "Proceed",
    "Do you wish to proceed?": 'Do you wish to proceed?',
    "will be discounted from your wallet.": "will be discounted from your wallet.",
    "Hint": "This coupon is valid for reservations of services in the categories of Hotel and Daycare. Once redeemed, it is valid for one year. You will receive a discount code that can be applied at checkout, given the conditions already listed."
} 