import React from 'react';
import { Box, Typography } from "@material-ui/core"
import { Request, Shedule } from "../CustomisableUserProfilesController";
import { Portrait } from '../assets';
import Field from "./Field";
import moment from "moment";
import DaySelect from './ChangeRequestModals/DaySelect';
import { transPt } from '../../../../components/src/HelperUtils';
const configJSON = require("../config");


type Props = {
    request: Request | null;
    selectedEvent: Shedule;
    fieldList: Record<any, boolean>;
}


const DisplayAppointmentRecurrenceFields = ({ request, selectedEvent, fieldList }: Props) => {
    const mockProps = {
        event: selectedEvent, 
        values: {}, 
        errors: {}, 
        setFieldValue: (field: string, value: any) => Promise.resolve(), 
        isDisabled: true,
        touched: {}, 
      };
    
    const getReccurenceLabel = (value: string | undefined) => {
        
        if(value) {
            const element = configJSON.reccurenceList.find((item: any) => item.title === value);
            if(element) {
                return element.title;
            }
        }
    }

    return (
        <Box className='section-wrapper'>
            <Box className='section-header'>
                <img src={Portrait} />
                <Typography className='section-text'>{transPt("Appointment Details", "customerUserProfile")}</Typography>
            </Box>
            <Box className='fields-wrapper'>
                <Box className='field-set'>
                    <Field title={fieldList.endDate ? transPt("Start Date", "customerUserProfile") : transPt("Date", "customerUserProfile")} value={moment(selectedEvent.start_date).format('DD/MM/YYYY')}/>
                    <Field title={transPt("Price", "customerUserProfile")} value={`${selectedEvent.total_price}€`}/>
                    {fieldList.reccurence && <Field title={transPt("Recurrence", "customerUserProfile")} value={getReccurenceLabel(selectedEvent.sub_services_price.title)} />}
                    {fieldList.days && <DaySelect responseData={undefined} recurranceData={undefined} isDisplay {...mockProps} label={transPt("Day Of The Week", "customerUserProfile")}/>}
                </Box>
                <Box className='field-set'>
                    {fieldList.endDate && <Field title={transPt("End Date", "customerUserProfile")} value={moment(selectedEvent.end_date).format('DD/MM/YYYY')} />}
                    {fieldList.time && <Field title={transPt("Time", "customerUserProfile")} value={selectedEvent.shift} />}
                    {fieldList.timeGrooming && <Field title={transPt("Time", "customerUserProfile")} value={moment(selectedEvent.start_time, "HH:mm").format("hh:mm A")} />}
                    <Field title={transPt("Service Type", "customerUserProfile")} value={selectedEvent.service.service_type}/>
                </Box>
            </Box>
        </Box>
    )
}

export default DisplayAppointmentRecurrenceFields;